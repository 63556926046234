import { forwardRef, useId, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import UiUtils from '../utils/UiUtils';

import IconTooltip from './IconTooltip';

const SelectDropdown = forwardRef((props, ref) => {
    const { id, variant, label, options, selectedOption, isDisabled, onChange, tooltip, required, help, helpColor, showHelp } = props;

    const styleVariant = variant ? variant : "default";

    let borderColour = "border-grey01";
    let chevronColour = "text-grey05";
    let buttonBgColour = "bg-grey04";
    let textFont = "font-vg-book";
    let textColour = "text-grey";
    let activeTextColour = "text-black";

    if (styleVariant === "blue") {
        borderColour = "border-blue";
        chevronColour = "text-blue";
        buttonBgColour = "bg-white";
        textFont = "font-vg-regular";
        textColour = "text-blue";
        activeTextColour = "text-blue";
    }

    const newId = useId();
    const selectId = id ? id : newId;

    const useLabel = required ? `<span class="text-grey">${label}</span><span class="text-red">&nbsp;&nbsp;*</span>` : label;

    function handleChange(selection) {
        if (onChange) {
            onChange(selection);
        }
    }

    const buttonClass = UiUtils.classNames(isDisabled ? "border-grey03" : borderColour, `relative w-full h-[50px] flex justify-between gap-3 items-center px-3 cursor-default border rounded-md ${buttonBgColour}`);
    const chevronClass = isDisabled ? "h-6 w-6 text-grey03" : `h-6 w-6 ${chevronColour}`;

    return(
        <div>
            <Listbox ref={ref} value={selectedOption} disabled={isDisabled} onChange={handleChange}>
                {({ open }) => (
                    <div>
                        {label && (
                            <>
                            {tooltip ? (
                                <div className="w-full flex items-start justify-between gap-2">
                                    <Listbox.Label className={`${textFont} ${textColour} text-base leading-120 mb-1.5 block`} dangerouslySetInnerHTML={{__html: useLabel}}/>
                                    <IconTooltip content={tooltip} position="top"/>
                                </div>
                            ) : (
                                <Listbox.Label className={`${textFont} ${textColour} text-base leading-120 mb-1.5 block`} dangerouslySetInnerHTML={{__html: useLabel}}/>
                            )}
                            </>
                        )}
                        <div className="relative">
                            <Listbox.Button className={buttonClass}>
                                <p className={`${textFont} ${textColour} text-base leading-130 truncate`}>{selectedOption && selectedOption.label ? selectedOption.label : ""}</p>
                                <ChevronDownIcon className={chevronClass} aria-hidden="true"/>
                            </Listbox.Button>
                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className={`absolute z-10 mt-1.5 max-h-60 w-full overflow-auto ${textFont} text-base leading-130 rounded-lg bg-white shadow-listbox-options focus:outline-none`}>
                                    {options.map((opt, index) => (
                                        <Listbox.Option
                                            key={`${selectId}-option-${index}`}
                                            className={({ selected, active }) => UiUtils.classNames(selected || active ? `${activeTextColour} bg-blue03` : `${textColour} bg-white`, "h-12 relative cursor-default select-none px-4 py-[15px]")}
                                            value={opt}
                                        >
                                            <span className="block truncate">
                                                {opt.label}
                                            </span>
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </div>
                )}
            </Listbox>
            {(help || showHelp === "always") && (
                <p className={`font-vg-book text-xs2 leading-[0.975rem] mt-1.5 h-[0.975rem] ${helpColor ? helpColor : "text-grey"}`} dangerouslySetInnerHTML={{__html: help}}/>
            )}
        </div>
    );
});

export default SelectDropdown;