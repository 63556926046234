import { ExclamationTriangleIcon, CheckIcon } from '@heroicons/react/24/solid';

export default function ViewOnlyStrap({ editing }) {
   return (
      <>
         {editing && (
         <div className="w-full flex items-center flex-start gap-3 p-4 bg-green03 border-l-4 border-green">
            <CheckIcon className="w-5 h-5 text-dark-green"/>
            <p className="font-vg-regular text-sm leading-5 text-dark-green">You can edit the data on this page.</p>
         </div>
         )}
         {!editing && (
         <div className="w-full flex items-center flex-start gap-3 p-4 bg-yellow04 border-l-4 border-dark-yellow01">
            <ExclamationTriangleIcon className="w-5 h-5 text-dark-yellow01"/>
            <p className="font-vg-regular text-sm leading-5 text-dark-yellow02">This page is in view only mode, press edit to change data.</p>
         </div>
         )}
      </>
   );
}