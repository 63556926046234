import { useId } from 'react';
import DOMPurify from 'dompurify';

import * as DashboardConstants from '../constants/DashboardConstants';
import * as UiConstants from '../constants/UiConstants';

import DashboardMoreMenu from './DashboardMoreMenu';
import LexicalRichTextEditor from './LexicalRichTextEditor';

export default function DashboardCanvasLexicalCard({ id, row, col, width, height, details, mode, onCanvasElementAction }) {
   const newId = useId();
   const cardId = id ? id : newId;

   let rowColClass = "";

   let rowStart = Math.min(row, DashboardConstants.CANVAS_ROWS);
   let rowEnd = Math.min(rowStart + height, DashboardConstants.CANVAS_ROWS + 1);
   let colStart = Math.min(col, DashboardConstants.CANVAS_COLS);
   let colEnd = Math.min(colStart + width, DashboardConstants.CANVAS_COLS + 1);

   switch (rowStart) {
      case 1:
         rowColClass = "row-start-1";
         break;
      case 2:
         rowColClass = "row-start-2";
         break;
      case 3:
         rowColClass = "row-start-3";
         break;
      default:
         break;
   }

   switch (colStart) {
      case 1:
         rowColClass = `${rowColClass} col-start-1`;
         break;
      case 2:
         rowColClass = `${rowColClass} col-start-2`;
         break;
      case 3:
         rowColClass = `${rowColClass} col-start-3`;
         break;
      case 4:
         rowColClass = `${rowColClass} col-start-4`;
         break;
      default:
         break;
   }

   switch (rowEnd) {
      case 2:
         rowColClass = `${rowColClass} row-end-2`;
         break;
      case 3:
         rowColClass = `${rowColClass} row-end-3`;
         break;
      case 4:
         rowColClass = `${rowColClass} row-end-4`;
         break;
      default:
         break;
   }

   switch (colEnd) {
      case 2:
         rowColClass = `${rowColClass} col-end-2`;
         break;
      case 3:
         rowColClass = `${rowColClass} col-end-3`;
         break;
      case 4:
         rowColClass = `${rowColClass} col-end-4`;
         break;
      case 5:
         rowColClass = `${rowColClass} col-end-5`;
         break;
      default:
         break;
   }

   let outerClass = `flex h-full flex-col items-stretch gap-6 p-6 overflow-hidden ${rowColClass}`;
   outerClass = `${outerClass} ${mode === DashboardConstants.CanvasMode.EDIT ? "rounded-lg bg-grey04 border border-grey03" : "bg-white"}`;

   const onMoreMenuClick = (event) => {
      if (onCanvasElementAction) {
         switch (event.action) {
            case DashboardConstants.ActionType.ELEMENT_MENU_EDIT:
               onCanvasElementAction({
                  action: DashboardConstants.ActionType.ELEMENT_MENU_EDIT,
                  args: {
                     id: event.args.id,
                     row: row,
                     col: col,
                     width: width,
                     height: height
                  }
               });
               break;
            case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
               if (window.confirm("Are you sure you want to delete this story/commentary element?")) {
                  onCanvasElementAction({
                     action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                     args: {
                        id: event.args.id,
                        row: row,
                        col: col
                     }
                  });
               }
               break;
            default:
               break;
         }
      }
   }

   const onChange = (content) => {
      if (onCanvasElementAction) {
         onCanvasElementAction({
            action: DashboardConstants.ActionType.TEXT_UPDATED,
            args: {
               id: details.id,
               text: DOMPurify.sanitize(content)
            }
         });
      }
   }

   return (
      <div key={`canvas-lexical-card-${cardId}-${row}-${col}`} className={outerClass}>
         <div className="flex items-center justify-between grow-0">
            <h6 className="font-vg-regular text-grey text-base leading-130 grow">{details.title}</h6>
            {mode === DashboardConstants.CanvasMode.EDIT && (
               <DashboardMoreMenu
                  items={UiConstants.DASH_MORE_MENU_ITEMS}
                  params={{id: details.id}}
                  onMenuClick={onMoreMenuClick}
               />
            )}
         </div>
         {mode === DashboardConstants.CanvasMode.EDIT ? (
            <div className="grow">
               <LexicalRichTextEditor
                  content={details.text}
                  onChange={onChange}
               />
            </div>
         ) : (
            <div className="font-vg-book grow overflow-hidden" dangerouslySetInnerHTML={{__html: details.text}}></div>
         )}
      </div>
   );
}