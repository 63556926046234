import { useState, useEffect, useMemo, createContext } from 'react';
import { INSERT_TABLE_COMMAND } from '@lexical/table';

import LexicalButton from './LexicalButton';
import LexicalTextInput from './LexicalTextInput';
import { DialogActions } from './LexicalDialog';

export const CellContext = createContext({
   cellEditorConfig: null,
   cellEditorPlugins: null,
   set: () => {
     // Empty
   },
});

export function TableContext({children}) {
   const [contextValue, setContextValue] = useState({
      cellEditorConfig: null,
      cellEditorPlugins: null,
   });
   return (
      <CellContext.Provider
         value={useMemo(
            () => ({
               cellEditorConfig: contextValue.cellEditorConfig,
               cellEditorPlugins: contextValue.cellEditorPlugins,
               set: (cellEditorConfig, cellEditorPlugins) => {
                  setContextValue({cellEditorConfig, cellEditorPlugins});
               },
            }),
            [contextValue.cellEditorConfig, contextValue.cellEditorPlugins],
         )}>
         {children}
      </CellContext.Provider>
   );
}
 

export function InsertTableDialog({ activeEditor, onClose }) {
   const [rows, setRows] = useState('5');
   const [columns, setColumns] = useState('5');
   const [isDisabled, setIsDisabled] = useState(true);

   useEffect(() => {
      const row = Number(rows);
      const column = Number(columns);
      if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
         setIsDisabled(false);
      } else {
         setIsDisabled(true);
      }
   }, [rows, columns]);

   const onClick = () => {
      activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
         columns,
         rows,
      });

      onClose();
   };

   return (
      <>
         <LexicalTextInput
            placeholder={'# of rows (1-500)'}
            label="Rows"
            onChange={setRows}
            value={rows}
            data-test-id="table-modal-rows"
            type="number"
         />
         <LexicalTextInput
            placeholder={'# of columns (1-50)'}
            label="Columns"
            onChange={setColumns}
            value={columns}
            data-test-id="table-modal-columns"
            type="number"
         />
         <DialogActions data-test-id="table-model-confirm-insert">
            <LexicalButton disabled={isDisabled} onClick={onClick}>
               Confirm
            </LexicalButton>
         </DialogActions>
      </>
   );
}
