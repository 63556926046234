export const PageTitle = {
    CHANGE_PASSWORD: "Change Password",
    EMAIL_CHANGE_VERIFICATION: "Email Change Verification",
    FORGOT_PASSWORD: "Forgot Password",
    FORGET_PASSWORD_EMAIL: "Forgot Password - Email Sent",
    FORGOT_PASSWORD_VERIFIED: "Forgot Password - Account Verified",
    LOGIN: "Login",
    LOGIN_VERIFICATION: "Login - Account Verification",
    SET_MEMBER_PASSWORD: "New Member - Set Password",
    SIGN_UP: "Sign Up",
    SIGN_UP_VERIFICATION: "Sign Up - Account Verification",
    ABOUT: "About",
    CONTACT_US: "Contact Us",
    RESOURCE: "Resource",
    DESKTOP_ONLY: "Desktop Only",
    HELP: "Help",
    HOME: "Home",
    BROWSE_RESOURCES: "Browse Resources",
    PRIVACY: "Privacy Policy",
    TERMS: "Terms of Use",
    DASHBOARDS: "Dashboards",
    DATA_MANAGEMENT: "Data Management",
    SOCIAL_ENTERPRISES: "Social Enterprises",
    INDICATORS: "Indicator Pathways",
    ORGANISATIONS: "Organisations",
    PROGRAMS_PROJECTS: "Programs & Projects",
    USER_MANAGEMENT: "User Management"
};

export const PageCategory = {
    USER_ACCOUNT: "User Account"
}

export const Event = {
    LOGIN: "Login",
    SIGN_UP: "Sign Up",
    INDICATOR_STEP: "Indicator Step"
};

export const LoginAction = {
    STANDARD: "Standard",
    TWO_FACTOR: "Two Factor",
    UNSUCCESSFUL: "Unsuccessful"
};

export const SignUpAction = {
    STANDARD: "Standard",
    TRIAL: "Trial Account",
    UNSUCCESSFUL: "Unsuccessful"
};

export const IndicatorAction = {
    DOMAIN_CHOSEN: "Domain Chosen",
    OUTCOME_AREA_CHOSEN: "Outcome Area Chosen",
    INDICATORS_ADDED: "Indicators Added",
    SINGLE_INDICATOR_ADDED: "Single Indicator Added",
    SINGLE_INDICATOR_EDITED: "Single Indicator Edited",
    PATHWAY_REVIEWED: "Pathway Reviewed",
    PATHWAY_SAVED: "Pathway Saved"
}