import axios from 'axios';

let PathwayService = {};

PathwayService.savePathway = (payload) => {
   return axios.post(process.env.REACT_APP_API_URL + '/pathway/v2',
      payload, 
      { withCredentials: true }
   );
};

PathwayService.updatePathway = (payload) => {
   return axios.put(process.env.REACT_APP_API_URL + '/pathway',
      payload, 
      { withCredentials: true }
   );
};

PathwayService.deletePathway = (entityId, pathwayId) => {
   return axios.delete(`${process.env.REACT_APP_API_URL}/pathway/${entityId}/${pathwayId}`,
      { withCredentials: true }
   );
};

export default PathwayService;