import { forwardRef } from 'react';

const RadioButton = forwardRef((props, ref) => {
   const { label, name, value, className, outerClass, checked, isDisabled, onChange, reversed, bold, ...otherProps } = props;
   let labelClass = "flex items-center text-base text-black";
   labelClass = bold ? `font-vg-regular ${labelClass}` : `font-vg-book ${labelClass}`;
   if (className) {
      labelClass = `${labelClass} ${className}`;
   }
   
   const buttonClass = `h-4 w-4 !bg-none shadow-[0_0_0_2px_white_inset] focus:ring-white focus:ring-0 ${isDisabled ? "text-grey01 border-grey01 checked:border-grey01" : "text-blue border-black checked:border-black"}`;

   return (
      <div className={outerClass ? outerClass : "w-full"}>
         <label className={`${labelClass} ${reversed ? "flex-row-reverse justify-between" : "gap-2"}`}>
            <input
               ref={ref} 
               name={name}
               value={value} 
               type="radio" 
               checked={checked}
               disabled={isDisabled}
               className={buttonClass}
               onChange={(e) => {
                  if (onChange) {
                     onChange(e);
                  }
               }}
               { ...otherProps } 
            />
            <span dangerouslySetInnerHTML={{__html: label}}></span>
         </label>
      </div>
   );
});

export default RadioButton;