import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodeToNearestRoot } from '@lexical/utils';
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical';
import { useEffect } from 'react';

import { $createYouTubeNode, LexicalYouTubeNode } from './LexicalYouTubeNode';

export const INSERT_YOUTUBE_COMMAND = createCommand(
  'INSERT_YOUTUBE_COMMAND',
);

export default function LexicalYouTubePlugin() {
   const [editor] = useLexicalComposerContext();

   useEffect(() => {
      if (!editor.hasNodes([LexicalYouTubeNode])) {
         throw new Error('LexicalYouTubePlugin: LexicalYouTubeNode not registered on editor');
      }

      return editor.registerCommand(
         INSERT_YOUTUBE_COMMAND,
         (payload) => {
            const youTubeNode = $createYouTubeNode(payload);
            $insertNodeToNearestRoot(youTubeNode);
            return true;
         },
         COMMAND_PRIORITY_EDITOR,
      );
   
   }, [editor]);

   return null;
}