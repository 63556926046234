import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

export default function HomeCard(props) {
    const { colour, title, text, link, image, imageAlt } = props;

    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isXLargeDesktop = UiUtils.isXLargeDesktop(width);
    const isLargeDesktop = UiUtils.isLargeDesktop(width);
    const isDesktop = !isMobile;

    let cardClass = "box-border w-full flex flex-col justify-start items-stretch h-full";

    let imageClass = "box-border aspect-card-image rounded-3xl border-green border-8 object-cover z-20";
    imageClass = isDesktop ? `${imageClass} mx-8 mt-[-163px] h-60` : `${imageClass} mx-4`;

    let titleClass =  isDesktop ? "font-vg-medium text-[32px] leading-110 min-h-[70px]" : "font-vg-medium text-[28px] leading-110";

    let textClass = "font-vg-book text-base leading-130";
    if (link) {
        textClass = isDesktop ? `${textClass} min-h-[114px]` : textClass;
    } else {
        textClass = isDesktop ? `${textClass} min-h-[154px]` : textClass;
    }

    let bgClass = "absolute inset-x-0 top-0 bottom-[54px] rounded-2xl z-10";

    switch (colour) {
        case "green":
            bgClass = `${bgClass} bg-light-green02`;
            break;
        default:
            break;
    }
    if (isXLargeDesktop) {
        cardClass = `${cardClass} min-h-[450px]`;
    } else if (isLargeDesktop) {
        cardClass = `${cardClass} min-h-[504px]`;
    } else if (isDesktop) {
        cardClass = `${cardClass} min-h-[564px]`;
    }

    return (
        <div className="box-border relative w-full h-full">
            <div className="flex flex-col items-stretch h-full">
                <div className={cardClass}>
                    <div className="flex flex-col items-start text-black gap-4 py-8 px-[42px] z-20 h-full">
                        <h4 className={titleClass}>{title}</h4>
                        <p className={textClass}>{text}</p>
                        {link && (
                            <a href={link} className="font-vg-regular text-base">Learn more</a>
                        )}
                    </div>
                </div>
                <img className={imageClass} src={image} alt={imageAlt}/>
            </div>
            <div className={bgClass}></div>
        </div>
    );
}