import UiUtils from '../utils/UiUtils';

import IconTooltip from "./IconTooltip";
import RadioButton from "./RadioButton";

export default function IndicatorDomainButton({ label, need, domain, color, state, tipContent, tipPosition, onChange }) {
    let outerClass = "flex items-center justify-between gap-4 w-full p-3 rounded-lg text-left";
    let iconOuterClass = "flex items-center justify-center w-[62px] h-[62px] rounded-md grow-0 shrink-0";
    let iconInnerClass = "w-8 h-8";

    switch (color) {
        case "purple":
            iconOuterClass = `${iconOuterClass} bg-purple03`;
            iconInnerClass = `${iconInnerClass} text-purple01`;
            break;
        case "yellow":
            iconOuterClass = `${iconOuterClass} bg-yellow03`;
            iconInnerClass = `${iconInnerClass} text-yellow01`;
            break;
        default:
            break;
    }

    switch (state) {
        case "default":
            outerClass = `${outerClass} bg-white border border-grey03`;
            break;
        case "selected":
            outerClass = `${outerClass} bg-blue03 border border-blue`;
            break;
        case "unselected":
            outerClass = `${outerClass} bg-white border border-grey03 opacity-30`;
            break;
        default:
            break;
    }

    const Icon = UiUtils.getDomainIcon(domain);

    return (
        <button 
            className={outerClass} 
            onClick={(_) => {
                if (onChange) {
                    onChange(need, domain, state);
                }
            }}
        >
            <div className={iconOuterClass}>
                <Icon className={iconInnerClass}/>
            </div>
            <div className="flex items-center grow justify-between">
                <p className="font-vg-regular text-sm leading-120 grow">{label}</p>
                {tipContent && (
                    <IconTooltip content={tipContent} position={tipPosition ? tipPosition : "top"}/>
                )}
            </div>
            <RadioButton 
                checked={state === "selected"} 
                outerClass="grow-0"
                onChange={(_) => {
                    if (onChange) {
                        onChange(need, domain, state);
                    }
                }}
            />
        </button>
    );
}