import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';

import * as DataConstants from '../constants/DataConstants';
//import DataUtils from '../utils/DataUtils';

import Button from './Button';
import CustomReactDatePicker from './CustomReactDatePicker';

var weekOfYear = require('dayjs/plugin/weekOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var utc = require('dayjs/plugin/utc');

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);

export default function DataPeriodModal({ frequency, open, onSet, onClose }) {

    const [date, setDate] = useState(null);

    let title = "Set Date";
    let label = "Choose a date you want to add data for.";

    switch (frequency) {
        case DataConstants.FrequencyType.WEEKLY:
            title = "Set Week";
            label = "Choose the week you want to add data for.";
            break;
        case DataConstants.FrequencyType.MONTHLY:
            title = "Set Month";
            label = "Choose the month you want to add data for.";
            break;
        case DataConstants.FrequencyType.QUARTERLY:
            title = "Set Quarter";
            label = "Choose the quarter you want to add data for.";
            break;
        case DataConstants.FrequencyType.FINANCIAL_YEAR:
            title = "Set Financial Year";
            label = "Choose the financial year you want to add data for.";
            break;
        case DataConstants.FrequencyType.ANNUALLY:
            title = "Set Year";
            label = "Choose the year you want to add data for.";
            break;
        default:
            break;
    }

    /* Interaction Functions */

    const onDateChange = (value) => {
        /*
        //let dateValue = dayjs(value);
        let dateValue = DataUtils.getUtcDate(value);
        switch (frequency) {
            case DataConstants.FrequencyType.WEEKLY:
                dateValue = dateValue.startOf("week");
                break;
            case DataConstants.FrequencyType.MONTHLY:
                dateValue = dateValue.startOf("month");
                break;
            case DataConstants.FrequencyType.QUARTERLY:
                dateValue = dateValue.startOf("quarter");
                break;
            case DataConstants.FrequencyType.FINANCIAL_YEAR:
                let year = dateValue.year();
                if (dateValue.month() < 6) {
                    year = year - 1;
                }
                dateValue = dateValue.date(1).month(6).year(year);
                break;
            case DataConstants.FrequencyType.ANNUALLY:
                dateValue = dateValue.startOf("year");
                break;
            default:
                break;
        }
        */
        setDate(value);
    }

    const onCancelClick = () => {
        onClose();
    };

    const onDateSet = () => {
        if (date && onSet) {
            onSet({
                frequency: frequency,
                date: date
            });
        }
        onClose();
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"                
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                                <div className="flex flex-col items-stretch gap-8">
                                    <Dialog.Title
                                        as="h4"
                                        className="font-vg-medium text-3.5xl text-black leading-110"
                                    >
                                        {title}
                                    </Dialog.Title>
                                    <CustomReactDatePicker
                                        label={label}
                                        frequency={frequency}
                                        value={date}
                                        onChange={onDateChange}
                                    />
                                    <div className="grid grid-cols-2 gap-3">
                                        <Button variant="outline" size="large" label="Cancel" className="w-full" onClick={onCancelClick}/>
                                        <Button variant="solid" size="large" label="Set" className="w-full" onClick={onDateSet}/>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}