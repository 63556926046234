export const ObjectTypes = {
    ORGANISATION: "organisation",
    USER: "user",
    ROLE: "role",
    ENTITY: "entity",
    PATHWAY: "indicator",
    INDICATOR: "indicator",
    DASHBOARD: "dashboard",
    LIBRARY: "library",
    DATA: "data"
};

export const AccessTypes = {
    CREATE: "create",
    DELETE: "delete",
    EDIT: "edit",
    READ: "read",
    EXPORT: "export",
    SUBMIT: "submit",
    MODERATE: "moderate"
};

export const DefaultRoles = {
    ADMINISTRATOR: "Administrator",
    TEAM_FULL_ACCESS: "Team Member (Full Access)",
    TEAM_READ_ONLY: "Team Member (Read Only)"
};

export const UserStatus = {
    INVITE_NOT_SENT: "Invite Not Sent",
    INVITE_SENT: "Invite Sent",
    ACTIVE: "Activated",
    INACTIVE: "Deactivated"
}


