import { forwardRef, useId, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Tag from './Tag';

import UiUtils from '../utils/UiUtils';

const TaggedSelectDropdown = forwardRef((props, ref) => {
    const { id, label, options, selectedOption, disabled, tagClass, onChange } = props;

    const newId = useId();
    const selectId = id ? id : newId;

    function handleChange(selection) {
        if (onChange) {
            onChange(selection);
        }
    }

    const buttonClass = UiUtils.classNames(disabled ? "border-grey03" : "border-grey01", label ? "mt-1.5" : "", "relative w-full h-[50px] flex justify-between gap-3 items-center px-3 cursor-default border bg-grey04 rounded-md");
    const chevronClass = disabled ? "h-6 w-6 text-grey03" : "h-6 w-6 text-grey05";

    return(
        <Listbox ref={ref} value={selectedOption} disabled={disabled} onChange={handleChange}>
            {({ open }) => (
                <div>
                    {label && (
                        <Listbox.Label className="font-vg-book text-grey text-base leading-130">{label}</Listbox.Label>
                    )}
                    <div className="relative">
                        <Listbox.Button className={buttonClass}>
                            <div className="flex gap-4 items-center">
                                {selectedOption && (
                                    selectedOption.tags.map((t, ti) => (
                                        <Tag key={`${selectId}-button-tag-${ti}`} text={t.text} color={t.color} className={tagClass}/>
                                    ))
                                )}
                                <p className="font-vg-book text-grey text-base leading-130 truncate">{selectedOption && selectedOption.label ? selectedOption.label : ""}</p>
                            </div>
                            <ChevronDownIcon className={chevronClass} aria-hidden="true"/>
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1.5 max-h-60 w-full overflow-auto font-vg-book text-base leading-130 rounded-lg bg-white shadow-listbox-options focus:outline-none">
                                {options.map((opt, index) => (
                                    <Listbox.Option
                                        key={`${selectId}-option-${index}`}
                                        className={({ selected, active }) => UiUtils.classNames(selected || active ? "text-black bg-blue03" : "text-grey bg-white", "flex gap-4 items-center h-12 relative cursor-default select-none px-4 py-[15px]")}
                                        value={opt}
                                    >
                                        {opt.tags.map((t, ti) => (
                                            <Tag key={`${selectId}-option-tag-${index}-${ti}`} text={t.text} color={t.color} className={tagClass}/>
                                        ))}
                                        <span className="block truncate">
                                            {opt.label}
                                        </span>
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );
});

export default TaggedSelectDropdown;