import { useId } from 'react';

import * as UiConstants from '../constants/UiConstants';

export default function Tag(props) {
    const { id, text, color, size, className, ...otherProps } = props;
    let bgColor = color;
    let textColor = "text-black";
    let textSize = size && size === "large" ? "text-sm" : "text-xs";
    switch (color) {
        case UiConstants.TagColor.GREEN:
            bgColor = 'bg-green03 border border-green03';
            break;
        case UiConstants.TagColor.YELLOW:
            bgColor = 'bg-yellow03 border border-yellow3';
            break;
        case UiConstants.TagColor.PURPLE:
            bgColor = 'bg-purple03 border border-purple03';
            break;
        case UiConstants.TagColor.GREY:
            bgColor = 'bg-grey04 border border-grey04';
            break;
        case UiConstants.TagColor.BLACK_OUTLINE:
            bgColor = 'bg-white border border-black';
            break;
        default:
            break;
    }
    let tagClass = `font-vg-medium text-black rounded-full px-3 py-2 leading-110 text-center ${textSize} ${textColor} ${bgColor}`;
    if (className) {
        tagClass = `${tagClass} ${className}`;
    }

    const newId = `p-${useId()}`;
    const pid = id ? `p-${id}` : newId;

    return (
        <p
            id={pid}
            key={pid} 
            className={tagClass}
            { ...otherProps }
        >
            {text}
        </p>
    );
}
