import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $getRoot, $insertNodes } from 'lexical';
import { useEffect, useRef } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';

export default function LexicalContentLoadPlugin({ initialContent }) {
    const [editor] = useLexicalComposerContext();
    const isMountedRef = useRef(false);

    useEffect(() => {
        /*
        if (!initialContent || isMountedRef.current) {
            return;
        }
        */
        if (isMountedRef.current) {
            return;
        }

        editor.update(() => {
            $getRoot().getChildren().forEach((n) => n.remove());
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialContent, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);
            if (nodes.length === 0) {
               const paragraphNode = $createParagraphNode();
               nodes.forEach((n) => paragraphNode.append(n));
               $getRoot().append(paragraphNode);
            } else {
               //$getRoot().append(nodes);
               $insertNodes(nodes);
            }
        });
        isMountedRef.current = true;
    }, [editor, initialContent]);

    return null;
};