import { useId } from 'react';
import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

import Tag from './Tag';

export default function ResourceBlock(props) {
    const { id, type, tags, title, text, link, image, readTime, imageAlt, durationText, ...otherProps } = props;

    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const newId = `block-${useId()}`;
    const blockId = id ? `block-${id}` : newId;

    return(
        <div id={blockId} key={blockId} className="flex flex-col items-stretch gap-4" { ...otherProps }>
            {image && (
                <a href={link}>
                    <img src={image} className="w-full rounded-xl object-cover h-[200px]" alt={imageAlt}/>
                </a>
            )}
            {isDesktop && (
            <div className="flex flex-col items-stretch justify-between h-full w-full grow">
                <div className="flex flex-col items-start gap-4 grow">
                    <div className="flex gap-4">
                        {tags.map((tag, index) => (
                            <Tag id={`tag-${index}`} key={`tag-${index}`} text={tag.text} color={tag.color} size="large"/>
                        ))}
                    </div>
                    <a href={link} className="no-underline">
                        <h5 className="font-vg-medium text-2xl leading-120 text-black">{title}</h5>
                    </a>
                    <p 
                        className="font-vg-book text-base leading-130 text-black line-clamp-2"
                        dangerouslySetInnerHTML={{__html: text}}
                    />
                </div>
                <div>
                    {type === "Text" && readTime ? (
                        <a href={link} className="font-vg-medium text-blue text-base no-underline">Read ∙ Est {readTime} min</a>
                    ) : (
                        <>
                        {type === "Video" && (
                            <a href={link} className="font-vg-medium text-blue text-base no-underline">Video ∙ Length {durationText}</a>
                        )}
                        {type === "Powerpoint" && (
                            <a href={link} className="font-vg-medium text-blue text-base no-underline">Powerpoint</a>
                        )}
                        </>
                    )}
                </div>
            </div>
            )}
            {isMobile && (
            <div className="flex flex-col items-stretch w-full gap-6">
                <div className="flex flex-col items-start gap-3">
                    <div className="flex gap-4">
                        {tags.map((tag, index) => (
                            <Tag id={`tag-${index}`} key={`tag-${index}`} text={tag.text} color={tag.color}/>
                        ))}
                    </div>
                    <a href={link} className="no-underline">
                        <h5 className="font-vg-medium text-2xl leading-120 text-black">{title}</h5>
                    </a>
                    <p 
                        className="font-vg-book text-base leading-130 text-black" 
                        dangerouslySetInnerHTML={{__html: text}}
                    />
                </div>
                <div>
                    {type === "Text" && readTime ? (
                        <a href={link} className="font-vg-medium text-blue text-base no-underline">Read ∙ Est {readTime} min</a>
                    ) : (
                        <>
                        {type === "Video" && (
                            <a href={link} className="font-vg-medium text-blue text-base no-underline">Video ∙ Length {durationText}</a>
                        )}
                        {type === "Powerpoint" && (
                            <a href={link} className="font-vg-medium text-blue text-base no-underline">Powerpoint</a>
                        )}
                        </>
                    )}
                </div>
            </div>
            )}
        </div>
    );
}