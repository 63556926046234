import * as DashboardConstants from '../constants/DashboardConstants';
import DashboardUtils from '../utils/DashboardUtils';

export default function DashboardThumbnail(props) {
    const { id, index, size, type, row, col, width, height } = props;

    let rowColClass = "";

    let rowStart = Math.min(row, DashboardConstants.CANVAS_ROWS);
    let rowEnd = Math.min(rowStart + height, DashboardConstants.CANVAS_ROWS + 1);
    let colStart = Math.min(col, DashboardConstants.CANVAS_COLS);
    let colEnd = Math.min(colStart + width, DashboardConstants.CANVAS_COLS + 1);

    switch (rowStart) {
        case 1:
            rowColClass = "row-start-1";
            break;
        case 2:
            rowColClass = "row-start-2";
            break;
        case 3:
            rowColClass = "row-start-3";
            break;
        default:
            break;
    }

    switch (colStart) {
        case 1:
            rowColClass = `${rowColClass} col-start-1`;
            break;
        case 2:
            rowColClass = `${rowColClass} col-start-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-start-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-start-4`;
            break;
        default:
            break;
    }

    switch (rowEnd) {
        case 2:
            rowColClass = `${rowColClass} row-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} row-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} row-end-4`;
            break;
        default:
            break;
    }

    switch (colEnd) {
        case 2:
            rowColClass = `${rowColClass} col-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-end-4`;
            break;
        case 5:
            rowColClass = `${rowColClass} col-end-5`;
            break;
        default:
            break;
    }

    let cardClass = `flex items-center justify-center ${rowColClass}`;
    let iconClass = "";

    switch (size) {
        case "small":
            cardClass = `${cardClass} rounded`;
            iconClass = "w-3 h-3";
            break;
    
        case "large":
            cardClass = `${cardClass} rounded-lg`;
            iconClass = "w-6 h-6";
            break;
    
        default:
            break;
    }

    return(
        <div key={`dash-thumb-inner-${id}-${index}`} className={cardClass} style={{ backgroundColor: type.backgroundColor }}>
            {DashboardUtils.getThumbIcon(type, iconClass)}
        </div>
    );
}