import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../context/GlobalContext';
import * as UiConstants from '../constants/UiConstants';
import * as EntityConstants from '../constants/EntityConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';
import UnitUtils from '../utils/UnitUtils';

import SelectDropdown from '../components/SelectDropdown';
import IndicatorStartContainer from './IndicatorStartContainer';
import IndicatorListContainer from './IndicatorListContainer';
import Button from './Button';

export default function IndicatorHomeContainer({ selectedUnit, onHomeAction, pathwayRefresh, userCanEdit }) {
    const { context } = useContext(GlobalContext);

    const navigate = useNavigate();

    const [selectedTypeOption, setSelectedTypeOption] = useState(UnitUtils.getTypeFromSelectedUnit(selectedUnit));
    const [unitOptions, setUnitOptions] = useState(context ? UnitUtils.getUnitOptionsFromContext(context, selectedUnit, false) : []);
    const [selectedUnitOption, setSelectedUnitOption] = useState(context ? UnitUtils.getSelectedUnitOptionFromContext(context, selectedUnit, false) : []);

    const hasIndicators = () => {
        if (selectedUnitOption === UiConstants.EMPTY_OPTION) {
            return false;
        } else {
            const type = selectedTypeOption ? selectedTypeOption.value : "";
            const id = selectedUnitOption ? selectedUnitOption.value : "";
            if (context.organisation) {
                switch (type) {
                    case EntityConstants.EntityType.ENTERPRISE:
                        const enterprises = context.organisation.enterprises ? context.organisation.enterprises.filter(e => e.status !== EntityConstants.EntityStatus.ARCHIVED) : [];
                        const eindex = enterprises.map(e => e._id).indexOf(id);
                        if (eindex > -1) {
                            const enterprise = enterprises[eindex];
                            return (enterprise.indicators && enterprise.indicators.length > 0 ? true : false);
                        } else {
                            return false;
                        }
                    case EntityConstants.EntityType.PROGRAM:
                        const programs = context.organisation.programs ? context.organisation.programs.filter(e => e.status !== EntityConstants.EntityStatus.ARCHIVED) : [];
                        const pgindex = programs.map(e => e._id).indexOf(id);
                        if (pgindex > -1) {
                            const program = programs[pgindex];
                            return (program.indicators && program.indicators.length > 0 ? true : false);
                        } else {
                            return false;
                        }
                    case EntityConstants.EntityType.PROJECT:
                        const projects = context.organisation.projects ? context.organisation.projects.filter(e => e.status !== EntityConstants.EntityStatus.ARCHIVED) : [];
                        const pjindex = projects.map(e => e._id).indexOf(id);
                        if (pjindex > -1) {
                            const project = projects[pjindex];
                            return (project.indicators && project.indicators.length > 0 ? true : false);
                        } else {
                            return false;
                        }
                    default:
                        return false;
                }
            } else {
                return false
            }
        }
    }

    /* Interaction Functions */

    const onTypeChange = (selection) => {
        setSelectedTypeOption(selection ? selection : UiConstants.EMPTY_OPTION);
        setUnitOptions(UnitUtils.getUnitOptionsByType(context, selection.value, false));
        setSelectedUnitOption(UiConstants.EMPTY_OPTION);
        if (onHomeAction) {
            onHomeAction({
                action: IndicatorConstants.ActionType.SELECT_UNIT,
                args: { type: EntityConstants.EntityType.NONE, id: ""}
            });
        }
    }

    const onUnitChange = (selection) => {
        setSelectedUnitOption(selection ? selection : UiConstants.EMPTY_OPTION);
        if (onHomeAction) {
            onHomeAction({
                action: IndicatorConstants.ActionType.SELECT_UNIT,
                args: { type: selectedTypeOption.value, id: selection.value }
            });
        }
    }

    const onStartAction = (details) => {
        if (onHomeAction) {
            onHomeAction(details);
        }
    }

    const onListAction = (details) => {
        if (onHomeAction) {
            onHomeAction(details);
        }
    }

    const onAddPathwayClick = () => {
        if (onHomeAction) {
            onHomeAction({ action: IndicatorConstants.ActionType.CREATE_PATHWAY });
        }
    }

    const onGotoManagementClick = () => {
        navigate('/kit/datamanagement');
    }

    const onViewDashboardClick = () => {
        navigate('/kit/dashboard');
    }

    return(
        <>
            <h3 className="font-vg-medium text-[38px] text-black leading-120">Indicators</h3>
            <div className="flex flex-col items-start gap-3 py-4 px-6 bg-grey04 border border-grey03 rounded-lg">
                <h6 className="font-vg-medium text-xl text-black">{`Select the reporting unit you want to ${userCanEdit ? "create an indicator for" : "view indicators for"}`}</h6>
                <div className="grid grid-cols-2 w-full gap-6 max-w-[826px]">
                    <SelectDropdown
                        label="Select Type"
                        options={UiConstants.UNIT_TYPE_OPTIONS}
                        selectedOption={selectedTypeOption || UiConstants.EMPTY_OPTION}
                        onChange={onTypeChange}
                    />
                    <SelectDropdown
                        label={selectedTypeOption && (selectedTypeOption.label !== "") ? `Select ${selectedTypeOption.label}` : "Select Reporting Unit"}
                        options={unitOptions ? unitOptions : []}
                        selectedOption={selectedUnitOption || UiConstants.EMPTY_OPTION}
                        onChange={onUnitChange}
                    />
                </div>
            </div>
            {hasIndicators() === false && (
                <IndicatorStartContainer 
                    type={selectedTypeOption ? selectedTypeOption.value : ""}
                    unit={selectedUnitOption ? selectedUnitOption.value : ""}
                    onStartAction={onStartAction}
                    userCanEdit={userCanEdit}
                />
            )}
            {hasIndicators() === true && (
                <>
                    <IndicatorListContainer
                        selectedUnit={selectedUnit}
                        onListAction={onListAction}
                        pathwayRefresh={pathwayRefresh}
                        userCanEdit={userCanEdit}
                    />
                    <div className="flex justify-end gap-3">
                        {userCanEdit && (
                            <Button variant="solid" size="large" label="Add A Domain" leftIcon="PlusCircleIcon" iconType="solid" onClick={onAddPathwayClick}/>
                        )}
                        <Button variant="outline" size="large" label="Go to Data Management" onClick={onGotoManagementClick}/>
                        <Button variant="outline" size="large" label="View Dashboards" onClick={onViewDashboardClick}/>
                    </div>
                </>
            )}
        </>
    );
}