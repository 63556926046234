import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
//import { Disclosure } from '@headlessui/react';

import * as UiConstants from '../constants/UiConstants';
import * as DashboardConstants from '../constants/DashboardConstants';

import DashboardElementStepButton from './DashboardElementStepButton';
import DashboardElementSizeSetter from './DashboardElementSizeSetter';
import TextInput from './TextInput';
import SelectDropdown from './SelectDropdown';
import CustomReactDatePicker from './CustomReactDatePicker';

export default function DashboardElementSizeStep({ details, visible, status, open, elementType, onStepAction }) {
    const { control, watch, setValue, getValues } = useFormContext();

    const [row, setRow] = useState(getValues("row"));
    const [col, setCol] = useState(getValues("col"));
    const [width, setWidth] = useState(getValues("width"));
    const [height, setHeight] = useState(getValues("height"));

    const toggleOpen = () => {
        if (onStepAction) {
            onStepAction({ action: DashboardConstants.ActionType.TOGGLE_STEP_SIZE });
        }
    }

    const onSetterAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.SETTER_GRID_UPDATE:
                setValue("row", details.args.row);
                setValue("col", details.args.col);
                setValue("width", details.args.width);
                setValue("height", details.args.height);

                setRow(details.args.row);
                setCol(details.args.col);
                setWidth(details.args.width);
                setHeight(details.args.height);
                break;
            case DashboardConstants.ActionType.SETTER_ROW_UPDATE:
                setValue("row", details.args.row);
                setRow(details.args.row);
                break;
            case DashboardConstants.ActionType.SETTER_COLUMN_UPDATE:
                setValue("col", details.args.col);
                setCol(details.args.col);
                break;
            case DashboardConstants.ActionType.SETTER_WIDTH_UPDATE:
                setValue("width", details.args.width);
                setWidth(details.args.width);
                break;
            case DashboardConstants.ActionType.SETTER_HEIGHT_UPDATE:
                setValue("height", details.args.height);
                setHeight(details.args.height);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            //TODO: Complete validation code
            if (name === "elementSubTypeOption") {
                if (onStepAction) {
                    onStepAction({
                        action: DashboardConstants.ActionType.SET_ELEMENT_SUBTYPE,
                        args: { elementSubType: data.elementSubTypeOption.value }
                    });
                }
            }
            if (name === "elementSubTypeOption" || name === "startDate" || name === "endDate") {
                if (onStepAction) {
                    switch (elementType) {
                        case DashboardConstants.ElementType.TEXT:
                            onStepAction({
                                action: DashboardConstants.ActionType.SIZE_STEP_COMPLETE,
                                args: {elementSubType: "" }
                            });
                            break;
                        case DashboardConstants.ElementType.GRAPH:
                            if (data.elementSubTypeOption !== UiConstants.EMPTY_OPTION && data.startDate !== null && data.endDate !== null) {
                                onStepAction({
                                    action: DashboardConstants.ActionType.SIZE_STEP_COMPLETE,
                                    args: { elementSubType: data.elementSubTypeOption.value }
                                });
                            }
                            break;
                        case DashboardConstants.ElementType.TABLE:
                            if (data.elementSubTypeOption !== UiConstants.EMPTY_OPTION && data.startDate !== null && data.endDate !== null) {
                                onStepAction({
                                    action: DashboardConstants.ActionType.SIZE_STEP_COMPLETE,
                                    args: { elementSubType: data.elementSubTypeOption.value }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, elementType, onStepAction, setValue]);

    return (
        <>
        {visible === true && (
            <div className="w-full flex flex-col items-stretch gap-4 px-6 py-4 rounded-lg bg-grey04">
                <DashboardElementStepButton
                    open={open}
                    title="Name, dates and element position / size"
                    number="2"
                    status={status === DashboardConstants.StepStatus.COMPLETE ? status : (open === true ? DashboardConstants.StepStatus.ACTIVE : true)}
                    onClick={toggleOpen}
                />
                <div className={open === true ? "flex flex-col items-stretch gap-4" : "hidden"}>
                    {(elementType === DashboardConstants.ElementType.NONE || elementType === DashboardConstants.ElementType.TEXT) && (
                        <Controller
                            name="title"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput
                                    label="Name of tile"
                                    {...field} 
                                />
                            )}
                        />
                    )}
                    {(elementType === DashboardConstants.ElementType.GRAPH) && (
                        <>
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Name of tile"
                                            {...field} 
                                        />
                                    )}
                                />
                                <Controller
                                    name="elementSubTypeOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown
                                            label="Graph Type"
                                            options={UiConstants.DASH_GRAPH_SUBTYPE_OPTIONS}
                                            selectedOption={field.value}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <CustomReactDatePicker
                                            label="Start Date"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <CustomReactDatePicker
                                            label="End Date"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                    {(elementType === DashboardConstants.ElementType.TABLE) && (
                        <>
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            label="Name of tile"
                                            {...field} 
                                        />
                                    )}
                                />
                                <Controller
                                    name="elementSubTypeOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown
                                            label="Table Type"
                                            options={UiConstants.DASH_TABLE_SUBTYPE_OPTIONS}
                                            selectedOption={field.value}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <CustomReactDatePicker
                                            label="Start Date"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <CustomReactDatePicker
                                            label="End Date"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                    <DashboardElementSizeSetter
                        details={details}
                        row={row}
                        col={col}
                        width={width}
                        height={height}
                        onSetterAction={onSetterAction}
                    />
                </div>
            </div>
        )}
        </>
     );
}
