import IconTooltip from "./IconTooltip";

export default function OrgEntPanelHeader({ title, subtitle, tooltip, className }) {
    const outerClass = "flex flex-col items-stretch gap-2";
    const tip = tooltip ? tooltip : "";
    return (
        <div className={className ? `${className} ${outerClass}` : outerClass}>
            {tip === "" ? (
                <h6 className="font-vg-medium text-xl leading-120 text-black">{title}</h6>
            ) : (
                <div className="flex items-center justify-start gap-2">
                    <h6 className="font-vg-medium text-xl leading-120 text-black">{title}</h6>
                    {tip !== "" && (
                        <IconTooltip content={tip} position="top"/>
                    )}
                </div>
            )}
            <p 
                className="font-vg-book text-base leading-130 text-grey"
                dangerouslySetInnerHTML={{__html: subtitle}}
            />
        </div>
    );
}