export const DataRowType = {
    INDICATOR: "Indicator",
    REQUIRED_DATA: "RequiredData"
}

export const RequiredDataType = {
    NONE: "",
    MANUAL: "Manual", 
    CALCULATED: "Calculated", 
    LIST_TEXT: "List Text",
    BASELINE: "Baseline",
    TARGET: "Target"
}

export const FrequencyType = {
    NONE: "",
    DAILY: "Daily",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    FINANCIAL_YEAR: "FinancialYear",
    ANNUALLY: "Annually"
}

export const MonthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
