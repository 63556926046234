import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';

import * as IndicatorConstants from '../constants/IndicatorConstants';
import * as UiConstants from '../constants/UiConstants';
import UiUtils from '../utils/UiUtils';
import LibraryUtils from '../utils/LibraryUtils';

import Button from './Button';
import IndicatorDomainPanel from './IndicatorDomainPanel';
import IndicatorOutcomeAreaPanel from './IndicatorOutcomeAreaPanel';
import IndicatorSelectPanel from './IndicatorSelectPanel';
import IndicatorReviewPanel from './IndicatorReviewPanel';

export default function IndicatorTabContainer(props) {
    const { 
        steps, 
        index, 
        subindex, 
        library, 
        domains,
        outcomeAreas,
        emptyLibraryMessage,
        selectedNeed, 
        selectedDomain,
        selectedArea,
        pathwayName,
        selectedIndicators,
        navButtons,
        onTabAction
    } = props;
    
    const tabClass = "flex flex-col gap-1.5 items-stretch border-t-4 ui-selected:border-blue02 pt-4";

    const indicators = LibraryUtils.getDomainIndicators(library, selectedDomain, true);

    const onChange = (selection) => {
        if (onTabAction) {
            onTabAction({ action: IndicatorConstants.ActionType.CHANGE_STEP, args: { step: selection }});
        }
    }

    const handleStepPanelAction = (details) => {
        if (onTabAction) {
            onTabAction(details);
        }
    }

    const onPreviousClick = () => {
        if (onTabAction) {
            onTabAction({ action: IndicatorConstants.ActionType.PREVIOUS_STEP });
        }
    }

    const onNextClick = () => {
        if (onTabAction) {
            onTabAction({ action: IndicatorConstants.ActionType.NEXT_STEP });
        }
    }

    return(
        <TabGroup as="div" selectedIndex={index} onChange={onChange} className="flex flex-col items-stretch gap-8">
            <TabList className="grid grid-cols-4 gap-1.5 px-6 py-4 rounded-lg bg-grey04 border border-grey03">
                {steps.map((s, i) => {
                    const stepNo = i + 1;
                    return(
                        <Tab key={`ind-step-tab-${stepNo}`} className={UiUtils.classNames(tabClass, s.completed ? "border-blue" : "border-grey")}>
                            <p className="font-vg-book text-xs2 text-grey leading-120">{`STEP ${stepNo}`}</p>
                            <p className="font-vg-book text-sm text-black leading-120">{s.title}</p>
                        </Tab>
                    );
                })}
            </TabList>
            <div className={`flex justify-between items-center ${navButtons.saving || navButtons.error !== "" ? "invisible" : ""}`}>
                <Button 
                    variant="outline" 
                    size="large" 
                    label="Previous" 
                    onClick={onPreviousClick}
                />
                {navButtons.rightButton === UiConstants.ButtonType.NEXT && (
                    <Button 
                        variant="solid" 
                        size="large"
                        label="Next" 
                        onClick={onNextClick}
                    />
                )}
            </div>
            <TabPanels>
                <IndicatorDomainPanel
                    domains={domains}
                    selectedValue={selectedDomain}
                    emptyMessage={emptyLibraryMessage}
                    onStepPanelAction={handleStepPanelAction}
                />
                <IndicatorOutcomeAreaPanel
                    domain={selectedDomain}
                    outcomeAreas={outcomeAreas}
                    selectedValue={selectedArea}
                    onStepPanelAction={handleStepPanelAction}
                />
                <IndicatorSelectPanel
                    library={library}
                    indicators={indicators}
                    reportingNeed={selectedNeed}
                    domain={selectedDomain}
                    outcomeArea={selectedArea}
                    substeps={steps[2].substeps}
                    subindex={subindex >= 0 ? subindex : 0}
                    selection={selectedIndicators}
                    onStepPanelAction={handleStepPanelAction}
                />
                <IndicatorReviewPanel
                    pathwayName={pathwayName}
                    reportingNeed={selectedNeed}
                    domain={selectedDomain}
                    outcomeArea={selectedArea}
                    selection={selectedIndicators}
                    onStepPanelAction={handleStepPanelAction}
                />
            </TabPanels>
        </TabGroup>
    );
}
