import { cloneDeep } from 'lodash';

import * as DashboardConstants from '../constants/DashboardConstants';
import * as UiConstants from '../constants/UiConstants';

import TextArea from './TextArea';
import TextInput from './TextInput';
import SelectDropdown from './SelectDropdown';
import SelectColorDropdown from './SelectColorDropdown';
import LabelledSwitch from './LabelledSwitch';

export default function DashboardDatasetSubform({ graphType, secondaryY, index, dataset, onDatasetChanged }) {

    const getDatasetTypeOption = (value) => {
        let index = UiConstants.DASH_GRAPH_DATASET_OPTIONS.map(opt => opt.value).indexOf(value);
        if (index > -1) {
            return UiConstants.DASH_GRAPH_DATASET_OPTIONS[index];
        } else {
            return UiConstants.EMPTY_OPTION;
        }
    }

    const getColorOption = (value) => {
        let index = UiConstants.DASH_COLOR_OPTIONS.map(opt => opt.value).indexOf(value);
        if (index > -1) {
            return UiConstants.DASH_COLOR_OPTIONS[index];
        } else {
            return UiConstants.EMPTY_OPTION;
        }
    }

    /* UI Interaction Functions */

    const updateDataset = (key, value) => {
        let newDataset = cloneDeep(dataset);
        newDataset[key] = value;
        if (onDatasetChanged) {
            onDatasetChanged({
                action: DashboardConstants.ActionType.GRAPH_DATA_UPDATED,
                args: { index: index, dataset: newDataset }
            });
        }
    }

    const onDisplayTypeChange = (option) => {
        updateDataset("displayType", option.value);
    }

    const onColorChange = (option) => {
        updateDataset("color", option.value);
    }

    const onLegendChange = (e) => {
        updateDataset("legend", e.target.value);
    }

    const onIsSecondaryYChange = (value) => {
        updateDataset("isSecondaryY", value);
    } 

    return (
        <div key={`dataset-subform-${index}`} className="flex flex-col items-stretch gap-6">
            <h6 className="font-vg-regular text-black text-xl">{`Data #${index + 1}`}</h6>
            {graphType === DashboardConstants.ElementSubType.PIE_CHART && (
            <TextInput
                label="Dataset Graph Type" 
                variant="disabled"
                value="Pie Chart"
            />
            )}
            {graphType === DashboardConstants.ElementSubType.XY_GRAPH && (
            <SelectDropdown
                label="Dataset Graph Type"
                options={UiConstants.DASH_GRAPH_DATASET_OPTIONS}
                selectedOption={getDatasetTypeOption(dataset.displayType)}
                onChange={onDisplayTypeChange}
            />
            )}
            <SelectColorDropdown
                label="Colour"
                options={UiConstants.DASH_COLOR_OPTIONS}
                selectedOption={getColorOption(dataset.color)}
                onChange={onColorChange}
            />
            <TextArea
                label="Legend"
                rows={3}
                value={dataset.legend}
                onChange={onLegendChange}
            />
            {graphType === DashboardConstants.ElementSubType.XY_GRAPH && secondaryY === true && (
            <LabelledSwitch
                label="Add To Secondary Y Axis"
                checked={dataset.isSecondaryY}
                onChange={onIsSecondaryYChange}
            />
            )}
        </div>
    );
}