import { evaluate } from 'mathjs';

let CalcUtils = {};

const extractVariables = (expression) => {
    const bracedVars = expression.match(/{([^}]+)}/g); 
    return bracedVars.map(v => v.substring(1, v.length - 1));
}

const evaluateExpression = (expression, varValueMap) => {
    let vars = extractVariables(expression);
    let replacedExp = expression;
    vars.forEach(v => {
        replacedExp = replacedExp.replace(`{${v}}`, varValueMap[v]);
    });
    return evaluate(replacedExp);
}

const getValueFromRowData = (key, colKey, rowData) => {
    let value = null
    let rowIndex = rowData.map(r => r.key).indexOf(key);
    if (rowIndex > -1) {
        if (rowData[rowIndex].hasOwnProperty(colKey)) {
            value = rowData[rowIndex][colKey];
        }
    }
    return value;
}

const getVarRows = (calcRow, allRows) => {
    const varKeys = extractVariables(calcRow.formula);
    let varRows = [];
    let allFound = true;
    varKeys.forEach(key => {
        const index = allRows.map(r => r.key).indexOf(key);
        if (index > -1) {
            varRows.push(allRows[index]);
        } else {
            allFound = false;
        }
    });
    return allFound ? varRows : [];
}

const evaluateItemInRow = (dateKey, calcRow, varRows) => {
    const varKeys = extractVariables(calcRow.formula);
    let varValueMap = {};
    let allFound = true;
    varKeys.forEach(key => {
        const index = varRows.map(r => r.key).indexOf(key);
        if (index > -1) {
            const varRow = varRows[index];
            if (varRow.hasOwnProperty(dateKey) && varRow[dateKey] != null) {
                varValueMap[key] = varRow[dateKey];
            } else {
                allFound = false;
            }
        } else {
            allFound = false;
        }
    });
    return allFound ? evaluateExpression(calcRow.formula, varValueMap) : null;
}

const evaluateRow = (dateCols, calcRow, varRows) => {
    let newCalcRow = JSON.parse(JSON.stringify(calcRow));
    dateCols.forEach(dc => {
        newCalcRow[dc] = evaluateItemInRow(dc, calcRow, varRows);
    });
    return newCalcRow;
}

CalcUtils.extractVariables = extractVariables;
CalcUtils.evaluateExpression = evaluateExpression;
CalcUtils.getVarRows = getVarRows;
CalcUtils.evaluateItemInRow = evaluateItemInRow;
CalcUtils.evaluateRow = evaluateRow;
CalcUtils.getValueFromRowData = getValueFromRowData;

export default CalcUtils;