import { useState, useContext, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
//import { yupResolver } from '@hookform/resolvers/yup';
//import * as Yup from 'yup';
import { TabPanel } from '@headlessui/react';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid';
import { cloneDeep } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as UiConstants from '../../constants/UiConstants';
import * as EntityConstants from '../../constants/EntityConstants';
import * as EnterpriseConstants from '../../constants/EnterpriseConstants';
import * as UserConstants from '../../constants/UserConstants';
import * as MatomoConstants from '../../constants/MatomoConstants';
import { GlobalContext } from '../../context/GlobalContext';
import EntDataService from '../../services/EntDataService';
import ABNService from '../../services/ABNService';
import OrgService from '../../services/OrgService';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import OrgEntHeader from '../../components/OrgEntHeader';
import OrgEntPanelHeader from '../../components/OrgEntPanelHeader';
import SelectDropdown from '../../components/SelectDropdown';
import TaggedSelectDropdown from '../../components/TaggedSelectDropdown';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import Button from '../../components/Button';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import TextInput from '../../components/TextInput';
import TextInputHeading from '../../components/TextInputHeading';
import Tabs from '../../components/Tabs';
import Checkbox from '../../components/Checkbox';
import Tag from '../../components/Tag';
import OtherItemsInput from '../../components/OtherItemsInput';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';
import Footer from '../../components/Footer';

export default function EnterprisePage() {
    const {context, setContextValues} = useContext(GlobalContext);

    const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

    /* Role Access Code */

    const roles = context.user && context.user.roles ? context.user.roles : [];
    const [userCanEdit, setUserCanEdit] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.EDIT));

    const [showSTCertified, setShowSTCertified] = useState(false);
    const [showPPFCertified, setShowPPFCertified] = useState(false);
    const [showNonCertified, setShowNonCertified] = useState(false);
    const [showCommPostcode, setShowCommPostcode] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showState, setShowState] = useState(false);
    const [showMultipleStates, setShowMultipleStates] = useState(false);

    const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

    /* Form Initialisation Functions */

    const getSocEntOptions = useCallback((organisation, archived) => {
        let ents = organisation.enterprises ? organisation.enterprises : [];
        if (!archived) {
            ents = ents.filter(e => (!e.hasOwnProperty("status")) || e.status !== EntityConstants.EntityStatus.ARCHIVED);
        }
        const opts = ents.map(e => ({ 
            value: e._id, 
            label: e.name,
            tags: [{
                text : e.status ? UiUtils.capitalise(e.status) : UiUtils.capitalise(EntityConstants.EntityStatus.ACTIVE),
                color : e.status ? (e.status === EntityConstants.EntityStatus.ACTIVE ? "green" : "purple") : "green"
            }] 
        }));
        return opts;
    }, []);

    const getSocEntFormValues = useCallback((organisation, index) => {
        let values = {};
        const canFind = (organisation.enterprises && index >= 0 && index < organisation.enterprises.length);
        if (canFind) {
            const e = organisation.enterprises[index];
            const ent = e.enterpriseData ? e.enterpriseData : {};
            values = {
                name: ent.name ? ent.name : e.name,
                abn: ent.abn ? ent.abn : "",
                abnBelongsTo: ent.abnBelongsTo ? ent.abnBelongsTo : "",
                standaloneTypeOther: ent.standaloneTypeOther ? ent.standaloneTypeOther : "",
                communityPostcode: ent.communityPostcode ? ent.communityPostcode : "",
                region: ent.region ? ent.region : "",
                purposeOtherText: ent.purposeOtherText ? ent.purposeOtherText : "",
                benefitOtherText: ent.benefitOtherItems ? JSON.stringify(ent.benefitOtherItems) : "[\"\"]",
                officeAddress: ent.officeAddress ? ent.officeAddress : "",
                opPostcodesLGAs: ent.opPostcodesLGAs ? ent.opPostcodesLGAs : "",
                socialTradersCertifiedOption: ent.socialTradersCertified ? getOptionFromValue(ent.socialTradersCertified, UiConstants.YES_NO_UNSURE_OPTIONS) : UiConstants.EMPTY_OPTION,
                socialTradersYearOption: ent.socialTradersYear ? getOptionFromValue(parseInt(ent.socialTradersYear), UiUtils.getYearOptions()) : UiConstants.EMPTY_OPTION,
                socialTradersCurrentOption: ent.socialTradersCurrent ? getOptionFromValue(ent.socialTradersCurrent, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                peoplePlanetFirstVerifiedOption: ent.peoplePlanetFirstVerified ? getOptionFromValue(ent.peoplePlanetFirstVerified, UiConstants.YES_NO_UNSURE_OPTIONS) : UiConstants.EMPTY_OPTION,
                peoplePlanetFirstYearOption: ent.peoplePlanetFirstYear ? getOptionFromValue(parseInt(ent.peoplePlanetFirstYear), UiUtils.getYearOptions()) : UiConstants.EMPTY_OPTION,
                peoplePlanetFirstCurrentOption: ent.peoplePlanetFirstCurrent ? getOptionFromValue(ent.peoplePlanetFirstCurrent, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                publicBenefitOption: ent.publicBenefit ? getOptionFromValue(ent.publicBenefit, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                prioritisePurposeOption: ent.prioritisePurpose ? getOptionFromValue(ent.prioritisePurpose, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                incomeFromSalesOption: ent.incomeFromSales ? getOptionFromValue(ent.incomeFromSales, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                selfSustainingRevenueOption: ent.selfSustainingRevenue ? getOptionFromValue(ent.selfSustainingRevenue, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                socialPurposeReinvestmentOption: ent.socialPurposeReinvestment ? getOptionFromValue(ent.socialPurposeReinvestment, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                purposeLockInOption: ent.purposeLockIn ? getOptionFromValue(ent.purposeLockIn, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION,
                socialEnterpriseTypeOption: ent.socialEnterpriseType ? getOptionFromValue(ent.socialEnterpriseType, UiConstants.SOC_ENT_TYPE_OPTIONS) : UiConstants.EMPTY_OPTION,
                standaloneTypeOption: ent.standaloneType ? getOptionFromValue(ent.standaloneType, UiConstants.STANDALONE_TYPE_OPTIONS) : UiConstants.EMPTY_OPTION,
                primaryCustomerOption: ent.primaryCustomer ? getOptionFromValue(ent.primaryCustomer, UiConstants.PRIMARY_CUSTOMER_OPTIONS) : UiConstants.EMPTY_OPTION,
                primaryStreamOption: ent.primaryStream ? getOptionFromValue(ent.primaryStream, UiConstants.BUSINESS_STREAM_OPTIONS) : UiConstants.EMPTY_OPTION,
                secondaryStreamOption: ent.secondaryStream ? getOptionFromValue(ent.secondaryStream, UiConstants.BUSINESS_STREAM_OPTIONS) : UiConstants.EMPTY_OPTION,
                stateOption: ent.state ? getOptionFromValue(ent.state, UiConstants.STATE_OPTIONS) : UiConstants.EMPTY_OPTION,
                operationStartYearOption: ent.operationStartYear ? getOptionFromValue(ent.operationStartYear, UiUtils.getYearOptions()) : UiConstants.EMPTY_OPTION,
                numberEmployeesOption: ent.numberEmployees ? getOptionFromValue(ent.numberEmployees, UiConstants.NUM_EMPLOYEE_OPTIONS) : UiConstants.EMPTY_OPTION,
                secondaryCustomers: getOptionsFromFields(ent, UiConstants.SECONDARY_CUSTOMER_OPTIONS),
                salesAreaOptions: getOptionsFromFields(ent, UiConstants.SALES_AREA_OPTIONS),
                multipleStateOptions: ent.multipleStates ? getOptionsFromValues(ent.multipleStates, UiConstants.STATE_OPTIONS) : [],
                purposeOptions: getOptionsFromFields(ent, UiConstants.PURPOSE_OPTIONS),
                benefitOptions: getOptionsFromFields(ent, UiConstants.BENEFIT_OPTIONS)
            }
        } else {
            values = {
                name: "",
                abn: "",
                abnBelongsTo: "",
                standaloneTypeOther: "",
                communityPostcode: "",
                region: "",
                purposeOtherText: "",
                benefitOtherText: "[\"\"]",
                officeAddress: "",
                opPostcodesLGAs: "",
                socialTradersCertifiedOption: UiConstants.EMPTY_OPTION,
                socialTradersYearOption: UiConstants.EMPTY_OPTION,
                socialTradersCurrentOption: UiConstants.EMPTY_OPTION,
                peoplePlanetFirstVerifiedOption: UiConstants.EMPTY_OPTION,
                peoplePlanetFirstYearOption: UiConstants.EMPTY_OPTION,
                peoplePlanetFirstCurrentOption: UiConstants.EMPTY_OPTION,
                publicBenefitOption: UiConstants.EMPTY_OPTION,
                prioritisePurposeOption: UiConstants.EMPTY_OPTION,
                incomeFromSalesOption: UiConstants.EMPTY_OPTION,
                selfSustainingRevenueOption: UiConstants.EMPTY_OPTION,
                socialPurposeReinvestmentOption: UiConstants.EMPTY_OPTION,
                purposeLockInOption: UiConstants.EMPTY_OPTION,
                socialEnterpriseTypeOption: UiConstants.EMPTY_OPTION,
                standaloneTypeOption: UiConstants.EMPTY_OPTION,
                primaryCustomerOption: UiConstants.EMPTY_OPTION,
                primaryStreamOption: UiConstants.EMPTY_OPTION,
                secondaryStreamOption: UiConstants.EMPTY_OPTION,
                stateOption: UiConstants.EMPTY_OPTION,
                operationStartYearOption: UiConstants.EMPTY_OPTION,
                numberEmployeesOption: UiConstants.EMPTY_OPTION,
                secondaryCustomers: [],
                salesAreaOptions: [],
                multipleStateOptions: [],
                purposeOptions: [],
                benefitOptions: [],
            };
        }
        return values;
    }, [])

    const getCert = useCallback((seIndex, cIndex) => {
        let cert = null;
        let canFind = (context.organisation && context.organisation.enterprises && seIndex >= 0 && seIndex < context.organisation.enterprises.length);
        if (canFind) {
            const ent = context.organisation.enterprises[seIndex];
            const e = ent.enterpriseData ? ent.enterpriseData : {};
            if (e.certifications && cIndex >= 0 && cIndex < e.certifications.length) {
                cert = e.certifications[cIndex];
            }
        }
        return cert;
    }, [context]);

    const getCertFormValues = useCallback((seIndex, cIndex) => {
        const cert = getCert(seIndex, cIndex);
        let values = {};
        if (cert) {
            values = {
                name: cert.name ? cert.name : "",
                year: cert.year ? cert.year : "",
                currentOption: cert.current ? getOptionFromValue(cert.current, UiConstants.YES_NO_OPTIONS) : UiConstants.EMPTY_OPTION
            }
        } else {
            values = {
                name: "",
                year: "",
                currentOption: UiConstants.EMPTY_OPTION
            }
        }
        return values;
    }, [getCert]);

    const getAllFormVars = useCallback(() => {
        let seOptions = getSocEntOptions(context.organisation ? context.organisation : {}, false);
        let optIndex = seOptions.length > 0 ? 0 : -1;
        let seActive = false;

        let seIndex = optIndex;

        if (context.selections && context.selections.type === EntityConstants.EntityType.ENTERPRISE) {
            const selection = context.selections.enterprise;
            seActive = selection.edit;
            const index = seOptions.map(opt => opt.value).indexOf(selection.id);
            if (index > -1) {
                optIndex = index
                const enterprises = context.organisation && context.organisation.enterprises ? context.organisation.enterprises : [];
                seIndex = enterprises.map(e => e._id).indexOf(selection.id);
            }
        }

        let vars = {
            formActive: seActive,
            socEntOptions: seOptions,
            socEntIndex: optIndex,
            formValues: getSocEntFormValues(context.organisation ? context.organisation : {}, seIndex),
            certOptions: [],
            certIndex: -1,
            certFormValues: []
        };
        if (seIndex > -1 && context.organisation && context.organisation.enterprises && context.organisation.enterprises[seIndex].enterpriseData && context.organisation.enterprises[seIndex].enterpriseData.certifications) {
            vars.certOptions = context.organisation.enterprises[seIndex].enterpriseData.certifications.map((c, index) => ({ value: index, label: c.name }));
            vars.certIndex = vars.certOptions.length > 0 ? 0 : -1;
            if (vars.certIndex === 0) {
                vars.certFormValues = getCertFormValues(seIndex, 0);
            }
        }
        return vars;
    }, [context, getSocEntOptions, getSocEntFormValues, getCertFormValues]);

    const vars = getAllFormVars();

    const [formActive, setFormActive] = useState(vars.formActive);

    const [socEntOptions, setSocEntOptions] = useState(vars.socEntOptions);
    const [socEntIndex, setSocEntIndex] = useState(vars.socEntIndex);

    const [certOptions, setCertOptions] = useState(vars.certOptions);
    const [certIndex, setCertIndex] = useState(vars.certIndex);

    const formOptions = {
        defaultValues: vars.formValues
    };

    const certFormOptions = {
        defaultValues: vars.certFormValues
    };

    const { 
        control, 
        handleSubmit,
        watch, 
        reset,
        getValues,
        setValue
    } = useForm(formOptions);

    const { 
        control: certControl, 
        handleSubmit: certHandleSubmit,
        watch: certWatch, 
        reset: certReset,
        getValues: getCertValues
    } = useForm(certFormOptions);

    /* UI State Variables */

    const [showStandalone, setShowStandalone] = useState(false);
    const [showStandaloneOther, setShowStandaloneOther] = useState(false);
    const [showPurposeOther, setShowPurposeOther] = useState(false);
    const [showBenefitOther, setShowBenefitOther] = useState(false);

    const [showArchived, setShowArchived] = useState(false);
    const [abnSearchMsg, setAbnSearchMsg] = useState("");

    const [entCreating, setEntCreating] = useState(false);
    const [entCreateError, setEntCreateError] = useState(false);

    /* Main Form Data Functions */

    function getStatus(index) {
        if (index > -1 && index < socEntOptions.length) {
            return socEntOptions[index].tags[0].text;
        } else {
            return "";
        }
    }

    function getStatusColor(index) {
        if (index > -1 && index < socEntOptions.length) {
            return socEntOptions[index].tags[0].color;
        } else {
            return "grey";
        }
    }

    function getOptionFromValue(value, options) {
        let option = UiConstants.EMPTY_OPTION;
        const index = options.map(o => o.value).indexOf(value);
        if (index > -1) {
            option = options[index];
        }
        return option;
    }

    function getOptionsFromValues(values, options) {
        let retOpts = [];
        values.forEach(v => {
            const index = options.map(o => o.value).indexOf(v);
            if (index > -1) {
                retOpts.push(options[index]);
            }
        });
        return retOpts;
    }

    function getOptionsFromFields(data, options) {
        let retOpts = [];
        options.forEach(o => {
            if (data.hasOwnProperty(o.value) && data[o.value] === true) {
                retOpts.push(o);
            }
        });
        return retOpts;
    }

    const setFieldsFromOptions = useCallback((data, values, options) => {
        let d = JSON.parse(JSON.stringify(data));
        options.forEach(o => {
            d[o.value] = false;
        });
        values.forEach(v => {
            d[v.value] = true;
        });
        return d;
    }, []);

    const getCerts = useCallback((seIndex) => {
        if (context.organisation && context.organisation.enterprises && seIndex >= 0 && seIndex < context.organisation.enterprises.length) {
            const e = context.organisation.enterprises[seIndex];
            return (e.enterpriseData && e.enterpriseData.certifications) ? e.enterpriseData.certifications : [];
        } else {
            return [];
        }
    }, [context]);

    const prepareSubmit = useCallback((seIndex, data) => {
        const id = (seIndex > -1 && seIndex < context.organisation.enterprises.length) ? context.organisation.enterprises[seIndex]._id : "";
        let details = {
            entity: id,
            name: data.name,
            abn: data.abn,
            abnBelongsTo: data.abnBelongsTo,
            standaloneTypeOther: data.standaloneTypeOther,
            communityPostcode: data.communityPostcode,
            region: data.region,
            purposeOtherText: data.purposeOtherText,
            benefitOtherItems: JSON.parse(data.benefitOtherText),
            officeAddress: data.officeAddress,
            opPostcodesLGAs: data.opPostcodesLGAs,
            peoplePlanetFirstVerified: data.peoplePlanetFirstVerifiedOption.value,
            peoplePlanetFirstYearOption: data.peoplePlanetFirstYearOption.value,
            peoplePlanetFirstCurrentOption: data.peoplePlanetFirstCurrentOption.value,
            socialTradersCertified: data.socialTradersCertifiedOption.value,
            socialTradersYear: data.socialTradersYearOption.value,
            socialTradersCurrent: data.socialTradersCurrentOption.value,
            publicBenefit: data.publicBenefitOption.value,
            prioritisePurpose: data.prioritisePurposeOption.value,
            incomeFromSales: data.incomeFromSalesOption.value,
            selfSustainingRevenue: data.selfSustainingRevenueOption.value,
            socialPurposeReinvestment: data.socialPurposeReinvestmentOption.value,
            purposeLockIn: data.purposeLockInOption.value,
            socialEnterpriseType: data.socialEnterpriseTypeOption.value,
            standaloneType: data.standaloneTypeOption.value,
            primaryCustomer: data.primaryCustomerOption.value,
            primaryStream: data.primaryStreamOption.value,
            secondaryStream: data.secondaryStreamOption.value,
            state: data.stateOption.value,
            operationStartYear: data.operationStartYearOption.value,
            numberEmployees: data.numberEmployeesOption.value,
            multipleStates: data.multipleStateOptions.map(o => o.value),
            certifications: getCerts(seIndex)
        };
        details = setFieldsFromOptions(details, data.secondaryCustomers, UiConstants.SECONDARY_CUSTOMER_OPTIONS);
        details = setFieldsFromOptions(details, data.salesAreaOptions, UiConstants.SALES_AREA_OPTIONS);
        details = setFieldsFromOptions(details, data.purposeOptions, UiConstants.PURPOSE_OPTIONS);
        details = setFieldsFromOptions(details, data.benefitOptions, UiConstants.BENEFIT_OPTIONS);

        return details;
    }, [context, getCerts, setFieldsFromOptions]);

    /* Cert Form Data Functions */

    const getInitialCertIndex = useCallback((seIndex) => {
        let index = -1;
        let canFind = (context.organisation && context.organisation.enterprises && seIndex >= 0 && seIndex < context.organisation.enterprises.length);
        if (canFind) {
            const ent = context.organisation.enterprises[seIndex];
            const e = ent.enterpriseData ? ent.enterpriseData : {};
            if (e.certifications && e.certifications.length > 0) {
                index = 0;
            }
        }
        return index;
    }, [context]);

    const getCertDetails = useCallback((data) => {
        return {
            name: data.name,
            year: data.year,
            current: data.currentOption.value
        } 
    }, []);

    const prepareCertSubmit = useCallback((seIndex, cIndex, details) => {
        const ent = context.organisation.enterprises[seIndex];
        const id = ent._id;
        let entdata;
        if (ent.enterpriseData) {
            entdata = JSON.parse(JSON.stringify(ent.enterpriseData));
            if (details) {
                entdata.certifications[cIndex] = details;
            }
            entdata.entity = id;
        } else {
            entdata = {
                entity: id,
                certifications: [details]
            };
        }
        return entdata;
    }, [context]);

    const updateCertContext = useCallback((seIndex, cIndex, details) => {
        let organisation = JSON.parse(JSON.stringify(context.organisation));
        if (seIndex > -1 && cIndex > -1 && details) {
            if (organisation.enterprises[seIndex].enterpriseData) {
                organisation.enterprises[seIndex].enterpriseData.certifications[cIndex] = {
                name: details.name,
                year: details.year,
                current: details.current
                }
            } else {
                organisation.enterprises[seIndex].enterpriseData = {
                    certifications: [{
                        name: details.name,
                        year: details.year,
                        current: details.current
                    }]
                }
            }
        }
        setContextValues([{ key: 'organisation', value: organisation }]);
    }, [context, setContextValues]);

    /* Button Interaction Functions */

    function setEnterpriseFromId(organisation, id) {
        let index = organisation.enterprises ? organisation.enterprises.map(e => e._id).indexOf(id) : -1;
        reset(getSocEntFormValues(organisation, index));
        const cindex = getInitialCertIndex(index);
        setCertIndex(cindex);
        certReset(getCertFormValues(index, cindex));
        return index > -1 ? organisation.enterprises[index] : null;
    }

    function setContextSelection(id, edit) {
        let selections = context.selections ? JSON.parse(JSON.stringify(context.selections)) : {};
        delete selections.program;
        delete selections.project;
        delete selections.enterprise;
        if (id !== "") {
            selections.type = EntityConstants.EntityType.ENTERPRISE;
            selections.enterprise = { id: id, edit: edit };
        } else {
            selections.type = EntityConstants.EntityType.NONE;
        }
        setContextValues([{ key: 'selections', value: selections }]);
    }

    function enterpriseChanged(selection) {
        const index = socEntOptions.map(se => se.value).indexOf(selection.value);
        if (index > -1) {
            setSocEntIndex(index);
            let enterprise = setEnterpriseFromId(context.organisation ? context.organisation : {}, selection.value);
            if (enterprise.hasOwnProperty("status") && enterprise.status === EntityConstants.EntityStatus.ARCHIVED) {
                setContextSelection("", false);
            } else {
                setContextSelection(selection.value, false);
            }
        }
    }

    function addEnterpriseClicked(e) {
        setEntCreating(true);
        setEntCreateError("");
        const name = `Social Enterprise #${context.organisation && context.organisation.enterprises ? context.organisation.enterprises.length + 1 : 1}`;
        OrgService.createEnterprise({
            name: name
        })
        .then(response => {
            setEntCreating(false);
            let organisation = context.organisation ? JSON.parse(JSON.stringify(context.organisation)) : { enterprises: [] };
            let selections = context.selections ? JSON.parse(JSON.stringify(context.selections)) : {};
            if (organisation.enterprises) {
                organisation.enterprises.push(response.data.entity);
            } else {
                organisation.enterprises = [response.data.entity];
            }
            delete selections.enterprise;
            delete selections.program;
            delete selections.project;
            selections.type = EntityConstants.EntityType.ENTERPRISE;
            selections.enterprise = { id : response.data.entity._id, edit : true };
            setContextValues([
                { key: "organisation", value: organisation },
                { key: "selections", value: selections }
            ]);
            const newOpts = getSocEntOptions(organisation, showArchived);
            const newIndex = newOpts.length - 1;
            setSocEntOptions(newOpts);
            setSocEntIndex(newIndex);
            setCertIndex(-1);
            reset(getSocEntFormValues(organisation, organisation.enterprises.length - 1));
            certReset({
                name: "",
                year: "",
                currentOption: UiConstants.EMPTY_OPTION
            });
            setFormActive(true);
        })
        .catch(err => {
            console.log(err);
            setEntCreating(false);
            setEntCreateError("An error occurred creating your enterprise. Please try again later.");
        });
    }

    function archivedChanged(e) {
        const archived = !showArchived;
        const currentId = socEntIndex > -1 && socEntIndex < socEntOptions.length ? socEntOptions[socEntIndex].value : "";
        const newOpts = getSocEntOptions(context.organisation ? context.organisation : {}, archived);
        let newIndex = newOpts.map(o => o.value).indexOf(currentId);
        newIndex = newIndex > -1 ? newIndex : (newOpts.length > 0 ? 0 : -1);
        const newId = newOpts[newIndex].value;
        setSocEntOptions(newOpts);
        setSocEntIndex(newIndex);
        setShowArchived(archived);
        setEnterpriseFromId(context.organisation ? context.organisation : {}, newId);
        setContextSelection(newId, false);
    }

    function editClicked(e) {
        e.preventDefault();
        if (formActive) {
            setFormActive(false);
        } else {
            if (socEntIndex > -1) {
                setFormActive(true);
            }
        }
    }

    function afterUnOrArchive(organisation, id) {
        const opts = getSocEntOptions(organisation, showArchived);
        let newIndex = opts.map(o => o.value).indexOf(id);
        newIndex = newIndex > -1 ? newIndex : (opts.length > 0 ? 0 : -1);
        const newId = newIndex > -1 ? opts[newIndex].value : "";
        setSocEntOptions(opts);
        setSocEntIndex(newIndex);
        setEnterpriseFromId(organisation, newId);
        setContextSelection(newId, false);
        setContextValues([{ key: "organisation", value: organisation }]);
    }

    function archiveClicked(e) {
        e.preventDefault();
        if (socEntIndex > -1) {
            let ent = context.organisation.enterprises[socEntIndex];
            if (window.confirm(`Are you sure you want to archive ${ent.name}?`)) {
                const id = ent._id;
                OrgService.archiveEntity(id)
                .then((_) => {
                    let organisation = JSON.parse(JSON.stringify(context.organisation));
                    organisation.enterprises[socEntIndex].status = EntityConstants.EntityStatus.ARCHIVED;
                    afterUnOrArchive(organisation, id);
                })
                .catch(err => {
                    console.log(err);
                });
            }
        }
    }

    function unArchiveClicked(e) {
        e.preventDefault();
        if (socEntIndex > -1) {
            let ent = context.organisation.enterprises[socEntIndex];
            if (window.confirm(`Are you sure you want to unarchive ${ent.name}?`)) {
                const id = ent._id;
                OrgService.unArchiveEntity(id)
                .then((_) => {
                    let organisation = JSON.parse(JSON.stringify(context.organisation));
                    organisation.enterprises[socEntIndex].status = EntityConstants.EntityStatus.ACTIVE;
                    setContextValues([{ key: "organisation", value: organisation }]);
                    afterUnOrArchive(organisation, id);
                })
                .catch(err => {
                    console.log(err);
                });
            }
        }
    }

    function certificationChanged(selection) {
        const index = certOptions.map(c => c.value).indexOf(selection.value);
        setCertIndex(index);
        certReset(getCertFormValues(socEntIndex, index));
    }

    function addCertificationClicked(e) {
        e.preventDefault();
        if (socEntIndex > -1) {
            let organisation = JSON.parse(JSON.stringify(context.organisation));
            if (!organisation.enterprises[socEntIndex].hasOwnProperty("enterpriseData")) {
                organisation.enterprises[socEntIndex].enterpriseData = { certifications: [] };
            }

            const index = organisation.enterprises[socEntIndex].enterpriseData.certifications.length;
            const num = index + 1;

            let certifications = organisation.enterprises[socEntIndex].enterpriseData.certifications;
            certifications.push({
                name: "Certification #" + num,
                year: "",
                current: ""
            });
            organisation.enterprises[socEntIndex].enterpriseData.certifications = certifications;
            const copts = certifications.map((c, index) => ({ value: index, label: c.name }));
            
            const data = {
                name: "Certification #" + num,
                year: "",
                currentOption: UiConstants.EMPTY_OPTION
            };

            EntDataService.updateEnterpriseData({
                entity: organisation.enterprises[socEntIndex]._id,
                certifications: certifications
            })
            .then((_) => {
                setCertOptions(copts);
                setCertIndex(index);
                certReset(data); 
                setContextValues([{ key: 'organisation', value: organisation }]);
            })
            .catch(err => {
                console.log(err);
                alert("Unable to update certifications. Please try again later.");
            });
        }
    }

    function deleteCertificationClicked(e) {
        e.preventDefault();
        if (socEntIndex > -1 && certIndex > -1) {
            let organisation = JSON.parse(JSON.stringify(context.organisation));
            if (organisation.enterprises[socEntIndex].enterpriseData && organisation.enterprises[socEntIndex].enterpriseData.certifications) {
                let certifications = organisation.enterprises[socEntIndex].enterpriseData.certifications;

                const certName = certifications[certIndex].name ? certifications[certIndex].name : "this certification";

                if (window.confirm(`Are you sure you want to delete ${certName}?`) === true) {
                let entData = cloneDeep(organisation.enterprises[socEntIndex].enterpriseData);
                certifications.splice(certIndex, 1);
                const index = certifications.length > 0 ? 0 : -1;
                entData.certifications = certifications;

                let payload = {
                    entity: organisation.enterprises[socEntIndex]._id,
                    certifications: certifications
                }

                if (certName === EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME) {
                    entData.socialTradersCertified = "no";
                    entData.socialTradersYear = "";
                    entData.socialTradersCurrent = "";

                    payload.socialTradersCertified = "no";
                    payload.socialTradersYear = "";
                    payload.socialTradersCurrent = "";
                }

                if (certName === EnterpriseConstants.PEOPLE_PLANET_CERT_NAME) {
                    entData.peoplePlanetFirstVerified = "no";
                    entData.peoplePlanetFirstYear = "";
                    entData.peoplePlanetFirstCurrent = "";

                    payload.peoplePlanetFirstVerified = "no";
                    payload.peoplePlanetFirstYear = "";
                    payload.peoplePlanetFirstCurrent = "";
                }

                organisation.enterprises[socEntIndex].enterpriseData = entData;
                const copts = certifications.map((c, index) => ({ value: index, label: c.name }));
    
                setContextValues([{ key: 'organisation', value: organisation }]);
    
                EntDataService.updateEnterpriseData(payload)
                .then(response => {
                    setCertOptions(copts);
                    setCertIndex(index);
                    certReset(getCertFormValues(socEntIndex, index));

                    if (certName === EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME) {
                        setValue("socialTradersCertifiedOption", UiConstants.NO_OPTION);
                        setValue("socialTradersYearOption", UiConstants.EMPTY_OPTION);
                        setValue("socialTradersCurrentOption", UiConstants.EMPTY_OPTION);
                        setShowSTCertified(false);
                        setShowNonCertified(true);
                    }

                    if (certName === EnterpriseConstants.PEOPLE_PLANET_CERT_NAME) {
                        setValue("peoplePlanetFirstVerifiedOption", UiConstants.NO_OPTION);
                        setValue("peoplePlanetFirstYearOption", UiConstants.EMPTY_OPTION);
                        setValue("peoplePlanetFirstCurrentOption", UiConstants.EMPTY_OPTION);
                        setShowPPFCertified(false);
                        setShowNonCertified(true);
                    }

                    setContextValues([{ key: 'organisation', value: organisation }]);
                })
                .catch(err => {
                    console.log(err);
                    alert("Unable to delete certification. Please try again late.");
                });
                }
            }
        }
    }

    function doABNSearch(e) {
        e.preventDefault();
        const abnValue = getValues("abn");
        if (abnValue !== "") {
            setAbnSearchMsg("Searching...");
            ABNService.checkAbn(abnValue, (error, data) => {
                if (error) {
                    setAbnSearchMsg("An error occurred. Please try again later.");
                } else {
                    if (data.EntityName !== '') {
                        setValue("name", data.EntityName);
                        setAbnSearchMsg("");
                    } else if (data.BusinessName.length > 0) {
                        setValue("name", data.BusinessName[0]);
                        setAbnSearchMsg("");
                    } else {
                        if (data.Message !== '') {
                            setAbnSearchMsg(data.Message);
                        } else {
                            setAbnSearchMsg('No results returned from ABN search');
                        }
                    }
                }
            });
        } else {
            setAbnSearchMsg("You need to provide an ABN");
        }
    }

    /* Form Watch Functions */

    const handleSocTradersInteractions = useCallback((type, option, otherCertOption) => {
        if (type !== "change") {
            return;
        }
        let cert = false;
        let nonCert = false;
        const v1 = option.value;
        const v2 = otherCertOption.value;
        if (v1 === "yes") {
            cert = true;
        }
        if (v1 === "no" || v1 === "unsure" || v2 === "no" || v2 === "unsure") {
            nonCert = true;
        }

        let payload = { 
            entity: context.organisation.enterprises[socEntIndex]._id,
            socialTradersCertified: option.value 
        };

        let organisation = JSON.parse(JSON.stringify(context.organisation));
        let enterpriseData = organisation.enterprises[socEntIndex].enterpriseData ? organisation.enterprises[socEntIndex].enterpriseData : { certifications: [] };
        let certifications = enterpriseData.certifications ? enterpriseData.certifications : [];
        let stIndex = certifications.map(c => c.name).indexOf(EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME);
        let certsUpdated = false;
        let newCertIndex = certIndex;
        let newCertValues = {
            name: "",
            year: "",
            current: UiConstants.EMPTY_OPTION
        };
        let currentIndex = -1;
        if (certIndex > -1 && certIndex < certifications.length) {
            let c1 = certifications[certIndex];
            currentIndex = c1.current ? UiConstants.YES_NO_OPTIONS.map(o => o.value).indexOf(c1.current) : -1;
            newCertValues = {
                name: c1.name,
                year: c1.year,
                current: currentIndex > -1 ? UiConstants.YES_NO_OPTIONS[currentIndex] : UiConstants.EMPTY_OPTION
            };
        }

        if (cert) {
            // Add to certifications
            if (stIndex === -1) {
                certifications.push({
                    name: EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME,
                    year: "",
                    current: ""
                });
                payload.certifications = certifications;
                newCertIndex = certifications.length - 1;
                newCertValues = {
                    name: EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME,
                    year: "",
                    current: UiConstants.EMPTY_OPTION
                }
                certsUpdated = true;
            }
        } else {
            // Remove from certifications
            if (stIndex > -1) {
                certifications.splice(stIndex, 1);
                payload.certifications = certifications;
                payload.socialTradersYear = "";
                payload.socialTradersCurrent = "";
                if (newCertIndex >= stIndex) {
                    newCertIndex = certifications.length > 0 ? 0 : -1;
                    if (newCertIndex > -1) {
                        let c2 = certifications[newCertIndex];
                        currentIndex = c2.current ? UiConstants.YES_NO_OPTIONS.map(o => o.value).indexOf(c2.current) : -1;
                        newCertValues = {
                            name: c2.name,
                            year: c2.year,
                            current: currentIndex > -1 ? UiConstants.YES_NO_OPTIONS[currentIndex] : UiConstants.EMPTY_OPTION
                        };
                    } else {
                        newCertValues = { name: "", year: "", current: UiConstants.EMPTY_OPTION };
                    }
                }
                certsUpdated = true;
            }
        }

        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            if (certsUpdated) {
                const copts = certifications.map((c, index) => ({ value: index, label: c.name }));
                enterpriseData.certifications = certifications;
                setCertOptions(copts);
                setCertIndex(newCertIndex);
                certReset(newCertValues);
            }
            setShowSTCertified(cert);
            setShowNonCertified(nonCert)

            //organisation.enterprises[socEntIndex].enterpriseData = enterpriseData;
            organisation.enterprises[socEntIndex].enterpriseData = response.data.enterpriseData;
            setContextValues([{ key: 'organisation', value: organisation }]);

            setValue("socialTradersYearOption", UiConstants.EMPTY_OPTION);
            setValue("socialTradersCurrentOption", UiConstants.EMPTY_OPTION);
        })
        .catch(err => {
            console.log(err);
            alert("Unable to update this value at this time. Please try again late.");
        });
    }, [setShowSTCertified, setShowNonCertified, setCertOptions, 
        setCertIndex, certReset, setContextValues, certIndex, context, setValue, socEntIndex]);

    const handleSalesAreaInteractions = useCallback((options) => {
        let pcode = false;
        let reg = false;
        let st = false;
        let mst = false;
        if (options) {
            options.forEach(o => {
                switch (o.value) {
                case "localCommunitySales":
                    pcode = true
                    break;
                case "regionalSales":
                    reg = true
                    break;
                case "stateSales":
                    st = true
                    break;
                case "multipleStateSales":
                    mst = true
                    break;
                default:
                    break;
                }
            });
        }
        setShowCommPostcode(pcode);
        setShowRegion(reg);
        setShowState(st);
        setShowMultipleStates(mst);
    }, [setShowCommPostcode, setShowRegion, setShowState, setShowMultipleStates]);

    const handleSocTradersYearInteractions = useCallback((type, option) => {
        if (type !== "change") {
            return;
        }
        let payload = {
            entity: context.organisation.enterprises[socEntIndex]._id,
            socialTradersYear: option.value
        };

        let organisation = JSON.parse(JSON.stringify(context.organisation));
        let enterpriseData = organisation.enterprises[socEntIndex].enterpriseData ? organisation.enterprises[socEntIndex].enterpriseData : { certifications: [] };
        
        enterpriseData.socialTradersYear = option.value;
        let certifications = enterpriseData.certifications ? enterpriseData.certifications : [];
        let stIndex = certifications.map(c => c.name).indexOf(EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME);
        if (stIndex > -1) {
            certifications[stIndex].year = option.value;
            payload.certifications = certifications;
        }

        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            let cValues = getCertValues();
            if (cValues.name === EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME) {
                cValues.year = option.value;
                certReset(cValues);
            }
            //organisation.enterprises[socEntIndex].enterpriseData = enterpriseData;
            organisation.enterprises[socEntIndex].enterpriseData = response.data.enterpriseData;
            setContextValues([{ key: 'organisation', value: organisation }]);
        })
        .catch(err => {
            alert("Unable to update this value at this time. Please try again late.");
        });

    }, [certReset, setContextValues, context, getCertValues, socEntIndex]);

    const handleSocTradersCurrentInteractions = useCallback((type, option) => {
        if (type !== "change") {
            return;
        }
        let payload = {
            entity: context.organisation.enterprises[socEntIndex]._id,
            socialTradersCurrent: option.value
        };

        let organisation = JSON.parse(JSON.stringify(context.organisation));
        let enterpriseData = organisation.enterprises[socEntIndex].enterpriseData ? organisation.enterprises[socEntIndex].enterpriseData : { certifications: [] };
        
        enterpriseData.socialTradersCurrent = option.value;
        let certifications = enterpriseData.certifications ? enterpriseData.certifications : [];
        let stIndex = certifications.map(c => c.name).indexOf(EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME);
        if (stIndex > -1) {
            certifications[stIndex].current = option.value;
            payload.certifications = certifications;
        }

        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            let cValues = getCertValues();
            if (cValues.name === EnterpriseConstants.SOCIAL_TRADERS_CERT_NAME) {
                cValues.currentOption = option;
                certReset(cValues);
            }
            //organisation.enterprises[socEntIndex].enterpriseData = enterpriseData;
            organisation.enterprises[socEntIndex].enterpriseData = response.data.enterpriseData;
            setContextValues([{ key: 'organisation', value: organisation }]);
        })
        .catch(err => {
            alert("Unable to update this value at this time. Please try again late.");
        });

    }, [certReset, setContextValues, context, getCertValues, socEntIndex]);

    const handlePeoplePlanetInteractions = useCallback((type, option, otherCertOption) => {
        if (type !== "change") {
            return;
        }
        let cert = false;
        let nonCert = false;
        const v1 = option.value;
        const v2 = otherCertOption.value;
        if (v1 === "yes") {
            cert = true;
        }
        if (v1 === "no" || v1 === "unsure" || v2 === "no" || v2 === "unsure") {
            nonCert = true;
        }

        let payload = { 
            entity: context.organisation.enterprises[socEntIndex]._id,
            peoplePlanetFirstVerified: option.value 
        };

        let organisation = JSON.parse(JSON.stringify(context.organisation));
        let enterpriseData = organisation.enterprises[socEntIndex].enterpriseData ? organisation.enterprises[socEntIndex].enterpriseData : { certifications: [] };
        let certifications = enterpriseData.certifications ? enterpriseData.certifications : [];
        let stIndex = certifications.map(c => c.name).indexOf(EnterpriseConstants.PEOPLE_PLANET_CERT_NAME);
        let certsUpdated = false;
        let newCertIndex = certIndex;
        let newCertValues = {
            name: "",
            year: "",
            current: UiConstants.EMPTY_OPTION
        };
        let currentIndex = -1;
        if (certIndex > -1 && certIndex < certifications.length) {
            let c1 = certifications[certIndex];
            currentIndex = c1.current ? UiConstants.YES_NO_OPTIONS.map(o => o.value).indexOf(c1.current) : -1;
            newCertValues = {
                name: c1.name,
                year: c1.year,
                current: currentIndex > -1 ? UiConstants.YES_NO_OPTIONS[currentIndex] : UiConstants.EMPTY_OPTION
            };
        }

        if (cert) {
            // Add to certifications
            if (stIndex === -1) {
                certifications.push({
                    name: EnterpriseConstants.PEOPLE_PLANET_CERT_NAME,
                    year: "",
                    current: ""
                });
                payload.certifications = certifications;
                newCertIndex = certifications.length - 1;
                newCertValues = {
                    name: EnterpriseConstants.PEOPLE_PLANET_CERT_NAME,
                    year: "",
                    current: UiConstants.EMPTY_OPTION
                }
                certsUpdated = true;
            }
        } else {
            // Remove from certifications
            if (stIndex > -1) {
                certifications.splice(stIndex, 1);
                payload.peoplePlanetFirstYear = "";
                payload.peoplePlanetFirstCurrent = "";
                payload.certifications = certifications;
                if (newCertIndex >= stIndex) {
                newCertIndex = certifications.length > 0 ? 0 : -1;
                if (newCertIndex > -1) {
                    let c2 = certifications[newCertIndex];
                    currentIndex = c2.current ? UiConstants.YES_NO_OPTIONS.map(o => o.value).indexOf(c2.current) : -1;
                    newCertValues = {
                        name: c2.name,
                        year: c2.year,
                        current: currentIndex > -1 ? UiConstants.YES_NO_OPTIONS[currentIndex] : UiConstants.EMPTY_OPTION
                    };
                } else {
                    newCertValues = { name: "", year: "", current: UiConstants.EMPTY_OPTION };
                }
                }
                certsUpdated = true;
            }
            setValue("peoplePlanetFirstYearOption", UiConstants.EMPTY_OPTION);
            setValue("peoplePlanetFirstCurrentOption", UiConstants.EMPTY_OPTION);
        }

        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            if (certsUpdated) {
                const copts = certifications.map((c, index) => ({ value: index, label: c.name }));
                //enterpriseData.certifications = certifications;
                setCertOptions(copts);
                setCertIndex(newCertIndex);
                certReset(newCertValues);
            }
            setShowPPFCertified(cert);
            setShowNonCertified(nonCert)

            //organisation.enterprises[socEntIndex].enterpriseData = enterpriseData;
            organisation.enterprises[socEntIndex].enterpriseData = response.data.enterpriseData;
            setContextValues([{ key: 'organisation', value: organisation }]);
        })
        .catch(err => {
            console.log(Error);
            alert("Unable to update this value at this time. Please try again late.");
        });
    }, [setShowPPFCertified, setShowNonCertified, setCertOptions, 
        setCertIndex, certReset, setContextValues, certIndex, context, setValue, socEntIndex]);

    const handlePeoplePlanetYearInteractions = useCallback((type, option) => {
        if (type !== "change") {
            return;
        }
        let payload = {
            entity: context.organisation.enterprises[socEntIndex]._id,
            peoplePlanetFirstYear: option.value
        };

        let organisation = JSON.parse(JSON.stringify(context.organisation));
        let enterpriseData = organisation.enterprises[socEntIndex].enterpriseData ? organisation.enterprises[socEntIndex].enterpriseData : { certifications: [] };
        
        enterpriseData.peoplePlanetFirstYear = option.value;
        let certifications = enterpriseData.certifications ? enterpriseData.certifications : [];
        let stIndex = certifications.map(c => c.name).indexOf(EnterpriseConstants.PEOPLE_PLANET_CERT_NAME);
        if (stIndex > -1) {
            certifications[stIndex].year = option.value;
            payload.certifications = certifications;
        }

        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            let cValues = getCertValues();
            if (cValues.name === EnterpriseConstants.PEOPLE_PLANET_CERT_NAME) {
                cValues.year = option.value;
                certReset(cValues);
            }
            //organisation.enterprises[socEntIndex].enterpriseData = enterpriseData;
            organisation.enterprises[socEntIndex].enterpriseData = response.data.enterpriseData;
            setContextValues([{ key: 'organisation', value: organisation }]);
        })
        .catch(err => {
            console.log(err);
            alert("Unable to update this value at this time. Please try again late.");
        });

    }, [certReset, setContextValues, context, getCertValues, socEntIndex]);

    const handlePeoplePlanetCurrentInteractions = useCallback((type, option) => {
        if (type !== "change") {
            return;
        }
        let payload = {
            entity: context.organisation.enterprises[socEntIndex]._id,
            peoplePlanetFirstCurrent: option.value
        };

        let organisation = JSON.parse(JSON.stringify(context.organisation));
        let enterpriseData = organisation.enterprises[socEntIndex].enterpriseData ? organisation.enterprises[socEntIndex].enterpriseData : { certifications: [] };
        
        enterpriseData.peoplePlanetFirstCurrent = option.value;
        let certifications = enterpriseData.certifications ? enterpriseData.certifications : [];
        let stIndex = certifications.map(c => c.name).indexOf(EnterpriseConstants.PEOPLE_PLANET_CERT_NAME);
        if (stIndex > -1) {
            certifications[stIndex].current = option.value;
            payload.certifications = certifications;
        }

        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            let cValues = getCertValues();
            if (cValues.name === EnterpriseConstants.PEOPLE_PLANET_CERT_NAME) {
                cValues.currentOption = option;
                certReset(cValues);
            }
            //organisation.enterprises[socEntIndex].enterpriseData = enterpriseData;
            organisation.enterprises[socEntIndex].enterpriseData = response.data.enterpriseData;
            setContextValues([{ key: 'organisation', value: organisation }]);
        })
        .catch(err => {
            console.log(err);
            alert("Unable to update this value at this time. Please try again late.");
        });
        
    }, [certReset, setContextValues, context, getCertValues, socEntIndex]);

    const handleSocEntTypeInteractions = useCallback((option) => {
        setShowStandalone(option.value === "standalone");
    }, [setShowStandalone]);

    const onSubmit = useCallback((data) => {
        const payload = prepareSubmit(socEntIndex, data);
        //updateContext(socEntIndex, payload);
        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            let enterprise = response.data.entity;
            enterprise.enterpriseData = response.data.enterpriseData;
            let organisation = JSON.parse(JSON.stringify(context.organisation));
            let index = organisation.enterprises.map(e => e._id).indexOf(enterprise._id);
            if (index > -1) {
                organisation.enterprises[index] = enterprise;
                setSocEntOptions(getSocEntOptions(organisation, showArchived));
                setContextValues([{ key: 'organisation', value: organisation }]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }, [socEntIndex, prepareSubmit, context.organisation, getSocEntOptions, setContextValues, showArchived]);

    const onCertSubmit = useCallback((seIndex, cIndex, data) => {
        let details = null;
        if (data) {
            details = getCertDetails(data);
        }
        const payload = prepareCertSubmit(seIndex, cIndex, details); 
        updateCertContext(seIndex, cIndex, details);
        EntDataService.updateEnterpriseData(payload)
        .then(response => {
            //console.log(response);
        })
        .catch(error => {
            console.log(error);
        });
    }, [getCertDetails, prepareCertSubmit, updateCertContext]);

    /* Update Checking Functions */

    function checkFieldUpdate(field, data) {
        if (context.organisation && context.organisation.enterprises && context.organisation.enterprises.length > socEntIndex) {
            if (context.organisation.enterprises[socEntIndex].enterpriseData) {
                const ent = context.organisation.enterprises[socEntIndex].enterpriseData;
                if (ent.hasOwnProperty(field)) {
                    if (ent[field] !== data[field]) {
                        onSubmit(data);
                    }
                } else {
                    if (data[field] !== "") {
                        onSubmit(data);
                    }
                }
            } else {
                onSubmit(data);
            }
        }
    }

    function checkCertFieldUpdate(field, data) {
        if (context.organisation && context.organisation.enterprises && context.organisation.enterprises.length > socEntIndex && context.organisation.enterprises[socEntIndex].enterpriseData) {
            const ent = context.organisation.enterprises[socEntIndex].enterpriseData;
            if (ent.certifications && ent.certifications.length > certIndex) {
                const cert = ent.certifications[certIndex];
                if (cert.hasOwnProperty(field)) {
                    if (cert[field] !== data[field]) {
                        onCertSubmit(socEntIndex, certIndex, data);
                    }
                } else {
                    if (data[field] !== "") {
                        onCertSubmit(socEntIndex, certIndex, data);
                    }
                }
            }
        }
    }

    const onNameSubmit = (data) => {
        checkFieldUpdate("name", data);
    }

    const onAbnSubmit = (data) => {
        checkFieldUpdate("abn", data);
    }

    const onStandaloneTypeOtherSubmit = (data) => {
        checkFieldUpdate("standaloneTypeOther", data);
    }

    const onCommunityPostcodeSubmit = (data) => {
        checkFieldUpdate("communityPostcode", data);
    }

    const onRegionSubmit = (data) => {
        checkFieldUpdate("region", data);
    }

    const onPurposeOtherTextSubmit = (data) => {
        checkFieldUpdate("purposeOtherText", data);
    }

    const onBenefitOtherSubmit = (data) => {
        checkFieldUpdate("benefitOtherText", data);
    }

    const onOfficeAddressSubmit = (data) => {
        checkFieldUpdate("officeAddress", data);
    }

    const onOpPostcodesLGAsSubmit = (data) => {
        checkFieldUpdate("opPostcodesLGAs", data);
    }

    const onCertNameSubmit = (data) => {
        checkCertFieldUpdate("name", data);
    }

    const onYearSubmit = (data) => {
        checkCertFieldUpdate("year", data);
    }

    /* useEffect Related Functions */

    const handleSubscription = useCallback((type, name, data) => {
        switch (name) {
            case "peoplePlanetFirstVerifiedOption":
                handlePeoplePlanetInteractions(type, data.peoplePlanetFirstVerifiedOption, data.socialTradersCertifiedOption);
                break;
            case "peoplePlanetFirstYearOption":
                handlePeoplePlanetYearInteractions(type, data.peoplePlanetFirstYearOption);
                break;
            case "peoplePlanetFirstCurrentOption":
                handlePeoplePlanetCurrentInteractions(type, data.peoplePlanetFirstCurrentOption);
                break;
            case "socialTradersCertifiedOption":
                handleSocTradersInteractions(type, data.socialTradersCertifiedOption, data.peoplePlanetFirstVerifiedOption);
                break;
            case "socialTradersYearOption":
                handleSocTradersYearInteractions(type, data.socialTradersYearOption);
                break;
            case "socialTradersCurrentOption":
                handleSocTradersCurrentInteractions(type, data.socialTradersCurrentOption);
                break;
            case "salesAreaOptions":
                handleSalesAreaInteractions(data.salesAreaOptions);
                onSubmit(data);
                break;
            case "socialEnterpriseTypeOption":
                handleSocEntTypeInteractions(data.socialEnterpriseTypeOption);
                onSubmit(data);
                break;
            case "abnBelongsTo":
            case "publicBenefitOption":
            case "incomeFromSalesOption":
            case "socialPurposeReinvestmentOption":
            case "standaloneTypeOption":
            case "primaryCustomerOption":
            case "secondaryCustomers":
            case "primaryStreamOption":
            case "secondaryStreamOption":
            case "stateOption":
            case "multipleStateOptions":
            case "operationStartYearOption":
            case "purposeOptions":
            case "benefitOptions":
            case "numberEmployeesOption":
                onSubmit(data);
                break;
            default:
                break;
        }
    }, [handleSocTradersInteractions, handleSocTradersYearInteractions,
        handleSocTradersCurrentInteractions, handleSalesAreaInteractions, 
        handlePeoplePlanetInteractions, handlePeoplePlanetYearInteractions,
        handlePeoplePlanetCurrentInteractions, onSubmit]);

    const handleCertSubscription = useCallback((name, data) => {
        switch (name) {
            case "currentOption":
                onCertSubmit(socEntIndex, certIndex, data);
                break;
            default:
                break;
        }
    }, [onCertSubmit, socEntIndex, certIndex]);

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            handleSubscription(type, name, data);
        });
        const certSubscription = certWatch((data, { name, type }) => {
            handleCertSubscription(name, data);
        });
        return () => {
            subscription.unsubscribe();
            certSubscription.unsubscribe();
        }
    }, [watch, certWatch, handleSubscription, handleCertSubscription]);

    useEffect(() => {
        const data = getValues();
        const standAloneVisible = (data.socialEnterpriseTypeOption.value === "standalone");
        setShowStandalone(standAloneVisible);
        setShowStandaloneOther(standAloneVisible && (data.standaloneTypeOption.value === "other"));
        setShowPurposeOther(data.purposeOptions.map(p => p.value).indexOf("purposeOther") > -1);
        setShowBenefitOther(data.benefitOptions.map(p => p.value).indexOf("benefitOther") > -1);

        let certST = false;
        let certPPF = false;
        let nonCert = false;
        const v1 = data.socialTradersCertifiedOption.value;
        const v2 = data.peoplePlanetFirstVerifiedOption.value;
        if (v1 === "yes") {
            certST = true;
        }
        if (v2 === "yes") {
            certPPF = true;
        }
        if (v1 === "no" || v1 === "unsure" || v2 === "no" || v2 === "unsure") {
            nonCert = true;
        }
        setShowSTCertified(certST);
        setShowPPFCertified(certPPF);
        setShowNonCertified(nonCert);
    }, [getValues]);

    /* Trial Upgrade Modal Functions */

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

    const onTrialUpgradeClick = () => {
        setUpgradeModalOpen(true);
    }

    const onTrialUpgradeDone = () => {
        setIsTrial(false);
        setUpgradeModalOpen(false);
    }
    
    /* Data Refresh Function */

    const [dataRefresh, setDataRefresh] = useState(false);
    
    useEffect(() => {
        if (dataRefresh) {
            const vars = getAllFormVars();

            setFormActive(vars.formActive);

            setSocEntOptions(vars.socEntOptions);
            setSocEntIndex(vars.socEntIndex);
            setCertOptions(vars.certOptions);
            setCertIndex(vars.certIndex);

            reset(vars.formValues);
            certReset(vars.certFormValues);

            const roles = context.user && context.user.roles ? context.user.roles : [];
            setUserCanEdit(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.EDIT));

            setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);
        
            setDataRefresh(false);
        }
    }, [dataRefresh, context, getAllFormVars, setFormActive, setSocEntOptions, setSocEntIndex, 
        setCertOptions, setCertIndex, reset, certReset, setUserCanEdit, setIsTrial, setDataRefresh]);

    /* Terms Agreement Functions */

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    useEffect(() => {
        if (context.user && context.user.id) {
            if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
                setTermsModalOpen(false);
            } else {
                setTermsModalOpen(true);
            }
        } else {
            setTermsModalOpen(false);
        }
    }, [context, setTermsModalOpen]);

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageCategory.USER_ACCOUNT + " / " + MatomoConstants.PageTitle.SOCIAL_ENTERPRISES
        });
    }, []);

    return(
        <div className="w-full h-full flex">
            <KitSideNav page="Social Enterprises" onToggle={(value) => setNavExpanded(value)}/>
            <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
                {isTrial ? (
                <KitTopBar 
                    onDataRefresh={() => setDataRefresh(true)} 
                    banner={GeneralConstants.TRIAL_BANNER}
                    onBannerClick={() => onTrialUpgradeClick()}
                />
                ) : (
                <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
                )}
                <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white bg-enterprise bg-contain bg-right-top bg-no-repeat">
                <OrgEntHeader 
                    title="Social Enterprises" 
                    subtitle={userCanEdit ? "Create your social enterprise/s and view or edit enterprise information." : "View information on your organisation's social enterprises"}
                    showStrap={userCanEdit}
                    editing={formActive}
                />
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col items-stretch gap-4">
                        <TaggedSelectDropdown
                            label="Select Social Enterprise to display below" 
                            options={socEntOptions}
                            selectedOption={socEntIndex > -1 ? socEntOptions[socEntIndex] : null}
                            tagClass="w-20"
                            onChange={enterpriseChanged}
                        />
                        <Checkbox
                            label="Show Archived Enterprises"
                            checked={showArchived}
                            noRing={true}
                            onChange={archivedChanged}
                        />
                    </div>
                    <div className="mt-7 h-[50px] flex justify-start items-center gap-4">
                        {userCanEdit && (
                            <Button 
                                variant="outline" 
                                size="small" 
                                label="Add" 
                                leftIcon="PlusSmIcon" 
                                className={entCreating ? "hidden" : ""}
                                onClick={addEnterpriseClicked}
                            />
                        )}
                        <p className={entCreating ? "font-vg-regular text-base text-black" : "hidden"}>Creating enterprise...</p>
                        <p className="font-vg-regular text-base text-red">{entCreateError}</p>
                    </div>
                </div>
                <form className="flex flex-col items-stretch">
                    <div className="flex justify-between items-center gap-4 pt-6 px-6 rounded-t-sm border-t border-x border-grey03 bg-grey04">
                        <div className="grow flex gap-4 items-center">
                            {socEntIndex > -1 && (
                            <Tag
                                text={getStatus(socEntIndex)}
                                color={getStatusColor(socEntIndex)}
                                className="w-20 shrink-0"
                            />
                            )}
                            {formActive && (
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                    <TextInputHeading
                                        ref={ref}
                                        textSize="text-3.5xl"
                                        onChange={onChange}
                                        value={value}
                                        onBlur={handleSubmit(onNameSubmit)}
                                    />
                                )}
                            />
                            )}
                            {!formActive && (
                                <h4 className="font-vg-medium text-3.5xl p-1 text-black">{watch("name")}</h4>
                            )}
                        </div>
                        {userCanEdit && (
                        <div className="grow-0 flex gap-4 items-center">
                            <Button 
                                variant="outline" 
                                size="small" 
                                label={formActive ? UiConstants.DONE_BUTTON_LABEL : UiConstants.EDIT_BUTTON_LABEL}
                                leftIcon="PencilAltIcon" 
                                onClick={editClicked}
                            />
                            {getStatus(socEntIndex).toLowerCase() === EntityConstants.EntityStatus.ARCHIVED ? (
                                <Button 
                                    variant="delete" 
                                    size="small" 
                                    label="Unarchive"
                                    leftIcon="BriefcaseIcon" 
                                    onClick={unArchiveClicked}
                                />
                            ) : (
                                <Button 
                                    variant="delete" 
                                    size="small" 
                                    label="Archive"
                                    leftIcon="ArchiveIcon" 
                                    onClick={archiveClicked}
                                />
                            )}
                        </div>
                        )}
                    </div>
                    <Tabs 
                        titles={["Social Enterprise Details","More Information"]}
                        listClass="px-6 rounded-t-sm border-b border-x border-grey03 bg-grey04"
                    >
                        <TabPanel className="w-full grid grid-cols-12 gap-6 py-10">
                            <OrgEntPanelHeader title="Social Enterprise Details" subtitle="" className="col-span-5 pl-6"/>
                            <div className="col-span-7 flex flex-col items-stretch gap-8">
                            <div className="flex flex-col items-stretch gap-4">
                                <div className="flex flex-col items-stretch gap-2">
                                    <h6 className="font-vg-medium text-xl text-black">ABN Details</h6>
                                    <p className="font-vg-book text-sm text-grey">Does the social enterprise have its own ABN or operate under another organisation?</p>
                                </div>
                                <Controller
                                    name="abnBelongsTo"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <RadioButtonGroup
                                        options={UiConstants.ABN_BELONGS_TO_OPTIONS} 
                                        isDisabled={!formActive} 
                                        {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="relative">
                                <Controller
                                    name="abn"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                        ref={ref} 
                                        label="ABN" 
                                        variant={formActive ? "default" : "disabled"}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={handleSubmit(onAbnSubmit)}
                                        showHelp="always"
                                        help={abnSearchMsg}
                                        />
                                    )}
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                                    <button 
                                        className="flex items-center justify-center p-3"
                                        disabled={!formActive}
                                        onClick={doABNSearch}
                                    >
                                        <MagnifyingGlassCircleIcon className={formActive ? "h-6 w-6 text-blue" : "h-6 w-6 text-grey01"}/>
                                    </button>
                                </div>
                            </div>
                            <Controller
                                name="peoplePlanetFirstVerifiedOption"
                                control={control}
                                render={({ field, formState }) => (
                                    <SelectDropdown 
                                        label='Is this enterprise People and Planet First Verified?' 
                                        options={UiConstants.YES_NO_UNSURE_OPTIONS}
                                        selectedOption={field.value}
                                        isDisabled={!formActive}
                                        help={'Find out more here: <a href="https://peopleandplanetfirst.org/get-verified/" target="_blank" rel="noopener noreferrer">People and Planet First</a>'}
                                        {...field}
                                    />
                                )}
                            />
                            <div className={showPPFCertified ? "flex flex-col items-stretch gap-8" : "hidden"}>
                                <Controller
                                    name="peoplePlanetFirstYearOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Year verification was obtained" 
                                            options={UiUtils.getYearOptions()}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="peoplePlanetFirstCurrentOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Is this verification current?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <Controller
                                name="socialTradersCertifiedOption"
                                control={control}
                                render={({ field, formState }) => (
                                    <SelectDropdown 
                                        label='Is this enterprise certified by Social Traders?' 
                                        options={UiConstants.YES_NO_UNSURE_OPTIONS}
                                        selectedOption={field.value}
                                        isDisabled={!formActive}
                                        help={'Find out more about <a href="https://www.socialtraders.com.au/" target="_blank" rel="noopener noreferrer">Social Traders</a>'}
                                        {...field}
                                    />
                                )}
                            />
                            <div className={showSTCertified ? "flex flex-col items-stretch gap-8" : "hidden"}>
                                <Controller
                                    name="socialTradersYearOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Year certification was obtained" 
                                            options={UiUtils.getYearOptions()}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="socialTradersCurrentOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Is this certification current?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className={showNonCertified ? "flex flex-col items-stretch gap-8" : "hidden"}>
                                <OrgEntPanelHeader 
                                    title="Is my Enterprise a Social Enterprise?"
                                    subtitle='Seedkit is available for you to use regardless of how you answer these questions.'
                                />
                                <Controller
                                    name="publicBenefitOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Does your organisation exist to solve a social and/or environment problem?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            tooltip="As distinct from private profit or benefit to individuals"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="prioritisePurposeOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Does your organisation prioritise purpose, people and planet over profit in the way it operates?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="incomeFromSalesOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Does your enterprise derive a substantial portion of operating income from selling products/services?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="selfSustainingRevenueOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Does your organisation have or aim to have a self-sustaining revenue model?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="socialPurposeReinvestmentOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Does your enterprise reinvest the majority of its profit or surplus in meeting its social purpose?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />     
                                <Controller
                                    name="purposeLockInOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Does your organisation choose legal structures and financing that protect and lock-in purpose long term?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />     
                            </div>
                            </div>
                        </TabPanel>   
                        <TabPanel className="w-full grid grid-cols-12 gap-6 py-10">
                            <OrgEntPanelHeader title="More Information" subtitle="" className="col-span-5 pl-6"/>
                            <div className="col-span-7 flex flex-col items-stretch gap-6">
                            <Controller
                                name="socialEnterpriseTypeOption"
                                control={control}
                                render={({ field, formState }) => (
                                    <SelectDropdown 
                                        label="What is the nature of this enterprise?" 
                                        options={UiConstants.SOC_ENT_TYPE_OPTIONS}
                                        selectedOption={field.value}
                                        isDisabled={!formActive}
                                        {...field}
                                    />
                                )}
                            /> 
                            <div className={showStandalone ? "block" : "hidden"}>
                                <Controller
                                    name="standaloneTypeOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="If this enterprise is a standalone social enterprise, the entity type is:" 
                                            options={UiConstants.STANDALONE_TYPE_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>    
                            <div className={showStandaloneOther ? "block" : "hidden"} >
                                <Controller
                                    name="standaloneTypeOther"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref}  
                                            label="If Other, please specify" 
                                            variant={formActive ? "default" : "disabled"}
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onStandaloneTypeOtherSubmit)}
                                        />
                                    )}
                                />
                            </div>     
                            <Controller
                                name="primaryCustomerOption"
                                control={control}
                                render={({ field, formState }) => (
                                    <SelectDropdown 
                                        label="This enterprise's primary customers are:" 
                                        options={UiConstants.PRIMARY_CUSTOMER_OPTIONS}
                                        selectedOption={field.value}
                                        isDisabled={!formActive}
                                        {...field}
                                    />
                                )}
                            />     
                            <Controller
                                name="secondaryCustomers"
                                control={control}
                                render={({ field, formState }) => (
                                    <MultiSelectDropdown 
                                        label="Who are your secondary or subsequent customers in addition to the primary customer named above?" 
                                        options={UiConstants.SECONDARY_CUSTOMER_OPTIONS}
                                        help="You can select multiple options"
                                        selectedOptions={field.value}
                                        isDisabled={!formActive}
                                        {...field}
                                    />
                                )}
                            />     
                            <Controller
                                name="primaryStreamOption"
                                control={control}
                                render={({ field, formState }) => (
                                    <SelectDropdown 
                                        label="The industry that the primary business stream of this enterprise operates in is:" 
                                        options={UiConstants.BUSINESS_STREAM_OPTIONS}
                                        selectedOption={field.value}
                                        isDisabled={!formActive}
                                        {...field}
                                    />
                                )}
                            />     
                            <Controller
                                name="secondaryStreamOption"
                                control={control}
                                render={({ field, formState }) => (
                                    <SelectDropdown 
                                        label="The industry that the secondary business stream of this enterprise operates in is:" 
                                        options={UiConstants.BUSINESS_STREAM_OPTIONS}
                                        selectedOption={field.value}
                                        isDisabled={!formActive}
                                        {...field}
                                    />
                                )}
                            />     
                            <Controller
                                name="salesAreaOptions"
                                control={control}
                                render={({ field, formState }) => (
                                    <MultiSelectDropdown 
                                        label="This enterprise is currently selling..." 
                                        options={UiConstants.SALES_AREA_OPTIONS}
                                        help="You can select multiple options"
                                        selectedOptions={field.value}
                                        isDisabled={!formActive}
                                        {...field}
                                    />
                                )}
                            />
                            <div className={showCommPostcode ? "block" : "hidden"}>
                                <Controller
                                    name="communityPostcode"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref}  
                                            label="Enter the postcode of the local community you are selling to:" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onCommunityPostcodeSubmit)}
                                        />
                                    )}
                                />
                            </div>  
                            <div className={showRegion ? "block" : "hidden"}>
                                <Controller
                                    name="region"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput 
                                            ref={ref} 
                                            label="Enter the name of the region you are selling to:" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onRegionSubmit)}
                                        />
                                    )}
                                />
                            </div>
                            <div className={showState ? "block" : "hidden"}>
                                <Controller
                                    name="stateOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="If you selling to a single state, select it here:" 
                                            options={UiConstants.STATE_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />     
                            </div>
                            <div className={showMultipleStates ? "block" : "hidden"}>
                                <Controller
                                    name="multipleStateOptions"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <MultiSelectDropdown 
                                            label="If you are selling to multiple states, select them here:" 
                                            options={UiConstants.MULTI_STATE_OPTIONS}
                                            help="You can select multiple options"
                                            selectedOptions={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />     
                            </div>
                            <div className="h-0 border border-grey02"></div>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <h5 className="font-vg-medium text-xl leading-120 text-black">
                                        Third party Certification/Memberships
                                    </h5>
                                    <h6 className="font-vg-medium text-base leading-130 text-black">
                                        Third party certifications or memberships this enterprise holds:
                                    </h6>
                                    <p className="font-vg-book text-base leading-130 text-grey">
                                        Third-party certifications are an increasingly popular component of the social enterprise ecosystem. 
                                        Third-party certification means that an independent organisation has reviewed your business or product 
                                        and has independently determined that it complies with their specific standards. Third-party certifications 
                                        may help to provide transparency and legitimacy.
                                    </p>
                                </div>
                                <div className="flex justify-between items-center gap-2">
                                    <div className="grow shrink">
                                        <SelectDropdown 
                                            label="Select Certification" 
                                            options={certOptions}
                                            selectedOption={certIndex > -1 ? certOptions[certIndex] : null}
                                            onChange={certificationChanged}
                                        />
                                    </div>
                                    {userCanEdit && (
                                        <Button variant={formActive ? "outline" : "disabled"} size="small" label="Add" leftIcon="PlusSmIcon" className="px-8 mt-7" onClick={addCertificationClicked}/>
                                    )}
                                </div>
                                <Controller
                                    name="name"
                                    control={certControl}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput 
                                            ref={ref}
                                            label="Name of certification or membership" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={certHandleSubmit(onCertNameSubmit)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="year"
                                    control={certControl}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput 
                                            ref={ref}
                                            label="Year obtained" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={certHandleSubmit(onYearSubmit)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="currentOption"
                                    control={certControl}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Is this certification or membership current?" 
                                            options={UiConstants.YES_NO_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="flex flex-start">
                                    {userCanEdit && (
                                        <Button variant={formActive ? "delete" : "disabled"} size="small" label="Delete this certification" className="px-8" onClick={deleteCertificationClicked}/>
                                    )}
                                </div>     
                            </div>
                            <div className="h-0 border border-grey02"></div>
                            <div className="flex flex-col gap-4">
                                <h5 className="font-vg-medium text-xl leading-120 text-black">
                                    Operational Details
                                </h5>
                                <Controller
                                    name="operationStartYearOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="Since which year has this enterprise been operating:" 
                                            options={UiUtils.getYearOptions()}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />     
                                <Controller
                                    name="purposeOptions"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <MultiSelectDropdown 
                                            label="The type that best describes this enterprise is..." 
                                            options={UiConstants.PURPOSE_OPTIONS}
                                            help="You can select multiple options"
                                            selectedOptions={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className={showPurposeOther ? "block" : "hidden"}>
                                    <Controller
                                        name="purposeOtherText"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput 
                                            ref={ref} 
                                            label="If Other, please specify" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onPurposeOtherTextSubmit)}
                                        />
                                        )}
                                    />
                                </div>     
                                <Controller
                                    name="benefitOptions"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <MultiSelectDropdown 
                                            label="This social enterprise primarily aims to benefit..." 
                                            options={UiConstants.BENEFIT_OPTIONS}
                                            help="You can select multiple options"
                                            selectedOptions={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />  
                                <div className={showBenefitOther ? "block" : "hidden"}>
                                    <Controller
                                        name="benefitOtherText"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <OtherItemsInput
                                            ref={ref}
                                            label="If Other, please specify"
                                            addLabel="Add another"
                                            value={value}
                                            disabled={!formActive}
                                            onChange={onChange}
                                            onBlur={handleSubmit(onBenefitOtherSubmit)}
                                        />
                                        )}
                                    />
                                </div>   
                                <Controller
                                    name="numberEmployeesOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown 
                                            label="How many employees does the business have?" 
                                            options={UiConstants.NUM_EMPLOYEE_OPTIONS}
                                            selectedOption={field.value}
                                            isDisabled={!formActive}
                                            tooltip='You can select indicators to document and track the characteristics of your workforce at "Indicators" workspace'
                                            {...field}
                                        />
                                    )}
                                />     
                            </div>
                            <div className="h-0 border border-grey02"></div>
                            <div className="flex flex-col gap-4">
                                <h5 className="font-vg-medium text-xl leading-120 text-black">
                                    Social Enterprise Location
                                </h5>
                                <Controller
                                    name="officeAddress"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref} 
                                            label="Head office address" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onOfficeAddressSubmit)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="opPostcodesLGAs"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInput
                                            ref={ref} 
                                            label="Postcode(s) or LGA(s) of the operational site(s)" 
                                            variant={formActive ? "default" : "disabled"} 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onOpPostcodesLGAsSubmit)}
                                        />
                                    )}
                                />
                            </div>   
                            </div>
                        </TabPanel>   
                    </Tabs>
                </form>
                <Footer/>
                </div>
            </div>
            <TrialUpgradeModal
                open={upgradeModalOpen}
                onUpgrade={(_) => onTrialUpgradeDone()}
                onClose={() => setUpgradeModalOpen(false)}
            />
            <TermsAgreementModal
                open={termsModalOpen}
                onAgreement={() => setTermsModalOpen(false)}
            />
        </div>
    );
}
