import IconTooltip from "./IconTooltip";

export default function IndicatorPanelHeader({ title, subtitle, tipContent, tipAfter }) {
    return (
        <div className="flex flex-col gap-4 grow">
            <div className="flex items-center gap-2">
                <h4 className="font-vg-medium text-black text-[32px]">
                    {title}
                </h4>
                {tipContent && tipContent !== "" && tipAfter === "title" && (
                    <IconTooltip content={tipContent} position="right"/>
                )}
            </div>
            <div className="flex items-start gap-2">
                <p className="font-vg-book text-black text-base leading-130" dangerouslySetInnerHTML={{__html: subtitle}}/>
                {tipContent && tipContent !== "" && tipAfter === "subtitle" && (
                    <IconTooltip content={tipContent} position="right"/>
                )}
            </div>
        </div>
    );
}