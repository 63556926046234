import { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import * as UiConstants from '../constants/UiConstants';
import { GlobalContext } from '../context/GlobalContext';

import Button from './Button';
import TextInput from './TextInput';
import SelectDropdown from './SelectDropdown';

export default function UserModal({ params, open, onSave, onClose, saving }) {
    const { context } = useContext(GlobalContext);

    const getRoleOptions = useCallback(() => {
        let options = [UiConstants.EMPTY_OPTION];
        if (context.organisation && context.organisation.roles) {
            let opts = context.organisation.roles.map(r => ({ value: r._id, label: r.name }));
            opts.sort((a,b) => {
                if (a.label < b.label) {
                    return -1
                } else if (a.label > b.label) {
                    return 1;
                } else {
                    return 0;
                }
            });
            options = options.concat(opts);
        }
        return options;
    }, [context]);

    const getRoleOptionFromValue = (options, value) => {
        const index = options.map(opt => opt.value).indexOf(value);
        if (index > -1) {
            return options[index];
        } else {
            return UiConstants.EMPTY_OPTION
        }
    }

    const getTitle = (action) => {
        if (action === UiConstants.ActionKeys.EDIT) {
            return "Edit Existing User";
        } else {
            return "Create New User";
        }
    }

    const [roleOptions, setRoleOptions] = useState(getRoleOptions());

    const [title, setTitle] = useState(getTitle(""));

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please enter an email address')
            .email('Please enter a valid email address'),
        roleOption: Yup.object().shape({ value: Yup.string().required("Please select a role")})
    });
    
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            roleOption: UiConstants.EMPTY_OPTION,
            email: ""
        }
    };

    const { 
        control, 
        handleSubmit,
        reset
    } = useForm(formOptions);

    /* Interaction Functions */

    const onCancelClick = (e) => {
        e.preventDefault();
        onClose();
    };

    const onFormSubmit = (data) => {
        let update = false;
        let details = {
            action: params.action
        };
        switch (params.action) {
            case UiConstants.ActionKeys.ADD:
                details.id = "";
                details.firstName = data.firstName;
                details.lastName = data.lastName;
                details.jobTitle = data.jobTitle;
                details.roleId = data.roleOption.value;
                details.email = data.email;
                update = true;
                break;

            case UiConstants.ActionKeys.EDIT:
                details.id = params.id;
                if (params.firstName !== data.firstName) {
                    details.firstName = data.firstName;
                    update = true;
                }
                if (params.lastName !== data.lastName) {
                    details.lastName = data.lastName;
                    update = true;
                }
                if (params.jobTitle !== data.jobTitle) {
                    details.jobTitle = data.jobTitle;
                    update = true;
                }
                if (params.roleId !== data.roleOption.value) {
                    details.roleId = data.roleOption.value;
                    update = true;
                }
                if (params.email !== data.email) {
                    details.email = data.email;
                    update = true;
                }
                break;
        
            default:
                break;
        }
        if (update && onSave) {
            onSave(details);
        }
        //onClose();
    };

    useEffect(() => {
        if (params.action) {
            const options = getRoleOptions();
            if (options.length !== roleOptions.length) {
                setRoleOptions(options);
            }
    
            setTitle(getTitle(params.action));
    
            const resetValues = {
                firstName: params.firstName ? params.firstName : "",
                lastName: params.lastName ? params.lastName : "",
                jobTitle: params.jobTitle ? params.jobTitle : "",
                roleOption: getRoleOptionFromValue(options, params.roleId ? params.roleId : ""),
                email: params.email ? params.email : ""
            };
            reset(resetValues);
        }
    }, [params, getRoleOptions, reset, roleOptions]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"                
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                                <Dialog.Title
                                    as="h4"
                                    className="font-vg-medium text-3.5xl text-black leading-110"
                                >
                                    {title}
                                </Dialog.Title>

                                <form className="flex flex-col items-stretch gap-8" onSubmit={handleSubmit(onFormSubmit)}>
                                    <div className="grid grid-cols-2 gap-4">
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            render={({ field, formState }) => (
                                                <TextInput 
                                                    label="First Name" 
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            render={({ field, formState }) => (
                                                <TextInput 
                                                    label="Last Name" 
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="roleOption"
                                            control={control}
                                            render={({ field, formState }) => (
                                                <SelectDropdown 
                                                    label="Role" 
                                                    options={roleOptions}
                                                    selectedOption={field.value}
                                                    showHelp="always"
                                                    help={formState.errors.roleOption?.value?.message} 
                                                    {...field}
                                                    />
                                            )}
                                        />
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field, formState }) => (
                                                <TextInput 
                                                    label="Email"
                                                    showHelp="always"
                                                    help={formState.errors.email?.message} 
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="grid grid-cols-2 gap-3">
                                        {saving ? (
                                        <div className="col-span-2 h-[52px] flex justify-center items-center">
                                            <p className="font-vg-regular text-base text-blue">Saving...</p>
                                        </div>
                                        ) : (
                                        <>
                                            <Button variant="outline" size="large" label="Cancel" className="w-full" onClick={onCancelClick}/>
                                            <Button variant="solid" size="large" label="Save" className="w-full" type="submit"/>
                                        </>
                                        )}
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}