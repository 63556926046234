import { useId } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import * as UiConstants from '../constants/UiConstants';

export default function DeletableTag(props) {
    const { id, label, value, color, size, className, onDeleteClick, ...otherProps } = props;

    const newId = useId();
    const tagId = id ? id : newId;

    let bgColor = color;
    let textColor = "text-black";
    let textSize = size && size === "large" ? "text-sm" : "text-xs";
    let markClass = size && size === "large" ? "w-4 h-4" : "w-3 h-3";
    switch (color) {
        case UiConstants.TagColor.GREEN:
            bgColor = 'bg-green03 border border-green03';
            break;
        case UiConstants.TagColor.YELLOW:
            bgColor = 'bg-yellow03 border border-yellow3';
            break;
        case UiConstants.TagColor.PURPLE:
            bgColor = 'bg-purple03 border border-purple03';
            break;
        case UiConstants.TagColor.GREY:
            bgColor = 'bg-grey04 border border-grey04';
            break;
        case UiConstants.TagColor.BLACK_OUTLINE:
            bgColor = 'bg-white border border-black';
            break;
        default:
            break;
    }
    let tagClass = `flex items-center justify-between gap-2 font-vg-medium text-black rounded-full px-3 py-2 leading-110 text-center ${textSize} ${textColor} ${bgColor}`;
    if (className) {
        tagClass = `${tagClass} ${className}`;
    }
    return (
        <div key={`tag-${tagId}`} className={tagClass} { ...otherProps }>
            <span className="text-left">{label}</span>
            <button
                onClick={e => {
                    e.preventDefault();
                    if (onDeleteClick) {
                        onDeleteClick(value);
                    }
                }}
            >
                <XMarkIcon className={markClass}/>
            </button>
        </div>
    );
}
