import { forwardRef, useId } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import enAU from 'date-fns/locale/en-AU';

import UiUtils from '../utils/UiUtils';
import DataUtils from '../utils/DataUtils';
import * as DataConstants from '../constants/DataConstants';

import '../styles/custom-react-datepicker.css';

registerLocale('en-AU', enAU);

var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(utc);
dayjs.extend(customParseFormat)

const CustomReactDatePicker = forwardRef((props, ref) => {
    const { id, label, help, showHelp, value, frequency, isDisabled, format, placeholder, onChange } = props;

    const newId = useId();
    const inputId = id ? id : newId;

    const pickerFreq = frequency ? frequency : DataConstants.FrequencyType.DAILY;

    const buttonInputClass = UiUtils.classNames(isDisabled ? "border-grey03" : "border-grey01", "h-12 w-full font-vg-book text-base text-left leading-130 text-grey p-3 rounded-md border bg-grey04");

    const DatePickerButton = forwardRef((btnProps, btnRef) => {
        let val = btnProps.value;
        if (val) {
            switch (pickerFreq) {
                case DataConstants.FrequencyType.WEEKLY:
                    let parts = val.split(", ");
                    parts[0] = (parseInt(parts[0]) - 1).toString();
                    val = "Week " + parts.join(", ");
                    break;
                case DataConstants.FrequencyType.FINANCIAL_YEAR:
                    const yearVal = parseInt(val);
                    val = "FY " + (yearVal - 1).toString() + "-" + val;
                    break;
                default:
                    break;
            }
        }
        return (
        <button 
            ref={btnRef}
            className={buttonInputClass}
            onClick={(e) => {
                e.preventDefault();
                btnProps.onClick(e);
            }} 
        >
            {val ? val : (isDisabled ? "" : (btnProps.placeholder ? btnProps.placeholder : "Select Date"))}
        </button>
    )});

    const getDatePicker = () => {
        return (
            <DatePicker
                ref={ref}
                wrapperClassName="w-full"
                className={buttonInputClass}
                calendarClassName="p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 text-xs2 font-normal"
                selected={DataUtils.getLocalDate(value)}
                onChange={(date) => {
                    const utcDate = DataUtils.getUtcDate(date);
                    if (onChange) {
                        onChange(utcDate);
                    }
                }}
                placeholderText={placeholder ? placeholder : "Select Date"}
                customInput={<DatePickerButton/>}
                popperPlacement="bottom-start"
                dateFormat={format ? format : "d MMM yyyy"}
            />
        );
    }

    const getWeekPicker = () => {
        return (
            <DatePicker
                ref={ref}
                wrapperClassName="w-full"
                className={buttonInputClass}
                calendarClassName="p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 text-xs2 font-normal"
                selected={DataUtils.getLocalDate(value)}
                onChange={(date) => {
                    const utcDate = DataUtils.getUtcDate(date);
                    if (onChange) {
                        onChange(utcDate);
                    }
                }}
                locale="en-AU"
                showWeekNumbers
                showWeekPicker
                placeholderText={placeholder ? placeholder : "Select Week"}
                customInput={<DatePickerButton/>}
                popperPlacement="bottom-start"
                dateFormat={format ? format : "w, yyyy"}
            />
        );
    }
    const getMonthPicker = () => {
        return (
            <DatePicker
                ref={ref}
                wrapperClassName="w-full"
                className={buttonInputClass}
                calendarClassName="p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 text-xs2 font-normal"
                selected={DataUtils.getLocalDate(value)}
                onChange={(date) => {
                    const utcDate = DataUtils.getUtcDate(date);
                    if (onChange) {
                        onChange(utcDate);
                    }
                }}
                showMonthYearPicker
                placeholderText={placeholder ? placeholder : "Select Month"}
                customInput={<DatePickerButton value={value}/>}
                popperPlacement="bottom-start"
                dateFormat={format ? format : "MMM yyyy"}
            />
        );
    }

    const getQuarterPicker = () => {
        return (
            <DatePicker
                ref={ref}
                wrapperClassName="w-full"
                className={buttonInputClass}
                calendarClassName="p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 text-xs2 font-normal"
                selected={DataUtils.getLocalDate(value)}
                onChange={(date) => {
                    const utcDate = DataUtils.getUtcDate(date);
                    if (onChange) {
                        onChange(utcDate);
                    }
                }}
                showQuarterYearPicker
                placeholderText={placeholder ? placeholder : "Select Quarter"}
                customInput={<DatePickerButton value={value}/>}
                popperPlacement="bottom-start"
                dateFormat={format ? format : "QQQ yyyy"}
            />
        );
    }

    const getAnnualPicker = () => {
        return (
            <DatePicker
                ref={ref}
                wrapperClassName="w-full"
                className={buttonInputClass}
                calendarClassName="p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 text-xs2 font-normal"
                selected={DataUtils.getLocalDate(value)}
                onChange={(date) => {
                    const utcDate = DataUtils.getUtcDate(date);
                    if (onChange) {
                        onChange(utcDate);
                    }
                }}
                showYearPicker
                placeholderText={placeholder ? placeholder : "Select Year"}
                customInput={<DatePickerButton value={value}/>}
                popperPlacement="bottom-start"
                dateFormat={format ? format : "yyyy"}
            />
        );
    }

    const getFinYearPicker = () => {
        return (
            <DatePicker
                ref={ref}
                wrapperClassName="w-full"
                className={buttonInputClass}
                calendarClassName="p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 text-xs2 font-normal"
                selected={DataUtils.getLocalDate(value)}
                onChange={(date) => {
                    const utcDate = DataUtils.getUtcDate(date);
                    if (onChange) {
                        onChange(utcDate);
                    }
                }}
                showYearPicker
                placeholderText={placeholder ? placeholder : "Select Financial Year"}
                customInput={<DatePickerButton value={value}/>}
                popperPlacement="bottom-start"
                dateFormat={format ? format : "yyyy"}
            />
        );
    }

    const getPicker = (freq) => {
        switch (freq) {
            case DataConstants.FrequencyType.WEEKLY:
                return getWeekPicker();
            case DataConstants.FrequencyType.MONTHLY:
                return getMonthPicker();
            case DataConstants.FrequencyType.QUARTERLY:
                return getQuarterPicker();
            case DataConstants.FrequencyType.ANNUALLY:
                return getAnnualPicker();
            case DataConstants.FrequencyType.FINANCIAL_YEAR:
                return getFinYearPicker();
            default:
                return getDatePicker();
        }
    }

    return (
        <div>
            {label && (
                <label htmlFor={inputId} className="block font-vg-book text-base leading-120 text-grey mb-1.5">{label}</label>
            )}
            <div className="relative">
                {getPicker(pickerFreq)}
                <div className="absolute inset-y-0 right-3 flex items-center justify-center">
                    <CalendarIcon className="w-6 h-6 text-grey01"/>
                </div>
            </div>
            {(help || showHelp === "always") && (
                <p className="font-vg-book text-xs2 leading-[0.975rem] text-grey mt-1 h-[0.975rem]">{help}</p>
            )}
        </div>
    );
});

export default CustomReactDatePicker;
