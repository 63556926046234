import { useNavigate } from 'react-router-dom';
import { QuestionMarkCircleIcon, BuildingOfficeIcon, BuildingStorefrontIcon, 
    UsersIcon, SparklesIcon, TableCellsIcon, LightBulbIcon, SquaresPlusIcon } from '@heroicons/react/24/solid';

import Button from './Button';

export default function KitHomeNewFeature(props) {
    const { icon, title, buttonLabel, link } = props;

    const navigate = useNavigate();

    let Icon = QuestionMarkCircleIcon;
    switch (icon) {
        case "BuildingOfficeIcon":
            Icon = BuildingOfficeIcon;
            break;
        case "BuildingStorefrontIcon":
            Icon = BuildingStorefrontIcon;
            break;
        case "UsersIcon":
            Icon = UsersIcon;
            break;
        case "SparklesIcon":
            Icon = SparklesIcon;
            break;
        case "TableCellsIcon":
            Icon = TableCellsIcon;
            break;
        case "LightBulbIcon":
            Icon = LightBulbIcon;
            break;
        case "SquaresPlusIcon":
            Icon = SquaresPlusIcon;
            break;
        default:
            break;
    }


    const buttonClicked = () => {
        navigate(link);
    }

    return(
        <div className="flex flex-col items-stretch justify-between gap-6 h-full py-8 px-6 rounded-lg bg-white shadow-standard">
            <div className="flex items-start justify-start grow-0 shrink-0">
                <div className="w-[72px] h-[72px] flex items-center justify-center rounded-lg bg-purple03">
                    <Icon className="w-9 h-9 text-purple01"/>
                </div>
            </div>
            <h4 className="font-vg-medium text-3.5xl text-black leading-110">{title}</h4>
            <Button variant="solid" size="large" label={buttonLabel} onClick={buttonClicked} className="grow-0 shrink-0"/>
        </div>
    );
}