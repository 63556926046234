import { useContext } from 'react';

import UiUtils from '../utils/UiUtils';
import * as EntityConstants from '../constants/EntityConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';
import { GlobalContext } from '../context/GlobalContext';

import PathwayTable from './PathwayTable';
import Button from './Button';

export default function IndicatorListContainer({ selectedUnit, onListAction, pathwayRefresh, userCanEdit }) {
    const { context } = useContext(GlobalContext);

    let index = -1;
    let domains = [];
    if (context.organisation) {
        const org = context.organisation;
        let domainDup = [];
        let pathwayDomains = [];
        switch (selectedUnit.type) {
            case EntityConstants.EntityType.ENTERPRISE:
                index = org.enterprises ? org.enterprises.map(e => e._id).indexOf(selectedUnit.id) : -1;
                if (index > -1) {
                    domainDup = org.enterprises[index].indicators.map(ind => ({ need: ind.reportingNeed, domain: ind.domain }));
                    pathwayDomains = org.enterprises[index].pathways.map(pwy => pwy.domain);
                }
                break;
            case EntityConstants.EntityType.PROGRAM:
                index = org.programs ? org.programs.map(e => e._id).indexOf(selectedUnit.id) : -1;
                if (index > -1) {
                    domainDup = org.programs[index].indicators.map(ind => ({ need: ind.reportingNeed, domain: ind.domain }));
                    pathwayDomains = org.programs[index].pathways.map(pwy => pwy.domain);
                }
                break;
            case EntityConstants.EntityType.PROJECT:
                index = org.projects ? org.projects.map(e => e._id).indexOf(selectedUnit.id) : -1;
                if (index > -1) {
                    domainDup = org.projects[index].indicators.map(ind => ({ need: ind.reportingNeed, domain: ind.domain }));
                    pathwayDomains = org.projects[index].pathways.map(pwy => pwy.domain);
                }
                break;
            default:
                break;
        }
        domainDup.forEach(dn => {
            if (domains.filter(d => d.domain === dn.domain).length === 0 && dn.domain.trim() !== "") {
                domains.push(dn);
            }
        });
        domains = domains.filter(d => pathwayDomains.indexOf(d.domain) > -1);
    }

    const onAddDomainPathwayClick = (need, domain) => {
        if (onListAction) {
            onListAction({ 
                action: IndicatorConstants.ActionType.CREATE_PATHWAY_WITH_DOMAIN, 
                args: { need: need, domain: domain }
            });
        }
    }

    return (
        <div className="flex flex-col gap-10 px-6 py-10 rounded-lg bg-grey04">
            <h3 className="font-vg-medium text-[38px] text-black leading-120">{`Indicators for ${selectedUnit.name}:`}</h3>
            {domains.map((d,i) => {
                const Icon = UiUtils.getDomainIcon(d.domain);
                return (
                    <div key={`ind-list-${i}`} className="flex flex-col items-stretch gap-4">
                        <div className="flex items-center gap-3">
                            <div className={`flex items-center justify-center w-10 h-10 rounded border ${d.need === IndicatorConstants.ReportingNeed.CHANGING_THE_WORLD ? "bg-purple03 border-grey03" : "border-grey03 bg-yellow03"}`}>
                                <Icon className={`w-6 h-6  ${d.need === IndicatorConstants.ReportingNeed.CHANGING_THE_WORLD ? "text-purple01": "text-yellow01"}`}/>
                            </div>
                            <h5 className="font-vg-medium text-2xl text-black leading-120">{d.domain}</h5>
                        </div>
                        <PathwayTable
                            selectedUnit={selectedUnit}
                            domain={d.domain} 
                            onTableAction={details => {
                                if (onListAction) {
                                    onListAction(details);
                                }
                            }}
                            refresh={pathwayRefresh}
                            userCanEdit={userCanEdit}
                        />
                        {userCanEdit && (
                            <div className="flex w-full justify-center">
                                <Button
                                    variant="outline"
                                    size="large"
                                    label="Add Another Outcome Area in this Domain"
                                    rightIcon="PlusCircleIcon"
                                    iconType="solid"
                                    onClick={() => onAddDomainPathwayClick(d.need, d.domain)}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}