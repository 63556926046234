import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

export default function HomeStep({ number, title, text }) {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    return(
        <>
            {isDesktop && (
            <div className="flex flex-col items-start gap-8">
                <div className="flex justify-center items-center font-vg-medium text-[38px] h-[68px] w-[68px] rounded-xl bg-purple03 text-purple01">{number}</div>
                <div className="flex flex-col items-stretch gap-4">
                    <h4 className="font-vg-medium text-black text-[32px] leading-110">{title}</h4>
                    <p className="font-vg-book text-black text-base">{text}</p>
                </div>
            </div>
            )}
            {isMobile && (
            <div className="flex flex-col items-start gap-4">
                <div className="flex justify-center items-center font-vg-medium text-[28px] h-[50px] w-[50px] rounded-xl bg-purple03 text-purple01">{number}</div>
                <div className="flex flex-col items-stretch gap-3">
                    <h4 className="font-vg-medium text-black text-[28px] leading-110">{title}</h4>
                    <p className="font-vg-book text-black text-base">{text}</p>
                </div>
            </div>
            )}
        </>
    );
}