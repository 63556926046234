import { forwardRef, useId } from 'react';
import { lookupIcon } from 'heroicons-lookup';

import IconTooltip from './IconTooltip';

const TextInput = forwardRef((props, ref) => {
    const { id, type, variant, label, help, icon, showHelp, helpColor, className, required, tooltip, onChange, onIconClick, ...otherProps } = props;
    const newId = useId();
    const inputId = id ? id : newId;
    const inputType = type ? type : "text";
    let inputClass = "w-full p-3 font-vg-book text-base bg-grey04 border rounded-md";
    switch (variant) {
        case "default":
            inputClass = `${inputClass} text-black border-grey01 hover:border-blue active:border-blue`;
            break;
        case "error":
            inputClass = `${inputClass} text-red border-red hover:border-blue active:border-blue`;
            break;
        case "success":
            inputClass = `${inputClass} text-green04 border-green04 hover:border-blue active:border-blue`;
            break;
        case "filled":
            inputClass = `${inputClass} text-black border-grey01 hover:border-blue active:border-blue`;
            break;
        case "disabled":
            inputClass = `${inputClass} text-grey border-grey03 hover:border-grey03 active:border-grey03 focus:ring-grey03 focus:border-grey03`;
            break;
        case "active":
            inputClass = `${inputClass} text-grey border-blue`;
            break;
        case "indicator-title":
            inputClass = `${inputClass} text-blue border-grey01 hover:border-blue active:border-blue`;
            inputClass = inputClass.replace("font-vg-book", "font-vg-medium");
            break;
        default:
            inputClass = `${inputClass} text-black border-grey01 hover:border-blue active:border-blue`;
            break;
    }
    let Icon = null;
    if (icon) {
        Icon = lookupIcon(icon, "outline");
    }

    const getLabel = () => {
        if (tooltip) {
            if (required) {
                return (
                    <div className="flex items-center gap-2 mb-1.5">
                        <label htmlFor={inputId} className="block font-vg-book text-base leading-120">
                            <span className="text-grey">{label}</span><span className="text-red">&nbsp;&nbsp;*</span>
                        </label>
                        <IconTooltip content={tooltip} position="top"/>
                    </div>
                );
            } else {
                return (
                    <div className="flex items-center gap-2 mb-1.5">
                        <label htmlFor={inputId} className="block font-vg-book text-base leading-120 text-grey">
                            {label}
                        </label>
                        <IconTooltip content={tooltip} position="top"/>
                    </div>
                );
            }
        } else {
            if (required) {
                return (
                    <label htmlFor={inputId} className="block font-vg-book text-base leading-120 mb-1.5">
                        <span className="text-grey">{label}</span><span className="text-grey">&nbsp;&nbsp;*</span>
                    </label>
                );
            } else {
                return (
                    <label htmlFor={inputId} className="block font-vg-book text-base leading-120 text-grey mb-1.5">
                        {label}
                    </label>
                );
            }
        }
    };
    
    return (
        <div className={className}>
            {label && getLabel()}
            <div className="relative">
                {Icon && ( onIconClick ? (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer" onClick={onIconClick}>
                        <Icon className="color-black h-6"/>
                    </div>
                ) : (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <Icon className="color-black h-6"/>
                    </div>
                ))}
                <input
                    ref={ref} 
                    id={inputId}
                    type={inputType}
                    className={inputClass}
                    readOnly={variant === "disabled"}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    { ...otherProps }
                />
            </div>
            {(help || showHelp === "always") && (
                <p className={`font-vg-book text-xs2 leading-[0.975rem] mt-1.5 h-[0.975rem] ${variant === "error" ? "text-red" : (helpColor ? helpColor :"text-grey")}`}>{help}</p>
            )}
        </div>
    );
});

export default TextInput;