import '../styles/lexical-button.css';

import UiUtils from '../utils/UiUtils';

export default function LexicalButton({
   'data-test-id': dataTestId,
   children,
   className,
   onClick,
   disabled,
   small,
   title,
}) {
return (
   <button
      disabled={disabled}
      className={UiUtils.classNames(
         'Button__root',
         disabled && 'Button__disabled',
         small && 'Button__small',
         className,
      )}
      onClick={onClick}
      title={title}
      aria-label={title}
      {...(dataTestId && {'data-test-id': dataTestId})}
   >
      {children}
   </button>
);
}
