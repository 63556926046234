import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, FormProvider } from 'react-hook-form';
//import { yupResolver } from '@hookform/resolvers/yup';
//import * as Yup from 'yup';
import { cloneDeep } from 'lodash';

import DashboardUtils from '../utils/DashboardUtils';
import * as DashboardConstants from '../constants/DashboardConstants';
import * as EntityConstants from '../constants/EntityConstants';
import * as UiConstants from '../constants/UiConstants';
import { GlobalContext } from '../context/GlobalContext';

import Button from './Button';
import DashboardElementTypeStep from './DashboardElementTypeStep';
import DashboardElementSizeStep from './DashboardElementSizeStep';
import DashboardElementSourceStep from './DashboardElementSourceStep';
import DashboardElementCustomStep from './DashboardElementCustomStep';

export default function DashboardElementModal({ params, open, onSave, onClose }) {
    const { context } = useContext(GlobalContext);

    const [step, setStep] = useState(0);
    const [lastStep, setLastStep] = useState(1);

    const [sizeStepVisible, setSizeStepVisible] = useState(false);
    const [sourceStepVisible, setSourceStepVisible] = useState(false);
    const [customStepVisible, setCustomStepVisible] = useState(false);

    const [typeStepStatus, setTypeStepStatus] = useState(DashboardConstants.StepStatus.INCOMPLETE);
    const [sizeStepStatus, setSizeStepStatus] = useState(DashboardConstants.StepStatus.INCOMPLETE);
    const [sourceStepStatus, setSourceStepStatus] = useState(DashboardConstants.StepStatus.INCOMPLETE);
    const [customStepStatus, setCustomStepStatus] = useState(DashboardConstants.StepStatus.INCOMPLETE);

    const [typeStepOpen, setTypeStepOpen] = useState(true);
    const [sizeStepOpen, setSizeStepOpen] = useState(false);
    const [sourceStepOpen, setSourceStepOpen] = useState(false);
    const [customStepOpen, setCustomStepOpen] = useState(false);

    const [elementType, setElementType] = useState("");
    const [elementSubType, setElementSubType] = useState("");
    const [secondaryY, setSecondaryY] = useState(false);
    const [datasets, setDatasets] = useState([]);

    const [sizeStepDetails, setSizeStepDetails] = useState([]);

    const defaultValues = {
        elementType: DashboardConstants.ElementType.NONE,
        title: "",
        text: "",
        row: 0,
        col: 0,
        width: 0,
        height: 0,
        elementSubTypeOption: UiConstants.EMPTY_OPTION,
        intervalTypeOption: UiConstants.EMPTY_OPTION,
        startDate: null,
        endDate: null,
        datasetOptions: [],
        datasetDetails: [],
        showLegend: true,
        showDataLabels: false,
        showXLabel: false,
        xLabel: "",
        showYLabel: false,
        yLabel: "",
        hasSecondaryY: false,
        secondaryYLabel: "",
        showFYTotals: false,
        showFullRangeTotals: false,
        showIndicatorTotals: false,
        groupByOutcomeAreas: false,
        groupByOutcomeArea: false,
        includeOutcomeAreaSubtotals: false,
    };
   
    const formOptions = { 
        defaultValues: defaultValues
    };

    const methods = useForm(formOptions);

    /* UI Change Functions */

    const doReset = (retain) => {
        let resetDefaults = JSON.parse(JSON.stringify(defaultValues));
        let values = methods.getValues();
        retain.forEach(r => {
            resetDefaults[r] = values[r];
        });
        methods.reset(resetDefaults);
    };

    const setOpenStep = (index) => {
        switch (index) {
            case DashboardConstants.Step.TYPE.index:
                setTypeStepOpen(true);
                setSizeStepOpen(false);
                setSourceStepOpen(false);
                setCustomStepOpen(false);
                break;
            case DashboardConstants.Step.SIZE.index:
                setTypeStepOpen(false);
                setSizeStepOpen(true);
                setSourceStepOpen(false);
                setCustomStepOpen(false);
                break;
            case DashboardConstants.Step.SOURCE.index:
                setTypeStepOpen(false);
                setSizeStepOpen(false);
                setSourceStepOpen(true);
                setCustomStepOpen(false);
                break;
            case DashboardConstants.Step.CUSTOM.index:
                setTypeStepOpen(false);
                setSizeStepOpen(false);
                setSourceStepOpen(false);
                setCustomStepOpen(true);
                break;
            default:
                break;
        }
    }

    const setStepEnd = (type) => {
        if (type === DashboardConstants.ElementType.TEXT) {
            setLastStep(1);
        } else if (type === DashboardConstants.ElementType.GRAPH) {
            setLastStep(3);
        } else {
            setLastStep(2);
        }
    }

    /* Interaction Functions */

    const onBackClick = (e) => {
        e.preventDefault();
        if (step === 0) {
            onClose();
        } else {
            const newStep = step - 1;
            setStep(newStep);
            setOpenStep(newStep);
        }
    };

    const onNextClick = (e) => {
        e.preventDefault();
        if (step < lastStep) {
            const newStep = step + 1;
            setStep(newStep);
            setOpenStep(newStep);
        }
    }
 
    const onSubmit = (data) => {
        let element = cloneDeep(data);
        element._id = params.element.id;
        let details = {
            boardId: params.boardId,
            page: params.page,
            element: element,
        }
        if (onSave) {
            onSave(details);
        }
        onClose();
    }

    const onTypeStepAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.TOGGLE_STEP_TYPE:
                const newTypeStepOpen = !typeStepOpen;
                setTypeStepOpen(!typeStepOpen);
                if (newTypeStepOpen) {
                    setStep(0);
                }
                break;

            case DashboardConstants.ActionType.TYPE_STEP_COMPLETE:
                setTypeStepStatus(DashboardConstants.StepStatus.COMPLETE);
                setElementType(details.args.elementType);
                setSizeStepVisible(true);
                setSourceStepVisible(details.args.elementType !== DashboardConstants.ElementType.TEXT ? true : false);
                setCustomStepVisible(details.args.elementType === DashboardConstants.ElementType.GRAPH ? true : false);
                setStepEnd(details.args.elementType);
                doReset(["elementType"]);
                break;

            default:
                break;
        }
    }

    const onSizeStepAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.TOGGLE_STEP_SIZE:
                const newSizeStepOpen = !sizeStepOpen;
                setSizeStepOpen(newSizeStepOpen);
                if (newSizeStepOpen) {
                    setStep(1);
                }
                break;

            case DashboardConstants.ActionType.SIZE_STEP_COMPLETE:
                setSizeStepStatus(DashboardConstants.StepStatus.COMPLETE);
                setElementSubType(details.args.elementSubType);
                setStep(1);
                break;

            case DashboardConstants.ActionType.SET_ELEMENT_SUBTYPE:
                setElementSubType(details.args.elementSubType);
                break;
    
            default:
                break;
        }
    }

    const onSourceStepAction = (details) => {
        //TODO: onSourceStepAction
        switch (details.action) {
            case DashboardConstants.ActionType.TOGGLE_STEP_SOURCE:
                const newSourceStepOpen = !sourceStepOpen;
                setSourceStepOpen(newSourceStepOpen);
                if (newSourceStepOpen) {
                    setStep(2);
                }
                break;
        
            case DashboardConstants.ActionType.GRAPH_DATA_UPDATED:
                setDatasets(details.args.datasets);
                break;
        
            case DashboardConstants.ActionType.SECONDARY_Y_UPDATED:
                setSecondaryY(details.args.secondaryY);
                if (details.args.secondaryY === false) {
                    let newDatasets = cloneDeep(datasets);
                    for (let d = 0; d < newDatasets.length; d++) {
                        newDatasets[d].isSecondaryY = false;
                    }
                    setDatasets(newDatasets);
                    methods.setValue("datasetDetails", newDatasets);
                }
                break;
        
            case DashboardConstants.ActionType.SOURCE_STEP_COMPLETE:
                setSourceStepStatus(DashboardConstants.StepStatus.COMPLETE);
                break;
        
            default:
                break;
        }
    }

    const onCustomStepAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.TOGGLE_STEP_CUSTOM:
                const newCustomStepOpen = !customStepOpen;
                setCustomStepOpen(newCustomStepOpen);
                if (newCustomStepOpen) {
                    setStep(3);
                }
                break;
        
            case DashboardConstants.ActionType.GRAPH_DATA_UPDATED:
                setDatasets(details.args.datasets);
                break;
        
            case DashboardConstants.ActionType.CUSTOM_STEP_COMPLETE:
                setCustomStepStatus(DashboardConstants.StepStatus.COMPLETE);
                break;
        
            default:
                break;
        }
    }

    /* useEffect Functions */

    useEffect(() => {
        const getEntityType = (entity) => {
            let entityType = EntityConstants.EntityType.NONE;
            if (context.organisation) {
                let index = -1;
                if (context.organisation.enterprises) {
                    index = context.organisation.enterprises.map(e => e._id).indexOf(entity);
                    if (index > -1) {
                        entityType = EntityConstants.EntityType.ENTERPRISE;
                    }
                }
                if (index === -1 && context.organisation.programs) {
                    index = context.organisation.programs.map(p => p._id).indexOf(entity);
                    if (index > -1) {
                        entityType = EntityConstants.EntityType.PROGRAM;
                    }
                }
                if (index === -1 && context.organisation.projects) {
                    index = context.organisation.projects.map(p => p._id).indexOf(entity);
                    if (index > -1) {
                        entityType = EntityConstants.EntityType.PROJECT;
                    }
                }
            }
            return entityType;
        }

        if (open) {
            let values = {
                elementType: params.element.elementType ? params.element.elementType : DashboardConstants.ElementType.NONE,
                title: params.element.title ? params.element.title : "",
                text: params.element.text ? params.element.text: "",
                row: params.row ? params.row : 0,
                col: params.col ? params.col : 0,
                width: params.width ? params.width : 0,
                height: params.height ? params.height : 0,
                startDate: params.element.startDate ? params.element.startDate : null,
                endDate: params.element.endDate ? params.element.endDate : null,
                showLegend: params.element.showLegend ? params.element.showLegend : false,
                showDataLabels: params.element.showDataLabels ? params.element.showDataLabels : false,
                showXLabel: params.element.showXLabel ? params.element.showXLabel : false,
                xLabel: params.element.xLabel ? params.element.xLabel : "",
                showYLabel: params.element.showYLabel ? params.element.showYLabel : false,
                yLabel: params.element.yLabel ? params.element.yLabel : "",
                hasSecondaryY: params.element.hasSecondaryY ? params.element.hasSecondaryY : false,
                secondaryYLabel: params.element.secondaryYLabel ? params.element.secondaryYLabel : "",
                showFYTotals: params.element.showFYTotals ? params.element.showFYTotals : false,
                showFullRangeTotals: params.element.showFullRangeTotals ? params.element.showFullRangeTotals : false,
                showIndicatorTotals: params.element.showIndicatorTotals ? params.element.showIndicatorTotals : false,
                groupByOutcomeArea: params.element.groupByOutcomeArea ? params.element.groupByOutcomeArea : false,
                includeOutcomeAreaSubtotals: params.element.includeOutcomeAreaSubtotals ? params.element.includeOutcomeAreaSubtotals : false
            }

            let elementSubTypeOption = UiConstants.EMPTY_OPTION;
            if (params.element.elementSubType) {
                let elementSubTypeIndex = UiConstants.DASH_ALL_SUBTYPE_OPTIONS.map(opt => opt.value).indexOf(params.element.elementSubType);
                if (elementSubTypeIndex > -1) {
                    elementSubTypeOption = UiConstants.DASH_ALL_SUBTYPE_OPTIONS[elementSubTypeIndex];
                } 
            }
            values.elementSubTypeOption = elementSubTypeOption;
            
            let intervalTypeOption = UiConstants.EMPTY_OPTION;
            if (params.element.intervalType) {
                let intervalTypeIndex = UiConstants.DASH_INTERVAL_OPTIONS.map(opt => opt.value).indexOf(params.element.intervalType);
                if (intervalTypeIndex > -1) {
                    intervalTypeOption = UiConstants.DASH_INTERVAL_OPTIONS[intervalTypeIndex];
                } 
            }
            values.intervalTypeOption = intervalTypeOption;

            let rdOptions = DashboardUtils.getRequiredDataOptions(context, false);
            let datasetOptions = [];
            if (params.element.datasets) {
                params.element.datasets.forEach(ds => {
                    const entityType = ds.entityType && ds.entityType !== "" ? ds.entityType : getEntityType(ds.entity);
                    const valueCheck = `${entityType}|${ds.entity}|${ds.dataKey}|${ds.intervalType}`;
                    let index = rdOptions.map(rdo => rdo.value).indexOf(valueCheck);
                    if (index > -1) {
                        datasetOptions.push(rdOptions[index]);
                    }
                });
            }
            values.datasetOptions = datasetOptions;
            let existingDetails = params.element.datasets ? DashboardUtils.datasetsToDetails(context, params.element.datasets) : [];
            values.datasetDetails = DashboardUtils.updateDatasetDetails(values.elementType, values.elementSubTypeOption.value, datasetOptions, existingDetails);

            methods.reset(values);
            setDatasets(values.datasetDetails);
            setElementType(values.elementType);
            setElementSubType(values.elementSubTypeOption.value);
            setSecondaryY(values.hasSecondaryY);

            setStep(0);
            setOpenStep(0);

            switch (values.elementType) {
                case DashboardConstants.ElementType.TEXT:
                    setLastStep(1);
                    setSizeStepVisible(true);
                    setSourceStepVisible(false);
                    setCustomStepVisible(false);
                    break;
                case DashboardConstants.ElementType.TABLE:
                    setLastStep(2);
                    setSizeStepVisible(true);
                    setSourceStepVisible(true);
                    setCustomStepVisible(false);
                    break;
                case DashboardConstants.ElementType.GRAPH:
                    setLastStep(3);
                    setSizeStepVisible(true);
                    setSourceStepVisible(true);
                    setCustomStepVisible(true);
                    break;
                default:
                    setLastStep(1);
                    setSizeStepVisible(false);
                    setSourceStepVisible(false);
                    setCustomStepVisible(false);
                    break;
            }

            setSizeStepDetails({
                boardId: params.boardId,
                pageIndex: params.page,
                elementId: params.element.id
            })
        }
    }, [open, context, methods, params]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"                
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-col items-stretch gap-12 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                                <FormProvider {...methods}>
                                    <form className="flex flex-col items-stretch gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
                                        <div className="flex flex-col items-stretch gap-8">
                                            <Dialog.Title
                                                as="h4"
                                                className="font-vg-medium text-3.5xl text-black leading-110"
                                            >
                                                New reporting element
                                            </Dialog.Title>
                                            <div className="flex flex-col gap-4">
                                                <DashboardElementTypeStep 
                                                    status={typeStepStatus}
                                                    open={typeStepOpen}
                                                    onStepAction={onTypeStepAction}
                                                />
                                                <DashboardElementSizeStep 
                                                    details={sizeStepDetails}
                                                    visible={sizeStepVisible} 
                                                    status={sizeStepStatus}
                                                    open={sizeStepOpen}
                                                    elementType={elementType}
                                                    onStepAction={onSizeStepAction}
                                                />
                                                <DashboardElementSourceStep
                                                    visible={sourceStepVisible} 
                                                    status={sourceStepStatus}
                                                    open={sourceStepOpen}
                                                    elementType={elementType}
                                                    elementSubType={elementSubType}
                                                    onStepAction={onSourceStepAction}
                                                />
                                                <DashboardElementCustomStep
                                                    visible={customStepVisible} 
                                                    status={customStepStatus}
                                                    open={customStepOpen}
                                                    graphType={elementSubType}
                                                    secondaryY={secondaryY}
                                                    datasets={datasets}
                                                    onStepAction={onCustomStepAction}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-3">
                                            <Button variant="outline" size="large" label="Back" className="w-full" onClick={onBackClick}/>
                                            {step < lastStep ? (
                                                <Button variant="solid" size="large" label="Next" className="w-full" onClick={onNextClick}/>
                                            ) : (
                                                <Button variant="solid" size="large" type="submit" label="Save" className="w-full"/>
                                            )}
                                        </div>
                                    </form>
                                </FormProvider>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}