import { TabPanel } from '@headlessui/react';

import * as UiConstants from '../constants/UiConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';

import IndicatorPanelHeader from './IndicatorPanelHeader';
import Table from './Table';
import Tag from './Tag';
import TextInput from './TextInput';
import Button from './Button';

export default function IndicatorReviewPanel({ pathwayName, reportingNeed, domain, outcomeArea, selection, onStepPanelAction }) {

    let inputIndicators = selection.filter(in1 => in1.indicatorType === "Input");
    inputIndicators.sort((a, b) => {
        if (a.subIndex < b.subIndex) {
            return -1
        } else if (b.subIndex < a.subIndex) {
            return 1;
        } else {
            return 0;
        }
    });

    let outputIndicators = selection.filter(in2 => in2.indicatorType === "Output");
    outputIndicators.sort((a, b) => {
        if (a.subIndex < b.subIndex) {
            return -1
        } else if (b.subIndex < a.subIndex) {
            return 1;
        } else {
            return 0;
        }
    });

    let outcomeIndicators = selection.filter(in3 => in3.indicatorType === "Outcome");
    outcomeIndicators.sort((a, b) => {
        if (a.subIndex < b.subIndex) {
            return -1
        } else if (b.subIndex < a.subIndex) {
            return 1;
        } else {
            return 0;
        }
    });

    const onPathwayNameChanged = (e) => {
        if (onStepPanelAction) {
            onStepPanelAction({ action: IndicatorConstants.ActionType.CHANGE_NAME_PATHWAY, args: e.currentTarget.value});
        }
    }

    const onReviewAction = (key, index, row) => {
        switch (key) {
            case UiConstants.ActionKeys.EDIT:
                if (onStepPanelAction) {
                onStepPanelAction({ action: IndicatorConstants.ActionType.EDIT_INDICATOR, args: row._id});
                }
                break;
            case UiConstants.ActionKeys.DUPLICATE:
                if (onStepPanelAction) {
                onStepPanelAction({ action: IndicatorConstants.ActionType.DUPLICATE_INDICATOR, args: row._id});
                }
                break;
            case UiConstants.ActionKeys.DELETE:
                if (window.confirm("Are you sure you want to remove this indicator from your pathway?")) {
                if (onStepPanelAction) {
                    onStepPanelAction({ action: IndicatorConstants.ActionType.DELETE_INDICATOR, args: row._id});
                }
                }
                break;
            default:
                break;
        }
    }

    const sendCreateIndicatorWithType = (type) => {
        if (onStepPanelAction) {
            onStepPanelAction({
                action: IndicatorConstants.ActionType.CREATE_INDICATOR_WITH_TYPE,
                args: {
                title: "",
                reportingNeed: reportingNeed,
                domain: domain,
                outcomeArea: outcomeArea,
                type: type
                }
            });
        }
    }

    const onAddInputClick = () => {
        sendCreateIndicatorWithType(IndicatorConstants.IndicatorType.INPUT);
    }

    const onAddOutputClick = () => {
        sendCreateIndicatorWithType(IndicatorConstants.IndicatorType.OUTPUT);
    }

    const onAddOutcomeClick = () => {
        sendCreateIndicatorWithType(IndicatorConstants.IndicatorType.OUTCOME);
    }

    return(
        <TabPanel className="flex flex-col items-stretch px-6 py-8 gap-10 rounded-lg bg-grey04 border border-grey03">
            <div className="flex flex-col items-stretch gap-3">
                <IndicatorPanelHeader
                    title="View and edit indicators"
                    subtitle="Click on the editing button to set up your indicators, and click on 'Save & Complete' when you are ready to finalise this impact pathway"
                    tipContent=""
                />
                <div className="grid grid-cols-2 gap-6">
                <div className="flex items-center gap-6">
                    <p className="font-vg-regular text-xs text-black">Domain:</p>
                    <Tag text={domain} size="large" color={UiConstants.TagColor.BLACK_OUTLINE}/>
                </div>
                <div className="flex items-center gap-6">
                    <p className="font-vg-regular text-xs text-black">Outcome area:</p>
                    <Tag text={outcomeArea} size="large" color={UiConstants.TagColor.BLACK_OUTLINE}/>
                </div>
                </div>
            </div>
            <div className="flex flex-col items-stretch gap-[54px]">
                <TextInput 
                    variant="default" 
                    label="Impact Pathway name" 
                    value={pathwayName} 
                    onChange={onPathwayNameChanged}
                />
                <div className="flex flex-col items-stretch gap-10">
                <div className="flex flex-col items-stretch">
                    <div className="flex items-center justify-between border-b border-grey">
                        <h6 className="font-vg-medium text-xl text-black leading-120">
                            Input indicators
                        </h6>
                        <Button variant="indicator-ghost" size="small" label="Add Input Indicator" onClick={onAddInputClick}/>
                    </div>
                    <Table
                        columns={["title"]}
                        data={inputIndicators}
                        variant="lined"
                        hideHeaders="true"
                        scrolling="false"
                        actions={UiConstants.INDICATOR_REVIEW_ACTIONS}
                        onActionClick={onReviewAction}
                        noDataMessage="No Input indicators were selected"
                    />
                </div>
                <div className="flex flex-col items-stretch">
                    <div className="flex items-center justify-between border-b border-grey">
                        <h6 className="font-vg-medium text-xl text-black leading-120">
                            Output indicators
                        </h6>
                        <Button variant="indicator-ghost" size="small" label="Add Output Indicator" onClick={onAddOutputClick}/>
                    </div>
                    <Table
                        columns={["title"]}
                        data={outputIndicators}
                        variant="lined"
                        hideHeaders="true"
                        scrolling="false"
                        actions={UiConstants.INDICATOR_REVIEW_ACTIONS}
                        onActionClick={onReviewAction}
                        noDataMessage="No Output indicators were selected"
                    />
                </div>
                <div className="flex flex-col items-stretch">
                    <div className="flex items-center justify-between border-b border-grey">
                        <h6 className="font-vg-medium text-xl text-black leading-120">
                            Outcome indicators
                        </h6>
                        <Button variant="indicator-ghost" size="small" label="Add Outcome Indicator" onClick={onAddOutcomeClick}/>
                    </div>
                    <Table
                        columns={["title"]}
                        data={outcomeIndicators}
                        variant="lined"
                        hideHeaders="true"
                        scrolling="false"
                        actions={UiConstants.INDICATOR_REVIEW_ACTIONS}
                        onActionClick={onReviewAction}
                        noDataMessage="No Outcome indicators were selected"
                    />
                </div>
                </div>
            </div>
        </TabPanel>
    );
}