import { forwardRef, useId } from 'react';

const TextArea = forwardRef((props, ref) => {
    const { id, variant, label, help, showHelp, className, onChange, children, ...otherProps } = props;
    const newId = useId();
    const textAreaId = id ? id : newId;
    let textAreaClass = "w-full p-3 font-vg-book text-base bg-grey04 border rounded-md hover:border-blue active:border-blue";
    switch (variant) {
        case "default":
            textAreaClass = `${textAreaClass} text-black border-grey01`;
            break;
        case "error":
            textAreaClass = `${textAreaClass} text-red border-red`;
            break;
        case "success":
            textAreaClass = `${textAreaClass} text-green04 border-green04`;
            break;
        case "filled":
            textAreaClass = `${textAreaClass} text-black border-grey01`;
            break;
        case "disabled":
            textAreaClass = `${textAreaClass} text-grey border-grey03 hover:border-grey03`;
            break;
        case "active":
            textAreaClass = `${textAreaClass} text-grey border-blue`;
            break;
        case "indicator-title":
            textAreaClass = `${textAreaClass} text-blue border-grey01`;
            textAreaClass = textAreaClass.replace("font-vg-book", "font-vg-medium");
            break;
        default:
            textAreaClass = `${textAreaClass} text-black border-grey01`;
            break;
    }
    return (
        <div className={className}>
            {label && (
                <label htmlFor={textAreaId} className="block font-vg-book text-base leading-120 text-grey mb-1.5">{label}</label>
            )}
            <textarea
                ref={ref} 
                id={textAreaId}
                className={textAreaClass}
                disabled={variant === "disabled"}
                onChange={(e) => {
                    if (onChange) {
                        onChange(e);
                    }
                }}
                { ...otherProps }
            >
                {children}
            </textarea>
            {(help || showHelp === "always") && (
                <p className="font-vg-book text-xs2 leading-[0.975rem] text-grey mt-1.5 h-[0.975rem]">{help}</p>
            )}
        </div>
    );
});

export default TextArea;