import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
   $getSelection,
   $isRangeSelection,
   $createParagraphNode,
   $isRootOrShadowRoot,
   $isElementNode,
   CAN_REDO_COMMAND,
   CAN_UNDO_COMMAND,
   COMMAND_PRIORITY_CRITICAL,
   COMMAND_PRIORITY_LOW,
   COMMAND_PRIORITY_NORMAL,
   FORMAT_ELEMENT_COMMAND,
   FORMAT_TEXT_COMMAND,
   INDENT_CONTENT_COMMAND,
   OUTDENT_CONTENT_COMMAND,
   REDO_COMMAND,
   SELECTION_CHANGE_COMMAND,
   UNDO_COMMAND,
} from 'lexical';
import { 
   $isListNode,
   INSERT_CHECK_LIST_COMMAND, 
   INSERT_ORDERED_LIST_COMMAND, 
   INSERT_UNORDERED_LIST_COMMAND,
   insertList,
   ListNode
} from '@lexical/list';
import { 
   $createHeadingNode, 
   $createQuoteNode,
   $isHeadingNode 
} from '@lexical/rich-text';
import { $setBlocksType, $patchStyleText, $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $isTableNode, $isTableSelection } from '@lexical/table';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { 
   $findMatchingParent, 
   $getNearestNodeOfType,
   mergeRegister 
} from '@lexical/utils';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin';

import { useCallback, useEffect, useState } from 'react';

import * as LexicalConstants from '../constants/LexicalConstants';
import LexicalUtils from '../utils/LexicalUtils';
import useLexicalModal from '../hooks/useLexicalModal';
import {
   INSERT_IMAGE_COMMAND,
   InsertImageDialog,
   InsertImagePayload,
} from './LexicalImagePlugin';
import { InsertInlineImageDialog } from './LexicalInlineImagePlugin';
import { InsertTableDialog } from './LexicalTableExtra';
import InsertLayoutDialog from './LexicalInsertLayoutDialog';
import LexicalDropdown from './LexicalDropDown';
import LexicalColourPicker from './LexicalColourPicker';

function LexicalDivider() {
   return <div className="divider"/>;
}

export default function LexicalToolbarPlugin({variant, setIsLinkEditMode}) {
   const PRESETS_LENGTH = 14;
   const toolbarVariant = variant ? variant : "standard";

   const [editor] = useLexicalComposerContext();
   const [activeEditor, setActiveEditor] = useState(editor);
   const [canUndo, setCanUndo] = useState(false);
   const [canRedo, setCanRedo] = useState(false);
   const [isBold, setIsBold] = useState(false);
   const [isItalic, setIsItalic] = useState(false);
   const [isUnderline, setIsUnderline] = useState(false);
   const [isStrikethrough, setIsStrikethrough] = useState(false);
   const [isSubscript, setIsSubscript] = useState(false);
   const [isSuperscript, setIsSuperscript] = useState(false);
   const [isLink, setIsLink] = useState(false);
   const [modal, showModal] = useLexicalModal();

   const [selectedAlignment, setSelectedAlignment] = useState(LexicalConstants.AlignmentOption.LEFT);
   const [selectedBlockFormat, setSelectedBlockFormat] = useState(LexicalConstants.BlockFormatOption.PARAGRAPH);
   const [selectedOtherFormat, setSelectedOtherFormat] = useState(LexicalConstants.EMPTY_OPTION);
   const [selectedInsertElement, setSelectedInsertElement] = useState(LexicalConstants.EMPTY_OPTION);

   const [rootType, setRootType] = useState('root');

   const [fontColor, setFontColor] = useState('#000');
   const [fontPresets, setFontPresets] = useState(['#000']);
   const [bgColor, setBgColor] = useState('#fff');
   const [bgPresets, setBgPresets] = useState(['#fff']);

   const $updateToolbar = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
         const anchorNode = selection.anchor.getNode();
         let element =
            anchorNode.getKey() === 'root'
               ? anchorNode
               : $findMatchingParent(anchorNode, (e) => {
                 const parent = e.getParent();
                 return parent !== null && $isRootOrShadowRoot(parent);
               });
   
         if (element === null) {
            element = anchorNode.getTopLevelElementOrThrow();
         }
   
         const elementKey = element.getKey();
         const elementDOM = activeEditor.getElementByKey(elementKey);

         // Update text format
         setIsBold(selection.hasFormat('bold'));
         setIsItalic(selection.hasFormat('italic'));
         setIsUnderline(selection.hasFormat('underline'));
         setIsStrikethrough(selection.hasFormat('strikethrough'));
         setIsSubscript(selection.hasFormat('subscript'));
         setIsSuperscript(selection.hasFormat('superscript'));

         if (selection.hasFormat('strikethrough')) {
            setSelectedOtherFormat(LexicalConstants.OtherFormatOption.STRIKETHROUGH);
         } else if (selection.hasFormat('subscript')) {
            setSelectedOtherFormat(LexicalConstants.OtherFormatOption.SUBSCRIPT);
         } else if (selection.hasFormat('superscript')) {
            setSelectedOtherFormat(LexicalConstants.OtherFormatOption.SUPERSCRIPT);
         } else {
            setSelectedOtherFormat(LexicalConstants.EMPTY_OPTION);
         }

         // Update links
         const node = LexicalUtils.getSelectedNode(selection);
         const parent = node.getParent();
         if ($isLinkNode(parent) || $isLinkNode(node)) {
            setIsLink(true);
         } else {
            setIsLink(false);
         }

         // Handle tables
         const tableNode = $findMatchingParent(node, $isTableNode);
         if ($isTableNode(tableNode)) {
           setRootType('table');
         } else {
           setRootType('root');
         }

         // Update block format
         if (elementDOM !== null) {
            if ($isListNode(element)) {
               const parentList = $getNearestNodeOfType(
                  anchorNode,
                  ListNode,
               );
               const type = parentList
                  ? parentList.getListType()
                  : element.getListType();
                  setSelectedBlockFormat(LexicalConstants.BlockFormatByType[type]);
            } else {
               const type = $isHeadingNode(element)
                  ? element.getTag()
                  : element.getType();
               if (type in LexicalConstants.BlockFormatByType) {
                  setSelectedBlockFormat(LexicalConstants.BlockFormatByType[type]);
               }
            }
         }

         // Update color
         setFontColor(
            $getSelectionStyleValueForProperty(selection, 'color', '#000'),
         );
         setBgColor(
            $getSelectionStyleValueForProperty(
               selection,
               'background-color',
               '#fff',
            ),
         );

         // Update alignment
         let matchingParent;
         if ($isLinkNode(parent)) {
            // If node is a link, we need to fetch the parent paragraph node to set format
            matchingParent = $findMatchingParent(
               node,
               (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
            );
         }
         // If matchingParent is a valid node, pass it's format type
         const formatType = $isElementNode(matchingParent) ? matchingParent.getFormatType()
            : $isElementNode(node) ? node.getFormatType() : parent?.getFormatType() || 'left';
         setSelectedAlignment(LexicalConstants.AlignmentByFormat[formatType]);
      }
   }, []);

   useEffect(() => {
      return editor.registerCommand(
         SELECTION_CHANGE_COMMAND,
         (_payload, newEditor) => {
            $updateToolbar();
            setActiveEditor(newEditor);
            return false;
         },
         COMMAND_PRIORITY_CRITICAL,
      );
   }, [editor, $updateToolbar]);

   useEffect(() => {
      return mergeRegister(
         activeEditor.registerUpdateListener(({editorState}) => {
            editorState.read(() => {
               $updateToolbar();
            });
         }),
         activeEditor.registerCommand(
            CAN_UNDO_COMMAND,
            (payload) => {
               setCanUndo(payload);
               return false;
            },
            COMMAND_PRIORITY_CRITICAL,
         ),
         activeEditor.registerCommand(
            CAN_REDO_COMMAND,
            (payload) => {
               setCanRedo(payload);
               return false;
            },
            COMMAND_PRIORITY_CRITICAL,
         ),
         activeEditor.registerCommand(
            INSERT_UNORDERED_LIST_COMMAND,
            () => {
               insertList(activeEditor, 'bullet');
               return true;
            },
            COMMAND_PRIORITY_LOW,
         ),
         activeEditor.registerCommand(
            INSERT_ORDERED_LIST_COMMAND,
            () => {
               insertList(activeEditor, 'number');
               return true;
            },
            COMMAND_PRIORITY_LOW,
         ),
         activeEditor.registerCommand(
            INSERT_CHECK_LIST_COMMAND,
            () => {
               insertList(activeEditor, 'check');
               return true;
            },
            COMMAND_PRIORITY_LOW,
         ),
      );
   }, [activeEditor, editor, $updateToolbar]);

   /* Dispatch Commands */

   const commandDispatch = (command, payload) => {
      switch (command) {
         case LexicalConstants.Command.FORMAT_ELEMENT:
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, payload);
            break;

         case LexicalConstants.Command.INDENT_CONTENT:
            activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
            break;

         case LexicalConstants.Command.OUTDENT_CONTENT:
            activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
            break;

         case LexicalConstants.Command.INSERT_UNORDERED_LIST:
            activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
            break;
         
         case LexicalConstants.Command.INSERT_ORDERED_LIST:
            activeEditor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
            break;                   

         case LexicalConstants.Command.INSERT_CHECK_LIST:
            activeEditor.dispatchCommand(INSERT_CHECK_LIST_COMMAND);
            break;                   
   
         case LexicalConstants.Command.FORMAT_TEXT:
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, payload);
            break;
   
         case LexicalConstants.Command.INSERT_HORIZONTAL_RULE:
            activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, payload);
            break;
   
         case LexicalConstants.Command.INSERT_EMBED:
            activeEditor.dispatchCommand(INSERT_EMBED_COMMAND, payload);
            break;

         default:
            break;
      }
   }

   /* Handle Block Format */

   const formatParagraph = () => {
      activeEditor.update(() => {
         let selection = $getSelection();
         if ($isRangeSelection(selection)) {
               $setBlocksType(selection, () => $createParagraphNode());
         }
      })
   }

   const formatHeading = (headingSize) => {
      activeEditor.update(() => {
         let selection = $getSelection();
         $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
   }

   const formatQuote = () => {
      activeEditor.update(() => {
         let selection = $getSelection();
         $setBlocksType(selection, () => $createQuoteNode());
      });
   }

   const handleBlockFormat = (selection) => {
      switch (selection.action) {
         case LexicalConstants.ActionType.COMMAND:
            if (selection.value !== selectedBlockFormat.value) {
               commandDispatch(selection.command, selection.payload ? selection.payload : null);
            } else {
               formatParagraph();
            }
            break;

         case LexicalConstants.ActionType.PARAGRAPH:
            formatParagraph();
            break;
               
         case LexicalConstants.ActionType.HEADING:
            if (selection.value !== selectedBlockFormat.value) {
               formatHeading(selection.payload);
            }
            break;
               
         case LexicalConstants.ActionType.QUOTE:
            if (selection.value !== selectedBlockFormat.value) {
               formatQuote();
            }
            break;
      
         default:
            break;
      }
      if (selection.setSelected) {
         setSelectedBlockFormat(selection);
      }
   }

   /* Handle Alignment */

   const handleAlignment = (selection) => {
      commandDispatch(selection.command, selection.payload);
      if (selection.setSelected) {
         setSelectedAlignment(selection);
      }
   }

   /* Handle Links */

   const insertLink = useCallback(() => {
      if (!isLink) {
         setIsLinkEditMode(true);
         activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, LexicalUtils.sanitizeUrl('https://'));
      } else {
         setIsLinkEditMode(false);
         activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
   }, [activeEditor, isLink, setIsLinkEditMode]);
   
   /* Handle Other Formats */

   const handleOtherFormats = (selection) => {
      if (selection.action === LexicalConstants.ActionType.COMMAND) {
         commandDispatch(selection.command, selection.payload);
      }
   }

   /* Handle Color Setting */
   
   const applyStyleText = useCallback((styles) => {
      activeEditor.update(() => {
         const selection = $getSelection();
         if (selection !== null) {
            $patchStyleText(selection, styles);
         }
      });
   }, [activeEditor]);
   
   const handleFontColor = useCallback((color) => {
      setFontColor(color);
      if (fontPresets.indexOf(color) === -1) {
         let fp = JSON.parse(JSON.stringify(fontPresets));
         fp.unshift(color);
         fp.slice(0, PRESETS_LENGTH);
         setFontPresets(fp);
      }
      applyStyleText({color: color});
   }, [applyStyleText, fontPresets]);

   const handleBackgroundColor = useCallback((color) => {
      setBgColor(color);
      if (bgPresets.indexOf(color) === -1) {
         let bp = JSON.parse(JSON.stringify(bgPresets));
         bp.unshift(color);
         bp.slice(0, PRESETS_LENGTH);
         setBgPresets(bp);
      }
      applyStyleText({'background-color': color});
   }, [applyStyleText, bgPresets]);

   /* Handle Element Insert */

   const handleElementInsert = (selection) => {
      switch (selection.action) {
         case LexicalConstants.ActionType.COMMAND:
            commandDispatch(selection.command, selection.payload);
            break;
      
         case LexicalConstants.ActionType.IMAGE:
            showModal('Insert Image', (onClose) => (
               <InsertImageDialog
                  activeEditor={activeEditor}
                  onClose={onClose}
               />
            ));
            break;
      
         case LexicalConstants.ActionType.INLINE_IMAGE:
            showModal('Insert Image', (onClose) => (
               <InsertInlineImageDialog
                  activeEditor={activeEditor}
                  onClose={onClose}
               />
            ));
            break;

         case LexicalConstants.ActionType.TABLE:
            showModal('Insert Table', (onClose) => (
               <InsertTableDialog
                 activeEditor={activeEditor}
                 onClose={onClose}
               />
            ));
            break;

         case LexicalConstants.ActionType.LAYOUT:
            showModal('Insert Columns Layout', (onClose) => (
               <InsertLayoutDialog
                 activeEditor={activeEditor}
                 onClose={onClose}
               />
             ));

         default:
            break;
      }
   }

   return (
      <div className="toolbar">
         <button
            disabled={!canUndo}
            onClick={() => {
               activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            className="toolbar-item spaced"
            aria-label="Undo"
         >
            <i className="format undo"/>
         </button>
         <button
            disabled={!canRedo}
            onClick={() => {
               activeEditor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            className="toolbar-item"
            aria-label="Redo"
         >
            <i className="format redo" />
         </button>
         <LexicalDivider/>
         <LexicalDropdown
            options={LexicalConstants.BLOCK_FORMAT_OPTIONS}
            selectedOption={selectedBlockFormat}
            listWidth="w-40"
            hideText={toolbarVariant === LexicalConstants.ToolbarVariant.STANDARD ? false : true}
            onChange={(selection) => {
               handleBlockFormat(selection);
            }}
         />
         <LexicalDivider/>
         <button
            onClick={() => {
               activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
            aria-label="Format Bold"
         >
            <i className="format bold"/>
         </button>
         <button
            onClick={() => {
               activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
            aria-label="Format Italics"
         >
            <i className="format italic"/>
         </button>
         <button
            onClick={() => {
               activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
            aria-label="Format Underline"
         >
            <i className="format underline"/>
         </button>
         <button
            onClick={() => insertLink()}
            className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
            aria-label="Insert link"
         >
            <i className="format link"/>
         </button>
         <LexicalColourPicker
            colour={fontColor}
            colourLine={true}
            presets={fontPresets}
            icon="font-color"
            onChange={(color) => {
               handleFontColor(color);
            }}
         />
         <LexicalColourPicker
            colour={bgColor}
            colourLine={true}
            presets={bgPresets}
            icon="bg-color"
            onChange={(color) => {
               handleBackgroundColor(color);
            }}
         />
         <LexicalDropdown
            options={LexicalConstants.OTHER_FORMAT_OPTIONS}
            selectedOption={selectedOtherFormat}
            selectIcon="dropdown-more"
            listWidth="w-40"
            hideText={toolbarVariant === LexicalConstants.ToolbarVariant.STANDARD ? false : true}
            onChange={(selection) => {
               handleOtherFormats(selection);
            }}
         />
         <LexicalDivider/>
         <LexicalDropdown
            options={LexicalConstants.ALIGNMENT_OPTIONS}
            selectedOption={selectedAlignment}
            listWidth="w-40"
            onChange={(selection) => {
               handleAlignment(selection);
            }}
         />
         <LexicalDivider/>
         <LexicalDropdown
            options={LexicalConstants.INSERT_ELEMENT_OPTIONS}
            selectedOption={selectedInsertElement}
            selectIcon="plus"
            selectText="Insert"
            listWidth="w-40"
            onChange={(selection) => {
               handleElementInsert(selection);
            }}
         />
         {modal}
      </div>
   );
}
