import { useContext, useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import * as GeneralConstants from '../constants/GeneralConstants';
import UiUtils from '../utils/UiUtils';
import AuthService from '../services/AuthService';
import OrgService from '../services/OrgService';
import { GlobalContext } from '../context/GlobalContext';

import Button from '../components/Button';
import IconTooltip from './IconTooltip';

export default function KitTopBar({ banner, onDataRefresh, onBannerClick }) {
   const { width } = useWindowSize();
   const isMobile = (width > 0 && UiUtils.isMobile(width));

   const navigate = useNavigate();

   const {context, setContextValues} = useContext(GlobalContext);
   const [contextStatus, setContextStatus] = useState(GeneralConstants.ContextStatus.OK);

   function clearContext() {
      setContextValues([
         { key: "user", value: {} },
         { key: "organisation", value: {} },
         { key: "authToken", value: "" },
         { key: "selections", value: {} }
      ]);
   };

   function logout() {
      clearContext();
      AuthService.logout()
      .then(response => {
         navigate('/');
      })
      .catch(error => console.log(error));
      return false;
   }

   const title = `Welcome to Seedkit${context.user && context.user.firstName ? `, ${context.user.firstName}` : ``}`;

   const bannerTextCss = banner ? `font-vg-regular text-xs leading-110 ${banner.foreColor}` : "";

   /* Data Refresh Functions */

   useEffect(() => {
      if ((!context.hasOwnProperty("organisation") || !context.hasOwnProperty("user")) && contextStatus === GeneralConstants.ContextStatus.OK) {
         setContextStatus(GeneralConstants.ContextStatus.LOADING);
         OrgService.getOrgData(true)
         .then(response => {
            setContextStatus(GeneralConstants.ContextStatus.OK);
            setContextValues([
               { key: "organisation", value: response.data.organisation },
               { key: "user", value: response.data.user }
            ]);
            if (onDataRefresh) {
               onDataRefresh();
            }
         })
         .catch(err => {
            setContextStatus(GeneralConstants.ContextStatus.ERROR);
            alert(GeneralConstants.CONTEXT_ERROR);
            console.log(err);
         });
      }
   }, [context, contextStatus, onDataRefresh, setContextValues]);

   return(
      <>
      {isMobile && (
         <Navigate to="/desktoponly" replace={true}/>
      )}
      {!isMobile && (
         <div className="w-full h-15 shrink-0 flex justify-between items-center px-10 bg-grey04 font-vg-regular text-grey gap-5">
            <h3 className="text-xl shrink-0">{title}</h3>
            {banner && (
               <div className="flex items-center gap-2 max-h-12 p-2 rounded-md" style={{ backgroundColor: banner.bgColor }}>
                  {banner.icon && (
                     UiUtils.getSVGIcon(banner.icon, banner.fill, "")
                  )}
                  <p 
                     className={bannerTextCss}
                     dangerouslySetInnerHTML={{__html: banner.text}}
                  >
                  </p>
                  {banner.tooltip && (
                     <div className="grow-0 shrink-0">
                        <IconTooltip content={banner.tooltip} position="bottom"/>
                     </div>
                  )}
                  {banner.button && (
                     <Button
                        size="x-small"
                        className="grow-0 shrink-0"
                        variant={banner.button.variant}
                        leftIconSVG={banner.button.icon}
                        leftIconFill={banner.button.fill}
                        onClick={onBannerClick}
                     />
                  )}
               </div>
            )} 
            <button className="flex justify-center items-center gap-3 text-sm leading-150 p-2 shrink-0" onClick={logout}>
               <ArrowRightOnRectangleIcon className="w-6 h-6"/>Logout
            </button>
         </div>
      )}
      </>
   );
}