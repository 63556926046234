import { addClassNamesToElement } from '@lexical/utils';
import  { ElementNode } from 'lexical';

export class LexicalLayoutItemNode extends ElementNode {
   static getType() {
      return 'layout-item';
   }

   static clone(node) {
      return new LexicalLayoutItemNode(node.__key);
   }

   createDOM(config) {
      const dom = document.createElement('div');
      if (typeof config.theme.layoutItem === 'string') {
         addClassNamesToElement(dom, config.theme.layoutItem);
      }
      return dom;
   }

   updateDOM() {
      return false;
   }

   static importDOM() {
      return {};
   }

   static importJSON() {
      return $createLayoutItemNode();
   }

   isShadowRoot() {
      return true;
   }

   exportJSON() {
      return {
         ...super.exportJSON(),
         type: 'layout-item',
         version: 1,
      };
   }
}

export function $createLayoutItemNode() {
   return new LexicalLayoutItemNode();
}

export function $isLayoutItemNode(node) {
   return node instanceof LexicalLayoutItemNode;
}
