//import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

import * as DashboardConstants from '../constants/DashboardConstants';

export default function DashboardElementStepButton({ open, title, number, status, onClick }) {
    let iconClass = "w-2 h-2 rounded-full";
    switch (status) {
        case DashboardConstants.StepStatus.ACTIVE:
            iconClass = `${iconClass} bg-blue`;
            break;
        case DashboardConstants.StepStatus.INCOMPLETE:
            iconClass = `${iconClass} bg-yellow`;
            break;
        case DashboardConstants.StepStatus.COMPLETE:
            iconClass = `${iconClass} bg-green`;
            break;
        default:
            iconClass = `${iconClass} bg-grey04`;
            break;
    }

    const handleClick = (e) => {
        e.preventDefault();
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <button 
            className="w-full flex items-start justify-between gap-3"
            onClick={handleClick}
        >
            <div className="flex items-start justify-start gap-2">
                <div className="flex w-2.5 h-6 items-center justify-center">
                    <div className={iconClass}></div>
                </div>
                <div className="flex flex-col items-start gap-2">
                    <h6 className={`font-vg-medium text-xl leading 120 ${open ? 'text-black' : 'text-grey'}`}>
                        {title}
                    </h6>
                    <p className="font-vg-book text-xs2 text-grey">{`STEP ${number}`}</p>
                </div>
            </div>
            <ChevronUpIcon className={`w-5 h-5 text-blue ${open ? 'rotate-180 transform' : ''}`}/>
        </button>
    );
}

/*
<Disclosure.Button className="w-full flex items-start justify-between gap-3">
    <div className="flex items-start justify-start gap-2">
        <div className="flex w-2.5 h-6 items-center justify-center">
            <div className={iconClass}></div>
        </div>
        <div className="flex flex-col items-start gap-2">
            <h6 className={`font-vg-medium text-xl leading 120 ${open ? 'text-black' : 'text-grey'}`}>
                {title}
            </h6>
            <p className="font-vg-book text-xs2 text-grey">{`STEP ${number}`}</p>
        </div>
    </div>
    <ChevronUpIcon className={`w-5 h-5 text-blue ${open ? 'rotate-180 transform' : ''}`}/>
</Disclosure.Button>
*/
