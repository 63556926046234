const EntityConstants = require('./EntityConstants');
const DataConstants = require('./DataConstants');
const IndicatorConstants = require('./IndicatorConstants');
const DashboardConstants = require('./DashboardConstants');

export const TOTAL_OPTIONS_YEARS = 50;

export const EMPTY_OPTION = { value: "", label: "" };

export const YES_OPTION = { value:"yes", label: "Yes" };
export const NO_OPTION = { value:"no", label:"No" };

export const YES_NO_OPTIONS = [
   YES_OPTION,
   NO_OPTION
];

export const YES_NO_UNSURE_OPTIONS = [
   EMPTY_OPTION,
   YES_OPTION,
   NO_OPTION,
   { value:"unsure", label:"Unsure" }
];

export const ABN_BELONGS_TO_OPTIONS = [
   { value:"enterprise", label:"Own ABN" }, 
   { value:"parentorg", label:"Under Top-Level Organisation" }
];

export const SOC_ENT_TYPE_OPTIONS = [
   EMPTY_OPTION,
   { value:"standalone", label: "A standalone social enterprise" },
   { value:"business unit", label:"A business unit of a larger organisation" },
   { value:"standalone under parent org", label:"A standalone social enterprise of a parent organisation" }
];

export const STANDALONE_TYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: "australian public company", label: "Australian Public Company" },
   { value: "other incorporated entity", label: "Other Incorporated Entity" },
   { value: "australian private company", label: "Australian Private Company" },
   { value: "co-operative", label: "Co-operative" },
   { value: "discretionary investment trust", label: "Discretionary Investment Trust" },
   { value: "discretionary trading trust", label: "Discretionary Trading Trust" },
   { value: "state government entity", label: "State Government Entity" },
   { value: "other", label: "Other" }
];

export const PRIMARY_CUSTOMER_OPTIONS = [
   EMPTY_OPTION,
   { value: "b2b services", label: "Sale of services to other businesses (B2B)" },
   { value: "b2b goods", label: "Sale of goods to other businesses (B2B)" },
   { value: "b2c goods", label: "Sale of goods direct to general consumers (B2C)" },
   { value: "b2c services", label: "Sale of services direct to general consumers (B2C)" },
   { value: "not applicable", label: "N/A - No primary customer; dual customers are selected below" }
];

export const SECONDARY_CUSTOMER_OPTIONS = [
   { value: "secondaryB2BServices", label: "Sale of services to other businesses (B2B)" },
   { value: "secondaryB2BGoods", label: "Sale of goods to other businesses (B2B)" },
   { value: "secondaryB2CGoods", label: "Sale of goods direct to general consumers (B2C)" },
   { value: "secondaryB2CServices", label: "Sale of services direct to general consumers (B2C)" },
   { value: "secondaryNA", label: "N/A" }
];

export const BUSINESS_STREAM_OPTIONS = [
   EMPTY_OPTION,
   { value: "agriculture/forestry/fishing", label: "Agriculture, forestry and fishing" },
   { value: "mining", label: "Mining" },
   { value: "manufacturing", label: "Manufacturing" },
   { value: "electricity/gas/water/waste", label: "Electricity, Gas, Water and Waste Services" },
   { value: "construction", label: "Construction" },
   { value: "wholesale trade", label: "Wholesale Trade" },
   { value: "retail trade", label: "Retail Trade" },
   { value: "accommodation/food", label: "Accommodation and Food Services" },
   { value: "transport/postal/warehousing", label: "Transport, Postal and Warehousing" },
   { value: "information/telecommunications", label: "Information Media and Telecommunications" },
   { value: "financial/insurance", label: "Financial and Insurance Services" },
   { value: "rental/hiring/real estate", label: "Rental, Hiring and Real Estate Services" },
   { value: "professional/scientific/technical", label: "Professional, Scientific and Technical Services" },
   { value: "admin/support", label: "Administrative and Support Services" },
   { value: "public admin/safety", label: "Public Administration and Safety" },
   { value: "education/training", label: "Education and Training" },
   { value: "health/social assistance", label: "Health Care and Social Assistance" },
   { value: "arts/recreation", label: "Arts and Recreation Services" },
   { value: "other", label: "Other Services" },
];

export const SALES_AREA_OPTIONS = [
   { value: "localCommunitySales", label: "To our local community" },
   { value: "regionalSales", label: "Within our region" },
   { value: "stateSales", label: "Within our state" },
   { value: "multipleStateSales", label: "In multiple states" },
   { value: "nationalSales", label: "Nationally" },
   { value: "internationalSales", label: "Internationally (beyond Australia)" }
];

export const STATE_OPTIONS = [
   EMPTY_OPTION,
   { value: "VIC", label: "VIC" },
   { value: "ACT", label: "ACT" },
   { value: "NSW", label: "NSW" },
   { value: "NT", label: "NT" },
   { value: "QLD", label: "QLD" },
   { value: "SA", label: "SA" },
   { value: "WA", label: "WA" }
];

export const MULTI_STATE_OPTIONS = [
   { value: "VIC", label: "VIC" },
   { value: "ACT", label: "ACT" },
   { value: "NSW", label: "NSW" },
   { value: "NT", label: "NT" },
   { value: "QLD", label: "QLD" },
   { value: "SA", label: "SA" },
   { value: "WA", label: "WA" }
];

export const PURPOSE_OPTIONS = [
   { value: "purposeEmployment", label: "Providing employment or pathway to employment for people who face barriers to work" },
   { value: "purposeCharityIncome", label: "Generating income to reinvest in charitable services or community activities" },
   { value: "purposeParticipation", label: "Creating opportunities for people to participate in their community" },
   { value: "purposeSpecificGroup", label: "Providing needed goods or services to a specific group or geographic community" },
   { value: "purposeDevelopment", label: "Developing other social enterprises or organisations in the for purpose sector" },
   { value: "purposeInnovation", label: "Promoting social and environmental innovation in business models and/or product" },
   { value: "purposeFairTrade", label: "Promoting fair trade and consumption of ethical products" },
   { value: "purposeOther", label: "Other" }
];

export const BENEFIT_OPTIONS = [
   { value: "benefitAnimals", label: "Animals" },
   { value: "benefitGeoCommunity", label: "A particular geographic community " },
   { value: "benefitReligCommunity", label: "A spiritual or religious community " },
   { value: "benefitYouth", label: "Children or young people" },
   { value: "benefitEmployBarriers", label: "People who face barriers to employment" },
   { value: "benefitPoverty", label: "People experiencing poverty" },
   { value: "benefitMenBoys", label: "Men and boys" },
   { value: "benefitWomenGirls", label: "Women and girls" },
   { value: "benefitEnvironment", label: "Environment" },
   { value: "benefitFirstNations", label: "First Nations peoples" },
   { value: "benefitTransNonBinary", label: "Transgender and nonbinary people" },
   { value: "benefitLGBTIAplus", label: "LGBTIA+ people and communities" },
   { value: "benefitMigrants", label: "Migrants, refugees or people seeking asylum" },
   { value: "benefitOlderPeople", label: "Older people" },
   { value: "benefitOrgs", label: "Other organisations" },
   { value: "benefitHomeless", label: "People who are homeless" },
   { value: "benefitDisability", label: "People with disability" },
   { value: "benefitPrisoners", label: "Prisoners and ex-offenders" },
   { value: "benefitRemote", label: "Remote or rural communities" },
   { value: "benefitViolenceSurvivors", label: "Victims or survivors of violence" },
   { value: "benefitOther", label: "Other" },
];

export const NUM_EMPLOYEE_OPTIONS = [
   EMPTY_OPTION,
   { value: "0-4", label: "0-4 employees" },
   { value: "5-19", label: "5-19 employees" },
   { value: "20-199", label: "20-199 employees" },
   { value: "200+", label: "200+ employees" }
];

export const EDIT_BUTTON_LABEL = "Edit";
export const DONE_BUTTON_LABEL = "Done";

export const RESOURCE_TYPE = {
   All: "All",
   News: "News",
   Learn: "Learn",
   HowTo: "HowTo"
};

export const RESOURCE_PAGE_SIZE = 6;
export const RESOURCE_PAGINATION_WINDOW_SIZE = 4;

export const RESOURCES_FILTER_OPTIONS = [
   { label: "All", value: "All" },
   { label: "How-to", value: "HowTo" },
   { label: "Learn", value: "Learn" },
   { label: "News", value: "News" }
];

export const RESOURCE_SORT_TYPE = {
   None: "",
   AlphaAsc: "a-z",
   AlphaDesc: "z-a",
   DateAsc: "oldest",
   DateDesc: "newest"
}

export const RESOURCE_SORT_OPTIONS = [
   { label: "Sort by", value: "" },
   { label: "A-Z", value: "a-z" },
   { label: "Z-A", value: "z-a" },
   { label: "Oldest First", value: "oldest" },
   { label: "Newest First", value: "newest" },
];

export const ROLE_OPTIONS = [
   { value: "enterpriseOwns", label: "Owner of the Program/Project" },
   { value: "enterpriseFunds", label: "Funder or co-funder of the Program/Project" },
   { value: "enterpriseManages", label: "Manager of the Program/Project" },
   { value: "enterpriseImplements", label: "Implementing the Program/Project" },
   { value: "enterprisePartners", label: "Partnering with other orgs/social enterprises to deliver the Program/Project" },
   { value: "enterpriseOther", label: "Other" }
];

export const IMPLEMENT_OPTIONS = [
   { value: "localCommunityImplement", label: "In our local community" },
   { value: "regionalImplement", label: "Within our region" },
   { value: "stateImplement", label: "Within our state" },
   { value: "multipleStateImplement", label: "In multiple states" },
   { value: "nationalImplement", label: "Nationally "},
   { value: "internationalImplement", label: "Internationally (beyond Australia)" }
];

export const UNIT_TYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: EntityConstants.EntityType.ENTERPRISE, label: "Social Enterprise" },
   { value: EntityConstants.EntityType.PROGRAM, label: "Program" },
   { value: EntityConstants.EntityType.PROJECT, label: "Project" }
];

export const FREQUENCY_OPTIONS = [
   EMPTY_OPTION,
   {  
      value: DataConstants.FrequencyType.WEEKLY, 
      label: "Weekly" 
   },
   { 
      value: DataConstants.FrequencyType.MONTHLY, 
      label: "Monthly" 
   },
   { 
      value: DataConstants.FrequencyType.QUARTERLY, 
      label: "Quarterly" 
   },
   { 
      value: DataConstants.FrequencyType.FINANCIAL_YEAR, 
      label: "Financial Year" 
   },
   { 
      value: DataConstants.FrequencyType.ANNUALLY, 
      label: "Calendar Year" 
   }
];

export const REPORTING_NEED_OPTIONS = [
   EMPTY_OPTION,
   {
      value: IndicatorConstants.ReportingNeed.CHANGING_THE_WORLD,
      label: IndicatorConstants.ReportingNeed.CHANGING_THE_WORLD
   },
   {
      value: IndicatorConstants.ReportingNeed.OPERATIONS,
      label: IndicatorConstants.ReportingNeed.OPERATIONS
   },
];

export const INDICATOR_TYPE_OPTIONS = [
   EMPTY_OPTION,
   { 
      value: IndicatorConstants.IndicatorType.INPUT, 
      label: IndicatorConstants.IndicatorType.INPUT 
   },
   { 
      value: IndicatorConstants.IndicatorType.OUTPUT, 
      label: IndicatorConstants.IndicatorType.OUTPUT 
   },
   { 
      value: IndicatorConstants.IndicatorType.OUTCOME, 
      label: IndicatorConstants.IndicatorType.OUTCOME 
   }
];

export const FORMAT_OPTIONS = [
   { value: IndicatorConstants.Format.NUMBER, label: "Number (#)" },
   { value: IndicatorConstants.Format.PERCENT, label: "Percent (%)" },
   { value: IndicatorConstants.Format.CURRENCY, label: "Currency ($)" },
   { value: IndicatorConstants.Format.TEXT, label: "Text (*)" }
];

export const DASH_ELEMENT_TYPE_OPTIONS = [
   { value: DashboardConstants.ElementType.GRAPH, label: "Graph / Chart" },
   { value: DashboardConstants.ElementType.TABLE, label: "Data Table" },
   { value: DashboardConstants.ElementType.TEXT, label: "Story / Commentary" }
];

export const DASH_ELEMENT_SIZE_OPTIONS = [
   DashboardConstants.ElementSize.SMALL,
   DashboardConstants.ElementSize.MEDIUM,
   DashboardConstants.ElementSize.LARGE,
   DashboardConstants.ElementSize.EXTRA_LARGE
];

/*
export const DASH_GRAPH_SUBTYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: DashboardConstants.ElementSubType.XY_GRAPH, label: "XY Graph (eg line / bar)" },
   { value: DashboardConstants.ElementSubType.PIE_CHART, label: "Pie Chart" }
];
*/

export const DASH_GRAPH_SUBTYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: DashboardConstants.ElementSubType.XY_GRAPH, label: "XY Graph (eg line / bar)" }
];

/*
export const DASH_TABLE_SUBTYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: DashboardConstants.ElementSubType.SIMPLE_INDICATOR_TABLE, label: "Simple Indicators Table" },
   { value: DashboardConstants.ElementSubType.GROUPED_INDICATOR_TABLE, label: "Grouped Indicators Table" }
];
*/

export const DASH_TABLE_SUBTYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: DashboardConstants.ElementSubType.SIMPLE_INDICATOR_TABLE, label: "Simple Indicators Table" }
];

export const DASH_ALL_SUBTYPE_OPTIONS = [
   EMPTY_OPTION,
   { value: DashboardConstants.ElementSubType.XY_GRAPH, label: "XY Graph (eg line / bar)" },
   { value: DashboardConstants.ElementSubType.PIE_CHART, label: "Pie Chart" },
   { value: DashboardConstants.ElementSubType.SIMPLE_INDICATOR_TABLE, label: "Simple Indicators Table" },
   { value: DashboardConstants.ElementSubType.GROUPED_INDICATOR_TABLE, label: "Grouped Indicators Table" }
];

export const DASH_GRAPH_DATASET_OPTIONS = [
   EMPTY_OPTION,
   { value: DashboardConstants.DatasetGraphType.BAR, label: "Bar Graph" },
   { value: DashboardConstants.DatasetGraphType.LINE, label: "Line Graph" }
];

export const DASH_COLOR_OPTIONS = [
   EMPTY_OPTION,
   DashboardConstants.GraphColor.BLUE,
   DashboardConstants.GraphColor.ORANGE,
   DashboardConstants.GraphColor.GREEN,
   DashboardConstants.GraphColor.RED,
   DashboardConstants.GraphColor.PURPLE,
   DashboardConstants.GraphColor.BROWN,
   DashboardConstants.GraphColor.PINK,
   DashboardConstants.GraphColor.GRAY,
   DashboardConstants.GraphColor.YELLOW_GREEN,
   DashboardConstants.GraphColor.TEAL
];

export const DASH_INTERVAL_OPTIONS = [
   EMPTY_OPTION,
   { value: DataConstants.FrequencyType.WEEKLY, label: "Weekly" },
   { value: DataConstants.FrequencyType.MONTHLY, label: "Monthly" },
   { value: DataConstants.FrequencyType.QUARTERLY, label: "Quarterly" },
   { value: DataConstants.FrequencyType.FINANCIAL_YEAR, label: "Financial Year" },
   { value: DataConstants.FrequencyType.ANNUALLY, label: "Calendar Year" },
];

export const DASH_EXPORT_OPTIONS = [
   { value: DashboardConstants.ExportType.ALL_PAGES, label: "All" },
   { value: DashboardConstants.ExportType.CURRENT_PAGE, label: "Current page" }
]

export const DASH_EXPORT_PAGE_OPTIONS = [
   { value: DashboardConstants.PageOrientation.PORTRAIT, label: "Portrait" },
   { value: DashboardConstants.PageOrientation.LANDSCAPE, label: "Landscape" }
]

/* Table Actions */

const ActionKeys = {
   ADD: "add",
   EDIT: "edit",
   DUPLICATE: "duplicate",
   DELETE: "delete",
   NOTIFY: "notify",
   ENABLE: "enable"
};

export { ActionKeys };

export const ORG_UNIT_ACTIONS = [
   {
      type: "action",
      key: "details",
      label: "See Details",
      leftIcon: "UsersIcon",
      variant: "default",
      condition: {
         key: "status",
         value: "active"
      }
   },
   {
      type: "action",
      key: "indicators",
      label: "See Indicators",
      leftIcon: "LightBulbIcon",
      variant: "default",
      condition: {
         key: "status",
         value: "active"
      }
   },
   {
      type: "action",
      key: "data",
      label: "Manage Indicator Data",
      leftIcon: "TableIcon",
      variant: "default",
      condition: {
         key: "status",
         value: "active"
      }
   }
];

export const SELECTED_INDICATOR_ACTIONS = [
   {
      type: "tip",
      content: "",
      position: "left"
   },
   {
      type: "action",
      key: ActionKeys.EDIT,
      label: "Edit",
      variant: "default"
   },
   {
      type: "action",
      key: ActionKeys.DELETE,
      label: "Remove",
      variant: "red"
   }
];

export const ALL_INDICATOR_ACTIONS = [
   {
      type: "tip",
      content: "",
      position: "left"
   },
   {
      type: "action",
      key: ActionKeys.ADD,
      label: "Add",
      variant: "default"
   }
];

export const INDICATOR_REVIEW_ACTIONS = [
   {
      type: "action",
      key: ActionKeys.EDIT,
      label: "Edit",
      variant: "default"
   },
   {
      type: "action",
      key: ActionKeys.DELETE,
      label: "Remove",
      variant: "red"
   }
];

export const MEMBER_MANAGEMENT_ACTIONS = [
   {
      type: "action",
      key: ActionKeys.NOTIFY,
      label: "Resend Invite",
      leftIcon: "MailIcon",
      variant: "default",
      conditions: [
         { key: "accountHolder", value: false, includeNull: true },
         { key: "verified", value: false, includeNull: true },
         { key: "inactive", value: false, includeNull: true }
      ]
   },
   {
      type: "action",
      key: ActionKeys.EDIT,
      label: "Edit",
      leftIcon: "PencilIcon",
      variant: "default",
      conditions: [
         { key: "accountHolder", value: false, includeNull: true },
         { key: "inactive", value: false, includeNull: true }
      ]
   },
   {
      type: "if else",
      flag: "inactive",
      trueValue: true,
      trueHeader: {
         key: ActionKeys.ENABLE,
         label: "Activate",
         leftIcon: "UserAddIcon",
         variant: "green",
      },
      falseHeader: {
         key: ActionKeys.DELETE,
         label: "Deactivate",
         leftIcon: "UserRemoveIcon",
         variant: "red",
      }
   }
];

/* Menu Items */

export const DASH_MORE_MENU_ITEMS = [
   {
      action: ActionKeys.EDIT,
      label: "Edit Settings"
   },
   {
      action: ActionKeys.DELETE,
      label: "Delete"
   },
];

export const DASH_MORE_DELETE_ONLY = [
   {
      action: ActionKeys.DELETE,
      label: "Delete"
   },
];

export const DASH_HAS_ARCHIVED_GRAPH = "This graph cannot be edited as it contains archived data.";
export const DASH_HAS_ARCHIVED_TABLE = "This table cannot be edited as it contains archived data.";

export const DASH_HAS_ARCHIVED_ERROR = "An error has occurred preventing this element from being edited";

/* Button Types */

export const ButtonType = {
   NEXT: "next",
   SAVE: "save"
};

/* Tag Colors */

export const TagColor = {
   GREEN: "green",
   YELLOW: "yellow",
   PURPLE: "purple",
   GREY: "grey",
   BLACK_OUTLINE: "black-outline"
}

/* Other Text */

export const RESOURCE_TYPE_SUBTITLES = {
   "All": "",
   "News": "News articles about feature development, events and resources offered by Seedkit.",
   "Learn": "Learning resources on impact measurement and communication.",
   "HowTo": "Platform-specific guidance and how-to's on using Seedkit." 
};
