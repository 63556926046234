import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import * as DashboardConstants from '../constants/DashboardConstants';
import * as UiConstants from '../constants/UiConstants';

import RadioButtonGroup from './RadioButtonGroup';
import Button from './Button';

import exportExample from '../images/export_example.png';

export default function DashboardExportModal({ open, onExport, onClose }) {

    const [exportType, setExportType] = useState("");
    const [pageOrientation, setPageOrientation] = useState("");
    const [orientationVisible, setOrientationVisible] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onExportTypeChanged = (e) => {
        setExportType(e.target.value);
        setErrorMsg("");
        switch (e.target.value) {
            case DashboardConstants.ExportType.CURRENT_PAGE:
                setOrientationVisible(false);
                break;
        
            case DashboardConstants.ExportType.ALL_PAGES:
                setOrientationVisible(true);
                setPageOrientation(DashboardConstants.PageOrientation.LANDSCAPE);
                break;
        
            default:
                break;
        }
    }

    const onOrientationChanged = (e) => {
        setPageOrientation(e.target.value);
    }

    const onExportClick = () => {
        switch (exportType) {
            case DashboardConstants.ExportType.CURRENT_PAGE:
                if (onExport) {
                    onExport({
                        action: DashboardConstants.ActionType.SET_CANVAS_MODE,
                        args: { mode: DashboardConstants.CanvasMode.EXPORT }
                    });
                }
                break;
            case DashboardConstants.ExportType.ALL_PAGES:
                if (pageOrientation) {
                    if (onExport) {
                        onExport({
                            action: DashboardConstants.ActionType.SET_CANVAS_MODE,
                            args: { 
                                mode: DashboardConstants.CanvasMode.EXPORT_ALL,
                                orientation: pageOrientation
                            }
                        });
                    }
                } else {
                    setErrorMsg("You need to select a page orientation");
                }
                break;
            default:
                setErrorMsg("You need to select a page range");
                break;
        } 
    }

    /* useEffect Functions */

    useEffect(() => {
        if (open) {
            setExportType("");
            setPageOrientation("");
            setOrientationVisible(false);
        }
    }, [open, setExportType, setPageOrientation, setOrientationVisible]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"                
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                                <Dialog.Title
                                    as="h3"
                                    className="font-vg-medium text-3.5xl text-black leading-110"
                                >
                                    Export Setting
                                </Dialog.Title>
                                <div className="grid grid-cols-2 gap-5">
                                    <div className="flex flex-col items-stretch gap-3">
                                        <h5 className="font-vg-regular text-2xl text-black leading-120">Page Range</h5>
                                        <RadioButtonGroup
                                            name="exportType"
                                            options={UiConstants.DASH_EXPORT_OPTIONS}
                                            value={exportType}
                                            onChange={onExportTypeChanged}
                                        />
                                        <p className="font-vg-regular text-base text-red">{errorMsg}</p>
                                    </div>
                                    <div className={`${orientationVisible ? "flex" : "hidden"} flex-col items-stretch gap-3`}>
                                        <h5 className="font-vg-regular text-2xl text-black leading-120">Page Orienation</h5>
                                        <RadioButtonGroup
                                            name="pageOrientation"
                                            options={UiConstants.DASH_EXPORT_PAGE_OPTIONS}
                                            value={pageOrientation}
                                            onChange={onOrientationChanged}
                                        />
                                        <p className="font-vg-regular text-base text-red">{errorMsg}</p>
                                    </div>
                                </div>
                                <div className="flex items-start justify-between gap-8">
                                    <div className="flex flex-col items-stretch gap-3 grow">
                                        <h5 className="font-vg-regular text-2xl text-black leading-120">Exporting Notes</h5>
                                        <ul className="font-vg-book text-base text-black list-disc">
                                            <li>When exporting all pages in portrait mode, two page screens get laid out onto one A4-sized sheet.</li>
                                            <li>When exporting all pages in landscape mode, each page screen is exported to an A4-sized sheet.</li>
                                            <li>Ensure that your dashboard is properly set up before proceeding with the export.</li>
                                        </ul>
                                    </div>
                                    <img src={exportExample} className="h-56 aspect-export-image grow-0" alt="layout diagram of two dashboard pages on a PDF page"/>
                                </div>
                                <div className="grid grid-cols-2 gap-3">
                                    <Button variant="outline" size="large" label="Exit" className="w-full" onClick={onClose}/>
                                    <Button variant="solid" size="large" label="Export" className="w-full" onClick={onExportClick}/>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}