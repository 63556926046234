import axios from 'axios';

let UserService = {};

UserService.createMemberUser = (payload) => {
   return axios.post(process.env.REACT_APP_API_URL + '/user/member', 
      payload, 
      { withCredentials: true }
   );
};

UserService.updateUser = (payload) => {
   return axios.put(process.env.REACT_APP_API_URL + '/user', 
      payload, 
      { withCredentials: true }
   );
};

UserService.sendMemberInvite = (payload) => {
   return axios.post(process.env.REACT_APP_API_URL + '/user/member/invite', 
      payload, 
      { withCredentials: true }
   );
};

UserService.upgradeUserOrg = (payload) => {
   return axios.put(process.env.REACT_APP_API_URL + '/user/upgrade', 
      payload, 
      { withCredentials: true }
   );
};

export default UserService;