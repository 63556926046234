import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';

import * as DashboardConstants from '../constants/DashboardConstants';

import DashboardElementStepButton from "./DashboardElementStepButton";
import DashboardDatasetSubform from './DashboardDatasetSubform';

export default function DashboardElementCustomStep({ visible, status, open, graphType, secondaryY, datasets, onStepAction }) {
    const { setValue } = useFormContext();

    const toggleOpen = () => {
        if (onStepAction) {
            onStepAction({ action: DashboardConstants.ActionType.TOGGLE_STEP_CUSTOM });
        }
    }

    const onDatasetChanged = (details) => {
        if (DashboardConstants.ActionType.GRAPH_DATA_UPDATED) {
            let newDatasets = cloneDeep(datasets);
            newDatasets[details.args.index] = details.args.dataset;
            setValue("datasetDetails", newDatasets);
            if (onStepAction) {
                onStepAction({ 
                    action: DashboardConstants.ActionType.GRAPH_DATA_UPDATED,
                    args: { datasets: newDatasets }
                });
            }
        }
    }

    return (
        <>
        {visible === true && (
            <div className="w-full flex flex-col items-stretch gap-4 px-6 py-4 rounded-lg bg-grey04">
                <DashboardElementStepButton
                    open={open}
                    title="Customise (optional)"
                    number="4"
                    status={status === DashboardConstants.StepStatus.COMPLETE ? status : (open === true ? DashboardConstants.StepStatus.ACTIVE : true)}
                    onClick={toggleOpen}
                />
                <div className={open ? "grid grid-cols-2 gap-16" : "hidden"}>
                    {datasets.map((dataset, index) => (
                    <DashboardDatasetSubform
                        key={`dataset-subform-wrapper-${index}`}
                        graphType={graphType}
                        secondaryY={secondaryY}
                        index={index}
                        dataset={dataset}
                        onDatasetChanged={(details) => onDatasetChanged(details)}
                    />
                    ))}
                </div>
            </div>
        )}
        </>
    );
}
