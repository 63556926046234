import { Navigate } from 'react-router-dom';

import AuthService from '../services/AuthService';

export default function PrivateRoute({ element, redirect }) {
    if (AuthService.isLoggedIn()) {
        return element;
    } else {
        return <Navigate to={redirect} replace={true}/>
    } 
}