import * as DataConstants from '../constants/DataConstants';

import IconTooltip from './IconTooltip';

export default function DataTitleCellRenderer(props) {
    if (props && props.data) {
        const text = `This data does not need to be entered as it is calculated. The formula for it is:<br/>${props.data.formulaText}`;
        return (
            <div className="flex items-center gap-2">
                <span>{props.value}</span>
                {props.data.dataType === DataConstants.RequiredDataType.CALCULATED && (
                    <IconTooltip content={text} position={"right"}/>
                )}
            </div>
        );
    } else {
        return (<></>);
    }
}