import { useId } from 'react';
import { SquaresPlusIcon } from '@heroicons/react/24/solid';

import * as DashboardConstants from "../constants/DashboardConstants";

export default function DashboardCanvasCreateCard({ id, row, col, width, height, mode, onClick }) {

    const newId = useId();
    const cardId = id ? id : newId;

    let rowColClass = "";

    let rowStart = Math.min(row, DashboardConstants.CANVAS_ROWS);
    let rowEnd = Math.min(rowStart + height, DashboardConstants.CANVAS_ROWS + 1);
    let colStart = Math.min(col, DashboardConstants.CANVAS_COLS);
    let colEnd = Math.min(colStart + width, DashboardConstants.CANVAS_COLS + 1);

    switch (rowStart) {
        case 1:
            rowColClass = "row-start-1";
            break;
        case 2:
            rowColClass = "row-start-2";
            break;
        case 3:
            rowColClass = "row-start-3";
            break;
        default:
            break;
    }

    switch (colStart) {
        case 1:
            rowColClass = `${rowColClass} col-start-1`;
            break;
        case 2:
            rowColClass = `${rowColClass} col-start-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-start-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-start-4`;
            break;
        default:
            break;
    }

    switch (rowEnd) {
        case 2:
            rowColClass = `${rowColClass} row-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} row-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} row-end-4`;
            break;
        default:
            break;
    }

    switch (colEnd) {
        case 2:
            rowColClass = `${rowColClass} col-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-end-4`;
            break;
        case 5:
            rowColClass = `${rowColClass} col-end-5`;
            break;
        default:
            break;
    }

    const buttonClass = `flex items-center justify-center rounded-lg bg-grey04 border border-dashed border-grey02 ${rowColClass}`;
    let cardClass = `flex items-center justify-center rounded-lg bg-white ${rowColClass}`;
    if (mode === DashboardConstants.CanvasMode.VIEW) {
        cardClass = `${cardClass} border border-dashed border-grey02`;
    }

    const getIconText = () => {
        if (width > 1 && height > 1) {
            return (
                <div className="flex flex-col items-center gap-6">
                    <SquaresPlusIcon className="w-[46px] h-[46px] text-grey"/>
                    <p className="font-vg-regular text-2xl text-grey">Create reporting element</p>
                </div>
            );
        } else {
            return (
                <div className="flex flex-col items-center gap-3">
                    <SquaresPlusIcon className="w-8 h-8 text-grey"/>
                    <p className="font-vg-regular text-xs2 text-grey">Create reporting element</p>
                </div>
            );
        }
    }

    const buttonClicked = () => {
        if (onClick) {
            onClick({ row: row, col: col});
        }
    }

    return (
        <>
        {mode === DashboardConstants.CanvasMode.EDIT ? (
            <button key={`canvas-create-card-${cardId}`} className={buttonClass} onClick={buttonClicked}>
                {getIconText()}
            </button>
        ) : (
            <div key={`canvas-create-card-${cardId}`} className={cardClass}></div>
        )}
        </>
    );
}