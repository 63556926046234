import { useState, useContext, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
//import { yupResolver } from '@hookform/resolvers/yup';
//import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import UiUtils from '../../utils/UiUtils';
import * as GeneralConstants from '../../constants/GeneralConstants';
import * as EntityConstants from '../../constants/EntityConstants';
import * as UiConstants from '../../constants/UiConstants';
import * as UserConstants from '../../constants/UserConstants';
import * as MatomoConstants from '../../constants/MatomoConstants';
import { GlobalContext } from '../../context/GlobalContext';
import ProgDataService from '../../services/ProgDataService';
import OrgService from '../../services/OrgService';

import KitSideNav from '../../components/KitSideNav';
import KitTopBar from '../../components/KitTopBar';
import OrgEntHeader from '../../components/OrgEntHeader';
import TaggedSelectDropdown from '../../components/TaggedSelectDropdown';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import OrgEntPanelHeader from '../../components/OrgEntPanelHeader';
import Tag from '../../components/Tag';
import TextInputHeading from '../../components/TextInputHeading';
import TextArea from '../../components/TextArea';
import CustomReactDatePicker from '../../components/CustomReactDatePicker';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import TextInput from '../../components/TextInput';
import SelectDropdown from '../../components/SelectDropdown';
import OtherItemsInput from '../../components/OtherItemsInput';
import TrialUpgradeModal from '../../components/TrialUpgradeModal';
import TermsAgreementModal from '../../components/TermsAgreementModal';
import Footer from '../../components/Footer';

export default function ProgProjectPage() {
    const {context, setContextValues} = useContext(GlobalContext);

    const [navExpanded, setNavExpanded] = useState(context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true);

    /* Role Access Code */

    const roles = context.user && context.user.roles ? context.user.roles : [];
    const [userCanEdit, setUserCanEdit] = useState(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.EDIT));

    const containerClass = "flex flex-col items-stretch h-screen shrink grow overflow-y-scroll";

    const getProgProjOptions = useCallback((organisation, archived) => {
        let programs = organisation.programs ? organisation.programs : [];
        let projects = organisation.projects ? organisation.projects : [];
        let progProjects = programs.concat(projects);
        if (!archived) {
            progProjects = progProjects.filter(p => (!p.hasOwnProperty("status")) || p.status !== EntityConstants.EntityStatus.ARCHIVED);
        }
        const opts = progProjects.map(p => ({ 
            value: p._id,
            type: p.entityType, 
            label: p.name,
            tags: [
                {
                    text : p.status ? UiUtils.capitalise(p.status) : UiUtils.capitalise(EntityConstants.EntityStatus.ACTIVE),
                    color : p.status ? (p.status === EntityConstants.EntityStatus.ACTIVE ? UiConstants.TagColor.GREEN : UiConstants.TagColor.PURPLE) : UiConstants.TagColor.GREEN
                },
                {
                    text: UiUtils.capitalise(p.entityType),
                    color: UiConstants.TagColor.BLACK_OUTLINE
                }
            ] 
        }));
        return opts;
    }, []);

    const getFormValues = useCallback((organisation, index, opts) => {
        let values = {
            name: "",
            goal: "",
            startDate: null,
            endDate: null,
            enterpriseRoleOptions: [],
            enterpriseOtherText: "[\"\"]",
            benefitOptions: [],
            benefitOtherText: "[\"\"]",
            implementOptions: [],
            communityPostcode: "",
            region: "",
            stateOption: UiConstants.EMPTY_OPTION,
            multipleStateOptions: []
        };
        if (index > -1 && index < opts.length) {
            const id = opts[index].value;
            const type = opts[index].type;
            let pr = {};
            let prdata = {};
            let pindex = -1;
            if (type === EntityConstants.EntityType.PROGRAM) {
                pindex = organisation.programs ? organisation.programs.map(p => p._id).indexOf(id) : -1;
                if (pindex > -1) {
                    pr = organisation.programs[pindex];
                    prdata = pr.progProjectData ? pr.progProjectData : {};
                }
            } else if (type === EntityConstants.EntityType.PROJECT) {
                pindex = organisation.projects ? organisation.projects.map(p => p._id).indexOf(id) : -1;
                if (pindex > -1) {
                    pr = organisation.projects[pindex];
                    prdata = pr.progProjectData ? pr.progProjectData : {};
                }
            }
            values = {
                name: prdata.name ? prdata.name : (pr.name ? pr.name : ""),
                goal: prdata.goal ? prdata.goal : "",
                startDate: prdata.startDate ? prdata.startDate : null,
                endDate: prdata.endDate ? prdata.endDate : null,
                enterpriseRoleOptions: UiUtils.getOptionsFromFields(prdata, UiConstants.ROLE_OPTIONS),
                enterpriseOtherText: prdata.enterpriseOtherItems ? JSON.stringify(prdata.enterpriseOtherItems) : "[\"\"]",
                benefitOptions: UiUtils.getOptionsFromFields(prdata, UiConstants.BENEFIT_OPTIONS),
                benefitOtherText: prdata.benefitOtherItems ? JSON.stringify(prdata.benefitOtherItems) : "[\"\"]",
                implementOptions: UiUtils.getOptionsFromFields(prdata, UiConstants.IMPLEMENT_OPTIONS),
                communityPostcode: prdata.communityPostcode ? prdata.communityPostcode : "",
                region: prdata.region ? prdata.region : "",
                stateOption: prdata.state ? UiUtils.getOptionFromValue(prdata.state, UiConstants.STATE_OPTIONS) : UiConstants.EMPTY_OPTION,
                multipleStateOptions: prdata.multipleStates ? UiUtils.getOptionsFromValues(prdata.multipleStates, UiConstants.STATE_OPTIONS) : [],
            };
        }
        return values;
    }, []);

    const getAllFormVars = useCallback(() => {
        const options = getProgProjOptions(context.organisation ? context.organisation : {}, false);
        let active = false;
        let ppindex = options.length > 0 ? 0 : -1;
        if (context.selections && context.selections.type) {
            switch (context.selections.type) {
                case EntityConstants.EntityType.PROGRAM:
                    ppindex = options.map(p => p.value).indexOf(context.selections.program.id);
                    if (ppindex > -1) {
                        active = context.selections.program.edit;
                    } else {
                        ppindex = options.length > 0 ? 0 : -1
                    }
                    break;
        
                case EntityConstants.EntityType.PROJECT:
                    ppindex = options.map(p => p.value).indexOf(context.selections.project.id);
                    if (ppindex > -1) {
                        active = context.selections.project.edit;
                    } else {
                        ppindex = options.length > 0 ? 0 : -1
                    }
                    break;
        
                default:
                    break;
            }
        }
        const formValues = getFormValues(context.organisation ? context.organisation : {}, ppindex, options);
        return {
            formActive: active,
            progProjOptions: options,
            progProjIndex: ppindex,
            formValues: formValues
        }
    }, [context, getProgProjOptions, getFormValues]);

    const vars = getAllFormVars();

    const [formActive, setFormActive] = useState(vars.formActive);

    const [progProjOptions, setProgProjOptions] = useState(vars.progProjOptions);
    const [progProjIndex, setProgProjIndex] = useState(vars.progProjIndex);

    const formOptions = {
        defaultValues: vars.formValues
    };

    const { 
        control, 
        handleSubmit,
        watch, 
        reset,
        getValues
    } = useForm(formOptions);

    const [progCreating, setProgCreating] = useState(false);
    const [projCreating, setProjCreating] = useState(false);
    const [progError, setProgError] = useState("");

    const [showArchived, setShowArchived] = useState(false);

    /* Entity Detail Functions */

    function getStatus(index) {
        if (index > -1 && index < progProjOptions.length) {
            const p = progProjOptions[index];
            return p.tags && p.tags.length > 0 && p.tags[0].text ? p.tags[0].text.toLowerCase() : "";
        } else {
            return "";
        }
    }

    function getStatusColor(index) {
        if (index > -1 && index < progProjOptions.length) {
            const p = progProjOptions[index];
            return p.tags && p.tags.length > 0 && p.tags[0].color ? p.tags[0].color : UiConstants.TagColor.GREY;
        } else {
            return UiConstants.TagColor.GREY;
        }
    }

    function getEntityTypeTitle(index, prefix, suffix) {
        const pref = prefix !== "" ? `${prefix} ` :  prefix;
        const suf = suffix !== "" ? ` ${suffix}` :  suffix;
        let type = "program/project";
        if (index > -1 && index < progProjOptions.length) {
            type = progProjOptions[index].type;
        }
        return `${pref}${UiUtils.capitalise(type)}${suf}`;
    }

    /* Form Functions */

    const [showCommPostcode, setShowCommPostcode] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showState, setShowState] = useState(false);
    const [showMultipleStates, setShowMultipleStates] = useState(false);
    const [showOtherRole, setShowOtherRole] = useState(false);
    const [showOtherBenefit, setShowOtherBenefit] = useState(false);

    /* useEffect Related / Form Submit Functions */

    const setImplementUI = useCallback((options) => {
        let pcode = false;
        let reg = false;
        let st = false;
        let mst = false;
        if (options) {
            options.forEach(o => {
                switch (o.value) {
                    case "localCommunityImplement":
                        pcode = true
                        break;
                    case "regionalImplement":
                        reg = true
                        break;
                    case "stateImplement":
                        st = true
                        break;
                    case "multipleStateImplement":
                        mst = true
                        break;
                    default:
                        break;
                }
            });
        }
        setShowCommPostcode(pcode);
        setShowRegion(reg);
        setShowState(st);
        setShowMultipleStates(mst);
    }, [setShowCommPostcode, setShowRegion, setShowState, setShowMultipleStates]);

    const prepareSubmit = useCallback((data) => {
        let details = {
            name: data.name,
            goal: data.goal,
            enterpriseOtherItems: JSON.parse(data.enterpriseOtherText),
            benefitOtherItems: JSON.parse(data.benefitOtherText),
            communityPostcode: data.communityPostcode,
            region: data.region,
            state: data.stateOption.value,
            multipleStates: data.multipleStateOptions.map(o => o.value),
        };
        if (data.startDate !== null) {
            details.startDate = data.startDate;
        }
        if (data.endDate !== null) {
            details.endDate = data.endDate;
        }
        details = UiUtils.setFieldsFromOptions(details, data.enterpriseRoleOptions, UiConstants.ROLE_OPTIONS);
        details = UiUtils.setFieldsFromOptions(details, data.benefitOptions, UiConstants.BENEFIT_OPTIONS);
        details = UiUtils.setFieldsFromOptions(details, data.implementOptions, UiConstants.IMPLEMENT_OPTIONS);
        return details;
    },[]);

    const updateContext = useCallback((details) => {
        if (progProjIndex > -1 && progProjIndex < progProjOptions.length) {
            const type = details.entityType;
            const entity = progProjOptions[progProjIndex].value;
            let organisation = cloneDeep(context.organisation);
            if (type === EntityConstants.EntityType.PROGRAM) {
                const index = organisation.programs.map(p => p._id).indexOf(entity);
                if (index > -1) {
                    organisation.programs[index].name = details.name;
                    organisation.programs[index].progProjectData = details;
                }
            } else {
                const index = organisation.projects.map(p => p._id).indexOf(entity);
                if (index > -1) {
                    organisation.projects[index].name = details.name;
                    organisation.projects[index].progProjectData = details;
                }
            }
            setContextValues([{ key : "organisation", value : organisation }]);
            return organisation;
        }
    }, [context, progProjIndex, progProjOptions, setContextValues]);

    const onSubmit = useCallback((data) => {
        if (progProjIndex > -1 && progProjIndex < progProjOptions.length) {
            let payload = prepareSubmit(data);
            payload.entity = progProjOptions[progProjIndex].value
            payload.entityType = progProjOptions[progProjIndex].type;
            ProgDataService.updateProgProjectData(payload)
            .then(response => {
                const organisation = updateContext(payload);
                setProgProjOptions(getProgProjOptions(organisation, showArchived));
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, [progProjOptions, getProgProjOptions, progProjIndex, prepareSubmit, updateContext, showArchived]);

    function checkFieldUpdate(field, data) {
        if (progProjIndex > -1 && progProjIndex < progProjOptions.length) {
            const id = progProjOptions[progProjIndex].value;
            const type = progProjOptions[progProjIndex].type;
            let hasProgProjectData = false;
            let pdata = {};
            if (type === EntityConstants.EntityType.PROGRAM) {
                const index = context.organisation.programs.map(p => p._id).indexOf(id);
                if (index > -1) {
                    let prog = context.organisation.programs[index];
                    pdata = prog.progProjectData ? prog.progProjectData : {};
                    hasProgProjectData = prog.progProjectData ? true : false;
                }
            } else if (type === EntityConstants.EntityType.PROJECT) {
                const index = context.organisation.projects.map(p => p._id).indexOf(id);
                if (index > -1) {
                    let proj = context.organisation.projects[index];
                    pdata = proj.progProjectData ? proj.progProjectData : {};
                    hasProgProjectData = proj.progProjectData ? true : false;
                }
            }
            if (hasProgProjectData) {
                if (pdata.hasOwnProperty(field)) {
                    if (pdata[field] !== data[field]) {
                        onSubmit(data);
                    }
                } else {
                    if (data[field] !== "") {
                        onSubmit(data);
                    }
                }
            } else {
                onSubmit(data);
            }
        }
    }

    function onNameSubmit(data) {
        checkFieldUpdate("name", data);
    }

    function onGoalSubmit(data) {
        checkFieldUpdate("goal", data);
    }

    function onEnterpriseOtherSubmit(data) {
        checkFieldUpdate("enterpriseOtherText", data);
    }

    function onBenefitOtherSubmit(data) {
        checkFieldUpdate("benefitOtherText", data);
    }

    function onCommunityPostcodeSubmit(data) {
        checkFieldUpdate("communityPostcode", data);
    }

    function onRegionSubmit(data) {
        checkFieldUpdate("region", data);
    }

    /* Interaction Functions*/

    function setContextSelection(type, id, edit) {
        let selections = context.selections ? cloneDeep(context.selections) : {};
        delete selections.program;
        delete selections.project;
        selections.type = type;
        if (type !== EntityConstants.EntityType.NONE) {
            selections[type] = { id: id, edit: edit };
        }
        setContextValues([{ key: 'selections', value: selections }]);
    }

    function progProjChanged(selection) {
        const index = progProjOptions.map(se => se.value).indexOf(selection.value);
        if (index > -1) {
            setProgProjIndex(index);
            reset(getFormValues(context.organisation ? context.organisation : {}, index, progProjOptions));
            if (getStatus(index) === EntityConstants.EntityStatus.ARCHIVED) {
                setContextSelection(EntityConstants.EntityType.NONE, "", false);
            } else {
                setContextSelection(selection.type, selection.value, false);
            }
            
        }
    }

    function archivedChanged(e) {
        const archived = !showArchived;
        const currentId = progProjIndex > -1 ? progProjOptions[progProjIndex].value : "";
        const newOpts = getProgProjOptions(context.organisation ? context.organisation : {}, archived);
        let newIndex = newOpts.map(o => o.value).indexOf(currentId);
        newIndex = newIndex > -1 ? newIndex : (newOpts.length > 0 ? 0 : -1);
        setProgProjOptions(newOpts);
        setProgProjIndex(newIndex);
        setShowArchived(archived);
        reset(getFormValues(context.organisation ? context.organisation : {}, newIndex, newOpts));
        setContextSelection(newIndex > -1 ? newOpts[newIndex].type : "", currentId, false);
    }

    function addProgramClicked(e) {
        setProgCreating(true)
        setProgError("")
        const name = `Program #${context.organisation && context.organisation.programs ? context.organisation.programs.length + 1 : 1}`;
        OrgService.createProgram({
            name: name
        })
        .then(response => {
            setProgCreating(false);
            let organisation = context.organisation ? cloneDeep(context.organisation) : { programs: [] };
            let selections = context.selections ? cloneDeep(context.selections) : {};
            if (organisation.programs) {
                organisation.programs.push(response.data.entity);
            } else {
                organisation.programs = [response.data.entity];
            }
            const id = response.data.entity._id;
            delete selections.enterprise;
            delete selections.program;
            delete selections.project;
            selections.type = EntityConstants.EntityType.PROGRAM;
            selections.program = { id : id, edit : true };
            setContextValues([
                { key: "organisation", value: organisation },
                { key: "selections", value: selections }
            ]);
            const opts = getProgProjOptions(organisation, showArchived);
            const index = opts.map(o => o.value).indexOf(id);
            setProgProjOptions(opts);
            setProgProjIndex(index);
            reset(getFormValues(organisation, index, opts));
            setFormActive(true);
        })
        .catch(err => {
            setProgCreating(false);
            setProgError("An error occurred creating your program. Please try again later.");
        });
    }

    function addProjectClicked(e) {
        setProjCreating(true)
        setProgError("")
        const name = `Project #${context.organisation && context.organisation.projects ? context.organisation.projects.length + 1 : 1}`;
        OrgService.createProject({
            name: name
        })
        .then(response => {
            setProjCreating(false);
            let organisation = context.organisation ? cloneDeep(context.organisation) : { projects: [] };
            let selections = context.selections ? cloneDeep(context.selections) : {};
            if (organisation.projects) {
                organisation.projects.push(response.data.entity);
            } else {
                organisation.projects = [response.data.entity];
            }
            const id = response.data.entity._id;
            delete selections.enterprise;
            delete selections.program;
            delete selections.project;
            selections.type = EntityConstants.EntityType.PROJECT;
            selections.project = { id : id, edit : true };
            setContextValues([
                { key: "organisation", value: organisation },
                { key: "selections", value: selections }
            ]);
            const opts = getProgProjOptions(organisation, showArchived);
            const index = opts.map(o => o.value).indexOf(id);
            setProgProjOptions(opts);
            setProgProjIndex(index);
            reset(getFormValues(organisation, index, opts));
            setFormActive(true);
        })
        .catch(err => {
            setProjCreating(false);
            setProgError("An error occurred creating your program. Please try again later.");
        });
    }

    function editClicked(e) {
        e.preventDefault();
        if (formActive) {
            setFormActive(false);
        } else {
            if (progProjIndex > -1) {
                setFormActive(true);
            }
        }
    }

    function afterUnOrArchive(organisation, id) {
        const opts = getProgProjOptions(organisation, showArchived);
        let newIndex = opts.map(o => o.value).indexOf(id);
        newIndex = newIndex > -1 ? newIndex : (opts.length > 0 ? 0 : -1);
        setProgProjOptions(opts);
        setProgProjIndex(newIndex);
        reset(getFormValues(organisation, newIndex, opts));
        setContextValues([{ key: "organisation", value: organisation }]);
    }

    function archiveClicked(e) {
        e.preventDefault();
        if (progProjIndex > -1) {
            const opt = progProjOptions[progProjIndex];
            if (window.confirm(`Are you sure you want to archive ${opt.label}?`)) {
                OrgService.archiveEntity(opt.value)
                .then((_) => {
                    let organisation = cloneDeep(context.organisation);
                    if (opt.type === EntityConstants.EntityType.PROGRAM) {
                        const index = organisation.programs.map(p => p._id).indexOf(opt.value);
                        if (index > -1) {
                            organisation.programs[index].status = EntityConstants.EntityStatus.ARCHIVED;
                        }
                    } else {
                        const index = organisation.projects.map(p => p._id).indexOf(opt.value);
                        if (index > -1) {
                            organisation.projects[index].status = EntityConstants.EntityStatus.ARCHIVED;
                        }
                    }
                    afterUnOrArchive(organisation, opt.value);
                })
                .catch(err => {
                    console.log(err);
                });
            }
        }
    }

    function unArchiveClicked(e) {
        e.preventDefault();
        if (progProjIndex > -1) {
            let opt = progProjOptions[progProjIndex];
            if (window.confirm(`Are you sure you want to unarchive ${opt.label}?`)) {
                OrgService.unArchiveEntity(opt.value)
                .then((_) => {
                    let organisation = cloneDeep(context.organisation);
                    if (opt.type === EntityConstants.EntityType.PROGRAM) {
                        const index = organisation.programs.map(p => p._id).indexOf(opt.value);
                        if (index > -1) {
                            organisation.programs[index].status = EntityConstants.EntityStatus.ACTIVE;
                        }
                    } else {
                        const index = organisation.projects.map(p => p._id).indexOf(opt.value);
                        if (index > -1) {
                            organisation.projects[index].status = EntityConstants.EntityStatus.ACTIVE;
                        }
                    }
                    afterUnOrArchive(organisation, opt.value);
                })
                .catch(err => {
                    console.log(err);
                });
            }
        }
    }

    /* useEffect Functions */

    useEffect(() => {
        const data = getValues();
        setImplementUI(data.implementOptions);
        setShowOtherRole(data.enterpriseRoleOptions.map(e => e.value).indexOf("enterpriseOther") > -1);
        setShowOtherBenefit(data.benefitOptions.map(e => e.value).indexOf("benefitOther") > -1);
    }, [getValues, setImplementUI]);

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            switch (name) {
                case "implementOptions":
                //setImplementUI(data.implementOptions);
                onSubmit(data);
                break;
                case "startDate":
                case "endDate":
                case "enterpriseRoleOptions":
                case "benefitOptions":
                case "stateOption":
                case "multipleStateOptions":
                onSubmit(data);
                break;
                default:
                break;
            }
        });
        return () => {
            subscription.unsubscribe();
        }
    }, [watch, setImplementUI, onSubmit]);

    /* Trial Upgrade Modal Functions */

    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(context.user && context.user.trialAccount ? context.user.trialAccount : false);

    const onTrialUpgradeClick = () => {
        setUpgradeModalOpen(true);
    }

    const onTrialUpgradeDone = () => {
        setIsTrial(false);
        setUpgradeModalOpen(false);
    }
    
    /* Data Refresh Function */

    const [dataRefresh, setDataRefresh] = useState(false);
    
    useEffect(() => {
        if (dataRefresh) {
            const vars = getAllFormVars();

            setFormActive(vars.formActive);
        
            setProgProjOptions(vars.progProjOptions);
            setProgProjIndex(vars.progProjIndex);
        
            reset(vars.formValues);
    
            const roles = context.user && context.user.roles ? context.user.roles : [];
            setUserCanEdit(UiUtils.checkUserAccess(roles, UserConstants.ObjectTypes.ENTITY, UserConstants.AccessTypes.EDIT));

            setIsTrial(context.user && context.user.trialAccount ? context.user.trialAccount : false);
    
            setDataRefresh(false);
        }
    }, [dataRefresh, context, getAllFormVars, setFormActive, setProgProjOptions, setProgProjIndex, 
        reset, setUserCanEdit, setIsTrial, setDataRefresh]);

    /* Terms Agreement Functions */

    const [termsModalOpen, setTermsModalOpen] = useState(false);

    useEffect(() => {
        if (context.user && context.user.id) {
            if (context.user.agreeToTerms && context.user.agreeToTerms === true) {
                setTermsModalOpen(false);
            } else {
                setTermsModalOpen(true);
            }
        } else {
            setTermsModalOpen(false);
        }
    }, [context, setTermsModalOpen]);

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageCategory.USER_ACCOUNT + " / " + MatomoConstants.PageTitle.PROGRAMS_PROJECTS
        });
    }, []);

    return (
        <div className="w-full h-full flex">
            <KitSideNav page="Programs/Projects" onToggle={(value) => setNavExpanded(value)}/>
            <div className={UiUtils.classNames(containerClass, navExpanded ? GeneralConstants.EXPANDED_NAV_MARGIN : GeneralConstants.COLLAPSED_NAV_MARGIN)}>
                {isTrial ? (
                    <KitTopBar 
                        onDataRefresh={() => setDataRefresh(true)} 
                        banner={GeneralConstants.TRIAL_BANNER}
                        onBannerClick={() => onTrialUpgradeClick()}
                    />
                ) : (
                    <KitTopBar onDataRefresh={() => setDataRefresh(true)}/>
                )}
                <div className="flex flex-col items-stretch gap-10 py-8 px-10 bg-white bg-enterprise bg-contain bg-right-top bg-no-repeat">
                    <OrgEntHeader 
                        title="Programs / Projects" 
                        subtitle={userCanEdit ? "Create your programs/projects and view or edit program/project information." : "View information on your organisation's programs/projects."}
                        tooltip="Programs usually consist of multiple projects, while projects typically represent specific efforts with shorter implementation periods than programs."
                        showStrap={userCanEdit}
                        editing={formActive}
                    />
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col items-stretch gap-4">
                            <TaggedSelectDropdown
                                label="Select Program or Project to display below" 
                                options={progProjOptions}
                                selectedOption={progProjIndex > -1 ? progProjOptions[progProjIndex] : null}
                                tagClass="w-20"
                                onChange={progProjChanged}
                            />
                            <Checkbox
                                label="Show Archived Programs/Projects"
                                checked={showArchived}
                                noRing={true}
                                onChange={archivedChanged}
                            />
                        </div>
                        {userCanEdit && (
                        <div className="mt-7 h-[50px] flex justify-start items-center gap-4">
                            <Button 
                                variant="outline" 
                                size="small" 
                                label="Add Program" 
                                leftIcon="PlusSmIcon"
                                className={projCreating || progCreating ? "hidden" : ""}  
                                onClick={addProgramClicked}
                            />
                            <Button 
                                variant="outline" 
                                size="small" 
                                label="Add Project" 
                                leftIcon="PlusSmIcon"
                                className={projCreating || progCreating ? "hidden" : ""}  
                                onClick={addProjectClicked}
                            />
                            <p 
                                className={projCreating || progCreating ? "font-vg-regular text-black" : "hidden"}
                            >
                                {progCreating ? "Creating program..." : (projCreating ? "Creating project..." : "")}
                            </p>
                            <p className="font-vg-regular text-base text-red">{progError}</p>
                        </div>
                        )}
                    </div>

                    <form className="flex flex-col items-stretch gap-10">
                        <div className="flex justify-between items-center gap-4">
                            <div className="grow flex gap-4 items-center">
                                {progProjIndex > -1 && (
                                <>
                                    <Tag
                                        text={getStatus(progProjIndex)}
                                        color={getStatusColor(progProjIndex)}
                                        className="w-20 shrink-0"
                                    />
                                    <Tag
                                        text={getEntityTypeTitle(progProjIndex, "", "")}
                                        color={UiConstants.TagColor.BLACK_OUTLINE}
                                        className="w-20 shrink-0"
                                    />
                                </>
                                )}
                                {formActive === true && (
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextInputHeading
                                            ref={ref}
                                            textSize="text-3.5xl" 
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onNameSubmit)}
                                        />
                                    )}
                                />
                                )}
                                {formActive === false && (
                                <h4 className="font-vg-medium text-3.5xl p-1 text-black">{watch("name")}</h4>
                                )}
                            </div>
                            {userCanEdit && (
                            <div className="grow-0 flex gap-4 items-center">
                                <Button 
                                    variant="outline" 
                                    size="small" 
                                    label={formActive ? UiConstants.DONE_BUTTON_LABEL : UiConstants.EDIT_BUTTON_LABEL}
                                    leftIcon="PencilAltIcon" 
                                    onClick={editClicked}
                                />
                                {getStatus(progProjIndex) !== EntityConstants.EntityStatus.ARCHIVED && (
                                <Button 
                                    variant="delete" 
                                    size="small" 
                                    label="Archive"
                                    leftIcon="ArchiveIcon" 
                                    onClick={archiveClicked}
                                />
                                )}
                                {getStatus(progProjIndex) === EntityConstants.EntityStatus.ARCHIVED && (
                                <Button 
                                    variant="delete" 
                                    size="small" 
                                    label="Unarchive"
                                    leftIcon="BriefcaseIcon" 
                                    onClick={unArchiveClicked}
                                />
                                )}
                            </div>
                            )}
                        </div>
                        <div className="grid grid-cols-12 gap-6 pb-10">
                            <OrgEntPanelHeader title={getEntityTypeTitle(progProjIndex, "", "Information")} subtitle="" className="col-span-5"/>
                            <div className="col-span-7 flex flex-col items-stretch gap-8">
                                <Controller
                                    name="goal"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                        <TextArea
                                            ref={ref}
                                            variant={formActive ? "default" : "disabled"}
                                            label="This Program/Project aims to..."
                                            placeholder={getEntityTypeTitle(progProjIndex, "Write the goal/objective of your", "here")}
                                            rows="3"
                                            onChange={onChange}
                                            value={value}
                                            onBlur={handleSubmit(onGoalSubmit)}
                                        />
                                    )}
                                />
                                <div className="grid grid-cols-2 gap-6">
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <CustomReactDatePicker 
                                                label="Start Date" 
                                                isDisabled={!formActive} 
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <CustomReactDatePicker 
                                                label="End Date" 
                                                isDisabled={!formActive} 
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <Controller
                                    name="enterpriseRoleOptions"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <MultiSelectDropdown 
                                            label={getEntityTypeTitle(progProjIndex, "The role(s) my social enterprise plays in the", "is/are...")} 
                                            options={UiConstants.ROLE_OPTIONS}
                                            help="You can select multiple options"
                                            selectedOptions={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className={showOtherRole ? "block" : "hidden"}>
                                    <Controller
                                        name="enterpriseOtherText"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                            <OtherItemsInput
                                                ref={ref}
                                                label="If Other, please specify"
                                                addLabel="Add another"
                                                value={value}
                                                disabled={!formActive}
                                                onChange={onChange}
                                                onBlur={handleSubmit(onEnterpriseOtherSubmit)}
                                            />
                                        )}
                                    />
                                </div>
                                <Controller
                                    name="benefitOptions"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <MultiSelectDropdown 
                                            label={getEntityTypeTitle(progProjIndex, "This", "aims to benefit...")} 
                                            options={UiConstants.BENEFIT_OPTIONS}
                                            help="You can select multiple options"
                                            selectedOptions={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                /> 
                                <div className={showOtherBenefit ? "block" : "hidden"}>
                                    <Controller
                                        name="benefitOtherText"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                            <OtherItemsInput
                                                ref={ref}
                                                label="If Other, please specify"
                                                addLabel="Add another"
                                                value={value}
                                                disabled={!formActive}
                                                onChange={onChange}
                                                onBlur={handleSubmit(onBenefitOtherSubmit)}
                                            />
                                        )}
                                    />
                                </div>    
                                <Controller
                                    name="implementOptions"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <MultiSelectDropdown 
                                            label={getEntityTypeTitle(progProjIndex, "This", "operates...")} 
                                            options={UiConstants.IMPLEMENT_OPTIONS}
                                            help="You can select multiple options"
                                            selectedOptions={field.value}
                                            isDisabled={!formActive}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className={showCommPostcode ? "block" : "hidden"}>
                                    <Controller
                                        name="communityPostcode"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                            <TextInput
                                                ref={ref}  
                                                label="Enter the postcode of the local community you are implementing in:" 
                                                variant={formActive ? "default" : "disabled"} 
                                                onChange={onChange}
                                                value={value}
                                                onBlur={handleSubmit(onCommunityPostcodeSubmit)}
                                            />
                                        )}
                                    />
                                </div>  
                                <div className={showRegion ? "block" : "hidden"}>
                                    <Controller
                                        name="region"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                            <TextInput 
                                                ref={ref} 
                                                label="Enter the name of the region you are implementing in:" 
                                                variant={formActive ? "default" : "disabled"} 
                                                onChange={onChange}
                                                value={value}
                                                onBlur={handleSubmit(onRegionSubmit)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={showState ? "block" : "hidden"}>
                                    <Controller
                                        name="stateOption"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <SelectDropdown 
                                                label="If you are implementing in a single state, select it here:" 
                                                options={UiConstants.STATE_OPTIONS}
                                                selectedOption={field.value}
                                                isDisabled={!formActive}
                                                {...field}
                                            />
                                        )}
                                    />     
                                </div>
                                <div className={showMultipleStates ? "block" : "hidden"}>
                                    <Controller
                                        name="multipleStateOptions"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <MultiSelectDropdown 
                                                label="If you are implementing multiple states, select them here:" 
                                                options={UiConstants.MULTI_STATE_OPTIONS}
                                                help="You can select multiple options"
                                                selectedOptions={field.value}
                                                isDisabled={!formActive}
                                                {...field}
                                            />
                                        )}
                                    />     
                                </div>
                            </div>
                        </div>
                    <Footer/>
                    </form>
                </div>
            </div>
            <TrialUpgradeModal
                open={upgradeModalOpen}
                onUpgrade={(_) => onTrialUpgradeDone()}
                onClose={() => setUpgradeModalOpen(false)}
            />
            <TermsAgreementModal
                open={termsModalOpen}
                onAgreement={() => setTermsModalOpen(false)}
            />
        </div>
    );
}