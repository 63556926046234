import { forwardRef, useId, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import UiUtils from '../utils/UiUtils';

import Checkbox from './Checkbox';

const MultiSelectDropdown = forwardRef((props, ref) => {
    const { id, label, options, selectedOptions, isDisabled, onChange, template } = props;

    const displayTemplate = template ? template : "";

    const newId = useId();
    const selectId = id ? id : newId;

    function handleChange(selection) {
        if (onChange) {
            onChange(selection);
        }
    }

    function getTemplateText(temp) {
        if (selectedOptions.length > 1) {
            let text = temp.replace("{COUNT}", selectedOptions.length);
            return (
                <span key={`${selectId}-selection-0`} className="font-vg-book text-grey text-base leading-130">{text}</span>
            );
        } else if (selectedOptions.length === 1) {
            return (
                <span key={`${selectId}-selection-0`} className="font-vg-book text-grey text-base leading-130">{selectedOptions[0].label}</span>
            );
        } else {
            return (
                <></>
            );
        }
    }

    const buttonClass = UiUtils.classNames(isDisabled ? "border-grey03" : "border-grey01", "relative w-full min-h-[50px] flex justify-between gap-3 items-center p-3 cursor-default border bg-grey04 rounded-md");
    const chevronClass = isDisabled ? "h-6 w-6 text-grey03 shrink-0 grow-0" : "h-6 w-6 text-grey05 shrink-0 grow-0";

    return (
        <Listbox ref={ref} value={selectedOptions} by="value" disabled={isDisabled} onChange={(selection) => handleChange(selection)} multiple>
            {({ open }) => (
                <div>
                    {label && (
                        <Listbox.Label className="font-vg-book text-grey text-base leading-130 mb-1.5 block">{label}</Listbox.Label>
                    )}
                    <div className="relative">
                        <Listbox.Button className={buttonClass}>
                            <div className="flex flex-wrap grow gap-2">
                                {displayTemplate !== "" ? 
                                    getTemplateText(displayTemplate) : 
                                    selectedOptions.map((opt, index) => (
                                        <span key={`${selectId}-selection-${index}`} className="font-vg-book text-grey text-base leading-130 text-left">{`${opt.label}${(index < selectedOptions.length - 1 ? "," : "")}`}</span>
                                    )
                                )}
                            </div>
                            <ChevronDownIcon className={chevronClass} aria-hidden="true"/>
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1.5 max-h-60 w-full overflow-auto font-vg-book text-base leading-130 rounded-lg bg-white shadow-listbox-options focus:outline-none">
                                {options.map((opt, index) => (
                                    <Listbox.Option key={`${selectId}-option-${index}`} value={opt} as={Fragment}>
                                        {({ selected, active }) => (
                                            <li className={UiUtils.classNames(selected ? "text-black bg-blue03" : (active ? "text-grey bg-blue03" : "text-grey bg-white") , "h-12 relative flex items-center justify-between cursor-default select-none px-4 py-[15px]")}>
                                                <span className="block truncate">{opt.label}</span>
                                                <Checkbox noRing={true} checked={selected}/>
                                            </li>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );
});

export default MultiSelectDropdown;