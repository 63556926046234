import { useState, useContext } from 'react';
import { BuildingOfficeIcon, BuildingStorefrontIcon, UsersIcon, SparklesIcon, TableCellsIcon, LightBulbIcon, SquaresPlusIcon } from '@heroicons/react/24/outline';
import { ArrowSmallLeftIcon, ArrowSmallRightIcon, HomeIcon, BookOpenIcon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet-async';

import UiUtils from '../utils/UiUtils';
import * as GeneralConstants from '../constants/GeneralConstants';
import { GlobalContext } from '../context/GlobalContext';

import Logo from '../images/seedkit_logo_black.svg';

export default function KitSideNav({ page, onToggle }) {
    const { context, setContextValues } = useContext(GlobalContext);

    let expanded = context.hasOwnProperty('expandedSideNav') ? context.expandedSideNav : true;
    const [isExpanded, setIsExpanded] = useState(expanded);
    
    const navigation = [
        { 
            name: "Home", 
            current: (page === "Home"), 
            href: "/kit/home",
            alert: ""
        },
        { 
            name: "Organisation", 
            current: (page === "Organisation"), 
            href: "/kit/organisation",
            alert: ""
        },
        { 
            name: "Social Enterprises", 
            current: (page === "Social Enterprises"), 
            href: "/kit/enterprise",
            alert: ""
        },
        { 
            name: "Programs/Projects", 
            current: (page === "Programs/Projects"), 
            href: "/kit/progproject",
            alert: ""
        },
        { 
            name: "Indicators", 
            current: (page === "Indicators"), 
            href: "/kit/indicators",
            alert: ""
        },
        { 
            name: "Data Management", 
            current: (page === "Data Management"), 
            href: "/kit/datamanagement",
            alert: ""
        },
        { 
            name: "Dashboards", 
            current: (page === "Dashboards"), 
            href: "/kit/dashboards",
            alert: ""
        },
        { 
            name: "Resources", 
            current: (page === "Resources"), 
            href: "/kit/resources",
            alert: ""
        },
        { 
            name: "User Management", 
            current: (page === "User Management"), 
            href: "/kit/usermanagement",
            alert: ""
        },
    ];

    /*
            name: "User Management", 
            current: (page === "User Management"), 
            href: "",
            alert: "This feature is currently under development and will be available soon. Once completed, it will allow organisational account holders to create multiple users and give them view only or edit access to specific enterprise/program/project data, indicator pathways or dashboards."
    */

    const containerClass = "h-screen shrink-0 flex flex-col items-stretch bg-grey04 px-4 pb-3";
    const navClass = "flex justify-start items-center gap-3 p-2 rounded-md leading-120 no-underline";

    function getItemIcon(item) {
        let Icon = null;
        switch (item.name) {
            case "Home":
                Icon = HomeIcon;
                break;     
            case "Organisation":
                Icon = BuildingOfficeIcon;
                break;        
            case "Social Enterprises":
                Icon = BuildingStorefrontIcon;
                break;       
            case "Programs/Projects":
                Icon = SparklesIcon;
                break; 
            case "Indicators":
                Icon = LightBulbIcon;
                break;      
            case "Data Management":
                Icon = TableCellsIcon;
                break;      
            case "Dashboards":
                Icon = SquaresPlusIcon;
                break;      
            case "Resources":
                Icon = BookOpenIcon;
                break;       
            case "User Management":
                Icon = UsersIcon;
                break;       
            default:
               break;
        }
        if (Icon) {
            return (<Icon className={UiUtils.classNames(item.current? "text-black" : "text-grey", "h-6 w-6")}/>);
        } else {
            return (<></>);
        }
    }
      
    function toggleWidth() {
        const newIsExpanded = !isExpanded;
        setIsExpanded(newIsExpanded);
        setContextValues([{ key: 'expandedSideNav', value: newIsExpanded }]);
        if (onToggle) {
            onToggle(newIsExpanded);
        }
    } 

    function showAlert(text) {
        if (text !== "") {
            alert(text);
        }
    }

    return(
        <>
        <Helmet>
            <title>{page ? `${GeneralConstants.DEFAULT_PAGE_TITLE} | ${(page === "Home" ? "My Account" : page)}` : GeneralConstants.DEFAULT_PAGE_TITLE}</title>
        </Helmet>
        {isExpanded && (
            <div className={UiUtils.classNames(containerClass, GeneralConstants.EXPANDED_NAV_WIDTH)}>
                <div className="flex items-center justify-between pl-1.5 h-15">
                    <a href="/"><img src={Logo} className="h-7" alt="Seedkit logo"/></a>
                    <button className="flex justify-center items-center h-6 w-6 rounded-sm bg-grey03" onClick={toggleWidth}>
                        <ArrowSmallLeftIcon className="h-4.5 w-4.5 text-grey"/>
                    </button>
                </div>
                <nav className="flex flex-col items-stretch py-8">
                    <ul className=" space-y-1">
                        {navigation.map(item => (
                            <li key={item.name}>
                                {!item.children ? (
                                    <>
                                    {item.href !== "" ? (
                                        <a 
                                            href={item.href} 
                                            className={UiUtils.classNames(item.current ? "font-vg-regular text-black bg-green03" : "font-vg-book text-grey hover:bg-green03", navClass)}
                                        >
                                            {getItemIcon(item)}
                                            {item.name}
                                        </a>
                                    ) : (
                                        <button 
                                            className={UiUtils.classNames(item.current ? "w-full font-vg-regular text-black bg-green03" : "w-full font-vg-book text-grey hover:bg-green03", navClass)}
                                            onClick={() => showAlert(item.alert)}
                                        >
                                            {getItemIcon(item)}
                                            {item.name}
                                        </button>
                                    )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        )}
        {!isExpanded && (
            <div className={UiUtils.classNames(containerClass, GeneralConstants.COLLAPSED_NAV_WIDTH)}>
                <div className="flex items-center justify-between pl-1.5 h-15">
                    <button className="flex justify-center items-center h-6 w-6 rounded-sm bg-grey03" onClick={toggleWidth}>
                        <ArrowSmallRightIcon className="h-4.5 w-4.5 text-grey"/>
                    </button>
                </div>
                <nav className="flex flex-col items-stretch py-8">
                    <ul className=" space-y-1">
                        {navigation.map(item => (
                            <li key={item.name}>
                                {!item.children ? (
                                    <>
                                    {item.href !== "" ? (
                                        <a 
                                            href={item.href} 
                                            className={UiUtils.classNames(item.current ? "font-vg-regular text-black bg-green03" : "font-vg-book text-grey hover:bg-green03", navClass)}
                                        >
                                            {getItemIcon(item)}
                                        </a>
                                    ) : (
                                        <button 
                                            className={UiUtils.classNames(item.current ? "w-full font-vg-regular text-black bg-green03" : "w-full font-vg-book text-grey hover:bg-green03", navClass)}
                                            onClick={() => showAlert(item.alert)}
                                        >
                                            {getItemIcon(item)}
                                        </button>
                                    )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        )}
        </>
    );
}