import * as DataConstants from '../constants/DataConstants';

export default function DataDetailsCellRenderer(props) {
    const onButtonClicked = () => {
        props.clicked(props.data.indicatorKey);
    }

    return (
        <>
        {props.data.rowType === DataConstants.DataRowType.INDICATOR && (
            <button className="w-8 h-full flex items-center justify-center text-blue" onClick={onButtonClicked}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                </svg>
            </button>
        )}
        </>
    );
}