let ColorUtils = {};

const toHex = (value) => {
    if (!value.startsWith('#')) {
        const ctx = document.createElement('canvas').getContext('2d');
        if (!ctx) {
            throw new Error('2d context not supported or canvas already initialized');
        }
        ctx.fillStyle = value;
        return ctx.fillStyle;

    } else if (value.length === 4 || value.length === 5) {
        value = value.split('').map((v, i) => (i ? v + v : '#')).join('');
        return value;
  

    } else if (value.length === 7 || value.length === 9) {
        return value;
    }

    return '#000000';
};

const hex2rgb = (hex) => {
    const rbgArr = (
        hex.replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m, r, g, b) => '#' + r + r + g + g + b + b,
            )
            .substring(1)
            .match(/.{2}/g) || []
    ).map((x) => parseInt(x, 16));

    return {
        b: rbgArr[2],
        g: rbgArr[1],
        r: rbgArr[0],
    };
};

const rgb2hsv = (rgb) => {
    let r = rgb.r;
    let g = rgb.g;
    let b = rgb.b;

    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const d = max - Math.min(r, g, b);

    const h = d
    ? (max === r
        ? (g - b) / d + (g < b ? 6 : 0)
        : max === g
        ? 2 + (b - r) / d
        : 4 + (r - g) / d) * 60
    : 0;
    const s = max ? (d / max) * 100 : 0;
    const v = max * 100;

    return {h, s, v};
};

const hsv2rgb = (hsv) => {
    let h = hsv.h;
    let s = hsv.s;
    let v = hsv.v;

    s /= 100;
    v /= 100;

    const i = ~~(h / 60);
    const f = h / 60 - i;
    const p = v * (1 - s);
    const q = v * (1 - s * f);
    const t = v * (1 - s * (1 - f));
    const index = i % 6;

    const r = Math.round([v, q, p, p, t, v][index] * 255);
    const g = Math.round([t, v, v, q, p, p][index] * 255);
    const b = Math.round([p, p, t, v, v, q][index] * 255);

    return {b, g, r};
};

const rgb2hex = (rgb) => {
    let r = rgb.r;
    let g = rgb.g;
    let b = rgb.b;

    return '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('');
};

const transformColor = (format, color) => {
    let hex = toHex('#121212');
    let rgb = hex2rgb(hex);
    let hsv = rgb2hsv(rgb);

    if (format === 'hex') {
        hex = toHex(color);
        rgb = hex2rgb(hex);
        hsv = rgb2hsv(rgb);

    } else if (format === 'rgb') {
        rgb = color;
        hex = rgb2hex(rgb);
        hsv = rgb2hsv(rgb);

    } else if (format === 'hsv') {

        hsv = color;
        rgb = hsv2rgb(hsv);
        hex = rgb2hex(rgb);
    }

    return {hex, hsv, rgb};
}

ColorUtils.toHex = toHex;
ColorUtils.hex2rgb = hex2rgb;
ColorUtils.rgb2hsv = rgb2hsv;
ColorUtils.hsv2rgb = hsv2rgb;
ColorUtils.rgb2hex = rgb2hex;
ColorUtils.transformColor = transformColor;

export default ColorUtils;
