import SelectDropdown from './SelectDropdown';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';

export default function DashboardsGridPagination({ variant, count, pageSize, page, onPageChange }) {

    const totalPages = Math.max(Math.ceil(count / pageSize), 1);

    const pageOptions = [];
    for (let i = 0; i < totalPages; i++) {
        const label = `${i + 1}/${totalPages}`;
        pageOptions.push({ value: i, label: label });
    }

    const selectedOption = pageOptions[page];

    const onLeftClick = (e) => {
        e.preventDefault();
        if (onPageChange && page > 0) {
            onPageChange(page - 1);
        }
    }

    const onRightClick = (e) => {
        e.preventDefault();
        if (onPageChange && page < totalPages) {
            onPageChange(page + 1);
        }
    }

    const onPageSelect = (selection) => {
        //TODO: onPageSelect
    }

    return (
        <div className="flex items-center gap-2">
            {variant === "full" ? (
            <>
                <div className="font-vg-regular text-base text-grey">Page</div>
                <SelectDropdown 
                    variant="blue"
                    options={pageOptions}
                    selectedOption={selectedOption}
                    onChange={onPageSelect}
                />
                <button className="flex h-full items-center justify-center" onClick={onLeftClick}>
                    <ArrowLeftIcon className="w-5 h-5 text-blue"/>
                </button>
                <button className="flex h-full items-center justify-center" onClick={onRightClick}>
                    <ArrowRightIcon className="w-5 h-5 text-blue"/>
                </button>
            </>
            ) : (
            <>
                <button className="flex h-full items-center justify-center" onClick={onLeftClick}>
                    <ArrowLeftIcon className="w-5 h-5 text-blue"/>
                </button>
                <p className>{`${page} of ${totalPages}`}</p>
                <button className="flex h-full items-center justify-center" onClick={onRightClick}>
                    <ArrowRightIcon className="w-5 h-5 text-blue"/>
                </button>
            </>
            )}
        </div>
    );
}