import { PlusCircleIcon } from '@heroicons/react/24/solid';

import DashboardConstants from '../constants/DashboardConstants';

import DashboardPagesListCard from "./DashboardPagesListCard";

export default function DashboardPagesList({ pages, selectedPage, onPagesListAction }) {

    const onCardClick = (index) => {
        if (onPagesListAction) {
            onPagesListAction({ action: DashboardConstants.ActionType.SELECT_PAGE, args: { index: index }});
        }
    }

    const onAddCardClick = () => {
        if (onPagesListAction) {
            onPagesListAction({ action: DashboardConstants.ActionType.CREATE_PAGE });
        }
    }

    const onDeleteCardClick = (index) => {
        if (onPagesListAction) {
            let deleteMsg = "Are you sure you want to delete this page?";
            if (pages.length === 1) {
                deleteMsg = `${deleteMsg} (As this is the last page in its dashboard, the dashboard will also be deleted.)`;
            }
            if (window.confirm(deleteMsg)) {
                onPagesListAction({ action: DashboardConstants.ActionType.DELETE_PAGE, args: { index: index }});
            }
        }
    }

    return (
        <div className="w-full flex overflow-x-scroll">
            <div className="flex flex-nowrap gap-6 mb-6">
                {pages.map((page, index) => (
                    <DashboardPagesListCard
                        key={`dash-page-list-${index}`} 
                        index={index} 
                        page={page}
                        selected={selectedPage === index}
                        onClick={onCardClick}
                        onDeleteClick={onDeleteCardClick}
                    />
                ))}
                <button 
                    className="flex flex-col items-center w-[118px] h-[72px] rounded border border-grey01 bg-white pt-2.5"
                    onClick={onAddCardClick}
                >
                    <div className="flex flex-col items-center gap-[13px]">
                        <PlusCircleIcon className="w-5 h-5 text-blue"/>
                        <p className="font-vg-regular text-sm text-blue">Add a page</p>
                    </div>
                </button>
            </div>
        </div>
    );
}