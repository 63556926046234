import { forwardRef } from 'react';

import IconTooltip from './IconTooltip';

const Checkbox = forwardRef((props, ref) => {
    const { variant, label, noRing, className, labelClass, tooltip, help, showHelp, helpColor, required, onChange, ...otherProps } = props;
    const checkVariant = variant ? variant : "default";
    let outerClass = "flex gap-2 items-center font-vg-book text-base text-black";
    if (className) {
        outerClass = `${outerClass} ${className}`;
    }
    let checkClass = "w-4 h-4 rounded-sm border bg-white border-black checked:bg-blue checked:border-blue";
    if (noRing) {
        checkClass = `${checkClass} focus:ring-transparent focus:ring-offset-transparent`;
    }
    return (
        <div>
            <div className={outerClass}>
                <label className={`flex gap-2 items-center ${labelClass ? labelClass : "font-vg-book text-base text-black"}`}>
                    <input
                        ref={ref} 
                        type="checkbox" 
                        className={checkClass}
                        onChange={(e) => {
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        { ...otherProps } 
                    />
                    {required ? (
                        <div>
                            <span dangerouslySetInnerHTML={{__html: label}}></span>
                            <span>&nbsp;&nbsp;*</span>
                        </div> 
                    ) : (
                        <span dangerouslySetInnerHTML={{__html: label}}></span>
                    )}
                    
                    {tooltip && (
                        <IconTooltip content={tooltip} position="right"/>
                    )}
                </label>
            </div>
            {(help || showHelp === "always") && (
                <p className={`font-vg-book text-xs2 leading-[0.975rem] mt-1.5 h-[0.975rem] ${checkVariant === "error" ? "text-red" : (helpColor ? helpColor :"text-grey")}`}>{help}</p>
            )}
        </div>
    );
});

export default Checkbox;