import {lookupIcon} from 'heroicons-lookup';

export default function HomeFeature({ icon, title, text }) {
    const Icon = lookupIcon(icon, "outline");
    return(
        <div className="flex flex-col items-stretch gap-4">
            <div className="flex items-center gap-3">
                <Icon className="text-purple01 h-6"/>
                <h4 className="font-vg-medium text-black text-2xl">{title}</h4>
            </div>
            <p className="font-vg-book text-black text-base">{text}</p>
        </div>
    );
}