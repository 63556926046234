import { calculateZoomLevel } from '@lexical/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import ColorUtils from '../utils/ColorUtils';

import TextInput from './TextInput';

import '../styles/color-picker.css';

let skipAddingToHistoryStack = true;

const WIDTH = 214;
const HEIGHT = 150;

function MoveWrapper({className, style, onChange, children}) {
   const divRef = useRef(null);
   const draggedRef = useRef(false);  
   
   const clamp = (value, max, min) => {
      return value > max ? max : value < min ? min : value;
   }

   const move = (e) => {
      if (divRef.current) {
         const {current: div} = divRef;
         const {width, height, left, top} = div.getBoundingClientRect();
         const zoom = calculateZoomLevel(div);
         const x = clamp(e.clientX / zoom - left, width, 0);
         const y = clamp(e.clientY / zoom - top, height, 0);
   
         onChange({x, y});
      }
   };

   const onMouseDown = (e) => {
      if (e.button !== 0) {
         return;
      }
      draggedRef.current = true;
   
      move(e);
   
      const onMouseMove = (_e) => {
         draggedRef.current = true;
         skipAddingToHistoryStack = true;
         move(_e);
      };
   
      const onMouseUp = (_e) => {
         if (draggedRef.current) {
               skipAddingToHistoryStack = false;
         }
   
         document.removeEventListener('mousemove', onMouseMove, false);
         document.removeEventListener('mouseup', onMouseUp, false);
   
         move(_e);
         draggedRef.current = false;
      };
   
      document.addEventListener('mousemove', onMouseMove, false);
      document.addEventListener('mouseup', onMouseUp, false);
   };
   
   return (
      <div
         ref={divRef}
         className={className}
         style={style}
         onMouseDown={onMouseDown}
      >
         {children}
      </div>
   );
}

export default function ColorPicker({ color, onChange, onClose, hideClose, presets }) {
   const [selfColor, setSelfColor] = useState(ColorUtils.transformColor('hex', color));
   const [inputColor, setInputColor] = useState(color);
   const innerDivRef = useRef(null);

   const showClose = hideClose ? false : true;

   const saturationPosition = useMemo(() => ({
      x: (selfColor.hsv.s / 100) * WIDTH,
      y: ((100 - selfColor.hsv.v) / 100) * HEIGHT,
   }), [selfColor.hsv.s, selfColor.hsv.v]);

   const huePosition = useMemo(() => ({
      x: (selfColor.hsv.h / 360) * WIDTH,
   }), [selfColor.hsv]);

   const onSetHex = (hex) => {
      setInputColor(hex);
      if (/^#[0-9A-Fa-f]{6}$/i.test(hex)) {
         const newColor = ColorUtils.transformColor('hex', hex);
         setSelfColor(newColor);
      }
   };

   const onMoveSaturation = ({x, y}) => {
      const newHsv = {
         ...selfColor.hsv,
         s: (x / WIDTH) * 100,
         v: 100 - (y / HEIGHT) * 100,
      };
      const newColor = ColorUtils.transformColor('hsv', newHsv);
      setSelfColor(newColor);
      setInputColor(newColor.hex);
   };

   const onMoveHue = ({x}) => {
      const newHsv = {...selfColor.hsv, h: (x / WIDTH) * 360};
      const newColor = ColorUtils.transformColor('hsv', newHsv);
   
      setSelfColor(newColor);
      setInputColor(newColor.hex);
   };

   useEffect(() => {
      // Check if the dropdown is actually active
      if (innerDivRef.current !== null && onChange) {
         onChange(selfColor.hex, skipAddingToHistoryStack);
         setInputColor(selfColor.hex);
         skipAddingToHistoryStack = true;
      }
   }, [selfColor, onChange]);
   
   useEffect(() => {
      if (color === undefined) {
         return;
      }
      const newColor = ColorUtils.transformColor('hex', color);
      setSelfColor(newColor);
      setInputColor(newColor.hex);
   }, [color]);
   
   return (
      <div 
         className="color-picker-wrapper"
         style={{ width: WIDTH }}
         ref={innerDivRef}
      >
         <div className="relative w-full">
               <TextInput label="Hex" onChange={onSetHex} value={inputColor} className="mb-4"/>
               {showClose && (
                  <XMarkIcon className="w-6 h-6 absolute right-0 -top-1" onClick={onClose}/>
               )}
         </div>
         <div className="color-picker-basic-color">
         {presets.map((preset) => (
               <button
                  className={preset === selfColor.hex ? ' ring-offset-2 ring' : ''}
                  key={preset}
                  style={{backgroundColor: preset}}
                  onClick={() => {
                     setInputColor(preset);
                     setSelfColor(ColorUtils.transformColor('hex', preset));
                     skipAddingToHistoryStack = false;
                  }}
               />
         ))}
         </div>
         <MoveWrapper
               className="color-picker-saturation"
               style={{ backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)` }}
               onChange={onMoveSaturation}
         >
               <div
                  className="color-picker-saturation_cursor"
                  style={{
                     backgroundColor: selfColor.hex,
                     left: saturationPosition.x,
                     top: saturationPosition.y
                  }}
               />
         </MoveWrapper>
         <MoveWrapper className="color-picker-hue" onChange={onMoveHue}>
            <div
               className="color-picker-hue_cursor"
               style={{
                  backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)`,
                  left: huePosition.x
               }}
            />
         </MoveWrapper>
         <div
            className="color-picker-color"
            style={{backgroundColor: selfColor.hex}}
         />
      </div>
   );
}