import {lookupIcon} from 'heroicons-lookup';

import UiUtils from '../utils/UiUtils';

export default function Button(props) {
    const { 
        children, 
        size, 
        variant, 
        label, 
        leftIcon,
        leftIconSVG, 
        leftIconFill,
        rightIcon, 
        rightIconSVG, 
        rightIconFill,
        iconType, 
        className, 
        disabled, 
        onClick, 
        ...otherProps } = props;
    let buttonClass = 'flex justify-center items-center gap-1.5 font-vg-medium border rounded-lg leading-120';
    switch (size) {
        case 'small':
            buttonClass = `h-10 px-4 ${buttonClass}`;
            break;
        case 'large':
            buttonClass = `h-[52px] px-6 ${buttonClass}`;
            break;
        case 'x-small':
            buttonClass = `h-8 px-2 ${buttonClass}`;
            break;
        default:
            break;
    }
    let useVariant = disabled ? "disabled" : variant; 
    switch (useVariant) {
        case 'solid':
            buttonClass = `${buttonClass} text-base text-white bg-blue border-blue hover:bg-blue02 hover:border-blue02 active:bg-blue01 active:border-blue01`;
            break;
        case 'outline':
            buttonClass = `${buttonClass} text-base bg-white text-blue border-blue hover:text-blue02 hover:border-blue02 active:text-blue01 active:border-blue01`;
            break;
        case 'ghost':
            buttonClass = `${buttonClass} text-base bg-transparent border-white text-blue hover:text-blue02 active:text-blue01`;
            break;
        case 'indicator-ghost':
            buttonClass = `${buttonClass} text-sm bg-transparent border-white text-blue hover:text-blue02 active:text-blue01`;
            break;
        case 'navlink':
            buttonClass = `${buttonClass} text-base bg-white border-white text-text-grey hover:text-grey active:text-grey`;
            break;
        case 'delete':
            buttonClass = `${buttonClass} text-base bg-white text-red border-red hover:text-red hover:border-red active:text-red active:border-red`;
            break;
        case 'disabled':
            buttonClass = `${buttonClass} text-base bg-white text-grey01 border-grey01`;
            break;
        default:
            break;
    }
    if (className) {
        buttonClass = `${buttonClass} ${className}`;
    }

    let IconLeft = null;
    let IconRight = null;

    const iconLibrary = iconType ? iconType : "outline";

    if (leftIcon) {
        IconLeft = lookupIcon(leftIcon, iconLibrary);
    }

    if (rightIcon) {
        IconRight = lookupIcon(rightIcon, iconLibrary);
    }

    return (
        <button
            className={buttonClass}
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                }
            }}
            disabled={useVariant === "disabled"}
            { ...otherProps } 
        >
            {IconLeft && (<IconLeft className="h-5"/>)}
            {leftIconSVG && leftIconFill && (
                UiUtils.getSVGIcon(leftIconSVG, leftIconFill, "h-5")
            )}
            {label}
            {IconRight && (<IconRight className="h-5"/>)}
            {rightIconSVG && leftIconFill && (
                UiUtils.getSVGIcon(rightIconSVG, rightIconFill, "h-5")
            )}
        </button>
    );
}