import { TrashIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

import DashboardThumbGrid from './DashboardThumbGrid';

export default function DashboardPagesListCard({ index, page, selected, onClick, onDeleteClick }) {

    const handleClick = () => {
        if (onClick) {
            onClick(index);
        }
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        if (onDeleteClick) {
            onDeleteClick(index);
        }
    }

    return (
        <div 
            className={`w-[118px] h-[72px] relative rounded bg-white pt-2.5 cursor-pointer ${selected ? "border-2 border-blue" : "border border-grey01"}`}
            onClick={handleClick}
        >
            <div className="absolute inset-0 flex items-center justify-center">
                {(page.elements && page.elements.length > 0) ? (
                    <DashboardThumbGrid 
                        size="small"
                        elements={page.elements}
                    />
                ) : (
                   <QuestionMarkCircleIcon className="w-4 h-4 text-grey"/>
                )}
            </div>
            <div className="absolute flex items-center justify-center left-1 top-1 px-1 rounded bg-grey h-5 min-w-[20px]">
                <span className="font-vg-regular text-sm text-white">{index + 1}</span>
            </div>
            <button 
                className="absolute right-1 top-1"
                onClick={handleDeleteClick}
            >
                <TrashIcon className="w-5 h-5 text-grey"/>
            </button>
        </div>
    );
}