import dayjs from 'dayjs';

import * as DataConstants from '../constants/DataConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';
import CalcUtils from './CalcUtils';

var weekOfYear = require('dayjs/plugin/weekOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var customParseFormat = require('dayjs/plugin/customParseFormat');
var utc = require('dayjs/plugin/utc');

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const NON_DATE_COLUMN_COUNT = 9;
const TITLE_COLUMN_WIDTH = 500;
const DATE_COLUMN_WIDTH = 140;
const EDIT_COLUMN_ALL = "All";
const NULL_COLUMN = "NULL";

let DataUtils = {};

const getUtcDate = (value) => {
   let utcDate = null;
   if (typeof value === "number") {
      utcDate = dayjs.utc(value)
      .hour(0).minute(0).second(0).millisecond(0);

   } else if ((Object.prototype.toString.call(value) === "[object Date]")) {
      utcDate = dayjs.utc().year(value.getFullYear()).month(value.getMonth()).date(value.getDate())
      .hour(0).minute(0).second(0).millisecond(0);

   } else if (typeof value === "string") {
      utcDate = dayjs.utc(value)
      .hour(0).minute(0).second(0).millisecond(0);

   } else {
      utcDate = dayjs.utc().year(value.year()).month(value.month()).date(value.date())
      .hour(0).minute(0).second(0).millisecond(0);    
   }
   return utcDate;
}

const getUtcDateFromYMD = (year, month, day) => {
   let utcDate = dayjs.utc().year(year).month(month).date(day)
   .hour(0).minute(0).second(0).millisecond(0); 
   return utcDate;
}

const getLocalDate = (value) => {
   let localDate = null
   if (typeof value === "number") {
      /*
      localDate = dayjs(value)
      .hour(0).minute(0).second(0).millisecond(0);
      */
      localDate = new Date(value);

   } else if ((Object.prototype.toString.call(value) === '[object Date]')) {
      /*
      localDate = dayjs().year(value.getFullYear()).month(value.getMonth()).date(value.getDate())
      .hour(0).minute(0).second(0).millisecond(0);
      */
      localDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0);

   } else if (typeof value === "string") {
      /*
      localDate = dayjs(value)
      .hour(0).minute(0).second(0).millisecond(0);
      */
      localDate = new Date(value);

   } else {
      /*
      localDate = dayjs().year(value.year()).month(value.month()).date(value.date())
      .hour(0).minute(0).second(0).millisecond(0); 
      */  
      localDate = value ? new Date(value.year(), value.month(), value.date(), 0, 0, 0, 0) : null; 
   }
   return localDate;
}

const getLocalDateFromYMD = (year, month, day) => {
   let localDate = dayjs().year(year).month(month).date(day)
   .hour(0).minute(0).second(0).millisecond(0); 
   return localDate;
}

const getTypeSubIndexOrdinal = (type, subIndex) => {
   switch (type) {
      case IndicatorConstants.IndicatorType.INPUT:
         return 100 + subIndex;              
      case IndicatorConstants.IndicatorType.OUTPUT:
         return 200 + subIndex;              
      case IndicatorConstants.IndicatorType.OUTCOME:
         return 300 + subIndex;               
      default:
         return subIndex;
   }
}

const getFinYearKey = (dateValue) => {
   let finyear = dateValue.year();
   if (dateValue.month() > 5) {
      finyear++;
   }
   return finyear;
}

const getTimeKey = (date, frequency) => {
   //const dateValue = dayjs(date);
   const dateValue = getUtcDate(date);
   const dateString = `date${dateValue.year()}_${String(dateValue.month() + 1).padStart(2, "0")}_${String(dateValue.date()).padStart(2, "0")}`;
   let timekey = "";
   switch (frequency) {
      case DataConstants.FrequencyType.WEEKLY:
         timekey =`${dateString}$week_${dateValue.week()}_${dateValue.year()}`;
         break;
      case DataConstants.FrequencyType.MONTHLY:
         timekey = `${dateString}$month_${dateValue.month()}_${dateValue.year()}`;
         break;
      case DataConstants.FrequencyType.QUARTERLY:
         timekey = `${dateString}$quarter_${dateValue.quarter()}_${dateValue.year()}`;
         break;
      case DataConstants.FrequencyType.FINANCIAL_YEAR:
         timekey = `${dateString}$finyear_${getFinYearKey(dateValue)}`;
         break;
      case DataConstants.FrequencyType.ANNUALLY:
         timekey = `${dateString}$year_${dateValue.year()}`;
         break;
      default:
         timekey = `${dateString}$${dateString}`;
         break;
   }
   return timekey
}

const getTimeKeyLabel = (timekey) => {
   const keyString = timekey.split("$");
   const labelParts = keyString[1].split("_");
   switch (labelParts[0]) {
      case "week":
         const dateString = keyString[0].replace("date","").replaceAll("_", "-");
         //const date = dayjs(dateString, "YYYY-MM-DD");
         const date = dayjs.utc(dateString, "YYYY-MM-DD");
         const startDate = date.startOf("week").format("D MMM");
         const endDate = date.endOf("week").format("D MMM");
         return `Week ${labelParts[1]}, ${labelParts[2]}<br/>(${startDate} - ${endDate})`;
      case "month":
         return `${DataConstants.MonthName[parseInt(labelParts[1])]} ${labelParts[2]}`;
      case "quarter":
         return `Quarter ${labelParts[1]}, ${labelParts[2]}`;
      case "finyear":
         const year2 = parseInt(labelParts[1]);
         return `Fin Year ${year2 - 1}-${year2}`;
      case "year":
         return labelParts[1];
      default:
         const dateParts = keyString[0].split("_");
         return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
   }
}

const getRequiredDataInfo = (entity, key) => {
   let info = null;
   const rdKeyTitles = entity.requiredData.map(rd => ({ key: rd.key, title: rd.title }));
   const index = entity.requiredData.map(rd => rd.key).indexOf(key);
   if (index > -1) {
      info = entity.requiredData[index];
      if (info.requiredDataType === DataConstants.RequiredDataType.CALCULATED) {
         let formulaText = info.formula ? info.formula : "";
         rdKeyTitles.forEach(rdk => {
               formulaText = formulaText.replace("{" + rdk.key + "}", " " + rdk.title + " ");
         });
         info.formulaText = formulaText.trim();
      } else {
         info.formulaText = "";
      }
   }
   return info;
}

const getNewColumnDefs = (dateCols) => {
   let columnDefs = [
      { 
         field: "rowType", 
         hide: true 
      },
      { 
         field: "key", 
         hide: true 
      },
      { 
         field: "indicatorKey", 
         hide: true 
      },
      { 
         field: "dataType", 
         hide: true 
      },
      { 
         field: "format", 
         hide: true 
      },
      { 
         field: "formula", 
         hide: true 
      },
      { 
         field: "formulaText", 
         hide: true 
      },
      {
         field: "editColumn",
         hide: true,
      },
      { 
         field: "title", 
         headerName: "Indicator / Required Data", 
         width: TITLE_COLUMN_WIDTH,
         editable: false,
         resizable: true,
         wrapText: true,
         autoHeight: true,
         autoHeaderHeight: true,
         wrapHeaderText: true,
         pinned: "left",
         cellClass: (params) => {
            return (params.data && params.data.rowType && params.data.rowType === "Indicator") || (params.data && params.data.dataType && params.data.dataType === "Baseline") || (params.data && params.data.dataType && params.data.dataType === "Target") ? "font-vg-regular text-black" : "font-vg-book text-grey";
         },
         cellStyle: (params) => {
            if (params.data && params.data.dataType && (params.data.dataType === "Manual" || params.data.dataType === "Calculated")) {
               return { paddingLeft: "80px" };
            } else {
               return null;
            }
         }
      }
   ];
   dateCols.forEach(dc => {
      let coldef = { 
         field: dc, 
         headerName: getTimeKeyLabel(dc),
         width: DATE_COLUMN_WIDTH,
         resizable: true,
         autoHeaderHeight: true,
         wrapHeaderText: true,
         editable: (params) => {
            const editableRow = (params.data && params.data.dataType && (params.data.dataType !== "Calculated" && params.data.dataType !== ""));
            const editableColumn = (params.data && params.data.editColumn && params.colDef && params.colDef.field && (params.data.editColumn === "All" || params.data.editColumn === params.colDef.field));
            return (editableRow && editableColumn);
         },
         cellClass: (params) => {
            return (params.data && params.data.rowType && params.data.rowType === "Indicator") || (params.data && params.data.dataType && params.data.dataType === "Baseline") || (params.data && params.data.dataType && params.data.dataType === "Target") ? "font-vg-regular text-black" : "font-vg-book text-grey";
         },
         cellStyle: (params) => {
            const editableRow = (params.data && params.data.dataType && (params.data.dataType !== "Calculated" && params.data.dataType !== ""));
            const editableColumn = (params.data && params.data.editColumn && params.colDef && params.colDef.field && (params.data.editColumn === "All" || params.data.editColumn === params.colDef.field));
            if (editableRow && editableColumn) {
               return { border: "1px solid rgb(186 191 199)", borderRadius: "6px" };
            } else {
               return null;
            }
         },
         valueFormatter: (params) => {
            if (params.data && params.data.format) {
               switch (params.data.format) {
                  case "%":
                     return params.value === null ? null : Number(params.value).toLocaleString(undefined,{style: 'percent', maximumFractionDigits:2}); 
                  case "$":
                     const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'AUD'});
                     return params.value === null ? null : formatter.format(params.value); 
                  default:
                     return params.value;
               }
            } else {
               return params.value;
            }
         } 
      };
      columnDefs.push(coldef);
   });
   return columnDefs;
}

const updateExistingColDefs = (colDefs) => {
   let newColDefs = colDefs;
   for (let i = 0; i < newColDefs.length; i++) {
      // Set cellClass / cellStyle / editable / valueFormatter functions for each columnDef
      const field = newColDefs[i].field;
      if (field === "title") {
         newColDefs[i].cellClass = (params) => {
            return (params.data && params.data.rowType && params.data.rowType === "Indicator") || (params.data && params.data.dataType && params.data.dataType === "Baseline") || (params.data && params.data.dataType && params.data.dataType === "Target") ? "font-vg-regular text-black" : "font-vg-book text-grey";
         };
         newColDefs[i].cellStyle = (params) => {
            if (params.data && params.data.dataType && (params.data.dataType === "Manual" || params.data.dataType === "Calculated")) {
               return { paddingLeft: "80px" };
            } else {
               return null;
            }
         };
      } else if (field.startsWith("date")) {
         newColDefs[i].editable = (params) => {
            const editableRow = (params.data && params.data.dataType && (params.data.dataType !== "Calculated" && params.data.dataType !== ""));
            const editableColumn = (params.data && params.data.editColumn && params.colDef && params.colDef.field && (params.data.editColumn === "All" || params.data.editColumn === params.colDef.field));
            return (editableRow && editableColumn);
         };
         newColDefs[i].cellClass = (params) => {
            return (params.data && params.data.rowType && params.data.rowType === "Indicator") || (params.data && params.data.dataType && params.data.dataType === "Baseline") || (params.data && params.data.dataType && params.data.dataType === "Target") ? "font-vg-regular text-black" : "font-vg-book text-grey";
         };
         newColDefs[i].cellStyle = (params) => {
            const editableRow = (params.data && params.data.dataType && (params.data.dataType !== "Calculated" && params.data.dataType !== ""));
            const editableColumn = (params.data && params.data.editColumn && params.colDef && params.colDef.field && (params.data.editColumn === "All" || params.data.editColumn === params.colDef.field));
            if (editableRow && editableColumn) {
               return { border: "1px solid rgb(186 191 199)", borderRadius: "6px" };
            } else {
               return null;
            }
         };
         newColDefs[i].valueFormatter = (params) => {
            if (params.data && params.data.format) {
               switch (params.data.format) {
                  case "%":
                     return params.value === null ? null : Number(params.value).toLocaleString(undefined,{style: 'percent', maximumFractionDigits:2}); 
                  case "$":
                     const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'AUD'});
                     return params.value === null ? null : formatter.format(params.value); 
                  default:
                     return params.value;
               }
            } else {
               return params.value;
            }
         };
      }
   }
   return newColDefs;
}

const gridColsDataForIndicators = (entity, data, colDefs, indicatorSet, frequency, start, end, options) => {

   let editAll = options.editAll ? options.editAll : false;
   let userCanEdit = options.userCanEdit ? options.userCanEdit : false;
   let forceNewDates = options.forceNewDates ? options.forceNewDates : false;

   // 1. Sort indicators
   let indicators = JSON.parse(JSON.stringify(indicatorSet));
   indicators.sort((a,b) => {
      const ordA = getTypeSubIndexOrdinal(a.indicatorType, a.subIndex);
      const ordB = getTypeSubIndexOrdinal(b.indicatorType, b.subIndex);
      if (ordA < ordB) {
         return -1
      } else if (ordB < ordA) {
         return 1;
      } else {
         return 0;
      }
   });

   // 2. Get Required Data Keys
   let dataKeys = [];
   indicators.forEach(ind => {
      ind.requiredData.forEach(rd => {
         if (dataKeys.indexOf(rd) === -1) {
               dataKeys.push(rd);
         }
      });
   });

   // 3. Filter Data By Data Keys, Frequency & Dates
   let filteredData = data.filter(d => dataKeys.indexOf(d.key) > -1 && d.interval === frequency);
   if (start !== null && start !== "") {
      //filteredData = filteredData.filter(d => dayjs(d.date).valueOf() >= dayjs(start).valueOf());
      filteredData = filteredData.filter(d => dayjs.utc(d.utcDate).valueOf() >= dayjs.utc(start).valueOf());
   }
   if (end !== null && end !== "") {
      //filteredData = filteredData.filter(d => dayjs(d.date).valueOf() <= dayjs(end).valueOf());
      filteredData = filteredData.filter(d => dayjs.utc(d.utcDate).valueOf() <= dayjs.utc(end).valueOf());
   }

   // 4. Get Date Cols & Column Defs
   let columnDefs = colDefs;
   let dateCols = columnDefs.length > 0 ? columnDefs.filter(cd => cd.field.startsWith("date")).map(dc => dc.field) : [];
   let colDefsUpdated;
   if (dateCols.length === 0 || forceNewDates) {
      filteredData.forEach(fd => {
         const dcol = getTimeKey(fd.utcDate, frequency);
         if (dateCols.indexOf(dcol) === -1) {
            dateCols.push(dcol);
         }
      });
      dateCols.sort();
      dateCols.reverse();
      columnDefs = getNewColumnDefs(dateCols);
      colDefsUpdated = true;
   } else {
      columnDefs = updateExistingColDefs(columnDefs);
      colDefsUpdated = false;
   }

   // 5. Create Required Data Rows
   const editCol = userCanEdit ? (editAll || dateCols.length === 0 ? EDIT_COLUMN_ALL : dateCols[0]) : NULL_COLUMN;
   let reqDataRows = []
   dataKeys.forEach(dkey => {
      const info = getRequiredDataInfo(entity, dkey);
      if (info) {
         let row = {
            rowType: DataConstants.DataRowType.REQUIRED_DATA,
            key: dkey,
            indicatorKey: "",
            dataType: info.requiredDataType,
            format: info.format,
            formula: info.formula ? info.formula : "",
            formulaText: info.formulaText,
            editColumn: editCol,
            title: info.title
         };
         const dataSet = filteredData.filter(d => d.key === dkey);
         dateCols.forEach(dc => {
            if (info.requiredDataType === DataConstants.RequiredDataType.MANUAL) {
               const dcindex = dataSet.map(d => getTimeKey(d.utcDate, frequency)).indexOf(dc);
               if (dcindex > -1) {
                  row[dc] = info.format === "*" ? dataSet[dcindex].value : parseFloat(dataSet[dcindex].value);
               } else {
                  row[dc] = null;
               }
            } else {
               row[dc] = null;
            }
         });
         reqDataRows.push(row);
      }
   });

   // 6. Recalculate Calculated Rows
   let calcRows = reqDataRows.filter(rqd => rqd.dataType === DataConstants.RequiredDataType.CALCULATED);

   let newCalcRows = [];
   calcRows.forEach(calcRow => {
      const varRows = CalcUtils.getVarRows(calcRow, reqDataRows);
      newCalcRows.push(CalcUtils.evaluateRow(dateCols, calcRow, varRows));
   });

   let nonCalcRows = reqDataRows.filter(rqd => rqd.dataType !== DataConstants.RequiredDataType.CALCULATED);
   reqDataRows = [...nonCalcRows, ...newCalcRows];

   // 7. Assemble Grid With Indicator Rows
   let finalDataRows = [];
   indicators.forEach(ind => {
      let indReqRows = reqDataRows.filter(rqd => ind.requiredData.indexOf(rqd.key) > -1);
      const indDataIndex =  indReqRows.map(irr => irr.key).indexOf(ind.indicatorDataKey);
      let indDataRow = indDataIndex > -1 ? indReqRows[indDataIndex] : null;
      let indRow = {
         rowType: DataConstants.DataRowType.INDICATOR,
         key: ind.indicatorDataKey,
         indicatorKey: ind.key,
         dataType: DataConstants.RequiredDataType.NONE,
         format: indDataRow ? indDataRow.format : "",
         formula: "",
         formulaText: "",
         editColumn: editCol,
         title: ind.title
      };
      dateCols.forEach(dc => {
         if (indDataRow) {
            indRow[dc] = isNaN(indDataRow[dc]) || indDataRow[dc] === null ? null : parseFloat(indDataRow[dc]);
         } else {
            indRow[dc] = null;
         }
      });
      finalDataRows.push(indRow);
      let indManualRows = indReqRows.filter(rqd => rqd.dataType === DataConstants.RequiredDataType.MANUAL);
      let indCalcRows = indReqRows.filter(rqd => rqd.dataType === DataConstants.RequiredDataType.CALCULATED);
      //let baselineRows = indReqRows.filter(rqd => rqd.dataType === DataConstants.RequiredDataType.BASELINE);
      //let targetRows = indReqRows.filter(rqd => rqd.dataType === DataConstants.RequiredDataType.TARGET);

      //finalDataRows = finalDataRows.concat(baselineRows);
      //finalDataRows = finalDataRows.concat(targetRows);
      finalDataRows = finalDataRows.concat(indManualRows);
      finalDataRows = finalDataRows.concat(indCalcRows);
   });

   return {
      rowData: finalDataRows,
      columnDefs: columnDefs,
      colDefsUpdated: colDefsUpdated
   };
}

const addPeriodDataColumn = (gridData, newDate, frequency, editAll) => {
   const timeKey = getTimeKey(newDate, frequency);
   const nonDateColDefs = gridData.columnDefs.filter((_, index) => index < NON_DATE_COLUMN_COUNT);
   const dateColDefs = gridData.columnDefs.filter((_, index) => index >= NON_DATE_COLUMN_COUNT);

   if (dateColDefs.map(cd => cd.field.split("$")[1]).indexOf(timeKey.split("$")[1]) === -1) {
      const newColDef = {
         field: timeKey,
         headerName: getTimeKeyLabel(timeKey),
         width: DATE_COLUMN_WIDTH,
         resizable: true, 
         editable: (params) => {
            const editableRow = (params.data && params.data.dataType && (params.data.dataType !== "Calculated" && params.data.dataType !== ""));
            const editableColumn = (params.data && params.data.editColumn && params.colDef && params.colDef.field && (params.data.editColumn === "All" || params.data.editColumn === params.colDef.field));
            return (editableRow && editableColumn);
         },
         cellClass: params => {
            return (params.data && params.data.rowType && params.data.rowType === "Indicator") || (params.data && params.data.dataType && params.data.dataType === "Baseline") || (params.data && params.data.dataType && params.data.dataType === "Target") ? "font-vg-regular text-black" : "font-vg-book text-grey";
         },
         cellStyle: params => {
            const editableRow = (params.data && params.data.dataType && (params.data.dataType !== "Calculated" && params.data.dataType !== ""));
            const editableColumn = (params.data && params.data.editColumn && params.colDef && params.colDef.field && (params.data.editColumn === "All" || params.data.editColumn === params.colDef.field));
            if (editableRow && editableColumn) {
               return { border: "1px solid rgb(186 191 199)", borderRadius: "6px" };
            } else {
               return null;
            }
         }, 
         valueFormatter: params => {
            if (params.data && params.data.format) {
               switch (params.data.format) {
                  case "%":
                     return params.value === null ? null : Number(params.value).toLocaleString(undefined,{style: 'percent', maximumFractionDigits:2}); 
                  case "$":
                     const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'AUD'});
                     return params.value === null ? null : formatter.format(params.value); 
                  default:
                     return params.value;
               }
            } else {
               return params.value;
            }
         } 
      };
      const columnDefs = [...nonDateColDefs, newColDef, ...dateColDefs];

      const editCol = editAll ? EDIT_COLUMN_ALL : timeKey;
      let rowData = JSON.parse(JSON.stringify(gridData.rowData));
      for (let i = 0; i < rowData.length; i++) {
         rowData[i][timeKey] = null;
         rowData[i].editColumn = editCol;
      }
      return {
         rowData: rowData,
         columnDefs: columnDefs
      };
   } else {
      return gridData;
   }
}

const updateRowData = (rowData, rowIndex, field, newValue) => {
   // 1: Update all cells with this key
   let newRowData = JSON.parse(JSON.stringify(rowData));
   const currentKey = newRowData[rowIndex].key
   for (let r1 = 0; r1 < newRowData.length; r1++) {
      if (newRowData[r1].key === currentKey) {
         newRowData[r1][field] = newValue;
      }
   }
   // 2. Find & Update effected Calculations
   for (let r2 = 0; r2 < newRowData.length; r2++) {
      if (newRowData[r2].dataType === DataConstants.RequiredDataType.CALCULATED) {
         let calcVariables = CalcUtils.extractVariables(newRowData[r2].formula);
         if (calcVariables.indexOf(currentKey) > -1) {
            let varValueMap = {};
            let allFound = true;
            calcVariables.forEach(cv => {
               const value = CalcUtils.getValueFromRowData(cv, field, newRowData);
               varValueMap[cv] = value;
               if (value === null) {
                  allFound = false;
               }
            });
            if (allFound) {
               const calcKey = newRowData[r2].key;
               const calcValue = CalcUtils.evaluateExpression(newRowData[r2].formula, varValueMap);
               for (let r3 = 0; r3 < newRowData.length; r3++) {
                  if (newRowData[r3].key === calcKey) {
                     newRowData[r3][field] = calcValue;
                  }
               }
            }
         }
      }
   }
   return newRowData;
}

const getIntervalDateFromKey = (timekey) => {
   const keySplit = timekey.split("$");
   const dateParts = keySplit[0].replace("date","").split("_");
   const year = parseInt(dateParts[0]);
   const month = parseInt(dateParts[1]) - 1;
   const day = parseInt(dateParts[2]);
   //const date = new Date(year, month, day);
   const date = getUtcDateFromYMD(year, month, day);
   const intervalSplit = keySplit[1].split("_");
   let interval = "";
   switch (intervalSplit[0]) {
      case "week":
         interval = DataConstants.FrequencyType.WEEKLY;
         break;
      case "month":
         interval = DataConstants.FrequencyType.MONTHLY;
         break;
      case "quarter":
         interval = DataConstants.FrequencyType.QUARTERLY;
         break;
      case "finyear":
         interval = DataConstants.FrequencyType.FINANCIAL_YEAR;
         break;
      case "year":
         interval = DataConstants.FrequencyType.ANNUALLY;
         break;
      default:
         break;
   }
   return {
      date: date,
      interval: interval
   }
}

const prepareColumnDataForSave = (rowData, colKey) => {
   const dateInterval = getIntervalDateFromKey(colKey);
   let preparedData = [];
   rowData.forEach(row => {
      if (row.hasOwnProperty(colKey)) {
         preparedData.push({
            utcDate: dateInterval.date,
            interval: dateInterval.interval,
            key: row.key,
            value: row[colKey]
         });
      }
   });
   return preparedData
}

const updateEntityData = (entityData, dataToAdd) => {
   let newEntityData = JSON.parse(JSON.stringify(entityData));

   // Deduplicate newData
   let newData = [];
   dataToAdd.forEach(d => {
      if (newData.filter(nd => ((d.key === nd.key) && (d.interval === nd.interval) && (dayjs.utc(nd.utcDate).valueOf() === dayjs.utc(d.utcDate).valueOf()))).length === 0) {
         newData.push(d);
      }
   });

   newData.forEach(nd => {
      const dataToRemove = newEntityData.filter(d => ((d.key === nd.key) && (d.interval === nd.interval) && (dayjs.utc(nd.utcDate).valueOf() === dayjs.utc(d.utcDate).valueOf())));
      newEntityData = newEntityData.filter(d => (dataToRemove.map(dr => dr._id).indexOf(d._id) === -1));
   });
   newData.forEach(nd => {
      if (newEntityData.filter(d => ((d.key === nd.key) && (d.interval === nd.interval) && (dayjs.utc(nd.utcDate).valueOf() === dayjs.utc(d.utcDate).valueOf())).length === 0)) {
         if (nd.value !== null) {
            newEntityData.push(nd);
         }
      }
   });
   return newEntityData;
}

/* Excel Import Functions */

const getRequiredDataType = (entity, key) => {
   const index = entity.requiredData.map(rd => rd.key).indexOf(key);
   if (index > -1) {
      return entity.requiredData[index].requiredDataType
   } else {
      return DataConstants.RequiredDataType.NONE;
   }
}

const getFormula = (entity, key) => {
   const index = entity.requiredData.map(rd => rd.key).indexOf(key);
   if (index > -1) {
      return entity.requiredData[index].formula ? entity.requiredData[index].formula : "";
   } else {
      return "";
   }
}

const getRecalcValue = (data, key, interval, utcDate) => {
   const items = data.filter(d => d.key === key && d.interval === interval && getUtcDate(d.utcDate).isSame(getUtcDate(utcDate)));
   if (items.length > 0) {
      return items[0].value;
   } else {
      return null;
   }
}

const postImportRecalc = (entity, data) => {
   let newData = [];
   data.forEach(d => {
      if (getRequiredDataType(entity, d.key) === DataConstants.RequiredDataType.CALCULATED) {
         let formula = getFormula(entity, d.key);
         let vars = CalcUtils.extractVariables(formula);
         let varValueMap = {};
         let abort = false;
         vars.forEach(variable => {
            const value = getRecalcValue(data, variable, d.interval, d.utcDate);
            if (value === null) {
               abort = true;
            } else {
               varValueMap[variable] = value;
            }
         });
         if (!abort) {
            d.value = CalcUtils.evaluateExpression(formula, varValueMap);
         }
      }
      newData.push(d);
   });
   return newData;
}

DataUtils.getUtcDate = getUtcDate;
DataUtils.getUtcDateFromYMD = getUtcDateFromYMD;
DataUtils.getLocalDate = getLocalDate;
DataUtils.getLocalDateFromYMD = getLocalDateFromYMD;
DataUtils.gridColsDataForIndicators = gridColsDataForIndicators;
DataUtils.addPeriodDataColumn = addPeriodDataColumn;
DataUtils.updateRowData = updateRowData;
DataUtils.prepareColumnDataForSave = prepareColumnDataForSave;
DataUtils.updateEntityData = updateEntityData;
DataUtils.postImportRecalc = postImportRecalc;

export default DataUtils;
