import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

import Tag from './Tag';

export default function HomeResourceBlock(props) {
    const { type, tags, title, text, link, image, imageAlt, ...otherProps } = props;

    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);

    let imageClass = "w-full rounded-xl object-cover";
    imageClass = isMobile ? `h-[211px] ${imageClass}` : `h-[332px] ${imageClass}`;

    return(
        <div className="flex flex-col items-stretch">
            {image && (
                <a href={link}>
                    <img src={image} className={imageClass} alt={imageAlt}/>
                </a>
            )}
            <div 
                className="flex flex-col items-stretch justify-between h-full w-full p-6 grow"
                { ...otherProps }
            >
                <div className="flex flex-col items-start gap-4 grow">
                    <div className="flex gap-4">
                        {tags.map((tag, index) => (
                            <Tag key={`tag-${index}`} text={tag.text} color={tag.color}/>
                        ))}
                    </div>
                    <a href={link} className="no-underline">
                        <h5 className="font-vg-medium text-2xl leading-120 text-black">{title}</h5>
                    </a>
                    <p className="font-vg-book text-base leading-130 text-black line-clamp-2">{text}</p>
                </div>
                <div>
                    {type === "Video" ? (
                        <a href={link} className="font-vg-regular text-blue text-sm">Watch now</a>
                    ) : (
                        <a href={link} className="font-vg-regular text-blue text-sm">Read more</a>
                    )}
                </div>
            </div>
        </div>
    );
}