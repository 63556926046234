import { useState, useContext, useEffect, useId, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';
import { cloneDeep } from 'lodash';
import Tippy from '@tippyjs/react';

import * as DashboardConstants from "../constants/DashboardConstants";
import * as UiConstants from "../constants/UiConstants";
import DashboardService from '../services/DashboardService';
import { GlobalContext } from '../context/GlobalContext';

import DashboardMoreMenu from "./DashboardMoreMenu";

export default function DashboardCanvasTableCard({ id, row, col, width, height, details, mode, version, onCanvasElementAction }) {
    const {context} = useContext(GlobalContext);

    const NON_VALUE_COLUMNS = ["format", "title", "outcomeArea"];

    const newId = useId();
    const cardId = id ? id : newId;

    const gridRef = useRef(null);

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const [hasArchived, setHasArchived] = useState(false);
    const [hasArchivedMessage, setHasArchivedMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState("");

    const [localVersion, setLocalVersion] = useState("0");

    const [allExportTrigger, setAllExportTrigger] = useState(false);
    const [loadSuccess, setLoadSuccess] = useState(false);

    let rowColClass = "";

    let rowStart = Math.min(row, DashboardConstants.CANVAS_ROWS);
    let rowEnd = Math.min(rowStart + height, DashboardConstants.CANVAS_ROWS + 1);
    let colStart = Math.min(col, DashboardConstants.CANVAS_COLS);
    let colEnd = Math.min(colStart + width, DashboardConstants.CANVAS_COLS + 1);

    switch (rowStart) {
        case 1:
            rowColClass = "row-start-1";
            break;
        case 2:
            rowColClass = "row-start-2";
            break;
        case 3:
            rowColClass = "row-start-3";
            break;
        default:
            break;
    }

    switch (colStart) {
        case 1:
            rowColClass = `${rowColClass} col-start-1`;
            break;
        case 2:
            rowColClass = `${rowColClass} col-start-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-start-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-start-4`;
            break;
        default:
            break;
    }

    switch (rowEnd) {
        case 2:
            rowColClass = `${rowColClass} row-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} row-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} row-end-4`;
            break;
        default:
            break;
    }

    switch (colEnd) {
        case 2:
            rowColClass = `${rowColClass} col-end-2`;
            break;
        case 3:
            rowColClass = `${rowColClass} col-end-3`;
            break;
        case 4:
            rowColClass = `${rowColClass} col-end-4`;
            break;
        case 5:
            rowColClass = `${rowColClass} col-end-5`;
            break;
        default:
            break;
    }

    let outerClass = `flex h-full flex-col items-stretch gap-6 p-6 overflow-hidden ${rowColClass}`;
    outerClass = `${outerClass} ${mode === DashboardConstants.CanvasMode.EDIT ? "rounded-lg bg-grey04 border border-grey03" : "bg-white"}`;

    const addValueFormatters = (colDefs) => {
        let modColDefs = cloneDeep(colDefs);
        for (let i = 0; i < modColDefs.length; i++) {
            if (NON_VALUE_COLUMNS.indexOf(modColDefs[i].field) === -1) {
                let cdef = modColDefs[i];
                cdef.valueFormatter = (params) => {
                    switch (params.data.format) {
                        case "%":
                            return params.value === null ? null : Number(params.value).toLocaleString(undefined,{style: 'percent', maximumFractionDigits:2}); 
                        case "$":
                            const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'AUD'});
                            return params.value === null ? null : formatter.format(params.value); 
                        default:
                            return params.value;
                    }
                };
                modColDefs[i] = cdef; 
            }
        }
        return modColDefs;
    }

    const onMoreMenuClick = (details) => {
        if (onCanvasElementAction) {
            switch (details.action) {
                case DashboardConstants.ActionType.ELEMENT_MENU_EDIT:
                    onCanvasElementAction({
                        action: DashboardConstants.ActionType.ELEMENT_MENU_EDIT,
                        args: {
                            id: details.args.id,
                            row: row,
                            col: col,
                            width: width,
                            height: height
                        }
                    });
                    break;
                case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
                    if (window.confirm("Are you sure you want to delete this table?")) {
                        onCanvasElementAction({
                            action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                            args: {
                                id: details.args.id,
                                row: row,
                                col: col
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }

    const onCSVExportClick = () => {
        if (context.user && context.user.trialAccount === true) {
            alert("You cannot access this functionality as you are currently on a trial account.");
            return;
        }
        const fileName = `${details.boardTitle} - ${details.title}.csv`;
        gridRef.current.api.exportDataAsCsv({
            fileName: fileName
        });
    }

    useEffect(() => {
        if ((loaded === false && loading === false) || localVersion !== version) {
            setError("");
            setLoading(true);
            setLocalVersion(version);
            DashboardService.getElementTableData(details.dashboardId, details.pageId, details.id)
            .then(response => {
                setLoading(false);
                setLoaded(true);
                setRowData(response.data.rowData);
                setColumnDefs(response.data.columnDefs);

                if (response.data.hasArchived.result === null) {
                    setHasArchived(true);
                    setHasArchivedMessage(UiConstants.DASH_HAS_ARCHIVED_ERROR);
                } else {
                    if (response.data.hasArchived.result) {
                        setHasArchived(true);
                        setHasArchivedMessage(UiConstants.DASH_HAS_ARCHIVED_GRAPH);
                    } else {
                        setHasArchived(false);
                        setHasArchivedMessage("");
                    }
                }

                setLoadSuccess(true);
                if (mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
                    setAllExportTrigger(true);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setLoaded(true);
                setRowData([]);
                setColumnDefs([]);
                setError("Unable to load table data.<br/>Please try again later.");

                setLoadSuccess(false);
                if (mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
                    setAllExportTrigger(true);
                }
            })
        }
        if (allExportTrigger) {
            if (onCanvasElementAction) {
                onCanvasElementAction({
                    action: DashboardConstants.ActionType.ELEMENT_LOAD_COMPLETE,
                    args: {
                        type: "table",
                        element: details.id,
                        success: loadSuccess
                    }
                });
            }
            setAllExportTrigger(false);
        }
    }, [
        mode, version, details, onCanvasElementAction, 
        loaded, loading, localVersion, loadSuccess, allExportTrigger
    ]);

    return (
        <div key={`canvas-table-card-${cardId}`} className={outerClass}>
            <div className="flex items-center justify-between grow-0">
                <h6 className="font-vg-regular text-grey text-base leading-130 grow">{details.title}</h6>
                {mode !== DashboardConstants.CanvasMode.EXPORT && mode !== DashboardConstants.CanvasMode.EXPORT_ALL && (
                    <div className="flex items-center gap-6">
                        {loaded === true && error === "" && (
                            <button className="flex items-center gap-2 text-grey" onClick={onCSVExportClick}>
                                <ArrowUpTrayIcon className="w-5 h-5"/>
                                <p className="font-vg-regular text-xs">Export<br/>to CSV</p>
                            </button>
                        )}
                        {mode === DashboardConstants.CanvasMode.EDIT && (
                            <div className="flex items-center gap-6">
                                {hasArchived && (
                                    <Tippy
                                        content={hasArchivedMessage}
                                        className="font-vg-book text-base leading-130 py-0.5 px-4"
                                        placement="top"
                                        interactive={true}
                                        appendTo={document.body}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="w-6 h-6" fill="#CF2730">
                                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L409.9 296l75.7-75.7L355.7 90.3l-91.5 91.5L38.8 5.1zM341.2 364.8L195.8 250.3l-73.2 73.2c-10.4 10.4-18 23.3-22.2 37.4L65 481.2c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2l88.6-88.6zM426.7 19.3L378.3 67.7 508.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L517.3 19.3c-25-25-65.5-25-90.5 0z"/>
                                        </svg>
                                    </Tippy>
                                )}
                                <DashboardMoreMenu
                                    items={hasArchived ? UiConstants.DASH_MORE_DELETE_ONLY : UiConstants.DASH_MORE_MENU_ITEMS}
                                    params={{id: details.id}} 
                                    onMenuClick={onMoreMenuClick}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {loaded === true && error === "" ? (
                <div className="ag-theme-alpine compact" style={{height: '100%', width: '100%'}}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={{
                            resizable: true,
                            wrapHeaderText: true,
                            autoHeaderHeight: true
                        }}
                        rowData={rowData}
                        columnDefs={addValueFormatters(columnDefs)}
                        headerHeight={40}
                    />
                </div>
            ) : (
                <div className="flex items-center justify-center grow">
                    {error !== "" ? (
                        <h6 className="font-vg-regular text-red text-base leading-130" dangerouslySetInnerHTML={{__html: error}}/>
                    ) : (
                        <>
                            {loading === true && (
                                <h6 className="font-vg-regular text-grey text-base leading-130">Loading...</h6>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}