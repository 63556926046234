import '../styles/lexical-select.css';

export default function LexicalSelect({
  children,
  label,
  className,
  ...other
}) {
   return (
      <div className="Input__wrapper">
         <label style={{marginTop: '-1em'}} className="Input__label">
            {label}
         </label>
         <select {...other} className={className || 'select'}>
            {children}
         </select>
      </div>
   );
}
