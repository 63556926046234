import { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react';
import { read, utils } from 'xlsx';
import dayjs from 'dayjs';

import * as UiConstants from '../constants/UiConstants';
import * as EntityConstants from '../constants/EntityConstants';
import * as DataConstants from '../constants/DataConstants';

import Button from './Button';
import SelectDropdown from './SelectDropdown';

var weekOfYear = require('dayjs/plugin/weekOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var customParseFormat = require('dayjs/plugin/customParseFormat');
var utc = require('dayjs/plugin/utc');

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export default function DataExcelUploadModal({ params, open, onImport, onClose }) {

   const [organisation, setOrganisation] = useState({});

   const [selectedTypeOption, setSelectedTypeOption] = useState(UiConstants.EMPTY_OPTION);

   const [unitOptions, setUnitOptions] = useState([]);
   const [selectedUnitOption, setSelectedUnitOption] = useState(UiConstants.EMPTY_OPTION);

   const fileInputRef = useRef(null);

   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

   const getUnitOptions = (org, type) => {
      switch (type) {
         case EntityConstants.EntityType.ENTERPRISE:
            return org.enterprises.map(e => ({ value: e._id, label: e.name }));

         case EntityConstants.EntityType.PROGRAM:
            return org.programs.map(p => ({ value: p._id, label: p.name }));

         case EntityConstants.EntityType.PROJECT:
            return org.projects.map(p => ({ value: p._id, label: p.name }));

         default:
            return [];
      }
   }

   /* Data Setting Functions */

   const onTypeChange = (selection) => {
      setSelectedTypeOption(selection);
      setUnitOptions(getUnitOptions(organisation, selection.value));
      setSelectedUnitOption(UiConstants.EMPTY_OPTION);
   }

   const onUnitChange = (selection) => {
      setSelectedUnitOption(selection);
   }

   /* File Import Functions */

   const getIntervalType = (row) => {
      let keys = Object.keys(row).filter(k => k !== "Data");
      if (keys.length > 0) {
         let checkKey = keys[0];
         let checkKeyParts = checkKey.split(" ");
         let checkPart = checkKeyParts[0];
         if (checkPart === "Week") {
            return DataConstants.FrequencyType.WEEKLY;
         } else if (months.indexOf(checkPart) > -1) {
            return DataConstants.FrequencyType.MONTHLY;
         } else if (checkPart.startsWith("Q")) {
            return DataConstants.FrequencyType.QUARTERLY;
         } else if (checkPart === "FY") {
            return DataConstants.FrequencyType.FINANCIAL_YEAR;
         } else if (!isNaN(checkPart)) {
            return DataConstants.FrequencyType.ANNUALLY;
         } else {
            return DataConstants.FrequencyType.NONE;
         }
      } else {
         return DataConstants.FrequencyType.NONE;
      }
   }

   const getDataKey = (title) => {
      let requiredData = [];
      let index = -1;
      switch (selectedTypeOption.value) {
         case EntityConstants.EntityType.ENTERPRISE:
            index = organisation.enterprises ? organisation.enterprises.map(e => e._id).indexOf(selectedUnitOption.value) : -1;
            if (index > -1) {
               requiredData = organisation.enterprises[index].requiredData;
            }
            break;
         case EntityConstants.EntityType.PROGRAM:
            index = organisation.programs ? organisation.programs.map(p => p._id).indexOf(selectedUnitOption.value) : -1;
            if (index > -1) {
               requiredData = organisation.programs[index].requiredData;
            }
            break;
         case EntityConstants.EntityType.PROJECT:
            index = organisation.projects ? organisation.projects.map(p => p._id).indexOf(selectedUnitOption.value) : -1;
            if (index > -1) {
               requiredData = organisation.projects[index].requiredData;
            }
            break;
         default:
            break;
      }
      const rdIndex = requiredData.map(rd => rd.title.replace(/(?:\r\n|\r|\n)/g, '')).indexOf(title.replace(/(?:\r\n|\r|\n)/g, ''));
      if (rdIndex > -1) {
         return requiredData[rdIndex].key;
      } else {
         return "";

      }
   }

   const getDate = (intervalType, text) => {
      const textParts = text.split(" ");
      let date = null;
      switch (intervalType) {
         case DataConstants.FrequencyType.WEEKLY:
            //date = dayjs().year(parseInt(textParts[2])).week(parseInt(textParts[1])).startOf("week");
            date = dayjs.utc().year(parseInt(textParts[2])).week(parseInt(textParts[1]))
            .hour(0).minute(0).second(0).millisecond(0).startOf("week");
            break;
         case DataConstants.FrequencyType.MONTHLY:
            //date = dayjs(`1 ${text}`, "D MMM YYYY");
            date = dayjs.utc(`1 ${text}`, "D MMM YYYY");
            break;
         case DataConstants.FrequencyType.QUARTERLY:
            //date = dayjs().year(parseInt(textParts[1])).quarter(parseInt(textParts[0].replace("Q",""))).startOf("quarter");
            date = dayjs.utc().year(parseInt(textParts[1])).quarter(parseInt(textParts[0].replace("Q","")))
            .hour(0).minute(0).second(0).millisecond(0).startOf("quarter");
            break;
         case DataConstants.FrequencyType.FINANCIAL_YEAR:
            const yearParts = textParts[1].split("-");
            //date = dayjs().year(parseInt(yearParts[0])).month(6).date(1);
            date = dayjs.utc().year(parseInt(yearParts[0])).month(6).date(1)
            .hour(0).minute(0).second(0).millisecond(0);
            break;
         case DataConstants.FrequencyType.ANNUALLY:
            //date = dayjs().year(parseInt(text)).month(0).date(1);
            date = dayjs.utc().year(parseInt(text)).month(0).date(1)
            .hour(0).minute(0).second(0).millisecond(0);
            break;
         default:
            break;
      }
      return date && date.isValid() ? date : null;
   }

   const getImportData = (intervalType, data) => {
      let importData = [];
      let importKeys = [];
      for (let i = 0; i < data.length; i++) {
         const row = data[i];
         const key = row["Data"] ? getDataKey(row["Data"]) : "";
         if (key !== "") {
            let dateKeys = Object.keys(row).filter(k => k !== "Data");
            for (let j = 0; j < dateKeys.length; j++) {
               const dateKey = dateKeys[j];
               const date = getDate(intervalType, dateKey);
               if (date) {
                  /*
                  importData.push({
                     date: date,
                     interval: intervalType,
                     key: key,
                     value: row[dateKey]
                  });
                  */
                  importData.push({
                     utcDate: date,
                     interval: intervalType,
                     key: key,
                     value: row[dateKey]
                  });
                  if (importKeys.indexOf(key) === -1) {
                     importKeys.push(key);
                  }
               }
            }
         }
      }
      return {
         data: importData,
         keys: importKeys
      };
   }

   const handleFileImport = (e) => {
      if (e.target.files.length > 0) {
         var reader = new FileReader();
         reader.onload = function(e) {
            try {
               const workbook = read(e.target.result);
               const worksheet = workbook.Sheets[workbook.SheetNames[0]];
               const data = utils.sheet_to_json(worksheet);
               if (data.length === 0) {
                  alert("There is no data to upload from this spreadsheet.");
                  return;
               }
               const intervalType = getIntervalType(data[0]);
               if (intervalType === DataConstants.FrequencyType.NONE) {
                  alert("Unable to upload data as no valid interval columns with data were found.");
                  return;
               }
               const importData = getImportData(intervalType, data);
               if (importData.length === 0) {
                  alert("Unable to extract any upload data from this spreadsheet");
                  return;
               }
               onImport({ 
                  interval: intervalType,
                  entityType: selectedTypeOption.value,
                  entity: selectedUnitOption.value,
                  data: importData.data,
                  keys: importData.keys
               });

            } catch (err) {
               console.log(err);
               alert("Unable to read & process the provided file");
            }
         }
         reader.readAsArrayBuffer(e.target.files[0]);
      }
   }

   /* Footer Button Interactions */

   const onCancelClick = () => {
      onClose();
   };

   const onUploadClick = () => {
      if (selectedUnitOption.value === "") {
         const alertmsg = `You need to select a ${selectedTypeOption.label === "" ? "reporting" : selectedTypeOption.label.toLowerCase()} before you can upload a spreadsheet.`;
         alert(alertmsg);
         return;
      }
      fileInputRef.current.click();
   }

   /* useEffect Functions */

   useEffect(() => {
      if (open) {
         const org = params.organisation ? params.organisation : { enterprises: [], programs: [], projects: [] };

         const type = params.type ? params.type : "";
         const typeIndex = UiConstants.UNIT_TYPE_OPTIONS.map(o => o.value).indexOf(type);
         const typeOpt = typeIndex > -1 ? UiConstants.UNIT_TYPE_OPTIONS[typeIndex] : UiConstants.EMPTY_OPTION;

         const unit = params.unit ? params.unit : "";
         const unitOpts = getUnitOptions(org, type);
         const unitIndex = unitOpts.map(u => u.value).indexOf(unit);
         const unitOpt = unitIndex > -1 ? unitOpts[unitIndex] : UiConstants.EMPTY_OPTION;

         setOrganisation(org);
         setSelectedTypeOption(typeOpt);
         setUnitOptions(unitOpts);
         setSelectedUnitOption(unitOpt);
      }
   }, [params, open]);

   return (
      <Transition show={open} as={Fragment}>
         <Dialog as="div" className="relative z-10" onClose={onClose}>
            <TransitionChild
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"                
            >
               <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
            </TransitionChild>
            <div className="fixed inset-0 z-10 overflow-y-auto">
               <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <TransitionChild
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <DialogPanel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                        <div className="flex flex-col items-stretch gap-8">
                           <div className="flex justify-between">
                              <DialogTitle
                                 as="h4"
                                 className="font-vg-medium text-3.5xl text-black leading-110 grow"
                              >
                                 Upload Excel Data
                              </DialogTitle>
                              <input
                                 type="file"
                                 ref={fileInputRef}
                                 className="invisible"
                                 onChange={handleFileImport}
                              />
                           </div>
                           <div className="grid grid-cols-3 gap-x-6 gap-y-3">
                              <div className="flex flex-col items-stretch gap-3 col-span-3 mb-4">
                                 <h6 className="font-vg-book text-base text-black">
                                    This will allow you to upload an Excel spreadsheet of data, using a template that was previously downloaded from this page.
                                 </h6>
                                 <h6 className="font-vg-book text-base text-black">
                                    Select the reporting unit that this data belongs to:
                                 </h6>
                              </div>
                              <SelectDropdown
                                 label="Select Type"
                                 options={UiConstants.UNIT_TYPE_OPTIONS}
                                 selectedOption={selectedTypeOption || UiConstants.EMPTY_OPTION}
                                 onChange={onTypeChange}
                              />
                              <div className="col-span-2">
                                 <SelectDropdown
                                    label={selectedTypeOption.label ? `Select ${selectedTypeOption.label}` : "Select Reporting Unit"}
                                    options={unitOptions ? unitOptions : []}
                                    selectedOption={selectedUnitOption || UiConstants.EMPTY_OPTION}
                                    onChange={onUnitChange}
                                 />
                              </div>
                           </div>
                           <div className="grid grid-cols-2 gap-3">
                              <Button variant="outline" size="large" label="Cancel" className="w-full" onClick={onCancelClick}/>
                              <Button variant="solid" size="large" label="Upload" className="w-full" onClick={onUploadClick}/>
                           </div>
                        </div>
                     </DialogPanel>
                  </TransitionChild>
               </div>
            </div>
         </Dialog>
      </Transition>
   );
}