import { useId } from 'react';

import * as DashboardConstants from '../constants/DashboardConstants';
import DashboardUtils from '../utils/DashboardUtils';

import DashboardThumbnail from './DashboardThumbnail';

export default function DashboardThumbGrid({ size, elements }) {

    const id = useId();

    let gridClass = "grid w-full h-full grid-cols-4 grid-rows-3";

    switch (size) {
        case "small":
            gridClass = `${gridClass} gap-1 p-1`;
            break;
        case "large":
            gridClass = `${gridClass} gap-2`;
            break;
        default:
            break;
    }

    const getEmptyGrid = () => {
        return [
            {
                index: 1,
                type: DashboardConstants.ThumbType.QUESTION,
                row: 0,
                col: 0,
                width: 4,
                height: 3
            }
        ];
    }

    const getBlankGridArray = () => {
        let blankGrid = [];
        for (let r = 1; r <= 3; r++) {
            for (let c = 1; c <= 4; c++) {
                blankGrid.push({
                    index: parseInt(r) * 10 + parseInt(c),
                    type: DashboardConstants.ThumbType.NONE,
                    row: r,
                    col: c,
                    width: 1,
                    height: 1
                });
            }
        }
        return blankGrid;
    }

    const filterElements = (elems, rowcols) => {
        let newElems = [];
        elems.forEach(el => {
            if (el.col < rowcols.startCol || el.col >= rowcols.endCol || el.row < rowcols.startRow || el.row >= rowcols.endRow) {
                newElems.push(el);
            }
        });
        return newElems;
    }

    let grid = [];

    if (elements.length === 0) {
        grid = getEmptyGrid();
    } else {
        let blankElements = getBlankGridArray();
        let thumbElements = [];
        elements.forEach(el => {
            thumbElements.push({
                index : parseInt(el.row) * 10 + parseInt(el.col),
                type: DashboardUtils.getThumbTypeColor(el),
                row: parseInt(el.row),
                col: parseInt(el.col),
                width: parseInt(el.width),
                height: parseInt(el.height)
            });
            blankElements = filterElements(blankElements, {
                startRow: parseInt(el.row),
                endRow: parseInt(el.row) + parseInt(el.height),
                startCol: parseInt(el.col),
                endCol: parseInt(el.col) + parseInt(el.width),
            });
        });
        grid = thumbElements.concat(blankElements);
        grid.sort((a, b) => {
            if (a.index < b.index) {
                return -1;
            } else if (a.index > b.index) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    return (
        <div className={gridClass}>
            {grid.map((element, index) => (
                <DashboardThumbnail 
                    key={`dash-thumb-${id}-${index}`} 
                    id={id}
                    index={index}
                    size={size} 
                    type={element.type}
                    row={element.row}
                    col={element.col}
                    width={element.width}
                    height={element.height}
                />
            ))}
        </div>
    );
}