import jsonp from 'jsonp';

let ABNService = {};

ABNService.checkAbn = (abn, callback) => {
   jsonp('https://abr.business.gov.au/json/AbnDetails.aspx?abn=' + abn + '&guid=' + process.env.REACT_APP_ABN_GUID, null, (error, data) => {
      callback(error, data);
   });
}

export default ABNService;