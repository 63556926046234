import axios from 'axios';

import * as EntityConstants from '../constants/EntityConstants';

let OrgService = {};

OrgService.getOrgData = (includeUser) => {
   const url = `${process.env.REACT_APP_API_URL}/organisation${includeUser ? "?user=true" : ""}`;
   return axios.get(url, 
      { withCredentials: true }
   );
};

OrgService.updateOrgData = (payload) => {
   return axios.put(process.env.REACT_APP_API_URL + '/organisation', 
      payload, 
      { withCredentials: true }
   );
};

const createEntity = (payload) => {
   return axios.post(process.env.REACT_APP_API_URL + '/entity', 
      payload, 
      { withCredentials: true }
   );
}

OrgService.createEnterprise = (details) => {
   let payload = JSON.parse(JSON.stringify(details));
   payload.entityType = EntityConstants.EntityType.SOCIAL_ENTERPRISE;
   return createEntity(payload);
}

const updateEntityStatus = (id, status) => {
   return axios.put(process.env.REACT_APP_API_URL + '/entity', 
      { id: id, status: status }, 
      { withCredentials: true }
   );
}

OrgService.archiveEntity = (id) => {
   return updateEntityStatus(id, EntityConstants.EntityStatus.ARCHIVED);
}

OrgService.unArchiveEntity = (id) => {
   return updateEntityStatus(id, EntityConstants.EntityStatus.ACTIVE);
}

OrgService.updateEntityData = (id, data) => {
   return axios.put(process.env.REACT_APP_API_URL + '/entity', 
      { id: id, data: JSON.stringify(data) }, 
      { withCredentials: true }
   );
}

OrgService.updateEntityDataColumns = (id, dataColumns) => {
   return axios.put(process.env.REACT_APP_API_URL + '/entity', 
      { id: id, dataColumns: JSON.stringify(dataColumns) }, 
      { withCredentials: true }
   );
}

OrgService.createProgram = (details) => {
   let payload = JSON.parse(JSON.stringify(details));
   payload.entityType = EntityConstants.EntityType.PROGRAM;
   return createEntity(payload);
}

OrgService.createProject = (details) => {
   let payload = JSON.parse(JSON.stringify(details));
   payload.entityType = EntityConstants.EntityType.PROJECT;
   return createEntity(payload);
}

OrgService.getMemberList = () => {
   return axios.get(process.env.REACT_APP_API_URL + '/organisation/memberlist', 
      { withCredentials: true }
   );
}

OrgService.sendDeleteEmail = () => {
   return axios.delete(process.env.REACT_APP_API_URL + '/organisation/deletemail', 
      { withCredentials: true }
   );
}

export default OrgService;