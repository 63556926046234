import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { PlusCircleIcon, QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/solid';

import * as DashboardConstants from '../constants/DashboardConstants';

import DashboardsGridPagination from './DashboardsGridPagination';
import DashboardThumbGrid from './DashboardThumbGrid';

export default function DashboardsGrid({ dashboardList, onGridAction, accessTypes, navExpanded }) {
    const [ selectedPageIndex, setSelectedPageIndex ] = useState(0);

    const buttonArrayRef = useRef([]);

    const [calcStyle, setCalcStyle] = useState(Array(dashboardList.length).fill({ display: "none" }));

    /* Interaction Functions */

    const onCreateClick = (e) => {
        if (accessTypes.create) {
            if (onGridAction) {
                onGridAction({ action: DashboardConstants.ActionType.CREATE_DASHBOARD });
            }
        }
    }

    const onSelectClick = (id) => {
        if (onGridAction) {
            onGridAction({ 
                action: DashboardConstants.ActionType.SELECT_DASHBOARD, 
                args: { id: id } 
            });
        }
    }

    const onDeleteClick = (id, title) => {
        if (accessTypes.delete) {
            if (window.confirm(`Are you sure you want to delete ${title}`)) {
                if (onGridAction) {
                    onGridAction({ 
                        action: DashboardConstants.ActionType.DELETE_DASHBOARD, 
                        args: { id: id }  
                    });
                }
            }
        }
    }

    const onDashboardListPageChange = (index) => {
        setSelectedPageIndex(index);
    }

    /* UI Generation Functions */

    const createDashboardThumbnail = (dashboard, index) => {
        let elements = [];
        if (dashboard.pages.length > 0) {
            elements = dashboard.pages[0].elements ? dashboard.pages[0].elements : [];
        }
        if (elements.length > 0) {
            return (
                <div style={calcStyle[index]}>
                    <DashboardThumbGrid 
                        size="large"
                        elements={elements}
                    />
                </div>
            );
        } else {
            return (
                <div className="flex items-center justify-center rounded-lg bg-grey03" style={calcStyle[index]}>
                    <QuestionMarkCircleIcon className="w-6 h-6 text-grey"/>
                </div>
            );
        }
    }

    const getDashboardGridCard = (d, index) => {
        return (
            <div key={`dash-card-${index}`} className="w-full h-full">
                {d._id !== "" ? (
                    <div className="w-full h-[351px] flex flex-col items-stretch justify-between bg-grey04 rounded-lg border border-grey03">
                        <button
                            ref={el => buttonArrayRef.current[index] = el} 
                            className="grow shrink flex flex-col items-center justify-start mx-5 mt-5"
                            onClick={(_) => onSelectClick(d._id)}
                        >
                            {createDashboardThumbnail(d, index)}
                        </button>
                        <div className="grow-0 shrink-0 flex items-center justify-between px-6 py-4 bg-grey04">
                            <button 
                                className="flex flex-col items-start gap-1"
                                onClick={(_) => onSelectClick(d._id)}
                            >
                                <h6 className="font-vg-medium text-xl text-black">{d.title}</h6>
                                <p className="font-vg-book text-sm text-black">{`Last edited ${new Date(d.updatedAt).toDateString()}`}</p>
                            </button>
                            {accessTypes.delete && (
                                <button onClick={(e) => onDeleteClick(d._id, d.title)}>
                                    <TrashIcon className="w-5 h-5 text-grey"/>
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                    {accessTypes.create && (
                        <button 
                            key={`dash-card-${index}`} 
                            ref={el => buttonArrayRef.current[index] = el} 
                            className="w-full h-[351px] flex flex-col items-center justify-center gap-2 rounded-lg bg-white border border-grey02"
                            onClick={onCreateClick}
                        >
                            <PlusCircleIcon className="w-[30px] h-[30px] text-blue"/>
                            <p className="font-vg-regular text-sm text-blue">Create a dashboard</p>
                        </button>
                    )}
                    </>
                )}
            </div>
        );
    }

    /* Thumbnail Resizing function */

    const handleResize = () => {
        let newCalcStyle = JSON.parse(JSON.stringify(calcStyle));
        buttonArrayRef.current.forEach((el, index) => {
            const { width, height } = el.getBoundingClientRect();
            const vFillWidth = height / 11 * 16;
            const hFillHeight = width / 16 * 11;
            if (vFillWidth > width) {
                newCalcStyle[index] = { width: width, height: hFillHeight };
            } else {
                newCalcStyle[index] = { width: vFillWidth, height: height };
            }
        });
        setCalcStyle(newCalcStyle);
    };

    useLayoutEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        handleResize();
    }, [navExpanded]);

    return (
        <>
            <h3 className="font-vg-medium text-black text-[38px]">Dashboards</h3>
            <div className="grid grid-cols-3 grid-rows-2 h-[750px] gap-6">
                {dashboardList.map((d, index) => {
                    if (index >= selectedPageIndex * DashboardConstants.GRID_PAGE_SIZE && index < (selectedPageIndex + 1) * DashboardConstants.GRID_PAGE_SIZE) {
                        return getDashboardGridCard(d, index);
                    } else {
                        return (
                            <></>
                        );
                    }
                })}
            </div>
            <DashboardsGridPagination 
                variant="full"
                count={dashboardList.length}
                pageSize={DashboardConstants.GRID_PAGE_SIZE}
                page={selectedPageIndex}
                onPageChange={onDashboardListPageChange}
            />
        </>
    );
}