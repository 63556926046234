import { Tab, TabPanels, TabPanel, TabGroup, TabList } from '@headlessui/react';
import { DocumentArrowDownIcon, DocumentArrowUpIcon, DocumentChartBarIcon } from '@heroicons/react/24/solid';

import * as IndicatorConstants from '../constants/IndicatorConstants';
import * as UiConstants from '../constants/UiConstants';

import IndicatorPanelHeader from './IndicatorPanelHeader';
import IndicatorTypePanel from './IndicatorTypePanel';
import Tag from './Tag';

export default function IndicatorSelectPanel({ library, indicators, reportingNeed, domain, outcomeArea, substeps, subindex, selection, onStepPanelAction }) {
   const title = substeps ? substeps[subindex].title : "";
   const subtitle = substeps ? substeps[subindex].subtitle : "";
   const tip = substeps ? substeps[subindex].tip : "";
   const tipAfter = substeps ? substeps[subindex].tipAfter : "";

   const iconClass = "w-5 h-5";

   const getIcon = (iconName) => {
      switch (iconName) {
         case "DocumentArrowDown":
            return (
               <DocumentArrowDownIcon className={iconClass}/>
            );
         case "DocumentArrowUp":
            return (
               <DocumentArrowUpIcon className={iconClass}/>
            );
         case "DocumentChartBar":
            return (
               <DocumentChartBarIcon className={iconClass}/>
            );
         default:
            return (
               <></>
            );
      }
   }

   const tabClass = "flex items-center justify-center gap-1.5 rounded-lg w-full px-4 py-3 text-grey bg-white";
   const selectedTabClass = "flex items-center justify-center gap-1.5 rounded-lg w-full px-4 py-3 text-white bg-blue shadow-type-button";

   const onTypeTabChange = (index) => {
      if (onStepPanelAction) {
         onStepPanelAction({ action: IndicatorConstants.ActionType.CHANGE_TYPE, args: index })
      }
   };

   const onTypePanelAction = (details) => {
      if (onStepPanelAction) {
         onStepPanelAction(details)
      }
   };
   
   return(
      <TabPanel className="flex flex-col items-stretch px-6 py-8 gap-10 rounded-lg bg-grey04 border border-grey03">
         <div className="flex flex-col items-stretch gap-3">
            <IndicatorPanelHeader
               title={title}
               subtitle={subtitle}
               tipContent={tip}
               tipAfter={tipAfter}
            />
            <div className="grid grid-cols-2 gap-6">
               <div className="flex items-center gap-6">
                  <p className="font-vg-regular text-xs text-black">Domain:</p>
                  <Tag text={domain} size="large" color={UiConstants.TagColor.BLACK_OUTLINE}/>
               </div>
               <div className="flex items-center gap-6">
                  <p className="font-vg-regular text-xs text-black">Outcome area:</p>
                  <Tag text={outcomeArea} size="large" color={UiConstants.TagColor.BLACK_OUTLINE}/>
               </div>
            </div>
         </div>
         <TabGroup as="div" selectedIndex={subindex} onChange={onTypeTabChange} className="flex flex-col items-stretch gap-8">
            <TabList className="grid grid-cols-3 gap-3 p-1.5">
               {substeps.map((s, i) => (
                  <Tab key={`indsel-tab-${i}`} className={i === subindex ? selectedTabClass : tabClass}>
                        {getIcon(s.iconName)}
                        <span className="font-vg-regular text-sm leading-120">{s.buttonText}</span>
                  </Tab>
               ))}
            </TabList>
            <TabPanels>
               {substeps.map((s, i) => (
                  <IndicatorTypePanel
                     key={`indsel-panel-${i}`}
                     library={library}
                     reportingNeed={reportingNeed}
                     domain={domain}
                     area={outcomeArea}
                     indicators={indicators}
                     type={s.type}
                     selection={selection}
                     onTypePanelAction={onTypePanelAction}
                  />
               ))}
            </TabPanels>
         </TabGroup>
      </TabPanel>
   );
}