import axios from 'axios';

let ResourceService = {};

ResourceService.getResources = (publicOnly, category) => {
   let url = `${process.env.REACT_APP_API_URL}/resource`;
   if (publicOnly) {
      url = `${url}/public`;
   }
   url = `${url}?category=${category}`;
   
   return axios.get(url, 
      { withCredentials: true }
   );
};

ResourceService.getResourceByKeyAuthCheck = (key) => {
   let url = `${process.env.REACT_APP_API_URL}/resource/checkauth/key/${key}`;    
   return axios.get(url, 
      { withCredentials: true }
   );
};

export default ResourceService;