
export default function IndicatorStartStep({ label, title, text }) {
    return(
        <div className="flex flex-col items-stretch gap-8">
            <div className="flex justify-start">
                <div className="flex justify-center items-center w-17 h-17 rounded-xl bg-purple03">
                    <h3 className="font-vg-medium text-[38px] text-purple01 leading-120">{label}</h3>
                </div>
            </div>
            <div className="flex flex-col items-stretch gap-4">
                <h5 className="font-vg-medium text-2xl text-black leading-120">{title}</h5>
                <p className="font-vg-book text-base text-black leading-130">{text}</p>
            </div>
        </div>
    );
}