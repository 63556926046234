import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

export default function Accordion({ title, text, newlines }) {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    function convertNewlines(string) {
        return string.replace(/\n/g, "<br/>")
    }    
    function stripNewlines(string) {
        return string.replace(/\n/g, "")
    }
    let html;
    switch (newlines) {
        case "convert":
            html = convertNewlines(text);
            break;
        case "strip":
            html = stripNewlines(text);
            break;
        default:
            html = text;
            break;
    } 
    return(
        <>
            {isDesktop && (
            <details className="box-border w-full flex flex-col items-stretch p-6 rounded-lg shadow-standard bg-white">
                <summary className="flex items-center justify-between">
                    <h6 className="font-vg-medium text-black text-2xl shrink">{title}</h6>
                    <svg className="w-5 h-5 text-blue shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
                    </svg>
                </summary>
                <div className="font-vg-book text-black text-base leading-130 mt-6" dangerouslySetInnerHTML={{__html: html}}></div>
            </details>
            )}
            {isMobile && (
            <details className="box-border w-full flex flex-col items-stretch p-6 rounded-lg shadow-standard bg-white">
                <summary className="flex items-center justify-between">
                <h6 className="font-vg-medium text-black text-xl shrink">{title}</h6>
                    <svg className="w-5 h-5 text-blue shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
                    </svg>
                </summary>
                <div className="font-vg-book text-black text-base leading-130 mt-6" dangerouslySetInnerHTML={{__html: html}}></div>
            </details>
            )}
        </>

    );
}