import { TabPanel } from '@headlessui/react';

import * as IndicatorConstants from '../constants/IndicatorConstants';

import Button from "./Button";
import IndicatorPanelHeader from './IndicatorPanelHeader';
import IndicatorDomainButton from './IndicatorDomainButton';

export default function IndicatorDomainPanel({ domains, selectedValue, emptyMessage, onStepPanelAction }) {

    const getCTWDomains = (dm, sd) => {
        let ctwd = dm.filter(d1 => d1.reportingNeed === IndicatorConstants.ReportingNeed.CHANGING_THE_WORLD).map(d2 => {
            return {
                domain: d2.domain,
                reportingNeed: d2.reportingNeed,
                state: sd === "" ? "default" : (sd === d2.domain ? "selected" : "unselected")
            };
        });
        ctwd.sort((a, b) => {
            if (a.domain < b.domain) {
                return -1;
            } else if (a.domain > b.domain) {
                return 1;
            } else {
                return 0;
            }
        });
        return ctwd;
    };

    const getOpDomains = (dm, sd) => {
        let opd = dm.filter(d1 => d1.reportingNeed === IndicatorConstants.ReportingNeed.OPERATIONS).map(d2 => {
            return {
                domain: d2.domain,
                reportingNeed: d2.reportingNeed,
                state: sd === "" ? "default" : (sd === d2.domain ? "selected" : "unselected")
            };
        });
        opd.sort((a, b) => {
            if (a.domain < b.domain) {
                return -1;
            } else if (a.domain > b.domain) {
                return 1;
            } else {
                return 0;
            }
        });
        return opd;
    };

    const ctwDomains = getCTWDomains(domains, selectedValue);
    const opDomains = getOpDomains(domains, selectedValue);

    const domainLabelMods = (text) => {
        let label = text;
        if (label === "Improving Systems, Organisations, and Practices") {
            label = "Improving Systems, Organisations and Practices";
        }
        if (label === "Sales & Marketing") {
            label = "Sales and Marketing";
        }
        return label
    }

    const onDomainClicked = (need, domain, state) => {
        if (state === "selected") {
            if (onStepPanelAction) {
                onStepPanelAction({ 
                    action: IndicatorConstants.ActionType.DESELECT_DOMAIN, 
                    args: {
                        need: need,
                        domain : domain
                    } 
                });
            }
        } else {
            if (onStepPanelAction) {
                onStepPanelAction({ 
                    action: IndicatorConstants.ActionType.SELECT_DOMAIN, 
                    args: {
                        need: need,
                        domain: domain 
                    }
                });
            }
        }
    };

    return(
        <TabPanel className="flex flex-col items-stretch px-6 py-8 gap-10 rounded-lg bg-grey04 border border-grey03">
            <div className="flex w-full items-center justify-between gap-16">
                <IndicatorPanelHeader 
                    title="Choose what you want to report on"
                    subtitle="Choose from the list of fully editable domains and indicators in Seedkit Indicators Library, or click 'Create Custom' to make your own indicators"
                    tipContent="Indicators in the Library are organised into thematic areas called domains"
                    tipAfter="subtitle"
                />
                <Button 
                    variant="solid"
                    size="large" 
                    label="Create Custom" 
                    leftIcon="PlusIcon" 
                    className="shrink-0"
                    onClick={() => {
                        if (onStepPanelAction) {
                            onStepPanelAction({ action: IndicatorConstants.ActionType.CREATE_INDICATOR });
                        }
                    }}
                />
            </div>
            <div className="flex flex-col items-stretch gap-4">
                <h6 className="font-vg-medium text-black text-xl">Changing The World</h6>
                <div className="grid grid-cols-3 gap-x-6 gap-y-4">
                    {ctwDomains.length === 0 && (
                        <div className="flex items-center justify-center col-span-3 h-36">
                            <h6 className="font-vg-medium text-black text-xl">
                                {emptyMessage ? emptyMessage : "No Changing The World Domains To Display"}
                            </h6>
                        </div>
                    )}
                    {ctwDomains.length > 0 && ctwDomains.map((d, i) => (
                        <IndicatorDomainButton
                            key={`ctw-domain-${i}`}
                            label={domainLabelMods(d.domain)}
                            need={IndicatorConstants.ReportingNeed.CHANGING_THE_WORLD}
                            domain={d.domain}
                            color="purple"
                            state={d.state}
                            tipContent=""
                            tipPosition="top"
                            onChange={onDomainClicked}
                        />
                    ))}
                </div>
            </div>
            <div className="flex flex-col items-stretch gap-4">
                <h6 className="font-vg-medium text-black text-xl">Managing Our Operations</h6>
                <div className="grid grid-cols-3 gap-x-6 gap-y-4">
                    {ctwDomains.length === 0 && (
                        <div className="flex items-center justify-center col-span-3 h-36">
                            <h6 className="font-vg-medium text-black text-xl">
                                {emptyMessage ? emptyMessage : "No Operations Domains To Display"}
                            </h6>
                    </div>
                )}
                    {opDomains.length > 0 && opDomains.map((d, i) => (
                        <IndicatorDomainButton
                            key={`op-domain-${i}`}
                            label={domainLabelMods(d.domain)}
                            need={IndicatorConstants.ReportingNeed.OPERATIONS}
                            domain={d.domain}
                            color="yellow"
                            state={d.state}
                            tipContent=""
                            tipPosition="top"
                            onChange={onDomainClicked}
                        />
                    ))}
                </div>
            </div>
        </TabPanel>
    );
}