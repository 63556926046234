import { useState, useEffect } from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import ColorPicker from './ColorPicker';
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function LexicalColourPicker({colour, className, pickerClass, presets, icon, text, colourLine, onChange}) {
    const [popoverElement, setPopoverElement] = useState(null);
    const [color, setColor] = useState(colour);

    let buttonClass = "flex justify-between gap-2 items-center cursor-default hover:bg-grey09 focus:outline-none";
    if (colourLine) {
        buttonClass = `${buttonClass} rounded-lg2 p-1`;
    } else {
        buttonClass = `${buttonClass} rounded-lg2 p-2`;
    }
    buttonClass = className ? `${buttonClass} ${className}` : buttonClass;

    let pickerClassName = "z-10 bg-white shadow-listbox-options";
    pickerClassName = pickerClass ? `${pickerClassName} ${pickerClass}` : pickerClassName;

    const onColorChange = (c, sh) => {
        setColor(c);
    }

    useEffect(() => {
        if (!popoverElement) {
            if (color !== colour) {
                onChange(color);
            }
        }
    }, [popoverElement, onChange, color, colour])
    
    
    return (
        <Popover className="relative">
            <PopoverButton className={buttonClass}>
                {colourLine ? (
                    <>
                        {icon && (
                            <i className={`${icon} w-6 h-6 bg-contain bg-center bg-no-repeat border-b-4 pt-0.5`} style={{borderColor: color}}></i>
                        )}
                        {text && (
                            <p className="font-vg-book text-grey text-sm leading-130 border-b-4 border-transparent">{text}</p>
                        )}
                        <ChevronDownIcon className="w-7 h-8 text-black border-b-4 border-transparent pt-1" aria-hidden="true"/>
                    </>
                ) : (
                    <>
                        {icon && (
                            <i className={`${icon} w-4.5 h-4.5 bg-contain bg-center bg-no-repeat`}></i>
                        )}
                        {text && (
                            <p className="font-vg-book text-grey text-sm leading-130">{text}</p>
                        )}
                        <ChevronDownIcon className="w-6 h-6 text-black" aria-hidden="true"/>
                    </>
                )}
            </PopoverButton>
            <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <PopoverPanel
                    ref={setPopoverElement}
                    anchor="bottom start"
                    className={pickerClassName}
                >
                    {({ close }) => (
                        <ColorPicker
                            color={color}
                            presets={presets}
                            onChange={onColorChange}
                            onClose={() => close()}
                        />
                    )}
                </PopoverPanel>
            </Transition>
        </Popover>
    );
}