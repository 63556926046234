import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

import * as IndicatorConstants from '../constants/IndicatorConstants';

import IconTooltip from './IconTooltip';
import Button from './Button';
import IndicatorStartStep from './IndicatorStartStep';

export default function IndicatorStartContainer({ type, unit, onStartAction, userCanEdit }) {
    const navigate = useNavigate();

    const onCreateClick = (e) => {
        if (onStartAction) {
            onStartAction({ action: IndicatorConstants.ActionType.CREATE_PATHWAY });
        }
    }

    const onLearnMoreClick = () => {
        navigate('/resources/what-is-indicator');
    }

    return(
        <div className="flex flex-col items-stretch gap-15">
            {userCanEdit ? (
            <>
                <div className="grid grid-cols-2 gap-6">
                    <div className="flex flex-col items-stretch px-6 py-8 gap-6 rounded-lg bg-grey04">
                        <div className="flex justify-between items-start">
                            <div className="flex justify-center items-center w-[62px] h-[62px] rounded-md bg-purple02">
                                <PlusCircleIcon className="w-[42px] h-[42px] text-purple01"/>
                            </div>
                            <IconTooltip
                                content="You can create a custom indicator at the next stage"
                            />
                        </div>
                        <h3 className="font-vg-medium text-[38px] text-black leading-120">Choose from Seedkit Indicators Library</h3>
                        <Button variant="solid" size="large" label="Create" onClick={onCreateClick}/>
                    </div>
                </div>
                <div className="flex flex-col items-stretch gap-10 px-6 py-10 rounded-lg bg-grey04 ">
                    <div className="flex flex-col items-center gap-2">
                        <h3 className="font-vg-medium text-[38px] text-black leading-120">It's as easy as...</h3>
                        <p className="font-vg-book text-base text-black leading-130">Setting up your indicators is a simple process. Here's how:</p>
                    </div>
                    <div className="grid grid-cols-4 gap-6">
                        <IndicatorStartStep
                            label="01"
                            title="Choose domain"
                            text="Select a domain you would like to report on, or create your own."
                        />
                        <IndicatorStartStep
                            label="02"
                            title="Select outcome area"
                            text="Choose the outcome(s) you want to track or create your own."
                        />
                        <IndicatorStartStep
                            label="03"
                            title="Add indicators"
                            text="Choose the inputs, outputs and outcomes that make up your impact pathway."
                        />
                        <IndicatorStartStep
                            label="04"
                            title="View and edit indicators"
                            text="Add details to set up your indicators."
                        />
                    </div>
                    <div className="flex justify-center">
                        <Button variant="outline" size="large" label="Learn more about indicators" onClick={() => onLearnMoreClick()}/>
                    </div>
                </div>
            </>
            ) : (
                <h3 className="font-vg-medium text-[38px] text-black leading-120 h-[46px]">
                    {(type && unit) ? `There are no indicators to view for this ${type}` : ""}
                </h3>
            )}
        </div>
    );
}