import { useContext, useState } from 'react';
import GridLayout from 'react-grid-layout';

import * as DashboardConstants from '../constants/DashboardConstants';
import { GlobalContext } from '../context/GlobalContext';

import TextInput from './TextInput';

export default function DashboardElementSizeSetter({ details, row, col, width, height, onSetterAction }) {
    const { context } = useContext(GlobalContext);

    const [rowValue, setRowValue] = useState(row);
    const [colValue, setColValue] = useState(col);
    const [widthValue, setWidthValue] = useState(width);
    const [heightValue, setHeightValue] = useState(width);

    const boardId = details.boardId;
    const pageIndex = details.pageIndex;
    const elementId = details.elementId;

    let otherElements = [];
    let organisation = context.organisation ? context.organisation : [];
    if (organisation.dashboards) {
        const boardIndex = organisation.dashboards.map(b => b._id).indexOf(boardId);
        if (boardIndex > -1) {
            const dashboard = organisation.dashboards[boardIndex];
            if (dashboard.pages && pageIndex >= 0 && pageIndex < dashboard.pages.length) {
                const page = dashboard.pages[pageIndex];
                otherElements = page.elements ? page.elements : [];
                otherElements = otherElements.filter(el => el._id !== elementId)
            }
        }
    }

    let statusGrid = [];
    for (let r = 1; r <= 3; r++) {
        let gridRow = [];
        for (let c = 1; c <= 4; c++) {
            let item = DashboardConstants.SetterGridStatus.VACANT;
            otherElements.forEach(element => {
                if (r >= element.row && r < element.row + element.height && c >= element.col && c < element.col + element.width) {
                    item = DashboardConstants.SetterGridStatus.EXCLUDED;
                }
            });
            if (item === DashboardConstants.SetterGridStatus.VACANT) {
                if (r >= row && r < row + height && c >= col && c < col + width) {
                    item = DashboardConstants.SetterGridStatus.OCCUPIED;
                }
            }
            gridRow.push(item);
        }
        statusGrid.push(gridRow);
    }

    let startTile = {
        i: "tile",
        x: col - 1,
        y: row - 1,
        w: width,
        h: height,
        maxW: 4,
        maxH: 3
    };

    const [tileLayout, setTileLayout] = useState([startTile]);

    const getStatus = (rw, cl) => {
        if (rw < 0 || cl < 0) {
            return DashboardConstants.SetterGridStatus.UNKNOWN;
        }
        let statusRow = statusGrid.length >= rw ? statusGrid[rw - 1] : null;
        if (statusRow) {
            return statusRow.length >= cl ? statusRow[cl - 1] : DashboardConstants.SetterGridStatus.UNKNOWN;
        } else {
            return DashboardConstants.SetterGridStatus.UNKNOWN;
        }
    }

    const getStatusHTML = (status, rowIndex, colIndex) => {
        switch (status) {
            case DashboardConstants.SetterGridStatus.VACANT:
                return (<div key={`setter-b-${rowIndex}-${colIndex}`} className="w-full h-full border border-blue bg-grey03"></div>);
            case DashboardConstants.SetterGridStatus.OCCUPIED:
                return (<div key={`setter-b-${rowIndex}-${colIndex}`} className="w-full h-full border border-blue bg-blue"></div>);
            case DashboardConstants.SetterGridStatus.EXCLUDED:
                return (<div key={`setter-b-${rowIndex}-${colIndex}`} className="w-full h-full border bg-grey03"></div>);
            default:
                return (<div key={`setter-b-${rowIndex}-${colIndex}`} className="w-full h-full"></div>);
        }
    }

    const parseNumber = (value) => {
        return isNaN(parseInt(value)) ? '' : parseInt(value);
    }

    const getTileSquares = (rw, cl, wd, ht) => {
        let tileSquares = [];
        for (let r = rw; r < rw + ht; r++) {
            for (let c = cl; c < cl + wd; c++) {
                tileSquares.push({ row: r, col: c });
            }
        }
        return tileSquares;
    }

    const overlapCheck = (rw, cl, wd, ht) => {
        const tileSquares = getTileSquares(rw, cl, wd, ht);
        let overlap = DashboardConstants.OverlapStatus.NONE;
        tileSquares.forEach(sq => {
            const status = getStatus(sq.row, sq.col);
            if (overlap === DashboardConstants.OverlapStatus.NONE) {
                switch (status) {
                    case DashboardConstants.SetterGridStatus.EXCLUDED:
                        overlap = DashboardConstants.OverlapStatus.OVERLAP;
                        break;
                    case DashboardConstants.SetterGridStatus.UNKNOWN:
                        overlap = DashboardConstants.OverlapStatus.ERROR;
                        break;
                    default:
                        break;
                }
            }
        })
        if (overlap === DashboardConstants.OverlapStatus.OVERLAP) {
            alert("This is not a valid position/size as it overlaps other dashboard elements.");
            return true;
        } else if (overlap === DashboardConstants.OverlapStatus.ERROR) {
            alert("An error occurred setting this position/size.");
            return true;
        }
        return false;
    }

    const onLayoutChanged = (layouts) => {
        if (layouts.length === 0) {
            return;
        }
        const layout = layouts[0];
        const r = layout.y + 1;
        const c = layout.x + 1;
        const w = layout.w;
        const h = layout.h;
        if (overlapCheck(r, c, w, h)) {
            setTileLayout([startTile]);
        } else {
            setTileLayout(layouts);
            setRowValue(r);
            setColValue(c);
            setWidthValue(w);
            setHeightValue(h);
            if (onSetterAction) {
                onSetterAction({
                    action: DashboardConstants.ActionType.SETTER_GRID_UPDATE,
                    args: {
                        row: r,
                        col: c,
                        width: w,
                        height: h
                    }
                });
            }
        }
    }

    const onRowChanged = (e) => {
        const newRow = rowValue;
        if (typeof newRow !== "number") {
            alert("This is not a valid row value.");
            setRowValue(row);
            return;
        }
        const newY = newRow - 1;
        if (newY < 0 || newY + height > 3) {
            alert("This takes the dimensions of this elements outside the valid range of values.");
            setRowValue(row);
            return;
        }
        if (overlapCheck(newRow, col, width, height)) {
            setTileLayout([startTile]);
        } else {
            setTileLayout([{
                i: "tile",
                x: col - 1,
                y: newY,
                w: width,
                h: height,
                maxW: 4,
                maxH: 3
            }]);
            if (onSetterAction) {
                onSetterAction({
                    action: DashboardConstants.ActionType.SETTER_ROW_UPDATE,
                    args: {
                        row: newRow
                    }
                });
            }
        }
    }

    const onColumnChanged = (e) => {
        const newCol = colValue;
        if (typeof newCol !== "number") {
            alert("This is not a valid column value.");
            setColValue(col);
            return;
        }
        const newX = newCol - 1;
        if (newX < 0 || newX + width > 4) {
            alert("This takes the dimensions of this elements outside the valid range of values.");
            setColValue(col);
            return;
        }
        if (overlapCheck(row, newCol, width, height)) {
            setTileLayout([startTile]);
        } else {
            setTileLayout([{
                i: "tile",
                x: newCol,
                y: row - 1,
                w: width,
                h: height,
                maxW: 4,
                maxH: 3
            }]);
            if (onSetterAction) {
                onSetterAction({
                    action: DashboardConstants.ActionType.SETTER_COLUMN_UPDATE,
                    args: {
                        col: newCol
                    }
                });
            }
        }
    }

    const onWidthChanged = (e) => {
        const newWidth = widthValue;
        if (typeof newWidth !== "number") {
            alert("This is not a valid width value.");
            setWidthValue(width);
            return;
        }
        if (newWidth < 1 || (col - 1) + newWidth > 4) {
            alert("This takes the dimensions of this elements outside the valid range of values.");
            setWidthValue(width);
            return;
        }
        if (overlapCheck(row, col, newWidth, height)) {
            setTileLayout([startTile]);
        } else {
            setTileLayout([{
                i: "tile",
                x: col - 1,
                y: row - 1,
                w: newWidth,
                h: height,
                maxW: 4,
                maxH: 3
            }]);
            if (onSetterAction) {
                onSetterAction({
                    action: DashboardConstants.ActionType.SETTER_WIDTH_UPDATE,
                    args: {
                        width: newWidth
                    }
                });
            }
        }
}

    const onHeightChanged = (e) => {
        const newHeight = heightValue;
        if (typeof newHeight !== "number") {
            alert("This is not a valid height value.");
            setHeightValue(height);
            return;
        }
        if (newHeight < 1 || (row - 1) + newHeight > 3) {
            alert("This takes the dimensions of this elements outside the valid range of values.");
            setHeightValue(height);
            return;
        }
        if (overlapCheck(row, col, width, newHeight)) {
            setTileLayout([startTile]);
        } else {
            setTileLayout([{
                i: "tile",
                x: col - 1,
                y: row - 1,
                w: width,
                h: newHeight,
                maxW: 4,
                maxH: 3
            }]);
            if (onSetterAction) {
                onSetterAction({
                    action: DashboardConstants.ActionType.SETTER_HEIGHT_UPDATE,
                    args: {
                        width: newHeight
                    }
                });
            }
        }
    }

    return (
        <div className="flex flex-col items-stretch gap-3">
            <p className="font-vg-book text-base leading-120">
                <span className="text-grey">Position / Size of Tile</span><span className="text-red">*</span>
            </p>
            <div className="w-full h-60 flex items-stretch justify-center gap-10">
                <div className="h-full w-24 flex flex-col items-stretch justify-center gap-5">
                    <TextInput
                        label="Top Row"
                        value={rowValue}
                        onChange={(e) => setRowValue(parseNumber(e.target.value))}
                        onBlur={onRowChanged}
                    />
                    <TextInput
                        label="Left Column"
                        value={colValue}
                        onChange={(e) => setColValue(parseNumber(e.target.value))}
                        onBlur={onColumnChanged}
                    />
                </div>
                <div className="w-88 h-60 border border-grey01 rounded-lg flex items-stretch">
                    <div className="w-10 py-10 grid grid-cols-1 grid-rows-3 grow-0 shrink-0">
                        <div className="flex items-center justify-center font-vg-book text-grey01 text-xs pt-2">
                            1
                        </div>
                        <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                            2
                        </div>
                        <div className="flex items-center justify-center font-vg-book text-grey01 text-xs pb-2">
                            3
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch">
                        <div className="h-10 px-2 grid grid-cols-4 grid-rows-1 grow-0 shrink-0">
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                1
                            </div>
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                2
                            </div>
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                3
                            </div>
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                4
                            </div>
                        </div>
                        <div className="w-68 h-60 relative">
                            <div className="absolute grid grid-cols-4 grid-rows-3 gap-4 p-2 inset-0">
                                {statusGrid.map((statusRow, rowIndex) => {
                                    return statusRow.map((status, colIndex) => getStatusHTML(status, rowIndex, colIndex))
                                })}
                            </div>
                            <div className="absolute inset-0">
                                <GridLayout
                                    className="layout"
                                    layout={tileLayout ? tileLayout : [startTile]}
                                    cols={4} 
                                    rowHeight={53} 
                                    width={272}
                                    resizeHandles={["s", "w", "e", "n", "sw", "nw", "se", "ne"]}
                                    margin={[0, 0]}
                                    containerPadding={[0, 0]}
                                    compactType={null}
                                    onLayoutChange={onLayoutChanged}
                                >
                                    <div key="tile"></div>
                                </GridLayout>
                            </div>
                        </div>
                        <div className="h-10 px-1.5 grid grid-cols-4 grid-rows-1 grow-0 shrink-0">
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                1
                            </div>
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                2
                            </div>
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                3
                            </div>
                            <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                                4
                            </div>
                        </div>
                    </div>
                    <div className="w-10 py-10 grid grid-cols-1 grid-rows-3 grow-0 shrink-0">
                        <div className="flex items-center justify-center font-vg-book text-grey01 text-xs pt-1.5">
                            1
                        </div>
                        <div className="flex items-center justify-center font-vg-book text-grey01 text-xs">
                            2
                        </div>
                        <div className="flex items-center justify-center font-vg-book text-grey01 text-xs pb-1.5">
                            3
                        </div>
                    </div>
                </div>
                <div className="h-full w-24 flex flex-col items-stretch justify-center gap-5">
                    <TextInput
                        label="Width"
                        value={widthValue}
                        onChange={(e) => setWidthValue(parseNumber(e.target.value))}
                        onBlur={onWidthChanged}
                    />
                    <TextInput
                        label="Height"
                        value={heightValue}
                        onChange={(e) => setHeightValue(parseNumber(e.target.value))}
                        onBlur={onHeightChanged}
                    />
                </div>
            </div>
        </div>
    );
}