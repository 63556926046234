import { forwardRef, useId, useState, Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';

import * as UiConstants from '../constants/UiConstants';

import Checkbox from './Checkbox';
import DeletableTag from './DeletableTag';

const MultiSelectComboBox = forwardRef((props, ref) => {
    const { id, label, options, selectedOptions, onChange, template, showTags, placeholder } = props;
    const [query, setQuery] = useState("");

    const displayTemplate = template ? template : "";

    const newId = useId();
    const comboBoxId = id ? id : newId;

    const filteredOptions = query === ''
        ? options
        : options.filter(opt =>
            opt.label
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.toLowerCase().replace(/\s+/g, ''))
            );

    function getTemplateText(temp, opts) {
        if (opts.length > 1) {
            return temp.replace("{COUNT}", selectedOptions.length);
        } else if (selectedOptions.length === 1) {
            return opts[0].label;
        } else {
            return "";
        }
    }

    /* Interaction Functions */

    const handleChange = (selection) => {
        if (onChange) {
            onChange(selection);
        }
    }

    const handleDeleteClick = (index) => {
        if (index < selectedOptions.length) {
            let updatedSelection = JSON.parse(JSON.stringify(selectedOptions));
            updatedSelection.splice(index, 1);
            if (onChange) {
                onChange(updatedSelection);
            }
        }
}

    return (
        <Combobox ref={ref} value={selectedOptions} by="value" onChange={(selection) => handleChange(selection)} multiple>
            {({open}) => (
                <div className="relative">
                    {label && (
                        <Combobox.Label className="font-vg-book text-grey text-base leading-130 mb-1.5 block">{label}</Combobox.Label>
                    )}
                    <div className="relative w-full min-h-[50px] flex justify-between gap-1.5 items-center p-3 cursor-default border bg-white rounded-md border-grey01">
                        <MagnifyingGlassIcon className="w-6 h-6 text-grey grow-0 shrink-0"/>
                        {placeholder ? (
                        <Combobox.Input
                            className="flex flex-wrap grow gap-1.5 p-0 border-0 focus:ring-0 focus:ring-offset-0"
                            placeholder={placeholder}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        ) : (
                        <Combobox.Input
                            className="flex flex-wrap grow gap-1.5 p-0 border-0 focus:ring-0 focus:ring-offset-0"
                            displayValue={(opts) => displayTemplate !== "" ? 
                                getTemplateText(displayTemplate, opts) : 
                                opts.map((o, index) => `${o.label}${index < opts.length - 1 ? ", ": ""}`)
                            }
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        )}
                        <Combobox.Button className="grow-0 shrink-0">
                            {open ? (
                                <XMarkIcon className="w-6 h-6 text-grey"/>
                            ) : (
                                <ChevronDownIcon className="w-6 h-6 text-grey"/>
                            )}
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute z-10 mt-1.5 max-h-60 w-full overflow-auto font-vg-book text-base leading-130 rounded-lg bg-white shadow-listbox-options focus:outline-none">
                            {filteredOptions.map((opt, index) => (
                                <Combobox.Option
                                    key={`${comboBoxId}-option-${index}`}
                                    value={opt}
                                >
                                    {({ selected, active }) => (
                                        <div className={`flex flex-col items-stretch px-4 ${active ? "bg-blue03" : "bg-white"}`}>
                                            <div className="flex justify-between items-start py-4">
                                                <div className="grow" dangerouslySetInnerHTML={{__html: opt.html}}></div>
                                                <Checkbox noRing={true} checked={selected}/>
                                            </div>
                                            {index < filteredOptions.length - 1 && (
                                                <div className="h-0 border border-grey09"></div>
                                            )}
                                        </div>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    </Transition>
                    {showTags && (
                    <div className="flex items-center gap-6 mt-4">
                        <p className="font-vg-regular text-xs text-black">Selected Data:</p>
                        {selectedOptions.map((opt, index) => (
                            <DeletableTag
                                key={`${comboBoxId}-tag-${index}`}
                                label={opt.label}
                                value={index}
                                color={UiConstants.TagColor.BLACK_OUTLINE}
                                size="large"
                                onDeleteClick={(index) => handleDeleteClick(index)}
                            />
                        ))}
                    </div>
                    )}
                </div>
            )}
        </Combobox>
    );
});

export default MultiSelectComboBox;