import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
//import { Disclosure } from '@headlessui/react';

import * as UiConstants from '../constants/UiConstants';
import * as DashboardConstants from '../constants/DashboardConstants';

import DashboardElementStepButton from "./DashboardElementStepButton";
import RadioButtonGroup from "./RadioButtonGroup";

export default function DashboardElementTypeStep({ status, open, onStepAction }) {
   const { control, watch } = useFormContext();

   const toggleOpen = () => {
      if (onStepAction) {
         onStepAction({ action: DashboardConstants.ActionType.TOGGLE_STEP_TYPE });
      }
   }

   useEffect(() => {
      const subscription = watch((data, { name, type }) => {
         if (name === "elementType") {
               if (onStepAction) {
                  onStepAction({ 
                     action: DashboardConstants.ActionType.TYPE_STEP_COMPLETE,
                     args: { elementType: data.elementType }
                  });
               }
         }
      });
      return () => subscription.unsubscribe();
   }, [watch, onStepAction]);

   return (
      <div className="w-full flex flex-col items-stretch gap-4 px-6 py-4 rounded-lg bg-grey04">
         <DashboardElementStepButton
               open={open}
               title="Type of reporting element"
               number="1"
               status={status === DashboardConstants.StepStatus.COMPLETE ? status : (open === true ? DashboardConstants.StepStatus.ACTIVE : true)}
               onClick={toggleOpen}
         />
         <div className={open === true ? "block" : "hidden"}>
               <Controller
                  name="elementType"
                  control={control}
                  render={({ field, formState }) => (
                     <RadioButtonGroup
                           options={UiConstants.DASH_ELEMENT_TYPE_OPTIONS} 
                           className="grid grid-cols-3 gap-6"
                           buttonClassName="p-4 rounded-lg bg-white border border-grey03"
                           reversed={true} 
                           {...field}
                     />
                  )}
               />
         </div>
      </div>
   );
}
