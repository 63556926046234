import { useId, Fragment } from 'react';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import UiUtils from '../utils/UiUtils';

export default function LexicalDropdown({ options, selectedOption, className, listWidth, selectIcon, selectText, hideText, onChange }) {

   const selectId = useId();
   const hide = hideText ? hideText : false;
   const showText = !hide;

   function handleChange(selection) {
      if (onChange) {
         onChange(selection);
      }
   }

   let outerClass = "relative flex justify-between gap-2 items-center p-2 cursor-default rounded-lg2 hover:bg-grey09";
   outerClass = className ? `${className} ${outerClass}` : outerClass;

   let listClass = "absolute z-10 mt-1.5 max-h-60 overflow-auto font-vg-book text-sm leading-130 rounded-b-lg2 bg-white shadow-listbox-options focus:outline-none";
   listClass = listWidth ? `${listWidth} ${listClass}` : `w-full ${listClass}`;

   const optClass = "flex items-center gap-2 text-grey p-2 relative cursor-default select-none";
   const optDividerClass = "flex-col items-stretch text-grey relative cursor-default select-none";

   const iconClass = "w-4.5 h-4.5 bg-contain bg-center bg-no-repeat";

   return(
      <div>
         <Listbox value={selectedOption} onChange={handleChange}>
            {({ open }) => (
               <div>
                  <div className="relative">
                        <ListboxButton className={outerClass}>
                           {selectIcon || selectText ? (
                              <>
                                 {selectIcon && (
                                    <i className={`${selectIcon} ${iconClass}`}></i>
                                 )}
                                 {selectText && (
                                    <p className="font-vg-book text-grey text-sm leading-130">{selectText}</p>
                                 )}
                              </>
                           ) : (
                              <>
                                 {selectedOption && selectedOption.icon && (
                                    <i className={`${selectedOption.icon} ${iconClass}`}></i>
                                 )}
                                 {showText && (
                                    <p className="font-vg-book text-grey text-sm leading-130">{selectedOption && selectedOption.label ? selectedOption.label : ""}</p>
                                 )}
                              </>
                           )}
                           <ChevronDownIcon className="w-6 h-6 text-black" aria-hidden="true"/>
                        </ListboxButton>
                        <Transition
                           show={open}
                           as={Fragment}
                           leave="transition ease-in duration-100"
                           leaveFrom="opacity-100"
                           leaveTo="opacity-0"
                        >
                           <ListboxOptions className={listClass}>
                              {options.map((opt, index) => (
                                 <ListboxOption
                                    key={`${selectId}-option-${index}`}
                                    className={({ active }) => UiUtils.classNames(opt.divider ? optDividerClass : optClass, active ? "bg-grey09" : "bg-white")}
                                    value={opt}
                                 >
                                    {opt.divider ? (
                                       <>
                                          <div className="h-px bg-grey09 mx-2"></div>
                                          <div className="flex items-center gap-2 text-grey p-2">
                                             {opt.icon && opt.showIcon && (
                                                <i className={`${opt.icon} ${iconClass}`}></i>
                                             )}
                                             <span className="block text-sm">
                                                {opt.label}
                                             </span>
                                          </div>
                                       </>
                                    ) : (
                                       <>
                                          {opt.icon && opt.showIcon && (
                                             <i className={`${opt.icon} ${iconClass}`}></i>
                                          )}
                                          <span className="block text-sm">
                                             {opt.label}
                                          </span>
                                       </>
                                    )}
                                 </ListboxOption>
                              ))}
                           </ListboxOptions>
                        </Transition>
                  </div>
               </div>
            )}
         </Listbox>
      </div>
   );
}