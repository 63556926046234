import { forwardRef } from 'react';
import { Switch } from '@headlessui/react';

const LabelledSwitch = forwardRef((props, ref) => {
    const { label, checked, className, labelClass, onChange, ...otherProps } = props;

    const handleChange = (checked) => {
        if (onChange) {
            onChange(checked);
        }
    }
    return (
        <label className={className ? `flex items-center gap-2 ${className}` : "flex items-center gap-2"} {...otherProps}>
            <p className={labelClass ? `font-vg-book text-base text-black ${labelClass}` : "font-vg-book text-base text-black"}>{label}</p>
            <Switch 
                ref={ref}
                checked={checked}
                className="w-11 h-6 relative inline-flex items-center rounded-full ui-checked:bg-blue ui-not-checked:bg-grey02"
                onChange={handleChange}
            >
                <span
                    className={`${checked ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </label>
    );
});

export default LabelledSwitch;