import { useState, useEffect } from 'react';
//import ReCAPTCHA from 'react-google-recaptcha';
import { useWindowSize } from 'usehooks-ts';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Buffer } from 'buffer';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import ContactService from '../../services/ContactService';
import UiUtils from '../../utils/UiUtils';
import * as MatomoConstants from '../../constants/MatomoConstants';

import Header from '../../components/Header';
import TextInput from '../../components/TextInput';
import TextArea from '../../components/TextArea';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

export default function ContactPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const [displayedEmail, setDisplayedEmail] = useState('');

    const errorClass = 'font-vg-regular text-red text-base';
    const doneClass = 'font-vg-regular text-black text-base';
    const [statusClass, setStatusClass] = useState(doneClass);
    const [statusText, setStatusText] = useState('');

    const defaultButtonClass = "w-[210px]";
    const [buttonClass, setButtonClass] = useState(defaultButtonClass);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please enter an email address')
            .email('Please enter a valid email address'),
        query: Yup.string().required('Please enter a message')
    });
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            query: ""
        } 
    };

    const { 
        control, 
        handleSubmit
    } = useForm(formOptions);

    const onSubmit = (data) => {
        data.captcha = "";
        data.useCaptcha = false;

        setButtonClass("hidden");
        setStatusClass(doneClass);
        setStatusText("Processing...")

        ContactService.contact(data)
        .then(response => {
            setStatusText(response.data.message);
            setStatusClass(response.data.success ? doneClass : errorClass);
            setButtonClass(defaultButtonClass);
        })
        .catch(error => {
            console.log('error', error);
            const msg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred while sending your message. Please try again later.'
            setStatusText(msg);
            setStatusClass(errorClass);
            setButtonClass(defaultButtonClass);
        })
    };

    useEffect(() => {
        setDisplayedEmail(Buffer.from('PGEgaHJlZj0ibWFpbHRvOmFkbWluQHNlZWRraXQuY29tLmF1IiBjbGFzcz0ibm8tdW5kZXJsaW5lIj5hZG1pbkBzZWVka2l0LmNvbS5hdTwvYT4=', 'base64').toString('ascii'));
    }, []);

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageTitle.CONTACT_US
        });
    }, []);

    return(
        <>
        {isDesktop && (
            <div className="box-border w-full h-full px-16 py-6 flex flex-col items-stretch gap-16">
                <Header title="Contact Seedkit"/>
                <div className="grid grid-cols-12 gap-6 w-full border-box max-w-content mx-auto">
                    <form className="col-span-8 flex flex-col items-stretch gap-6" onSubmit={handleSubmit(onSubmit)}>
                        <h2 className="font-vg-medium text-2xl text-black">Contact or provide feedback on the platform.</h2>
                        <div className="grid grid-cols-2 gap-6">
                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput variant="default" label="First name" placeholder="Enter your first name" {...field}/>
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput variant="default" label="Last name" placeholder="Enter your last name" {...field}/>
                                )}
                            />
                        </div>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput 
                                    variant={formState.errors.email ? "error" : "default"}
                                    label="Email"
                                    placeholder="Enter your email address"
                                    help={formState.errors.email?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput variant="default" label="Phone" placeholder="Enter your phone number" {...field}/>
                            )}
                        />
                        <Controller
                            name="query"
                            control={control}
                            render={({ field, formState }) => (
                                <TextArea
                                    variant={formState.errors.query ? "error" : "default"}
                                    label="Message"
                                    placeholder="Write your message here"
                                    help={formState.errors.query?.message} 
                                    showHelp="always"
                                    rows="5"
                                    {...field}
                                />
                            )}
                        />
                        <div className="flex items-center gap-6 min-h-[52px]">
                            <Button type="submit" variant="solid" size="large" label="Submit" className={buttonClass}/>
                            <p className={statusClass} aria-live="polite" dangerouslySetInnerHTML={{__html: statusText}}></p>
                        </div>
                    </form>
                    <div className="col-span-4 flex flex-col items-start gap-8 px-8 py-12 h-full rounded-3xl bg-grey04 bg-contact-card bg-right-bottom bg-contain bg-no-repeat">
                        <div className="flex flex-col items-start gap-4">
                            <h5 className="font-vg-medium text-black text-2xl">Contact information</h5>
                            <p className="font-vg-book text-black text-base leading-130">Send the Seedkit team a message via our contact form or email Seedkit directly via</p>
                        </div>
                        <div className="flex flex-col items-start gap-6">
                            <div className="flex gap-3 items-center">
                                <EnvelopeIcon className="w-6"/>
                                <p className="font-vg-regular text-xl text-black no-underline" dangerouslySetInnerHTML={{__html: displayedEmail}}></p>
                            </div>
                            <p className="font-vg-book text-black text-base leading-130">Join the Seedkit LinkedIn community</p>
                            <div className="flex gap-3 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="text-black w-6">
                                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                                </svg>
                                <a href="https://www.linkedin.com/company/seedkit-for-social-enterprise/" className="font-vg-regular text-xl text-black no-underline leading-110" target="_blank" rel="noopener noreferrer">Seedkit for Social Enterprise</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full px-5 py-[34px] flex flex-col items-stretch gap-12">
                <Header title="Contact Seedkit"/>
                <div className="flex flex-col items-stretch gap-6">
                    <form className="flex flex-col items-stretch gap-6" onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput variant="default" label="First name" placeholder="Enter your first name" {...field}/>
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput variant="default" label="Last name" placeholder="Enter your last name" {...field}/>
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput 
                                    variant={formState.errors.email ? "error" : "default"}
                                    label="Email"
                                    placeholder="Enter your email address"
                                    help={formState.errors.email?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput variant="default" label="Phone" placeholder="Enter your phone number" {...field}/>
                            )}
                        />
                        <Controller
                            name="query"
                            control={control}
                            render={({ field, formState }) => (
                                <TextArea
                                    variant={formState.errors.query ? "error" : "default"}
                                    label="Message"
                                    placeholder="Write your message here"
                                    help={formState.errors.query?.message} 
                                    showHelp="always"
                                    rows="5"
                                    {...field}
                                />
                            )}
                        />
                        <div className="flex items-center gap-6 min-h-[52px]">
                            <Button type="submit" variant="solid" size="large" label="Submit" className={buttonClass}/>
                            <p className={statusClass} aria-live="polite" dangerouslySetInnerHTML={{__html: statusText}}></p>
                        </div>
                    </form>
                    <div className="box-border h-[580px] flex flex-col items-stretch gap-8 py-12 px-6 rounded-3xl bg-grey03 bg-contact-card-mobile bg-right-bottom bg-contain bg-no-repeat">
                        <div className="flex flex-col items-start gap-4">
                            <h5 className="font-vg-medium text-black text-2xl">Contact information</h5>
                            <p className="font-vg-book text-black text-base leading-130">Send the Seedkit team a message via our contact form or email Seedkit directly via</p>
                        </div>
                        <div className="flex flex-col items-start gap-6">
                            <div className="flex gap-3 items-center">
                                <EnvelopeIcon className="w-6"/>
                                <p className="font-vg-regular text-xl text-black no-underline" dangerouslySetInnerHTML={{__html: displayedEmail}}></p>
                            </div>
                            <div className="flex gap-3 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="text-black w-6">
                                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                                </svg>
                                <a href="https://www.linkedin.com/company/seedkit-for-social-enterprise/" className="font-vg-regular text-xl text-black no-underline" target="_blank" rel="noopener noreferrer">Join the Seedkit LinkedIn community</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            )}
            </>
    );
}
