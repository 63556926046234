const EntityType = {
    COLLABORATION: "collaboration",
    ORGANISATION: "organisation",
    SOCIAL_ENTERPRISE: "social enterprise",
    ENTERPRISE: "enterprise",
    PROGRAM: "program",
    PROJECT: "project",
    EVENT: "event",
    NONE: ""
};

const ALL_ENTITY_TYPES = [
    EntityType.COLLABORATION,
    EntityType.ORGANISATION,
    EntityType.SOCIAL_ENTERPRISE,
    EntityType.ENTERPRISE,
    EntityType.PROGRAM,
    EntityType.PROJECT,
    EntityType.EVENT,
    EntityType.NONE
];

exports.EntityType = EntityType;
exports.ALL_ENTITY_TYPES = ALL_ENTITY_TYPES;

const EntityStatus = {
    ACTIVE: "active",
    ARCHIVED: "archived"
};

const ALL_ENTITY_STATUSES = [
    EntityStatus.ACTIVE,
    EntityStatus.ARCHIVED,
];

exports.EntityStatus = EntityStatus;
exports.ALL_ENTITY_STATUSES = ALL_ENTITY_STATUSES;
