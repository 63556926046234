import { hex, hsl } from "color-convert";

import * as DataConstants from "../constants/DataConstants";
import * as DashboardConstants from "../constants/DashboardConstants";
import * as UiConstants from "../constants/UiConstants";
import * as EntityConstants from "../constants/EntityConstants";

let DashboardUtils = {};

const getEntityType = (context, entity) => {
    let entityType = EntityConstants.EntityType.NONE;
    if (context.organisation) {
        let index = -1;
        if (context.organisation.enterprises) {
            index = context.organisation.enterprises.map(e => e._id).indexOf(entity);
            if (index > -1) {
                entityType = EntityConstants.EntityType.ENTERPRISE;
            }
        }
        if (index === -1 && context.organisation.programs) {
            index = context.organisation.programs.map(p => p._id).indexOf(entity);
            if (index > -1) {
                entityType = EntityConstants.EntityType.PROGRAM;
            }
        }
        if (index === -1 && context.organisation.projects) {
            index = context.organisation.projects.map(p => p._id).indexOf(entity);
            if (index > -1) {
                entityType = EntityConstants.EntityType.PROJECT;
            }
        }
    }
    return entityType;
}

const getDataIntervals = (entity, key) => {
    let intervals = [];
    entity.indicators.forEach(ind => {
        if (ind.requiredData.indexOf(key) > -1 && intervals.indexOf(ind.frequency) === -1) {
            intervals.push(ind.frequency);
        }
    });
    return intervals;
}

const getEntityDataOptions = (type, entities, hasDataOnly) => {
    let opts = [];
    let capitalisedType = type.charAt(0).toUpperCase() + type.slice(1);

    entities.forEach(e => {
        if (e.requiredData) {
            e.requiredData.forEach(rd => {
                const dataInclude = hasDataOnly === false ? true : (e.data.filter(d => d.key === rd.key).length > 0);
                const typeInclude = (rd.requiredDataType === DataConstants.RequiredDataType.MANUAL || rd.requiredDataType === DataConstants.RequiredDataType.CALCULATED);
                if (dataInclude && typeInclude) {
                    const intervals = getDataIntervals(e, rd.key);
                    intervals.forEach(interval => {
                        const value = `${type}|${e._id}|${rd.key}|${interval}`;
                        const label = `${e.name} - ${rd.title}`;
                        const html = `<div class="flex flex-col text-grey items-stretch gap-4"><p class="font-vg-regular text-base">${rd.title}</p><div class="flex gap-4 font-vg-book text-xs2"><p>Type: ${capitalisedType}</p><p>Unit: ${e.name}</p></div><p class="font-vg-book text-xs2">Interval: ${interval}</p></div>`;
                        opts.push({
                            value: value,
                            label: label,
                            html: html,
                            format: rd.format,
                            interval: interval
                        });
                    }); 
                }
            });
        }
    });
    return opts;
}

const getRequiredDataOptions = (context, hasDataOnly) => {
    let dataOptions = [];
    if (context.organisation) {
        if (context.organisation.enterprises) {
            let enterprises = context.organisation.enterprises.filter(e => e.status ? e.status !== EntityConstants.EntityStatus.ARCHIVED : true);
            dataOptions = dataOptions.concat(getEntityDataOptions("enterprise", enterprises, hasDataOnly));
        }
        if (context.organisation.programs) {
            let programs = context.organisation.programs.filter(e => e.status ? e.status !== EntityConstants.EntityStatus.ARCHIVED : true);
            dataOptions = dataOptions.concat(getEntityDataOptions("program", programs, hasDataOnly));
        }
        if (context.organisation.projects) {
            let projects = context.organisation.projects.filter(e => e.status ? e.status !== EntityConstants.EntityStatus.ARCHIVED : true);
            dataOptions = dataOptions.concat(getEntityDataOptions("project", projects, hasDataOnly));
        }
    }
    return dataOptions;
};

const updateDatasetDetails = (elementType, elementSubType, options, details) => {
    // NB: Use Line as default displayType for Graphs & get default color
    let newDetails = JSON.parse(JSON.stringify(details));
    options.forEach(opt => {
        if (newDetails.map(nd => nd.entityType + "|" + nd.entity + "|" + nd.dataKey + "|" + nd.intervalType).indexOf(opt.value) === -1) {
            const valueSplit = opt.value.split("|");
            let displayType = DashboardConstants.DatasetGraphType.NONE;
            let color = "";
            if (elementType === DashboardConstants.ElementType.GRAPH) {
                displayType = elementSubType === DashboardConstants.ElementSubType.PIE_CHART ? DashboardConstants.DatasetGraphType.PIE : DashboardConstants.DatasetGraphType.LINE;
                const colorIndex = (newDetails.length % (UiConstants.DASH_COLOR_OPTIONS.length - 1)) + 1;
                color = UiConstants.DASH_COLOR_OPTIONS[colorIndex].value;
            }
            newDetails.push({
                value: opt.value,
                entityType: valueSplit[0],
                entity: valueSplit[1],
                dataKey: valueSplit[2],
                format: opt.format,
                legend: opt.label,
                intervalType: opt.interval,
                displayType: displayType,
                color: color,
                showDataLabels: false,
                isSecondaryY: false
            });
        }
    });
    return newDetails
}

const datasetsToDetails = (context, datasets) => {
    return datasets.map(ds => {
        const entityType = ds.entityType && ds.entityType !== "" ? ds.entityType : getEntityType(context, ds.entity);
        const value = `${entityType}|${ds.entity}|${ds.dataKey}|${ds.intervalType}`;
        return {
            value: value,
            entityType: entityType,
            entity: ds.entity,
            dataKey: ds.dataKey,
            format: ds.format,
            legend: ds.legend,
            intervalType: ds.intervalType,
            displayType: ds.displayType,
            color: ds.color,
            showDataLabels: ds.showDataLabels,
            isSecondaryY: ds.isSecondaryY
        };
    });
}

const getElementColors = (type, element) => {
    if (element.datasets && element.datasets.length > 0 && element.datasets[element.datasets.length - 1].color) {
        let hexColor = element.datasets[element.datasets.length - 1].color.replace("#","").toUpperCase();
        let hslArray = hex.hsl(hexColor);

        let hslBackColor = JSON.parse(JSON.stringify(hslArray));
        hslBackColor[2] = DashboardConstants.BACK_LIGHTNESS;
        let backColor = `#${hsl.hex(hslBackColor)}`;

        let hslForeColor = JSON.parse(JSON.stringify(hslArray));
        hslForeColor[2] = DashboardConstants.FORE_LIGHTNESS;
        let foreColor = `#${hsl.hex(hslForeColor)}`;

        return {
            key: type.key,
            color: foreColor,
            backgroundColor: backColor
        }
    } else {
        return {
            key: type.key,
            color: type.color,
            backgroundColor: type.backgroundColor
        };
    }
}

const getThumbTypeColor = (element) => {
    switch (element.elementType) {
        case DashboardConstants.ElementType.TEXT:
            return { 
                key: DashboardConstants.ThumbType.TEXT.key,
                color: DashboardConstants.ThumbType.TEXT.color,
                backgroundColor: DashboardConstants.ThumbType.TEXT.backgroundColor
            };

        case DashboardConstants.ElementType.TABLE:
            return {
                key: DashboardConstants.ThumbType.TABLE.key,
                color: DashboardConstants.ThumbType.TABLE.color,
                backgroundColor: DashboardConstants.ThumbType.TABLE.backgroundColor
            };

        case DashboardConstants.ElementType.GRAPH:
            if (element.elementSubType === DashboardConstants.ElementSubType.PIE_CHART) {
                return getElementColors(DashboardConstants.ThumbType.PIE, element);
            } else {
                if (element.datasets.map(ds => ds.displayType).indexOf(DashboardConstants.DatasetGraphType.BAR) > -1) {
                    return getElementColors(DashboardConstants.ThumbType.BAR, element);
                } else {
                    return getElementColors(DashboardConstants.ThumbType.LINE, element);
                }
            }
            
        default:
            return {
                key: DashboardConstants.ThumbType.NONE.key,
                color: DashboardConstants.ThumbType.NONE.color,
                backgroundColor: DashboardConstants.ThumbType.NONE.backgroundColor
        };
    }
}

const getThumbIcon = (type, thumbClass) => {
    switch (type.key) {
        case DashboardConstants.ThumbType.BAR.key:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={thumbClass} fill={type.color}>
                    <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/>
                </svg>
            );
        case DashboardConstants.ThumbType.LINE.key:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={thumbClass} fill={type.color}>
                    <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/>
                </svg>            
            );

        case DashboardConstants.ThumbType.PIE.key:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={thumbClass} fill={type.color}>
                    <path d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z"/>
                </svg>            
            );

        case DashboardConstants.ThumbType.TEXT.key:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className={thumbClass} fill={type.color}>
                    <path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5V78.6c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8V454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5V83.8c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11V456c0 11.4 11.7 19.3 22.4 15.5z"/>
                </svg>            
            );

        case DashboardConstants.ThumbType.TABLE.key:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={thumbClass} fill={type.color}>
                    <path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
                </svg>            
            );

        case DashboardConstants.ThumbType.QUESTION.key:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={thumbClass} fill={type.color}>
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                </svg>            
            );

        default:
            return (<></>);
    }
}

DashboardUtils.getRequiredDataOptions = getRequiredDataOptions;
DashboardUtils.updateDatasetDetails = updateDatasetDetails;
DashboardUtils.datasetsToDetails = datasetsToDetails;
DashboardUtils.getThumbTypeColor = getThumbTypeColor;
DashboardUtils.getThumbIcon = getThumbIcon;

export default DashboardUtils;