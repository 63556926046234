import { useWindowSize } from 'usehooks-ts';

import NavBar from './NavBar';
import UiUtils from '../utils/UiUtils';
import * as GeneralConstants from '../constants/GeneralConstants';

export default function Header({ title, pageTitle, pageDescription, className }) {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let headTitle = pageTitle ? `${GeneralConstants.DEFAULT_PAGE_TITLE} | ${pageTitle}` : (title ? `${GeneralConstants.DEFAULT_PAGE_TITLE} | ${title}` : GeneralConstants.DEFAULT_PAGE_TITLE);
    headTitle = headTitle.replace("<br/>", " ");
    const headDescription = pageDescription ? pageDescription : GeneralConstants.DEFAULT_PAGE_DESCRIPTION;

    let outerClass = "box-border min-h-[242px] flex flex-col gap-8 rounded-3xl bg-header bg-right-bottom bg-cover bg-light-green01 bg-no-repeat";
    outerClass = isDesktop ? `${outerClass} p-8` : `${outerClass} px-4 py-6`;
    if (className) {
        outerClass = `${outerClass} ${className}`;
    }

    return (
        <>
            {isDesktop && (
            <div className={outerClass}>
                <NavBar title={headTitle} description={headDescription}/>
                <div className="box-border w-full min-h-[82px] pt-3 pl-6 max-w-content-narrow mx-auto">
                    <h1 className="font-vg-medium text-black text-[64px] leading-110 m-0 p-0" dangerouslySetInnerHTML={{__html: title}}></h1>
                </div>
            </div>
            )}
            {isMobile && (
            <div className={outerClass}>
                <NavBar title={headTitle} description={headDescription}/>
                <div className="min-h-[114px]">
                    <h1 className="font-vg-medium text-black text-[52px] leading-110 m-0 p-0" dangerouslySetInnerHTML={{__html: title}}></h1>
                </div>
            </div>
            )}
        </>
    );
}