import { useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import SubscribeService from '../services/SubscribeService';
import UiUtils from '../utils/UiUtils';

import TextInput from './TextInput';
import Button from './Button';

export default function SubscribeForm() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const dataText = 'Your data is in safe hands.<br/>Read the <a href="/privacy">Privacy policy</a>';
    const [statusText, setStatusText] = useState(dataText);

    const dataClass = 'font-vg-book text-black text-xs2';
    const errorClass = 'font-vg-regular text-red text-base';
    const doneClass = 'font-vg-regular text-black text-base';
    const [statusClass, setStatusClass] = useState(dataClass);

    const [buttonClass, setButtonClass] = useState('');

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please enter an email address')
            .email('Please enter a valid email address')
    });
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            organisation: ""
        } 
    };

    const { 
        control, 
        handleSubmit
    } = useForm(formOptions);

    const onSubmit = (data) => {
        data.captcha = "";
        data.useCaptcha = false;

        setButtonClass('hidden');
        setStatusText('Processing...');
        setStatusClass(doneClass);

        SubscribeService.subscribe(data)
        .then(response => {
            setButtonClass('');
            setStatusText(response.data.message);
            setStatusClass(response.data.success ? doneClass : errorClass);
        })
        .catch(error => {
            console.log('error', error);
            const msg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred while saving your details. Please try again later.'
            setStatusText(msg);
            setStatusClass(errorClass);
        })
    };

    return (
        <>
            {isDesktop && (
                <div className="flex items-stretch w-full p-12 gap-[135px] rounded-3xl bg-green03 bg-subscribe-form bg-right-bottom bg-contain bg-no-repeat box-border max-w-content-narrow mx-auto">
                    <div className="flex flex-col items-start gap-4 w-[373px]">
                        <h4 className="font-vg-medium text-black text-[32px] leading-110">
                            Keep in touch with Seedkit developments
                        </h4>
                        <p className="font-vg-book text-black text-base leading-130">
                            If you would like to be notified of Seedkit's developments and events, please fill out the form.   
                        </p>
                    </div>
                    <form className="flex flex-col items-stretch gap-6 grow" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col items-stretch gap-3">
                            <div className="grid grid-cols-2 gap-3">
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="First name" {...field}/>
                                    )}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="Last name" {...field}/>
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-3">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput 
                                            variant={formState.errors.email ? "error" : "default"}
                                            label="Email address"
                                            help={formState.errors.email?.message} 
                                            showHelp="always" 
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="organisation"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="Organisation name" showHelp="always" {...field}/>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex gap-6 items-center min-h-[52px]">
                            <Button variant="solid" size="large" label="SUBSCRIBE" type="submit" className={buttonClass}/>
                            <p className={statusClass} aria-live="polite" dangerouslySetInnerHTML={{__html: statusText}}></p>
                        </div>
                    </form>
                </div>
            )}
            {isMobile && (
                <div className="box-border flex items-stretch px-6 py-12 rounded-3xl bg-green03 bg-subscribe-form bg-right-bottom bg-contain bg-no-repeat mx-5">
                    <div className="flex flex-col items-stretch gap-6">
                        <div className="flex flex-col items-stretch gap-4 text-black">
                            <h4 className="font-vg-medium text-[28px]">Keep in touch with Seedkit developments</h4>
                            <p className="font-vg-book text-base">
                                If you would like to be notified of Seedkit's developments and events, please fill out the form.  
                            </p>
                        </div>
                        <form className="flex flex-col items-stretch gap-6" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col items-stretch gap-3">
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="First name" {...field}/>
                                    )}
                                />
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="Last name" {...field}/>
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput 
                                            variant={formState.errors.email ? "error" : "default"}
                                            label="Email address"
                                            help={formState.errors.email?.message} 
                                            showHelp="always" 
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="organisation"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput label="Organisation name" {...field}/>
                                    )}
                                />
                            </div>
                            <Button variant="solid" size="large" label="SUBSCRIBE" type="submit"/>
                            <p className={statusClass} dangerouslySetInnerHTML={{__html: statusText}}></p>
                       </form>
                    </div>
                </div>
            )}
        </>
    );
}