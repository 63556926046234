import { useEffect, useContext, useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
//import { Disclosure } from '@headlessui/react'

import DashboardUtils from '../utils/DashboardUtils';
import * as DashboardConstants from '../constants/DashboardConstants';
import * as UiConstants from '../constants/UiConstants';
import { GlobalContext } from '../context/GlobalContext';

import DashboardElementStepButton from './DashboardElementStepButton';
import MultiSelectComboBox from './MultiSelectComboBox';
import LabelledSwitch from './LabelledSwitch';
import TextInput from './TextInput';
import SelectDropdown from './SelectDropdown';
import DeletableTag from './DeletableTag';
import Checkbox from './Checkbox';

export default function DashboardElementSourceStep({ visible, status, open, elementType, elementSubType, onStepAction }) {
    const { context } = useContext(GlobalContext);
    const { control, setValue, getValues, watch } = useFormContext();

    const [hasDataOnly, setHasDataOnly] = useState(true);

    const toggleOpen = () => {
        if (onStepAction) {
            onStepAction({ action: DashboardConstants.ActionType.TOGGLE_STEP_SOURCE });
        }
    }

    const datasetsUpdated = useCallback((datasets) => {
        if (onStepAction) {
            onStepAction({
                action: DashboardConstants.ActionType.GRAPH_DATA_UPDATED,
                args: { datasets: datasets }
            });
        }
    },[onStepAction]);

    const datasetDeleteClicked = (value) => {
        let datasetOpts = JSON.parse(JSON.stringify(getValues("datasetOptions")));
        let optindex = datasetOpts.map(d => d.value).indexOf(value);
        if (optindex > -1) {
            datasetOpts.splice(optindex, 1);
            setValue("datasetOptions", datasetOpts);
        }
        let datasets = JSON.parse(JSON.stringify(getValues("datasetDetails")));
        let dsindex = datasets.map(ds => ds.value).indexOf(value);
        if (dsindex > -1) {
            datasets.splice(dsindex, 1);
            setValue("datasetDetails", datasets);
            datasetsUpdated(datasets);
        }

    }

    const onHasDataOnlyChange = (e) => {
        setValue("datasetOptions", []);
        setValue("datasetDetails", []);
        datasetsUpdated([]);
        setHasDataOnly(!hasDataOnly)
    }

    const onSecondaryYChanged = useCallback((value) => {
        if (onStepAction) {
            onStepAction({
                action: DashboardConstants.ActionType.SECONDARY_Y_UPDATED,
                args: { secondaryY: value }
            });
        }
    }, [onStepAction]);

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            //TODO: Complete validation 
            if (name === "datasetOptions") {
                let datasets = DashboardUtils.updateDatasetDetails(
                    data.elementType, 
                    data.elementSubTypeOption.value, 
                    data.datasetOptions, 
                    data.datasetDetails
                );
                setValue("datasetDetails", datasets);
                datasetsUpdated(datasets);

            } else if (name === "hasSecondaryY") {
                onSecondaryYChanged(data.hasSecondaryY);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, datasetsUpdated, onSecondaryYChanged, setValue]);

    return (
        <>
        {visible === true && (
            <div className="w-full flex flex-col items-stretch gap-4 px-6 py-4 rounded-lg bg-grey04">
                <DashboardElementStepButton
                    open={open}
                    title="Data to visualise"
                    number="3"
                    status={status === DashboardConstants.StepStatus.COMPLETE ? status : (open === true ? DashboardConstants.StepStatus.ACTIVE : true)}
                    onClick={toggleOpen}
                />
                <div className={open === true ? "flex flex-col items-stretch gap-8" : "hidden"}>
                    <Controller
                        name="datasetOptions"
                        control={control}
                        render={({ field, formState }) => (
                            <div className="flex flex-col items-stretch gap-4">
                                <MultiSelectComboBox
                                    options={DashboardUtils.getRequiredDataOptions(context, hasDataOnly)}
                                    selectedOptions={field.value}
                                    template="{COUNT} Datasets Selected"
                                    placeholder="Type here to search for datasets..."
                                    {...field}
                                />
                                <Checkbox
                                    label="Only show datasets with saved data"
                                    checked={hasDataOnly}
                                    onChange={onHasDataOnlyChange}
                                />
                                {field.value.length > 0 && (
                                    <div className="flex flex-col items-strech gap-2">
                                        <p className="font-vg-book text-xs2 text-black">Current selected data:</p>
                                        {field.value.map((v, index) => (
                                            <DeletableTag
                                                key={`dset-opt-tag-${index}`}
                                                size="large"
                                                label={`${v.label} - ${v.interval}`}
                                                value={v.value}
                                                color={UiConstants.TagColor.BLACK_OUTLINE}
                                                className="w-full"
                                                onDeleteClick={datasetDeleteClicked}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <div className="h-0 border border-grey02"></div>
                    {elementType === DashboardConstants.ElementType.GRAPH && (
                    <div className="flex flex-col items-stretch gap-6">
                        <h6 className="font-vg-regular text-xl text-black">Legend / Axis / Data Details</h6>
                        <div className="flex flex-col items-stretch gap-10">
                            <div className="w-full flex gap-10 min-h-[50px]">
                                <Controller
                                    name="showLegend"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Show legend label/s"
                                            checked={field.value}
                                            className="min-h-[50px]"
                                            labelClass="w-44"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="showDataLabels"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Show data point labels on chart"
                                            checked={field.value}
                                            className="min-h-[50px]"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full flex gap-10 min-h-[50px]">
                                <Controller
                                    name="showXLabel"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Show X axis label"
                                            checked={field.value}
                                            labelClass="w-44"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="xLabel"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            placeholder="X axis label"
                                            variant="default"
                                            className="grow"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full flex gap-10 min-h-[50px]">
                                <Controller
                                    name="showYLabel"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Show Y axis label"
                                            checked={field.value}
                                            labelClass="w-44"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="yLabel"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            placeholder="Y axis label"
                                            variant="default"
                                            className="grow"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full flex gap-10 min-h-[50px]">
                                <Controller
                                    name="hasSecondaryY"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Add secondary Y axis"
                                            checked={field.value}
                                            labelClass="w-44"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="secondaryYLabel"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <TextInput
                                            placeholder="Secondary Y axis label"
                                            variant="default"
                                            className="grow"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    )}
                    {elementType === DashboardConstants.ElementType.TABLE && (
                    <div className="flex flex-col items-stretch gap-6">
                        <h6 className="font-vg-regular text-xl text-black">
                            {`${elementSubType === DashboardConstants.ElementSubType.GROUPED_INDICATOR_TABLE ? "Grouped" : "Simple"} Indicator Table Details`}
                        </h6>
                        <div className="flex flex-col items-stretch gap-10">
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="intervalTypeOption"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <SelectDropdown
                                            label="Interval Type"
                                            options={UiConstants.DASH_INTERVAL_OPTIONS}
                                            selectedOption={field.value}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <Controller
                                    name="showFYTotals"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Show FY totals column"
                                            checked={field.value}
                                            labelClass="w-52"
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="showFullRangeTotals"
                                    control={control}
                                    render={({ field, formState }) => (
                                        <LabelledSwitch
                                            label="Show full date range totals column"
                                            checked={field.value}
                                            labelClass="mr-4"
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="col-span-2">
                                    <Controller
                                        name="showIndicatorTotals"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <LabelledSwitch
                                                label="Show indicator totals row"
                                                checked={field.value}
                                                labelClass="w-52"
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                {elementSubType === DashboardConstants.ElementSubType.GROUPED_INDICATOR_TABLE && (
                                <>
                                    <Controller
                                        name="groupByOutcomeArea"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <LabelledSwitch
                                                label="Group by outcome areas"
                                                checked={field.value}
                                                labelClass="w-52"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="includeOutcomeAreaSubtotals"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <LabelledSwitch
                                                label="Include indicator outcome subtotal rows"
                                                checked={field.value}
                                                labelClass="mr-4"
                                                {...field}
                                            />
                                        )}
                                    />
                                </>
                                )}
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        )}
        </>
    );
}
