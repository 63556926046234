import { forwardRef, useId } from 'react';

import TextInput from "./TextInput";
import Button from "./Button";

const OtherItemsInput = forwardRef((props, ref) => {
    const { value, label, addLabel, disabled, onChange, onBlur } = props;

    const id = useId();
    let items = value ? JSON.parse(value) : [""];
    if (items.length === 0) {
        items.push("");
    }

    const onItemChange = (e, index) => {
        items[index] = e.target.value;
        if (onChange) {
            onChange(JSON.stringify(items));
        }
    }

    const onItemBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    }

    const onItemDelete = (e, index) => {
        e.preventDefault();
        items.splice(index, 1);
        if (onChange) {
            onChange(JSON.stringify(items));
        }
    }

    const addInput = (e) => {
        e.preventDefault();
        items.push("");
        if (onChange) {
            onChange(JSON.stringify(items));
        }
    }

    return (
        <div className="flex flex-col items-stretch">
            {label && (
                <label className="block font-vg-book text-base leading-120 text-grey mb-1.5">
                    {label}
                </label>
            )}
            <input
                ref={ref}
                type="hidden"
                value={value}
            />
            <div className="flex flex-col items-stretch gap-2">
                {items.map((item, index) => (
                    <div key={`other-${id}-${index}`} className="flex justify-between items-center gap-2">
                        <TextInput
                            variant={disabled ? "disabled" : "default"}
                            className="grow"
                            value={item}
                            onChange={(e) => onItemChange(e, index)}
                            onBlur={onItemBlur}
                        />
                        {index > 0 && (
                            <Button
                                variant={disabled ? "disabled" : "delete"}
                                className="grow-0"
                                size="large"
                                leftIcon="XIcon"
                                onClick={(e) => onItemDelete(e, index)}
                            />
                        )}
                    </div>
                ))}
                <Button
                    variant={disabled ? "disabled" : "outline"}
                    size="large"
                    leftIcon="PlusIcon"
                    label={addLabel}
                    onClick={addInput}
                />
            </div>
        </div>
    );
});

export default OtherItemsInput;