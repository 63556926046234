export const ActionType = {
   NONE: "",
   COMMAND: "command",
   PARAGRAPH: "paragraph",
   HEADING: "heading",
   QUOTE: "quote",
   UNORDERED_LIST: "unorderedList",
   ORDERED_LIST: "orderedList",
   CLEAR: "clear",
   IMAGE: "image",
   INLINE_IMAGE: "inlineImage",
   TABLE: "table",
   LAYOUT: "layout"
};

export const Command = {
   INSERT_CHECK_LIST: "INSERT_CHECK_LIST",
   INSERT_ORDERED_LIST: "INSERT_ORDERED_LIST",
   INSERT_UNORDERED_LIST: "INSERT_UNORDERED_LIST",
   FORMAT_ELEMENT: "FORMAT_ELEMENT",
   FORMAT_TEXT: "FORMAT_TEXT",
   INDENT_CONTENT: "INDENT_CONTENT",
   OUTDENT_CONTENT: "OUTDENT_CONTENT",
   INSERT_HORIZONTAL_RULE: "INSERT_HORIZONTAL_RULE",
   INSERT_EMBED: "INSERT_EMBED"
}

export const EMPTY_OPTION = {
   value: "",
   icon: "",
   label: "",
   action: ActionType.NONE,
   command: null,
   payload: null,
   divider: false,
   setSelected: false,
   showIcon: false
}

export const BlockFormatOption = {
   PARAGRAPH: {
      value: "paragraph",
      icon: "paragraph",
      label: "Normal",
      action: ActionType.PARAGRAPH,
      command: null,
      payload: null,
      divider: false,
      setSelected: true,
      showIcon: true
   },
   H1: {
      value: "h1",
      icon: "h1",
      label: "Heading 1",
      action: ActionType.HEADING,
      command: null,
      payload: "h1",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   H2: {
      value: "h2",
      icon: "h2",
      label: "Heading 2",
      action: ActionType.HEADING,
      command: null,
      payload: "h2",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   H3: {
      value: "h3",
      icon: "h3",
      label: "Heading 3",
      action: ActionType.HEADING,
      command: null,
      payload: "h3",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   H4: {
      value: "h4",
      icon: "h4",
      label: "Heading 4",
      action: ActionType.HEADING,
      command: null,
      payload: "h4",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   H5: {
      value: "h5",
      icon: "h5",
      label: "Heading 5",
      action: ActionType.HEADING,
      command: null,
      payload: "h5",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   H6: {
      value: "h6",
      icon: "h6",
      label: "Heading 6",
      action: ActionType.HEADING,
      command: null,
      payload: "h6",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   BULLET_LIST: {
      value: "bullet",
      icon: "bullet-list",
      label: "Bullet List",
      action: ActionType.COMMAND,
      command: Command.INSERT_UNORDERED_LIST,
      payload: null,
      divider: false,
      setSelected: true,
      showIcon: true
   },
   NUMBERED_LIST: {
      value: "number",
      icon: "numbered-list",
      label: "Numbered List",
      action: ActionType.COMMAND,
      command: Command.INSERT_ORDERED_LIST,
      payload: null,
      divider: false,
      setSelected: true,
      showIcon: true
   },
   CHECK_LIST: {
      value: "check",
      icon: "check-list",
      label: "Check List",
      action: ActionType.COMMAND,
      command: Command.INSERT_CHECK_LIST,
      payload: null,
      divider: false,
      setSelected: true,
      showIcon: true
   },
   QUOTE: {
      value: "quote",
      icon: "quote",
      label: "Quote",
      action: ActionType.QUOTE,
      command: null,
      payload: null,
      divider: false,
      setSelected: true,
      showIcon: true
   }
};

export const BLOCK_FORMAT_OPTIONS = [
   BlockFormatOption.PARAGRAPH,
   BlockFormatOption.H1,
   BlockFormatOption.H2,
   BlockFormatOption.H3,
   BlockFormatOption.BULLET_LIST,
   BlockFormatOption.NUMBERED_LIST,
   BlockFormatOption.CHECK_LIST,
   BlockFormatOption.QUOTE
];

export const BlockFormatByType = {
   paragraph: BlockFormatOption.PARAGRAPH,
   h1: BlockFormatOption.H1,
   h2: BlockFormatOption.H2,
   h3: BlockFormatOption.H3,
   h4: BlockFormatOption.H4,
   h5: BlockFormatOption.H5,
   h6: BlockFormatOption.H6,
   bullet: BlockFormatOption.BULLET_LIST,
   number: BlockFormatOption.NUMBERED_LIST,
   check: BlockFormatOption.CHECK_LIST,
   quote: BlockFormatOption.QUOTE
};

export const AlignmentOption = {
   LEFT: { 
      value: "left-align",
      icon: "left-align",
      label: "Left Align",
      action: ActionType.COMMAND,
      command: Command.FORMAT_ELEMENT,
      payload: "left",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   CENTER: { 
      value: "center-align",
      icon: "center-align",
      label: "Centre Align",
      action: ActionType.COMMAND,
      command: Command.FORMAT_ELEMENT,
      payload: "center",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   RIGHT: { 
      value: "right-align",
      icon: "right-align",
      label: "Right Align",
      action: ActionType.COMMAND,
      command: Command.FORMAT_ELEMENT,
      payload: "right",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   JUSTIFY: { 
      value: "justify-align",
      icon: "justify-align",
      label: "Justify Align",
      action: ActionType.COMMAND,
      command: Command.FORMAT_ELEMENT,
      payload: "justify",
      divider: false,
      setSelected: true,
      showIcon: true
   },
   INDENT: { 
      value: "indent",
      icon: "indent",
      label: "Indent",
      action: ActionType.COMMAND,
      command: Command.INDENT_CONTENT,
      payload: null,
      divider: true,
      setSelected: false,
      showIcon: true
   },
   OUTDENT: { 
      value: "outdent",
      icon: "outdent",
      label: "Outdent",
      action: ActionType.COMMAND,
      command: Command.OUTDENT_CONTENT,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   }
};

export const ALIGNMENT_OPTIONS = [
   AlignmentOption.LEFT,
   AlignmentOption.CENTER,
   AlignmentOption.RIGHT,
   AlignmentOption.JUSTIFY,
   AlignmentOption.INDENT,
   AlignmentOption.OUTDENT
];

export const AlignmentByFormat = {
   left: AlignmentOption.LEFT,
   center: AlignmentOption.CENTER,
   right: AlignmentOption.RIGHT,
   justify: AlignmentOption.JUSTIFY
};

export const OtherFormatOption = {
   STRIKETHROUGH: {
      value: "strikethrough",
      icon: "strikethrough",
      label: "Strikethrough",
      action: ActionType.COMMAND,
      command: Command.FORMAT_TEXT,
      payload: "strikethrough",
      divider: false,
      setSelected: false,
      showIcon: true
   },
   SUBSCRIPT: {
      value: "subscript",
      icon: "subscript",
      label: "Subscript",
      action: ActionType.COMMAND,
      command: Command.FORMAT_TEXT,
      payload: "subscript",
      divider: false,
      setSelected: false,
      showIcon: true
   },
   SUPERSCRIPT: {
      value: "superscript",
      icon: "superscript",
      label: "Superscript",
      action: ActionType.COMMAND,
      command: Command.FORMAT_TEXT,
      payload: "superscript",
      divider: false,
      setSelected: false,
      showIcon: true
   },
   CLEAR: {
      value: "clear",
      icon: "clear",
      label: "Clear Formatting",
      action: ActionType.CLEAR,
      command: null,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   }
};

export const OTHER_FORMAT_OPTIONS = [
   OtherFormatOption.STRIKETHROUGH,
   OtherFormatOption.SUBSCRIPT,
   OtherFormatOption.SUPERSCRIPT
];

export const InsertElementOption = {
   HORIZONTAL_RULE: {
      value: "horizontal-rule",
      icon: "horizontal-rule",
      label: "Horizontal Rule",
      action: ActionType.COMMAND,
      command: Command.INSERT_HORIZONTAL_RULE,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   },
   IMAGE: {
      value: "image",
      icon: "image",
      label: "Image",
      action: ActionType.IMAGE,
      command: null,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   },
   INLINE_IMAGE: {
      value: "inline-image",
      icon: "image",
      label: "Inline Image",
      action: ActionType.INLINE_IMAGE,
      command: null,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   },
   TABLE: {
      value: "table",
      icon: "table",
      label: "Table",
      action: ActionType.TABLE,
      command: null,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   },
   COLUMNS: {
      value: "columns",
      icon: "columns",
      label: "Columns Layout",
      action: ActionType.LAYOUT,
      command: null,
      payload: null,
      divider: false,
      setSelected: false,
      showIcon: true
   },
   YOUTUBE: {
      value: "youtube",
      icon: "youtube",
      label: "YouTube Video",
      action: ActionType.COMMAND,
      command: Command.INSERT_EMBED,
      payload: "youtube-video",
      divider: false,
      setSelected: false,
      showIcon: true
   },
};

export const INSERT_ELEMENT_OPTIONS = [
   InsertElementOption.HORIZONTAL_RULE,
   InsertElementOption.IMAGE,
   InsertElementOption.INLINE_IMAGE,
   InsertElementOption.TABLE,
   InsertElementOption.COLUMNS,
   InsertElementOption.YOUTUBE
];

export const ToolbarVariant = {
   STANDARD: "standard",
   ICONS: "icons",
   COMPACT: "compact"
};

