import axios from 'axios';

let SubscribeService = {};

SubscribeService.subscribe = (details) => {
   return axios.post(process.env.REACT_APP_API_URL + '/subscription', {
      email: details.email,
      firstName: details.firstName,
      lastName: details.lastName,
      organisation: details.organisation,
      useCaptcha: details.useCaptcha,
      captcha: details.captcha
   });
};

export default SubscribeService;