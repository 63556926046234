import { forwardRef, createRef } from 'react';

import RadioButton from './RadioButton';

const RadioButtonGroup = forwardRef((props, ref) => {
   const { name, options, value, isDisabled, className, buttonClassName, onChange, ...otherProps } = props;
   return (
      <fieldset 
         ref={ref}
         className={className}
         {...otherProps}
      >
         {options.map((option, index) => (
            <RadioButton
               key={`${name}-option-${index}`}
               ref={createRef()}
               name={name}
               label={option.label}
               value={option.value}
               checked={option.value === value}
               isDisabled={isDisabled}
               className={buttonClassName ? buttonClassName : ""}
               onChange={(e) => {
                  if (onChange) {
                     onChange(e);
                  }
               }}
            />
         ))}
      </fieldset>
   );
});

export default RadioButtonGroup;