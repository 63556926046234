import Accordion from "./Accordion";

export default function FaqSet() {
    return (
        <>
            <Accordion
                title="How much does it cost to use Seedkit?"
                text="Seedkit is free and publicly available to use. It is a not-for-profit initiative."
            />
            <Accordion
                title="Who should use Seedkit?"
                text={`Seedkit has been designed for use for small-to-medium Australian based social enterprises.
                <br/><br/>
                In the Australian context, there is no legal structure called 'social enterprise'. As such, we use these below questions for you to self-determine if your enterprise will be broadly defined as a social enterprise.
                <br/><br/>
                <ol class="list-lower-roman ps-10">
                <li>Is the <span class="vg-font-medium">primary objective</span> of your enterprise to create public or community benefit - addressing a social, environmental, cultural or economic problem?</li>
                <li>Does your enterprise derive a <span class="vg-font-medium">substantial portion</span> (most) of operating income from selling goods or services (or intends to if the enterprise is less than 5 years old)?</li>
                <li>Does your enterprise reinvest the <span class="vg-font-medium">majority</span> of its profit or surplus in meeting its social purpose?</li>
                </ol>
                <br/>
                If you answered 'yes' to all of these three questions, then your enterprise will be broadly defined as a social enterprise.
                <br/><br/>
                If you answered 'no' to any of these questions, your enterprise may not be a social enterprise.
                <br/><br/>
                If you were unsure how to answer these questions, we suggest you take some time to complete <a href="https://www.socialstartupstudio.com.au/social-enterprise-fundamentals-2/" target="_blank" rel="noopener noreferrer">The Social Enterprise Fundamentals</a> program provided for free by the <a href="https://www.socialstartupstudio.com.au/" target="_blank" rel="noopener noreferrer">Social Start-up Studio</a>.
                <br/><br/>
                Seedkit is available for you to use regardless of how you answer these questions.`}
                newlines="strip"
            />
            <Accordion
                title="My organisation is based outside of Victoria, Australia. Can I still use Seedkit?"
                text="Yes, Seedkit is freely accessible and publicly available for everyone to use."
            />
            <Accordion
                title="Who stores my information and how is it used?"
                text={`Information that you provide is de-identified and grouped with other de-identified responses to inform the development and refinement of Seedkit. In addition, this de-identified information is used to provide aggregated insights about the social enterprise sector, to inform policy and build the evidence base for the sector based on its economic and social contributions. Information may also be used to more generally inform industry-focused and academic outputs (e.g. academic journal articles and conference presentations), as well as future related research. For more information, please read the Terms of Use carefully, as registering for an account on this site indicates that you agree to abide by these conditions.

                In order to protect the privacy of consumers, Seedkit uses and implements professional and industry standards for the security and protection of personal information. For more information, please read the <a href="/terms">Terms of Use</a>.`}
                newlines="convert"
            />
            <Accordion
                title="What is Seedkit and how can it help my social enterprise?"
                text={`Seedkit enables social enterprises to better understand, measure and communicate their organisational characteristics, operations, and impacts in a consistent manner and for a variety of stakeholders.

                It is an online platform that generates evaluation reports for individual enterprises based on the organisational information they input. In addition, through the educational resources provided, Seedkit builds the operations and impact evaluation capacity of your social enterprise.
                
                Alongside this, Seedkit provides de-identified aggregated insights about the social enterprise sector, to inform policy and build the evidence base for the sector based on its economic and social contributions.`}
                newlines="convert"
            />
            <Accordion
                title="How does Seedkit help with impact measurement?"
                text={`Seedkit offers a one-of-a-kind Indicators Library, which has been developed from years of evaluations, impact measurements and action research with social enterprises. The Seedkit Indicators Library provides indicators for a variety of organisational features, activities, outcomes and impacts. You can select and tailor indicators from the Library or create entirely new indicators for your organisation's use. 

                Seedkit helps keep information you input against the indicators, allowing you to create dashboards to track and demonstrate changes over time.`}
                newlines="convert"
            />
            <Accordion
                title="How can I register and use Seedkit?"
                text="Go to the 'Sign Up' page and complete the registration form. If you would like to be notified of Seedkit's development and events, please fill out the subscription form below."
            />
            <Accordion
                title="Can I import / export my data to / from Seedkit?"
                text="Yes, you can. Currently Seedkit supports you to import data to indicators (in CSV files) and export from dashboards (in CSV and PDF files). More integration options will be available in future releases."
            />
            <Accordion
                title="Who developed Seedkit?"
                text='Seedkit was developed in 2023 by researchers at the Centre for Social Impact Swinburne and Melbourne Social Equity Institute, and co-designed with a group of social enterprises. For more information about social enterprises in the Seedkit Co-design group, please visit <a href="/about" target="_blank" rel="noopener noreferrer">About</a>.'
            />
            <Accordion
                title="When was Seedkit launched?"
                text={`Seedkit was launched on 10 November 2023.
                
                If you would like to be notified of Seedkit's further development, please sign up for updates using the form on the Home or About page.`}
                newlines="convert"
            />
        </>
    );
}