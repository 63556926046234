import { Fragment, useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';

import * as IndicatorConstants from '../constants/IndicatorConstants';
import * as UiConstants from '../constants/UiConstants';
import * as DataConstants from '../constants/DataConstants';
import LibraryUtils from '../utils/LibraryUtils';

import Button from './Button';
import TextInput from './TextInput';
import SelectDropdown from './SelectDropdown';
import TextArea from './TextArea';

export default function IndicatorModal({ params, open, onSave, onClose }) {

const [usesCohort, setUsesCohort] = useState(false);

const [domainOptions, setDomainOptions] = useState([]);
const [outcomeAreaOptions, setOutcomeAreaOptions] = useState([]);
const [cohortOptions, setCohortOptions] = useState([]);

const [showDomainCustom, setShowDomainCustom] = useState(false);
const [showAreaCustom, setShowAreaCustom] = useState(false);

const [library, setLibrary] = useState([]);

const getOption = (options, value) => {
   const index = options.map(o => o.value).indexOf(value); 
   if (index > -1) {
      return options[index];
   } else {
      return UiConstants.EMPTY_OPTION;
   }
}

const getDomainOptionCustom = (value, opts) => {
   if (value === "") {
      return {
         option: UiConstants.EMPTY_OPTION,
         value: ""
      };
   } else {
      const index = opts.map(o => o.value).indexOf(value);
      if (index > -1) {
         return {
            option: opts[index],
            custom: ""
         };
      } else {
         return {
            option: { 
               value: IndicatorConstants.CUSTOM_DOMAIN_VALUE, 
               label: IndicatorConstants.CUSTOM_DOMAIN_LABEL 
            },
            custom: value
         };
      }
   }
}

const getDomainValue = (option, custom) => {
   return option.value === IndicatorConstants.CUSTOM_DOMAIN_VALUE ? custom : option.value;
}

const getOutcomeAreaOptionCustom = (value, opts) => {
   if (value === "") {
      return {
         option: UiConstants.EMPTY_OPTION,
         value: ""
      };
   } else {
      const index = opts.map(o => o.value).indexOf(value);
      if (index > -1) {
         return {
            option: opts[index],
            custom: ""
         };
      } else {
         return {
            option: { 
               value: IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE, 
               label: IndicatorConstants.CUSTOM_OUTCOME_AREA_LABEL 
            },
            custom: value
         };
      }
   }
}

const getOutcomeAreaValue = (option, custom) => {
   return option.value === IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE ? custom : option.value;
}

const validationSchema = Yup.object().shape({
reportingNeedOption: Yup.object().shape({ value: Yup.string().required("Please select a reporting need")}),
domainOption: Yup.object().shape({ value: Yup.string().required("Please select a domain")}),
outcomeAreaOption: Yup.object().shape({ value: Yup.string().required("Please select an outcome area")}),
indicatorTypeOption: Yup.object().shape({ value: Yup.string().required("Please select an indicator type")}),
frequencyOption: Yup.object().shape({ value: Yup.string().required("Please select a data entry interval")}),
formatOption: Yup.object().shape({ value: Yup.string().required("Please select a format")}),
domainCustom: Yup.string().when("domainOption", {
   is: (domainOption) => domainOption.value === IndicatorConstants.CUSTOM_DOMAIN_VALUE,
   then: () => Yup.string().required("Please enter a custom domain")
}),
outcomeAreaCustom: Yup.string().when("outcomeAreaOption", {
   is: (outcomeAreaOption) => outcomeAreaOption.value === IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE,
   then: () => Yup.string().required("Please enter a custom outcome area")
})
});

const formOptions = { 
   resolver: yupResolver(validationSchema),
   defaultValues: {
      baseKey: "",
      baseDataKey: "",
      key: "",
      indicatorDataKey: "",
      pathwayName: "",
      reportingNeedOption: UiConstants.EMPTY_OPTION,
      domainOption: UiConstants.EMPTY_OPTION,
      domainCustom: "",
      outcomeAreaOption: UiConstants.EMPTY_OPTION,
      outcomeAreaCustom: "",
      description: "",
      indicatorTypeOption: UiConstants.EMPTY_OPTION,
      frequencyOption: UiConstants.EMPTY_OPTION,
      cohortOption:  UiConstants.EMPTY_OPTION,
      formatOption: UiConstants.EMPTY_OPTION,
      source: "",
      notes: "",
      cohortTextPlaceholders: [],
      cohortKeyPlaceholder: "",
      requiredData: [],
      fullRequiredData: [],
      baselineKey: "",
      targetKey: ""
   }
};

const { 
   control, 
   handleSubmit,
   watch,
   reset,
   setValue,
   getValues
} = useForm(formOptions);

/* Interaction Functions */

const onCancelClick = (e) => {
   e.preventDefault();
   onClose();
};

const escapeRegExp = (str) => {
   return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
   
const replaceAll = (str, find, replace) => {
   return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const setTitleFromCohort = (oldTitle, placeholders, text) => {
   let newTitle = oldTitle;
   placeholders.forEach(tpl => {
      newTitle = replaceAll(newTitle, tpl, text);
   });
   return newTitle;
}

const setKeyFromCohort = (oldKey, placeholder, text) => {
   return replaceAll(oldKey, placeholder, text);
}

const setFormulaFromCohort = (oldFormula, placeholder, text) => {
   return oldFormula.replace(placeholder, text);
}

const onSubmit = (data) => {
   //let indicator = JSON.parse(JSON.stringify(params.indicator));
   let indicator = cloneDeep(params.indicator);
   indicator.key = data.key;
   indicator.baseKey = data.baseKey;
   indicator.baseDataKey = data.baseDataKey;
   indicator.indicatorDataKey = data.indicatorDataKey;
   indicator.title = data.title;
   indicator.reportingNeed = data.reportingNeedOption.value;
   indicator.domain = getDomainValue(data.domainOption, data.domainCustom);
   indicator.outcomeArea = getOutcomeAreaValue(data.outcomeAreaOption, data.outcomeAreaCustom);
   indicator.description = data.description;
   indicator.indicatorType = data.indicatorTypeOption.value;
   indicator.frequency = data.frequencyOption.value;
   if (usesCohort) {
      indicator.cohort = data.cohortOption.label;
      indicator.cohortKey = data.cohortOption.value;
      indicator.cohortTextPlaceholders = data.cohortTextPlaceholders;
      indicator.cohortKeyPlaceholder = data.cohortKeyPlaceholder;
   }
   indicator.format = data.formatOption.value;
   indicator.source = data.source;
   indicator.notes = data.notes;
   indicator.requiredData = data.requiredData;
   indicator.fullRequiredData = data.fullRequiredData;

   indicator.baselineKey = data.baselineKey;
   indicator.targetKey = data.targetKey;
   
   let details = {
      type: params.type,
      pathwayName: data.pathwayName,
      pathwayId: params.pathwayId,
      indicator: indicator
   }
   if (onSave) {
      onSave(details);
   }
   onClose();
};

const onError = (errors) => {
   console.log("errors", errors);
}

const onOutcomeAreaCustomBlur = () => {
   const pathwayName = getValues("pathwayName");
   const outcomeAreaCustom = getValues("outcomeAreaCustom");
   if (pathwayName === "" || outcomeAreaOptions.map(opt => opt.label).indexOf(pathwayName) > -1) {
      setValue("pathwayName", outcomeAreaCustom);
   }
}

useEffect(() => {
   const subscription = watch((data, { name, type }) => {
      if (name === "cohortOption") {
         let oldTitle = data.title;
         let oldKey = data.key;
         let oldDataKey = data.indicatorDataKey;
         let newTitle = setTitleFromCohort(oldTitle, data.cohortTextPlaceholders, data.cohortOption.label);
         let newKey = setKeyFromCohort(oldKey, data.cohortKeyPlaceholder, data.cohortOption.value);
         let newDataKey = oldDataKey ? setKeyFromCohort(oldDataKey, data.cohortKeyPlaceholder, data.cohortOption.value) : "";

         let newRequiredData = [];
         let newFullRequiredData = [];

         let newBaselineKey = setKeyFromCohort(data.baselineKey, data.cohortKeyPlaceholder, data.cohortOption.value);
         let newTargetKey = setKeyFromCohort(data.targetKey, data.cohortKeyPlaceholder, data.cohortOption.value);

         let oldPercentNonKey = oldDataKey.replace("percent", ""); 
         let newPercentNonKey = newDataKey.replace("percent", ""); 
         data.fullRequiredData.forEach(rd => {
            let k = rd.key
            let t = rd.requiredDataType;
            if (k === oldDataKey) {
               rd.key = newDataKey;
               rd.title = setTitleFromCohort(rd.title, data.cohortTextPlaceholders, data.cohortOption.label);
               if (rd.formula) {
                  rd.formula = setFormulaFromCohort(rd.formula, data.cohortKeyPlaceholder, data.cohortOption.value);
               }

            } else if (t === "Baseline") {
               rd.key = newKey + t; 
               newBaselineKey = newBaselineKey ? newBaselineKey : rd.key;

            } else if (t === "Target") {
               rd.key = newKey + t;
               newTargetKey = newTargetKey ? newTargetKey : rd.key;

            } else {
               if (oldDataKey.indexOf("percent") === 0) {
                  if (k.indexOf(oldPercentNonKey) !== -1) {
                     rd.key = k.replace(oldPercentNonKey, newPercentNonKey);
                     rd.title = setTitleFromCohort(rd.title, data.cohortTextPlaceholders, data.cohortOption.label);
                  }
               }
            } 
            newRequiredData.push({ id: rd._id, key: rd.key });
            newFullRequiredData.push(rd);
         });
         
         setValue("title", newTitle);
         setValue("key", newKey);
         setValue("indicatorDataKey", newDataKey);
         setValue("cohortTextPlaceholders", [data.cohortOption.label]);
         setValue("cohortKeyPlaceholder", data.cohortOption.value);
         setValue("requiredData", newRequiredData);
         setValue("fullRequiredData", newFullRequiredData);

         setValue("baselineKey", newBaselineKey);
         setValue("targetKey", newTargetKey);

      } else if (name === "reportingNeedOption") {
         setDomainOptions(LibraryUtils.getDomainOptions(library, data.reportingNeedOption.value, true));
         setValue("domainOption", UiConstants.EMPTY_OPTION);
         setValue("domainCustom", "");
         setShowDomainCustom(false);
         setOutcomeAreaOptions(LibraryUtils.getOutcomeAreaOptions(library, "", true));
         if (data.pathwayName === data.outcomeAreaOption.label || data.pathwayName === data.outcomeAreaCustom) {
            setValue("pathwayName", "");
         }
         setValue("outcomeAreaOption", UiConstants.EMPTY_OPTION);
         setValue("outcomeAreaCustom", "");
         setShowAreaCustom(false);
            
      } else if (name === "domainOption") {
         setOutcomeAreaOptions(LibraryUtils.getOutcomeAreaOptions(library, data.domainOption.value, true));
         if (data.pathwayName === data.outcomeAreaOption.label || data.pathwayName === data.outcomeAreaCustom) {
            setValue("pathwayName", "");
         }
         setValue("outcomeAreaOption", UiConstants.EMPTY_OPTION);
         setValue("outcomeAreaCustom", "");
         setShowDomainCustom(data.domainOption.value === IndicatorConstants.CUSTOM_DOMAIN_VALUE);
         setShowAreaCustom(false);

      } else if (name === "outcomeAreaOption") {
         setShowAreaCustom(data.outcomeAreaOption.value === IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE);
         if (data.pathwayName !== data.outcomeAreaOption.label && data.outcomeAreaOption.value !== IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE) {
            if (data.pathwayName === "" || outcomeAreaOptions.map(opt => opt.label).indexOf(data.pathwayName) > -1) {
               setValue("pathwayName", data.outcomeAreaOption.label);
            }
         }
      }
   });
   return () => subscription.unsubscribe();
}, [watch, library, setValue, outcomeAreaOptions]);

useEffect(() => {
   const usesCohortFlag = params.indicator.usesCohort && params.indicator.usesCohort === true ? true : false;
   const domainOpts = LibraryUtils.getDomainOptions(params.library, params.indicator.reportingNeed ? params.indicator.reportingNeed : "", true);
   const outcomeAreaOpts = params.indicator.domain ? LibraryUtils.getOutcomeAreaOptions(params.library, params.indicator.domain, true) : [];

   const domainOptCustom = getDomainOptionCustom(params.indicator.domain ? params.indicator.domain : "", domainOpts);
   const outcomeAreaOptCustom = getOutcomeAreaOptionCustom(params.indicator.outcomeArea ? params.indicator.outcomeArea : "", outcomeAreaOpts);

   setShowDomainCustom(domainOptCustom.option.value === IndicatorConstants.CUSTOM_DOMAIN_VALUE);
   setShowAreaCustom(outcomeAreaOptCustom.option.value === IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE);

   setUsesCohort(usesCohortFlag);
   setDomainOptions(domainOpts);
   setOutcomeAreaOptions(outcomeAreaOpts);
   setCohortOptions(params.cohortOptions);
   setLibrary(params.library);

   let fullRequiredData = [];

   if (params.indicator.fullRequiredData) {
      fullRequiredData = params.indicator.fullRequiredData
   } else {
      if (params.indicator.requiredData) {
         params.indicator.requiredData.forEach(ird => {
            let index = -1;
            if (ird.key) {
                  // New indicators
               index = params.library.requiredData.map(lrd => lrd.key).indexOf(ird.key);
               if (index > -1) {
                  fullRequiredData.push(params.library.requiredData[index]);
               } else {
                  fullRequiredData.push(ird);
               }
            
            } else {
               // Saved indicators
               index = params.library.requiredData.map(lrd => lrd.key).indexOf(ird); 
               if (index === -1) {
                  index = params.unitRequiredData.map(lrd => lrd.key).indexOf(ird);
                  if (index > -1) {
                     fullRequiredData.push(params.unitRequiredData[index]);
                  } else {
                     fullRequiredData.push(ird);
                  }
               } else {
                  fullRequiredData.push(params.library.requiredData[index]);
               }
            }
         });
      }
   }

   const resetValues = {
      baseKey: params.indicator.key,
      baseDataKey: params.indicator.indicatorDataKey,
      key: params.indicator.key,
      indicatorDataKey: params.indicator.indicatorDataKey,
      title: params.indicator.title,
      pathwayName: params.pathwayName,
      reportingNeedOption: params.indicator.reportingNeed ? getOption(UiConstants.REPORTING_NEED_OPTIONS, params.indicator.reportingNeed) : UiConstants.EMPTY_OPTION,
      domainOption: domainOptCustom.option,
      domainCustom: domainOptCustom.custom,
      outcomeAreaOption: outcomeAreaOptCustom.option,
      outcomeAreaCustom: outcomeAreaOptCustom.custom,
      description: params.indicator.description ? params.indicator.description : "",
      indicatorTypeOption: params.indicator.indicatorType ? getOption(UiConstants.INDICATOR_TYPE_OPTIONS, params.indicator.indicatorType) : UiConstants.EMPTY_OPTION,
      frequencyOption: params.indicator.frequency ? getOption(UiConstants.FREQUENCY_OPTIONS, params.indicator.frequency) : getOption(UiConstants.FREQUENCY_OPTIONS, DataConstants.FrequencyType.MONTHLY),
      cohortOption: usesCohortFlag && params.indicator.cohortKey ? getOption(params.cohortOptions, params.indicator.cohortKey) : UiConstants.EMPTY_OPTION,
      formatOption: params.indicator.format ? getOption(UiConstants.FORMAT_OPTIONS, params.indicator.format) : UiConstants.EMPTY_OPTION,
      source: params.indicator.source ? params.indicator.source : "",
      notes: params.indicator.notes ? params.indicator.notes : "",
      cohortTextPlaceholders: params.indicator.cohortTextPlaceholders ? params.indicator.cohortTextPlaceholders : [],
      cohortKeyPlaceholder: params.indicator.cohortKeyPlaceholder ? params.indicator.cohortKeyPlaceholder : "",
      requiredData: params.indicator.requiredData ? params.indicator.requiredData : [],
      fullRequiredData: fullRequiredData,
      baselineKey: params.indicator.baselineKey ? params.indicator.baselineKey : "",
      targetKey: params.indicator.targetKey ? params.indicator.targetKey : ""
   };

   reset(resetValues);
}, [params, reset]);

return (
   <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
         <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"                
         >
            <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
         </TransitionChild>
         <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
               <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
               >
                  <DialogPanel className="relative flex flex-col items-stretch gap-8 transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                     <DialogTitle
                        as="h4"
                        className="font-vg-medium text-3.5xl text-black leading-110"
                     >
                        Indicator Settings
                     </DialogTitle>
                     <form className="grid grid-cols-2 gap-8" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="col-span-2">
                           <Controller
                              name="title"
                              control={control}
                              render={({ field, formState }) => (
                                 <TextArea 
                                    label="Name of Indicator"
                                    variant="indicator-title" 
                                    {...field}
                                 />
                              )}
                           />
                        </div>
                        <Controller
                           name="reportingNeedOption"
                           control={control}
                           render={({ field, formState }) => (
                              <SelectDropdown 
                                 label="Reporting Need" 
                                 options={UiConstants.REPORTING_NEED_OPTIONS}
                                 selectedOption={field.value}
                                 required={true}
                                 help={formState.errors.reportingNeedOption?.value.message}
                                 showHelp="always"
                                 helpColor="text-red"
                                 {...field}
                              />
                           )}
                        />
                        <Controller
                           name="domainOption"
                           control={control}
                           render={({ field, formState }) => (
                              <SelectDropdown 
                                 label="Domain" 
                                 options={domainOptions}
                                 selectedOption={field.value}
                                 required={true}
                                 help={formState.errors.domainOption?.value.message}
                                 showHelp="always"
                                 helpColor="text-red"
                                 {...field}
                              />
                           )}
                        />
                        {showDomainCustom === true && (
                           <>
                              <div></div>
                              <Controller
                                 name="domainCustom"
                                 control={control}
                                 render={({ field, formState }) => (
                                    <TextInput 
                                       label="Name of Custom Domain" 
                                       required={true}
                                       help={formState.errors.domainCustom?.message}
                                       showHelp="always"
                                       helpColor="text-red"
                                       {...field}
                                    />
                                 )}
                              />
                           </>
                        )}
                        <div className="col-span-2">
                           <Controller
                              name="outcomeAreaOption"
                              control={control}
                              render={({ field, formState }) => (
                                 <SelectDropdown 
                                    label="Outcome Area" 
                                    options={outcomeAreaOptions}
                                    selectedOption={field.value}
                                    tooltip="The list shows all outcome areas this indicator is associated with in the Library."
                                    required={true}
                                    help={formState.errors.outcomeAreaOption?.value.message}
                                    helpColor="text-red"
                                    {...field}
                                 />
                              )}
                           />
                        </div>
                        {showAreaCustom === true && (
                           <div className="col-span-2">
                              <Controller
                                 name="outcomeAreaCustom"
                                 control={control}
                                 render={({ field: { onChange, onBlur, value, ref }, formState }) => (
                                    <TextInput
                                       ref={ref} 
                                       label="Name of Custom Outcome Area" 
                                       onChange={onChange}
                                       value={value}
                                       required={true}
                                       help={formState.errors.outcomeAreaCustom?.message}
                                       showHelp="always"
                                       helpColor="text-red"
                                       onBlur={onOutcomeAreaCustomBlur}
                                    />
                                 )}
                              />
                           </div>
                        )}
                        <div className="col-span-2">
                           <Controller
                              name="pathwayName"
                              control={control}
                              render={({ field, formState }) => (
                                 <TextInput 
                                    label="Name of Associated Indicator Pathway" 
                                    {...field}
                                 />
                              )}
                           />
                        </div>
                        <div className="col-span-2">
                           <Controller
                              name="description"
                              control={control}
                              render={({ field, formState }) => (
                                 <TextArea
                                    label="Description"
                                    rows="3"
                                    {...field}
                                 />
                              )}
                           />
                        </div>
                        <Controller
                           name="indicatorTypeOption"
                           control={control}
                           render={({ field, formState }) => (
                              <SelectDropdown 
                                 label="Indicator Type" 
                                 options={UiConstants.INDICATOR_TYPE_OPTIONS}
                                 selectedOption={field.value}
                                 required={true}
                                 help={formState.errors.indicatorTypeOption?.value.message}
                                 showHelp="always"
                                 helpColor="text-red"
                                 {...field}
                              />
                           )}
                        />
                        <Controller
                           name="frequencyOption"
                           control={control}
                           render={({ field, formState }) => (
                              <SelectDropdown 
                                 label="Data Entry Interval" 
                                 options={UiConstants.FREQUENCY_OPTIONS}
                                 selectedOption={field.value}
                                 required={true}
                                 help={formState.errors.frequencyOption?.value.message}
                                 showHelp="always"
                                 helpColor="text-red"
                                 {...field}
                              />
                           )}
                        />
                        {usesCohort && (
                           <div className="col-span-2">
                              <Controller
                                 name="cohortOption"
                                 control={control}
                                 render={({ field, formState }) => (
                                    <SelectDropdown 
                                       label="Cohort" 
                                       options={cohortOptions}
                                       selectedOption={field.value}
                                       {...field}
                                    />
                                 )}
                              />
                           </div>
                        )}
                        <Controller
                           name="formatOption"
                           control={control}
                           render={({ field, formState }) => (
                              <SelectDropdown 
                                 label="Format" 
                                 options={UiConstants.FORMAT_OPTIONS}
                                 selectedOption={field.value}
                                 required={true}
                                 help={formState.errors.formatOption?.value.message}
                                 showHelp="always"
                                 helpColor="text-red"
                                 {...field}
                              />
                           )}
                        />
                        <Controller
                           name="source"
                           control={control}
                           render={({ field, formState }) => (
                              <TextInput 
                                 label="Source" 
                                 {...field}
                              />
                           )}
                        />
                        <div className="col-span-2">
                           <Controller
                              name="notes"
                              control={control}
                              render={({ field, formState }) => (
                                 <TextArea
                                    label="Notes"
                                    rows="3"
                                    {...field}
                                 />
                              )}
                           />
                        </div>
                        <div className="col-span-2">
                           <div className="grid grid-cols-2 gap-3">
                              <Button variant="outline" size="large" label="Cancel" className="w-full" onClick={onCancelClick}/>
                              <Button variant="solid" size="large" label="Save" className="w-full" type="submit"/>
                           </div>
                        </div>
                     </form>
                  </DialogPanel>
               </TransitionChild>
            </div>
         </div>
      </Dialog>
   </Transition>
);
}