import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form';

import Button from './Button';
import TextInput from './TextInput';
import TextArea from './TextArea';

export default function DataIndicatorModal({ params, open, onSave, onClose }) {

    const [title, setTitle] = useState("");
    const [reportingNeed, setReportingNeed] = useState("");
    const [domain, setDomain] = useState("");
    //const [outcomeArea, setOutcomeArea] = useState("");
    const [description, setDescription] = useState("");
    const [indicatorType, setIndicatorType] = useState("");
    const [frequency, setFrequency] = useState("");
    
    const formOptions = { 
        defaultValues: {
            source: "",
            notes: "",
        }
    };

    const { 
        control, 
        handleSubmit,
        reset
    } = useForm(formOptions);

    /* Interaction Functions */

    const onCancelClick = (e) => {
        e.preventDefault();
        onClose();
    };

    const onSubmit = (data) => {
        let details = {
            key: params.indicator.key,
            source: data.source,
            notes: data.notes
        }
        if (onSave) {
            onSave(details);
        }
        onClose();
    };
    
    useEffect(() => {
        let resetValues = {
            source: "",
            notes: ""
        }
        if (params.indicator) {
            setTitle(params.indicator.title ? params.indicator.title : "");
            setReportingNeed(params.indicator.reportingNeed ? params.indicator.reportingNeed : "");
            setDomain(params.indicator.domain ? params.indicator.domain : "");
            //setOutcomeArea(params.indicator.outcomeArea ? params.indicator.outcomeArea : "");
            setDescription(params.indicator.description ? params.indicator.description : "");
            setIndicatorType(params.indicator.indicatorType ? params.indicator.indicatorType : "");
            setFrequency(params.indicator.frequency ? params.indicator.frequency : "");
    
            resetValues = {
                source: params.indicator.source ? params.indicator.source : "",
                notes: params.indicator.notes ? params.indicator.notes : "",
            };
        }
        reset(resetValues);
    }, [params, reset]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"                
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative flex flex-col items-stretch gap-8 transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                                <Dialog.Title
                                    as="h4"
                                    className="font-vg-medium text-3.5xl text-black leading-110"
                                >
                                    Indicator Settings
                                </Dialog.Title>

                                <form className="flex flex-col items-stretch gap-8" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="col-span-2 flex flex-col items-stretch gap-2">
                                            <p className="font-vg-regular text-base text-black">Indicator Name:</p>
                                            <p className="font-vg-regular text-base text-blue">{title}</p>
                                        </div>
                                        {description !== "" && (
                                            <div className="col-span-2 flex flex-col items-stretch gap-2">
                                                <p className="font-vg-regular text-base text-black">Description:</p>
                                                <p className="font-vg-book text-base text-black">{description}</p>
                                            </div>
                                        )}
                                        <div className="flex items-start gap-2">
                                            <p className="font-vg-regular text-base text-black">Reporting Need:</p>
                                            <p className="font-vg-book text-base-text-black">{reportingNeed}</p>
                                        </div>
                                        <div className="flex items-start gap-2">
                                            <p className="font-vg-regular text-base text-black">Domain:</p>
                                            <p className="font-vg-book text-base-text-black">{domain}</p>
                                        </div>
                                        <div className="flex items-start gap-2">
                                            <p className="font-vg-regular text-base text-black">Indicator Type:</p>
                                            <p className="font-vg-book text-base-text-black">{indicatorType}</p>
                                        </div>
                                        <div className="flex items-start gap-2">
                                            <p className="font-vg-regular text-base text-black">Frequency:</p>
                                            <p className="font-vg-book text-base-text-black">{frequency}</p>
                                        </div>
                                    </div>
                                    <Controller
                                        name="source"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <TextInput 
                                                label="Source" 
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="notes"
                                        control={control}
                                        render={({ field, formState }) => (
                                            <TextArea
                                                label="Notes"
                                                rows="3"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <div className="grid grid-cols-2 gap-3">
                                        <Button variant="outline" size="large" label="Cancel" className="w-full" onClick={onCancelClick}/>
                                        <Button variant="solid" size="large" label="Save" className="w-full" type="submit"/>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}