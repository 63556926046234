import { forwardRef, useState, useEffect, useRef, useCallback } from 'react';

import * as DashboardConstants from '../constants/DashboardConstants';

import DashboardCanvasCreateCard from "./DashboardCanvasCreateCard";
import DashboardCanvasTextCard from './DashboardCanvasTextCard';
import DashboardCanvasGraphCard from './DashboardCanvasGraphCard';
import DashboardCanvasTableCard from './DashboardCanvasTableCard';

const DashboardCanvas = forwardRef((props, ref) => {
    const { dashboard, pageIndex, mode, version, onCanvasAction } = props;
    const page = dashboard.pages[pageIndex];
    const elements = page && page.elements ? page.elements : [];

    /* Canvas Building Functions */

    const getCreateElementsArray = () => {
        let createElems = [];
        for (let r = 1; r <= 3; r++) {
            for (let c = 1; c <= 4; c++) {
                createElems.push({
                    index: parseInt(r) * 10 + parseInt(c),
                    type: DashboardConstants.ElementType.CREATE,
                    row: r,
                    col: c,
                    width: 1,
                    height: 1
                });
            }
        }
        return createElems;
    }

    const filterCreateElements = (createElems, rowcols) => {
        let newElems = [];
        createElems.forEach(el => {
            if (el.col < rowcols.startCol || el.col >= rowcols.endCol || el.row < rowcols.startRow || el.row >= rowcols.endRow) {
                newElems.push(el);
            }
        });
        return newElems;
    }

    const buildCanvas = () => {
        let createElements = getCreateElementsArray();
        let canvasElements = [];
        elements.forEach(el => {
            canvasElements.push({
                index : parseInt(el.row) * 10 + parseInt(el.col),
                type: el.elementType,
                row: parseInt(el.row),
                col: parseInt(el.col),
                width: parseInt(el.width),
                height: parseInt(el.height),
                details: el
            });
            createElements = filterCreateElements(createElements, {
                startRow: parseInt(el.row),
                endRow: parseInt(el.row) + parseInt(el.height),
                startCol: parseInt(el.col),
                endCol: parseInt(el.col) + parseInt(el.width),
            });
        });
        canvasElements = canvasElements.concat(createElements);
        canvasElements.sort((a, b) => {
            if (a.index < b.index) {
                return -1;
            } else if (a.index > b.index) {
                return 1;
            } else {
                return 0;
            }
        });

        return (
            <>
            {canvasElements.map((canvElem, index) => {
                switch (canvElem.type) {
                    case DashboardConstants.ElementType.CREATE:
                        return (
                            <DashboardCanvasCreateCard
                                key={`canvas-create-${index}`}
                                row={canvElem.row}
                                col={canvElem.col}
                                width={canvElem.width}
                                height={canvElem.height}
                                mode={mode}
                                onClick={() => onAddElementClick({ 
                                    row: canvElem.row,
                                    col: canvElem.col 
                                })}
                            />
                        );
                    case DashboardConstants.ElementType.TEXT:
                        return (
                            <DashboardCanvasTextCard
                                key={`canvas-text-${index}`}
                                row={canvElem.row}
                                col={canvElem.col}
                                width={canvElem.width}
                                height={canvElem.height}
                                details={{
                                    id: canvElem.details._id,
                                    title: canvElem.details.title,
                                    text: canvElem.details.text ? canvElem.details.text : ""
                                }}
                                mode={mode}
                                onCanvasElementAction={onCanvasElementAction}
                            />
                        );
                    case DashboardConstants.ElementType.GRAPH:
                        return (
                            <DashboardCanvasGraphCard
                                key={`canvas-graph-${index}`}
                                row={canvElem.row}
                                col={canvElem.col}
                                width={canvElem.width}
                                height={canvElem.height}
                                details={{
                                    id: canvElem.details._id,
                                    title: canvElem.details.title,
                                    dashboardId: dashboard._id,
                                    pageId: page._id,
                                }}
                                mode={mode}
                                version={version}
                                onCanvasElementAction={onCanvasElementAction}
                            />
                        );
                    case DashboardConstants.ElementType.TABLE:
                        return (
                            <DashboardCanvasTableCard
                                key={`canvas-table-${index}`}
                                row={canvElem.row}
                                col={canvElem.col}
                                width={canvElem.width}
                                height={canvElem.height}
                                details={{
                                    id: canvElem.details._id,
                                    boardTitle: dashboard.title,
                                    title: canvElem.details.title,
                                    dashboardId: dashboard._id,
                                    pageId: page._id,
                                }}
                                mode={mode}
                                version={version}
                                onCanvasElementAction={onCanvasElementAction}
                            />
                        );
                    default:
                        return (
                            <></>
                        );
                }
            })}
            </>
        );
    }

    /* Element Load Checking Functions */

    const [elementsLoaded, _setElementsLoaded] = useState([]);
    const elementsLoadedRef = useRef(elementsLoaded);
    const setElementsLoaded = data => {
        elementsLoadedRef.current = data;
        _setElementsLoaded(data);
    };

    const [allExportTrigger, setAllExportTrigger] = useState(false);
    const [allExportDone, setAllExportDone] = useState(false);

    /*
    if (lastMode && lastMode !== mode) {
        if (mode !== DashboardConstants.CanvasMode.EXPORT_ALL) {
            setElementsLoaded([]);
        }
        setLastMode(mode);
    }
    */

    const getCollectiveSuccess = useCallback(() => {
        let success = true;
        elementsLoadedRef.current.forEach(el => {
            success = success && el.success;
        });
        return success;
    }, [elementsLoadedRef]);

    const onElementLoaded = (details) => {
        const id = details.args.element;
        const success = details.args.success;
        if (elementsLoadedRef.current.map(eld => eld.id.toString()).indexOf(id.toString()) === -1) {
            let newElementsLoaded = JSON.parse(JSON.stringify(elementsLoadedRef.current));
            newElementsLoaded.push({ id: id, success: success });
            setElementsLoaded(newElementsLoaded);
            /*
            if (newElementsLoaded.length >= loadableCount) {
                if (onCanvasAction) {
                    const success = getCollectiveSuccess();
                    onCanvasAction({
                        action: DashboardConstants.ActionType.CANVAS_LOAD_COMPLETE,
                        args: {
                            page: pageIndex,
                            success: success
                        }
                    });
                }
            }
            */
        }
    }

    /* Interaction Functions */
    
    const onAddElementClick = (details) => {
        if (onCanvasAction) {
            onCanvasAction({
                action: DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_CREATE,
                args: {
                    page: pageIndex,
                    row: details.row,
                    col: details.col
                }
            });
        }
    }

    const onCanvasElementAction = (details) => {
        switch (details.action) {
            case DashboardConstants.ActionType.TEXT_UPDATED:
                if (onCanvasAction) {
                    onCanvasAction({
                        action: DashboardConstants.ActionType.TEXT_UPDATED,
                        args: {
                            page: pageIndex,
                            id: details.args.id,
                            text: details.args.text 
                        }
                    });
                }
                break;
            case DashboardConstants.ActionType.ELEMENT_MENU_EDIT:
                if (onCanvasAction) {
                    onCanvasAction({
                        action: DashboardConstants.ActionType.OPEN_ELEMENT_MODAL_EDIT,
                        args: {
                            page: pageIndex,
                            id: details.args.id,
                            row: details.args.row,
                            col: details.args.col,
                            width: details.args.width,
                            height: details.args.height
                        }
                    });
                }
                break;
            case DashboardConstants.ActionType.ELEMENT_MENU_DELETE:
                if (onCanvasAction) {
                    onCanvasAction({
                        action: DashboardConstants.ActionType.ELEMENT_MENU_DELETE,
                        args: {
                            page: pageIndex,
                            id: details.args.id,
                            row: details.args.row,
                            col: details.args.col
                        }
                    });
                }
                break;
            case DashboardConstants.ActionType.ELEMENT_LOAD_COMPLETE:
                onElementLoaded(details);
                break;
            default:
                break;
        }
    }

    /* useEffect Functions */

    useEffect(() => {
        let exportTrigger = allExportTrigger;
        if (mode === DashboardConstants.CanvasMode.EXPORT_ALL) {
            if (!allExportDone && elementsLoadedRef.current.length >= dashboard.pages[pageIndex].elements.length) {
                exportTrigger = true;
                setAllExportTrigger(true);
            }
        } else {
            setAllExportDone(false);
        }
        if (exportTrigger) {
            if (onCanvasAction) {
                const success = getCollectiveSuccess();             
                onCanvasAction({
                    action: DashboardConstants.ActionType.CANVAS_LOAD_COMPLETE,
                    args: {
                        page: pageIndex,
                        success: success
                    }
                });
            }
            setAllExportTrigger(false);
            setElementsLoaded([]);
            setAllExportDone(true);
        }
    }, [
        mode, dashboard, pageIndex, onCanvasAction, elementsLoaded, elementsLoadedRef, 
        allExportTrigger, allExportDone, getCollectiveSuccess
    ]);

    return (
        <div ref={ref} className="grid w-full aspect-half-a4 grid-cols-4 grid-rows-3 gap-6 overflow-hidden">
            {elements.length === 0 ? (
               <DashboardCanvasCreateCard 
                    row={1} 
                    col={1} 
                    width={4} 
                    height={3} 
                    mode={mode}
                    onClick={() => onAddElementClick({ row: 1, col: 1 })}
               />
            ) : (
                <>
                    {buildCanvas()}
                </>
            )}
        </div>
    );
});

export default DashboardCanvas;