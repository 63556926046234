import { useState, useRef, useEffect } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

export default function ResizableGridContainer(props) {
    const { className, height, children, ...otherProps } = props;

    const [mouseDown, setMouseDown] = useState(false);

    const containerRef = useRef(null);

    const handleMouseDown = () => {
        setMouseDown(true);
    }


    useEffect(() => {
        const handleHeightResize = (movementY) => {
            const container = containerRef.current;
            if (!container) return;
    
            const rect = container.getBoundingClientRect();
            container.style.height = `${rect.height + movementY}px`;
        }
        
        const handleMouseMove = (e) => {
            handleHeightResize(e.movementY);
        }

        if (mouseDown) {
            window.addEventListener("mousemove", handleMouseMove);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        }

    }, [mouseDown, containerRef]);

    useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);

        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        }
    });

    return (
        <div className="flex flex-col items-stretch">
            <div 
                ref={containerRef}
                className={className} 
                style={{height: height}}
                {...otherProps}
            >
                {children}
            </div>
            <div className="flex justify-center">
                <div 
                    className="flex items-center justify-center w-12 h-4 rounded-b-lg bg-grey03 border-x border-b border-grid-grey cursor-ns-resize"
                    onMouseDown={handleMouseDown}
                >
                    <ChevronUpDownIcon className="w-3 h-3 text-black"/>
                </div>
            </div>
        </div>
    );
}