import ViewOnlyStrap from './ViewOnlyStrap';
import IconTooltip from './IconTooltip';

export default function OrgEntHeader({ title, subtitle, tooltip, subTooltip, showStrap, editing }) {
   const strap = (showStrap === true || showStrap === "true") ? true : false;
   return (
      <div className="flex flex-col items-stretch gap-6">
         <div className="flex flex-col items-stretch gap-3">
               {tooltip ? (
                  <div className="flex items-center gap-2">
                     <h2 className="font-vg-medium text-5xl leading-110 text-black">{title}</h2>
                     <IconTooltip content={tooltip} position="right"/>
                  </div>
               ) : (
                  <h2 className="font-vg-medium text-5xl leading-110 text-black">{title}</h2>
               )}
               {subTooltip ? (
                  <div className="flex items-center gap-2">
                     <p className="font-vg-regular text-xl leading-130 text-grey">{subtitle}</p>
                     <IconTooltip content={subTooltip} position="right"/>
                  </div>
               ) : (
                  <p className="font-vg-regular text-xl leading-130 text-grey">{subtitle}</p>
               )}
         </div>
         {strap && (
            <ViewOnlyStrap editing={editing}/>
         )}
      </div>
   );
}