export default function PaginationButton(props) {
    const { label, value, state, onPageClick, ...otherProps } = props;
    let buttonClass = "flex justify-center items-center w-9 h-9 rounded-md font-vg-regular text-sm leading-5 hover:bg-blue03";
    switch (state) {
        case "default":
            buttonClass = `${buttonClass} bg-white text-grey`;
            break;
        case "active":
            buttonClass = `${buttonClass} bg-blue03 text-blue`;
            break;
        case "disabled":
            buttonClass = `${buttonClass} bg-white text-grey02`;
            break;
        default:
            break;
    }
    return (
        <button 
            key={`pgbtn-${value}`}
            className={buttonClass} 
            onClick={() => {
                if (onPageClick && state !== "disabled") {
                    onPageClick(value);
                }
            }}
            { ...otherProps }
        >
            {label}
        </button>
    );
}