import { cloneDeep } from 'lodash';

import * as UiConstants from '../constants/UiConstants';
import * as EntityConstants from '../constants/EntityConstants';

let UnitUtils = {};

const getSelectedUnitName = (context, type, id) => {
    let name = "Reporting Unit";
    let index = -1;
    switch (type) {
        case EntityConstants.EntityType.SOCIAL_ENTERPRISE:
        case EntityConstants.EntityType.ENTERPRISE:
            name = "Social Enterprise";
            if (context.organisation.enterprises) {
                index = context.organisation.enterprises.map(e => e._id).indexOf(id);
                if (index > -1) {
                    name = context.organisation.enterprises[index].name;
                }
            }
            break;
        case EntityConstants.EntityType.PROGRAM:
            name = "Program";
            if (context.organisation.programs) {
                index = context.organisation.programs.map(e => e._id).indexOf(id);
                if (index > -1) {
                    name = context.organisation.programs[index].name;
                }
            }
            break;
        case EntityConstants.EntityType.PROJECT:
            name = "Project";
            if (context.organisation.project) {
                index = context.organisation.projects.map(e => e._id).indexOf(id);
                if (index > -1) {
                    name = context.organisation.projects[index].name;
                }
            }
            break;
        default:
            break;
    }
    return name;
}

UnitUtils.getSelectedUnitName = getSelectedUnitName;

UnitUtils.getSelectedUnit = (context) => {
    let selUnit = { type: EntityConstants.EntityType.NONE, id: "" };
    if (context.selections && context.selections.type) {
        switch (context.selections.type) {
            case EntityConstants.EntityType.ENTERPRISE:
                if (context.selections.enterprise) {
                    selUnit = { 
                        type: EntityConstants.EntityType.ENTERPRISE, 
                        id: context.selections.enterprise.id,
                        name: getSelectedUnitName(EntityConstants.EntityType.ENTERPRISE, context.selections.enterprise.id)
                    };
                }
                break;
            case EntityConstants.EntityType.PROJECT:
                if (context.selections.project) {
                    selUnit = { 
                        type: EntityConstants.EntityType.PROJECT, 
                        id: context.selections.project.id,
                        name: getSelectedUnitName(EntityConstants.EntityType.PROJECT, context.selections.project.id)
                    };
                }
                break;
            case EntityConstants.EntityType.PROGRAM:
                if (context.selections.program) {
                    selUnit = { 
                        type: EntityConstants.EntityType.PROGRAM, 
                        id: context.selections.program.id,
                        name: getSelectedUnitName(EntityConstants.EntityType.PROGRAM, context.selections.program.id) 
                    };
                }
                break;
            default:
                break;
        }
    }
    return selUnit
}

UnitUtils.getSelectedEntity = (context, selectedUnit) => {
    let index = -1;
    switch (selectedUnit.type) {
        case EntityConstants.EntityType.ENTERPRISE:
            if (context.organisation.enterprises) {
                index = context.organisation.enterprises.map(e => e._id).indexOf(selectedUnit.id);
                if (index > -1) {
                    return context.organisation.enterprises[index];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        case EntityConstants.EntityType.PROGRAM:
            if (context.organisation.programs) {
                index = context.organisation.programs.map(e => e._id).indexOf(selectedUnit.id);
                if (index > -1) {
                    return context.organisation.programs[index];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        case EntityConstants.EntityType.PROJECT:
            if (context.organisation.projects) {
                index = context.organisation.projects.map(e => e._id).indexOf(selectedUnit.id);
                if (index > -1) {
                    return context.organisation.projects[index];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        default:
            return null;
    }
}

UnitUtils.getTypeFromSelectedUnit = (selectedUnit) => {
    if (selectedUnit.type !== EntityConstants.EntityType.NONE) {
        const index = UiConstants.UNIT_TYPE_OPTIONS.map(o => o.value).indexOf(selectedUnit.type);
        if (index > -1) {
            return UiConstants.UNIT_TYPE_OPTIONS[index];
        } else {
            return UiConstants.EMPTY_OPTION;
        }
    } else {
        return UiConstants.EMPTY_OPTION;
    }
}

const getUnitOptionsByType = (context, type, includeArchived) => {
    switch (type) {
        case EntityConstants.EntityType.ENTERPRISE:
            let enterprises = cloneDeep(context.organisation && context.organisation.enterprises ? context.organisation.enterprises : []);
            if (!includeArchived) {
                enterprises = enterprises.filter(e => e.status ? e.status !== EntityConstants.EntityStatus.ARCHIVED : true);
            }
            return enterprises.map(e => {
                return {
                    value: e._id,
                    label: e.name
                };
            });           
        case EntityConstants.EntityType.PROGRAM:
            let programs = JSON.parse(JSON.stringify(context.organisation.programs ? context.organisation.programs : []));
            if (!includeArchived) {
                programs = programs.filter(p => p.status ? p.status !== EntityConstants.EntityStatus.ARCHIVED : true);
            }
            return programs.map(p => {
                return {
                    value: p._id,
                    label: p.name
                };
            });           
        case EntityConstants.EntityType.PROJECT:
            let projects = JSON.parse(JSON.stringify(context.organisation.projects ? context.organisation.projects : []));
            if (!includeArchived) {
                projects = projects.filter(p => p.status ? p.status !== EntityConstants.EntityStatus.ARCHIVED : true);
            }
            return projects.map(p => {
                return {
                    value: p._id,
                    label: p.name
                };
            });           
        default:
            return [];
    }
}

UnitUtils.getUnitOptionsByType = getUnitOptionsByType;
    
UnitUtils.getUnitOptionsFromContext = (context, selectedUnit, includeArchived) => {
    if (selectedUnit.type !== EntityConstants.EntityType.NONE) {
        return getUnitOptionsByType(context, selectedUnit.type, includeArchived);
    } else {
        return [];
    }
}

UnitUtils.getSelectedUnitOptionFromContext = (context, selectedUnit, includeArchived) => {
    let index = -1;
    switch (selectedUnit.type) {
        case EntityConstants.EntityType.ENTERPRISE:
            let enterprises = context.organisation.enterprises ? context.organisation.enterprises : [];
            if (!includeArchived) {
                enterprises = enterprises.filter(e => e.status ? e.status !== EntityConstants.EntityType.ARCHIVED : true);
            }
            index = enterprises.map(e => e._id).indexOf(selectedUnit.id);
            if (index > -1) {
                const e = enterprises[index];
                return { value: selectedUnit.id, label: e.name };
            } else {
                return UiConstants.EMPTY_OPTION;
            }
        case EntityConstants.EntityType.PROGRAM:
            let programs = context.organisation.programs ? context.organisation.programs : [];
            if (!includeArchived) {
                programs = programs.filter(p => p.status ? p.status !== EntityConstants.EntityType.ARCHIVED : true);
            }
            index = programs.map(p => p._id).indexOf(selectedUnit.id);
            if (index > -1) {
                const pg = programs[index];
                return { value: selectedUnit.id, label: pg.name };
            } else {
                return UiConstants.EMPTY_OPTION;
            }
        case EntityConstants.EntityType.PROJECT:
            let projects = context.organisation.projects ? context.organisation.projects : [];
            if (!includeArchived) {
                projects = projects.filter(p => p.status ? p.status !== EntityConstants.EntityType.ARCHIVED : true);
            }
            index = projects.map(p => p._id).indexOf(selectedUnit.id);
            if (index > -1) {
                const pj = projects[index];
                return { value: selectedUnit.id, label: pj.name };
            } else {
                return UiConstants.EMPTY_OPTION;
            }
        default:
            return UiConstants.EMPTY_OPTION;
    }
}

UnitUtils.getIndicatorOptions = (context, selectedUnit) => {
    let index = -1;
    if (context.organisation) {
        const org = context.organisation;
        switch (selectedUnit.type) {
            case EntityConstants.EntityType.ENTERPRISE:
                index = org.enterprises ? org.enterprises.map(e => e._id).indexOf(selectedUnit.id) : -1;
                if (index > -1) {
                    const e = org.enterprises[index];
                    return e.map.indicators.map(ind => {
                        return {
                            value: ind._id,
                            label: ind.title
                        };
                    });
                } else {
                    return [];
                }
            case EntityConstants.EntityType.PROGRAM:
                index = org.programs ? org.programs.map(e => e._id).indexOf(selectedUnit.id) : -1;
                if (index > -1) {
                    const pg = org.programs[index];
                    return pg.map.indicators.map(ind => {
                        return {
                            value: ind._id,
                            label: ind.title
                        };
                    });
                } else {
                    return [];
                }
            case EntityConstants.EntityType.PROJECT:
                index = org.projects ? org.projects.map(e => e._id).indexOf(selectedUnit.id) : -1;
                if (index > -1) {
                    const pj = org.projects[index];
                    return pj.map.indicators.map(ind => {
                        return {
                            value: ind._id,
                            label: ind.title
                        };
                    });
                } else {
                    return [];
                }
            default:
                return [];
        }
    } else {
        return [];
    }
}

export default UnitUtils;