import Tippy from '@tippyjs/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import 'tippy.js/dist/tippy.css';

export default function IconTooltip({ content, position }) {
    return(
        <>
        {content && content !== "" ? (
            <Tippy
                content={<div dangerouslySetInnerHTML={{__html: content}}/>}
                className="font-vg-book text-base leading-130 py-0.5 px-4"
                placement={position ? position : "top"}
                interactive={true}
                appendTo={document.body}
            >
                <InformationCircleIcon className="w-6 h-6 text-black"/>
            </Tippy>
        ) : (
            <InformationCircleIcon className="w-6 h-6 text-grey02"/>
        )} 
        </>
    );
}
