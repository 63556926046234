import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

export default function HomeCard(props) {
    const { logo, logoAlt, quote, avatar, avatarAlt, name, role } = props;

    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);

    let quoteClass = "font-vg-medium text-black leading-110 shrink grow";
    quoteClass = isMobile ? `${quoteClass} text-[28px]` : `${quoteClass} text-3.5xl`;

    let outerClass = "flex flex-col items-stretch gap-8 h-full py-8";
    outerClass = isMobile ? `${outerClass} gap-6` : `${outerClass} h-full gap-8`;

    return (
        <div className={outerClass}>
            {logo && (
                <div className="h-[88px] flex justify-start shrink-0 grow-0">
                    <img src={logo} className=" h-full" alt={logoAlt}/>
                </div>
            )}
            <p className={quoteClass}>"{quote}"</p>
            <div className="flex justify-start items-center gap-3 shrink-0 grow-0">
                {avatar && (
                    <img src={avatar} className="h-[50px] w-[50px] rounded-full" alt={avatarAlt}/>
                )}
                <div className="flex flex-col items-start gap-1.5">
                    <p className="font-vg-book text-black text-xl leading-130">{name}</p>
                    {role && (
                        <p className="font-vg-book text-black text-base leading-130">{role}</p>
                    )}
                </div>
            </div>
        </div>
    );
}