import { forwardRef, useId } from 'react';

const TextInputHeading = forwardRef((props, ref) => {
    const { id, textSize, onChange, ...otherProps } = props;
    const newId = useId();
    const inputId = id ? id : newId;
    let inputClass = `w-full p-1 font-vg-medium text-black bg-grey04 border rounded-md hover:border-blue active:border-blue ${textSize}`;
    return (
        <input
            ref={ref} 
            id={inputId}
            type="text"
            className={inputClass}
            onChange={(e) => {
                if (onChange) {
                    onChange(e);
                }
            }}
            { ...otherProps }
        />
    );
});

export default TextInputHeading;