import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

import PaginationButton from './PaginationButton';

export default function Pagination({ pageCount, windowStart, current, windowSize, disabled, onPageClick }) {

    const onLeftClick = () => {
        if (current > 0) {
            const newCurrent = current - 1;
            const newStart = newCurrent < windowStart ? newCurrent : windowStart;
            onPageClick(newStart, newCurrent);
        }
    }

    const onRightClick = () => {
        if (current < pageCount - 1) {
            const newCurrent = current + 1;
            let newStart = newCurrent > windowStart + windowSize ? newCurrent - windowSize : windowStart;
            onPageClick(newStart, newCurrent);
        }
    }

    let pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        let n = i.toString();
        if (n.length < 2) {
            n = "0" + n;
        }
        pageNumbers.push(n);
    }

    return (
        <div className="flex items-center gap-3">
            <button onClick={onLeftClick}>
                <ChevronLeftIcon className="w-6 h-6 text-black"/>
            </button>
            <div className="flex gap-2">
                {pageNumbers.filter((_, index) => index >= windowStart && index < windowStart + windowSize).map((pg, pgindex) => (
                    <PaginationButton
                        key={`pgbtn-${pgindex}`}
                        label={pg}
                        value={pgindex}
                        state={disabled ? "disabled" : (pgindex === current ? "active" : "default")}
                        onPageClick={(value) => onPageClick(windowStart, value)}
                    />
                ))}
            </div>
            <button onClick={onRightClick}>
                <ChevronRightIcon className="w-6 h-6 text-black"/>
            </button>
        </div>
    );
}