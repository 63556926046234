import { Fragment, useState, useContext } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid';
import { cloneDeep } from 'lodash';

import ABNService from '../services/ABNService';
import UserService from '../services/UserService';
import * as GeneralConstants from '../constants/GeneralConstants';
import { GlobalContext } from '../context/GlobalContext';

import TextInput from './TextInput';
import Button from './Button';

export default function TrialUpgradeModal({ open, onUpgrade, onClose }) {
   const {context, setContextValues} = useContext(GlobalContext);

   const [abnStatus, setAbnStatus] = useState('');
   const [hideAbnButton, setHideAbnButton] = useState(false);

   const processingClass = 'col-span-2 font-vg-regular text-black text-base text-center mt-6';
   const errorClass = 'col-span-2 font-vg-regular text-red text-base text-center';
   const doneClass = 'col-span-2 font-vg-regular text-black text-base text-center';
   const [hideSubmit, setHideSubmit] = useState(false);
   const [statusClass, setStatusClass] = useState(doneClass);
   const [statusText, setStatusText] = useState('');

   const validationSchema = Yup.object().shape({
      abn: Yup.string().required('Please enter a valid ABN'),
      organisationName: Yup.string().required('Use ABN search to find valid organisation name')
   });
   
   const formOptions = { 
      resolver: yupResolver(validationSchema),
      defaultValues: {
         abn: "",
         organisationName: "",
      } 
   };

   const { 
      control, 
      handleSubmit, 
      setValue,
      getValues
   } = useForm(formOptions);

   const doABNSearch = (e) => {
      e.preventDefault();
      setHideAbnButton(true);
      setAbnStatus('Searching...');
      const abn = getValues("abn");
      ABNService.checkAbn(abn, (error, data) => {
         setHideAbnButton(false);
         if (error) {
            alert(error);
            setAbnStatus('');
         } else {
            if (data.EntityName !== '') {
               setValue("organisationName", data.EntityName);
               setAbnStatus('');
            } else if (data.BusinessName.length > 0) {
               setValue("organisationName", data.BusinessName[0]);
               setAbnStatus('');
            } else {
               if (data.Message !== '') {
                  setAbnStatus(data.Message);
               } else {
                  setAbnStatus('No results returned from ABN search');
               }
            }
         }
      });
   }
   
   const onSubmit = (data) => {
      setHideSubmit(true);
      setStatusClass(processingClass);
      setStatusText("Upgrading...");
      const details = {
         user: {
            id: context.user.id,
            trialAccount: false
         },
         organisation: {
            abn: data.abn,
            name: data.organisationName,
         }
      }
      UserService.upgradeUserOrg(details)
      .then(response => {
         setHideSubmit(false);
         setStatusClass(doneClass);
         setStatusText("");
         let contextValues = [];
         if (context.organisation) {
            let organisation = cloneDeep(context.organisation);
            organisation.abn = response.data.organisation.abn;
            organisation.name = response.data.organisation.name;
            contextValues.push({ key: "organisation", value: organisation });
         }
         if (context.user) {
            let user = cloneDeep(context.user);
            user.trialAccount = false;
            contextValues.push({ key: "user", value: user });
         }
         if (contextValues.length > 0) {
            setContextValues(contextValues);
         }
         onUpgrade({
            abn: response.data.organisation.abn,
            organisationName: response.data.organisation.name,
            trialAccount: false
         });
      })
      .catch((error => {
         console.log('error', error);
         setHideSubmit(false);
         setStatusClass(errorClass);
         setStatusText(error.response && error.response.data ? error.response.data.message || error.message : error.message);
      }));
   }

   const onCancel = (e) => {
      e.preventDefault();
      setStatusClass(doneClass);
      setStatusText("");
      onClose();
   }

   return (
      <Transition show={open} as={Fragment}>
         <Dialog as="div" className="relative z-10" onClose={onClose}>
            <TransitionChild
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"                
            >
               <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
            </TransitionChild>
            <div className="fixed inset-0 z-10 overflow-y-auto">
               <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <TransitionChild
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <DialogPanel className="relative flex flex-col items-stretch gap-8 transform rounded-lg bg-white p-8 text-left shadow-modal transition-all sm:my-8 sm:w-full sm:max-w-[916px]">
                        <form className="flex flex-col items-stretch gap-8" onSubmit={handleSubmit(onSubmit)}>
                           <DialogTitle
                              as="h4"
                              className="font-vg-medium text-3.5xl text-black leading-110"
                           >
                              Upgrade Account
                           </DialogTitle>
                           <p className="font-vg-book text-black text-base">You will need provide a valid ABN to complete the upgrade</p>
                           <div className="relative">
                              <Controller
                                 name="abn"
                                 control={control}
                                 render={({ field, formState }) => (
                                    <TextInput
                                       label="Organisation's ABN" 
                                       variant="default"
                                       showHelp="always"
                                       help={abnStatus ? abnStatus : formState.errors.abn?.message}
                                       {...field}
                                    />
                                 )}
                              />
                              <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                                 <button 
                                    className={hideAbnButton ? "hidden" : "flex items-center justify-center p-3"}
                                    onClick={doABNSearch}
                                 >
                                    <MagnifyingGlassCircleIcon className="h-6 w-6 text-blue"/>
                                 </button>
                              </div>
                           </div>
                           <Controller
                              name="organisationName"
                              control={control}
                              render={({ field, formState }) => (
                                 <TextInput
                                    label="Organisation's name"
                                    variant="disabled"
                                    showHelp="always"
                                    readOnly={true}
                                    help={formState.errors.organisationName?.message ? formState.errors.organisationName?.message : GeneralConstants.ORG_NAME_HELP}
                                    {...field}
                                 />
                              )}
                           />
                           <div className="grid grid-cols-2 gap-3">
                              <Button variant="outline" size="large" label="Cancel"  className={hideSubmit ? "hidden" : "w-full"} onClick={onCancel}/>
                              <Button type="submit" variant="solid" size="large" label="Upgrade" className={hideSubmit ? "hidden" : "w-full"}/>
                              <p className={statusClass} aria-live="polite">{statusText}</p>
                           </div>
                        </form>
                     </DialogPanel>
                  </TransitionChild>
               </div>
            </div>
         </Dialog>
      </Transition>
   );
}