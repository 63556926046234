import axios from 'axios';

import DataUtils from '../utils/DataUtils';

let DashboardService = {};

DashboardService.createDashboard = (payload) => {
   return axios.post(process.env.REACT_APP_API_URL + '/dashboard',
      payload, 
      { withCredentials: true }
   );
};

const updateDashboard = (payload) => {
   return axios.put(process.env.REACT_APP_API_URL + '/dashboard',
      payload, 
      { withCredentials: true }
   );
};

DashboardService.updateDashboard = updateDashboard;

DashboardService.createUpdateElement = (details) => {
   let pages = details.dashboard.pages;
   let page = pages[details.pageIndex];
   let element = {};
   let index = -1;
   if (details.element._id && details.element._id !== "") {
      index = page.elements.map(e => e._id).indexOf(details.element._id);
      if (index > -1) {
         element = JSON.parse(JSON.stringify(page.elements[index]));
      }
   }
   if (details.element.elementType) {
      element.elementType = details.element.elementType;
   }
   if (details.element.title) {
      element.title = details.element.title;
   }
   if (details.element.text) {
      element.text = details.element.text;
   }
   if (details.element.row) {
      element.row = details.element.row;
   }
   if (details.element.col) {
      element.col = details.element.col;
   }
   if (details.element.width) {
      element.width = details.element.width;
   }
   if (details.element.height) {
      element.height = details.element.height;
   }
   if (details.element.elementSubTypeOption) {
      element.elementSubType = details.element.elementSubTypeOption.value;
   }
   if (details.element.intervalTypeOption) {
      element.intervalType = details.element.intervalTypeOption.value;
   }
   if (details.element.startDate) {
      element.startDate = DataUtils.getUtcDate(details.element.startDate);
   }
   if (details.element.endDate) {
      element.endDate = DataUtils.getUtcDate(details.element.endDate);
   }
   if (details.element.hasOwnProperty("showLegend")) {
      element.showLegend = details.element.showLegend;
   }
   if (details.element.hasOwnProperty("showDataLabels")) {
      element.showDataLabels = details.element.showDataLabels;
   }
   if (details.element.hasOwnProperty("showXLabel")) {
      element.showXLabel = details.element.showXLabel;
   }
   if (details.element.xLabel) {
      element.xLabel = details.element.xLabel;
   }
   if (details.element.hasOwnProperty("showYLabel")) {
      element.showYLabel = details.element.showYLabel;
   }
   if (details.element.yLabel) {
      element.yLabel = details.element.yLabel;
   }
   if (details.element.hasOwnProperty("hasSecondaryY")) {
      element.hasSecondaryY = details.element.hasSecondaryY;
   }
   if (details.element.secondaryYLabel) {
      element.secondaryYLabel = details.element.secondaryYLabel;
   }
   if (details.element.hasOwnProperty("showFYTotals")) {
      element.showFYTotals = details.element.showFYTotals;
   }
   if (details.element.hasOwnProperty("showFullRangeTotals")) {
      element.showFullRangeTotals = details.element.showFullRangeTotals;
   }
   if (details.element.hasOwnProperty("showIndicatorTotals")) {
      element.showIndicatorTotals = details.element.showIndicatorTotals;
   }
   if (details.element.hasOwnProperty("groupByOutcomeArea")) {
      element.groupByOutcomeArea = details.element.groupByOutcomeArea;
   }
   if (details.element.hasOwnProperty("includeOutcomeAreaSubtotals")) {
      element.includeOutcomeAreaSubtotals = details.element.includeOutcomeAreaSubtotals;
   }
   if (details.element.datasetDetails) {
      element.datasets = details.element.datasetDetails;
   }
   if (index === -1) {
      page.elements.push(element);
   } else {
      page.elements[index] = element;
   }
   pages[details.pageIndex] = page;
   const payload = {
      id: details.dashboard._id,
      pages: pages
   };
   return updateDashboard(payload);
}

DashboardService.deleteElement = (details) => {
   let pages = details.dashboard.pages;
   let page = JSON.parse(JSON.stringify(pages[details.pageIndex]));
   let index = page.elements.map(e => e._id).indexOf(details.elementId);
   if (index > -1) {
      page.elements.splice(index, 1);
   }
   pages[details.pageIndex] = page
   const payload = {
      id: details.dashboard._id,
      pages: pages
   };
   return updateDashboard(payload);
}

DashboardService.getElementPlotData = (dashboardId, pageId, elementId) => {
   const url = `${process.env.REACT_APP_API_URL}/dashboard/plot/${dashboardId}/${pageId}/${elementId}`;
   return axios.get(url, 
      { withCredentials: true }
   );
}

DashboardService.getElementTableData = (dashboardId, pageId, elementId) => {
   const url = `${process.env.REACT_APP_API_URL}/dashboard/table/${dashboardId}/${pageId}/${elementId}`;
   return axios.get(url, 
      { withCredentials: true }
   );
}

DashboardService.deleteDashboard = (id) => {
   const url = `${process.env.REACT_APP_API_URL}/dashboard/${id}`;
   return axios.delete(url,
      { withCredentials: true }
   );
};

export default DashboardService;
