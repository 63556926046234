import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';
import * as MatomoConstants from '../../constants/MatomoConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function SignUpVerifyPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    const [verifyMessage, setVerifyMessage] = useState('');
    const [verifyCss, setVerifyCss] = useState('text-black');

    const params = useParams();
    const navigate = useNavigate();

    const authToken = params.token;
    const accessCode = params.code;

    useEffect(() => {
		AuthService.verifySignUp(authToken, accessCode)
        .then(response => {
            setVerifyCss('text-black');
            setVerifyMessage('Account verified. Redirecting to Seedkit tool...');
            navigate('/kit/home');
        })
        .catch(error => {
            console.log('error', error);
            setVerifyCss('text-red');
            setVerifyMessage(error.response && error.response.data ? error.response.data.message || error.message : error.message);
        });
    }, [accessCode, authToken, navigate]);

    /* Matomo Tracking Code */

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            documentTitle: MatomoConstants.PageTitle.SIGN_UP_VERIFICATION
        });
    }, []);

    return(
        <div className={containerClass}>
            <Header title="Sign Up"/>
            <div className="container flex flex-col w-full h-96 justify-center items-center font-vg-medium text-black text-xl">
                <h2 className="font-vg-medium text-black text-3xl text-center mt-12">Verifying Account</h2>
                <p className={`font-vg-bold text-base text-red my-5 min-h-8 leading-8 ${verifyCss}`}>{verifyMessage}</p>
            </div>
            <Footer/>
        </div>
    );
}