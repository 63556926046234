import { useId } from 'react';

import * as UiConstants from '../constants/UiConstants';

export default function TagButton(props) {
    const { id, label, value, color, selected, size, onClick, ...otherProps } = props;
    let textSize = size && size === "large" ? "text-sm" : "text-xs";
    let colorClass = "";

    const newId = `button-${useId()}`;
    const buttonId = id ? `button-${id}` : newId;

    switch (color) {
        case UiConstants.TagColor.GREEN:
            colorClass = selected === true ? 'bg-green03 border-green03' : 'border-green03';
            break;
        case UiConstants.TagColor.YELLOW:
            colorClass = selected === true ? 'bg-yellow03 border-yellow03' : 'border-yellow03';
            break;
        case UiConstants.TagColor.PURPLE:
            colorClass = selected === true ? 'bg-purple03 border-purple03' : 'border-purple03';
            break;
        default:
            break;
    }
    let outerClass = `font-vg-medium text-black rounded-full px-6 py-2 leading-110 border ${textSize}`;
    outerClass = colorClass !== "" ? `${outerClass} ${colorClass}` : outerClass;
    return (
        <button 
            id={buttonId}
            key={buttonId}
            className={outerClass}
            onClick={e => {
                e.preventDefault();
                onClick(value);
            }}
            { ...otherProps }
        >
            {label}
        </button>
    );
}
