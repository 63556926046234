import axios from 'axios';

let EntDataService = {};

EntDataService.getEnterpriseData = () => {
   // TODO: getEnterpriseData
};

EntDataService.updateEnterpriseData = (payload) => {
   return axios.put(process.env.REACT_APP_API_URL + '/enterprisedata', 
      payload, 
      { withCredentials: true }
   );
};

export default EntDataService;