import { $isAtNodeEnd } from '@lexical/selection';
import { ElementNode, RangeSelection, TextNode } from 'lexical';


let LexicalUtils = {};

LexicalUtils.getSelectedNode = (selection) => {
   const anchor = selection.anchor;
   const focus = selection.focus;
   const anchorNode = selection.anchor.getNode();
   const focusNode = selection.focus.getNode();
   if (anchorNode === focusNode) {
      return anchorNode;
   }
   const isBackward = selection.isBackward();
   if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
   } else {
      return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
   }
};

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

LexicalUtils.setFloatingElemPositionForLinkEditor = (targetRect, floatingElem, anchorElem, verticalGap = VERTICAL_GAP, horizontalOffset = HORIZONTAL_OFFSET) => {
   const scrollerElem = anchorElem.parentElement;

   if (targetRect === null || !scrollerElem) {
      floatingElem.style.opacity = '0';
      floatingElem.style.transform = 'translate(-10000px, -10000px)';
      return;
   }

   const floatingElemRect = floatingElem.getBoundingClientRect();
   const anchorElementRect = anchorElem.getBoundingClientRect();
   const editorScrollerRect = scrollerElem.getBoundingClientRect();

   let top = targetRect.top - verticalGap;
   let left = targetRect.left - horizontalOffset;

   if (top < editorScrollerRect.top) {
      top += floatingElemRect.height + targetRect.height + verticalGap * 2;
   }

   if (left + floatingElemRect.width > editorScrollerRect.right) {
      left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
   }

   top -= anchorElementRect.top;
   left -= anchorElementRect.left;

   floatingElem.style.opacity = '1';
   floatingElem.style.transform = `translate(${left}px, ${top}px)`;
};

const SUPPORTED_URL_PROTOCOLS = new Set([
   'http:',
   'https:',
   'mailto:',
   'sms:',
   'tel:',
]);


LexicalUtils.sanitizeUrl = (url) => {
   try {
      const parsedUrl = new URL(url);
      // eslint-disable-next-line no-script-url
      if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
         return 'about:blank';
      }
   } catch {
      return url;
   }
   return url;
};

// Source: https://stackoverflow.com/a/8234912/2013580
const urlRegExp = new RegExp(
   /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);

LexicalUtils.validateUrl = (url) => {
   // TODO Fix UI for link insertion; it should never default to an invalid URL such as https://.
   // Maybe show a dialog where they user can type the URL before inserting it.
   return url === 'https://' || urlRegExp.test(url);
}  

LexicalUtils.CAN_USE_DOM =
   typeof window !== 'undefined' &&
   typeof window.document !== 'undefined' &&
   typeof window.document.createElement !== 'undefined';

export default LexicalUtils;