import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet-async';

import Logo from '../images/seedkit_logo_black.svg';
import Button from './Button';
import AuthService from '../services/AuthService';
import UiUtils from '../utils/UiUtils';
import * as GeneralConstants from '../constants/GeneralConstants';
import { GlobalContext } from '../context/GlobalContext';

export default function NavBar({ title, description }) {
    const { setContextValues } = useContext(GlobalContext);

    const [menuOpen, setMenuOpen] = useState(false);

    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const navigate = useNavigate();
    
    const loggedIn = AuthService.isLoggedIn();

    function login() {
        navigate('/login');
    }

    function signUp() {
        navigate('/signup');
    }

    function clearContext() {
        setContextValues([
            { key: "user", value: {} },
            { key: "organisation", value: {} },
            { key: "authToken", value: "" },
            { key: "selections", value: {} }
        ]);
    };

    function logOut() {
        clearContext();
        AuthService.logout()
        .then(response => {
            navigate('/');
        })
        .catch(error => console.log(error));
        return false;
    }

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    function doNavigate(link) {
        navigate(link);
    }

    return (
        <>
            <Helmet>
                <title>{title ? title : GeneralConstants.DEFAULT_PAGE_TITLE}</title>
                <meta name="description" content={description ? description : GeneralConstants.DEFAULT_PAGE_DESCRIPTION}/>
            </Helmet>
            {isDesktop && (
            <div className="flex justify-between items-center box-border max-w-content-narrow w-full h-16 px-6 rounded-xl bg-white shadow-standard z-50 mx-auto">
                <a href="/">
                    <img src={Logo} alt="Seedkit logo" className="h-[26px]"/>
                </a>
                <div className="flex items-center justify-center">
                    <Button size="small" variant="navlink" label="About" onClick={() => doNavigate("/about")}/>
                    <Button size="small" variant="navlink" label="Resources" onClick={() => doNavigate("/resources")}/>
                    <Button size="small" variant="navlink" label="Contact" onClick={() => doNavigate("/contact")}/>
                    <Button size="small" variant="navlink" label="Account" onClick={() => {
                        if (loggedIn) {
                            doNavigate("/kit/home");
                        } else {
                            if (window.location.href.indexOf("login") > -1) {
                                alert("You will need to login to go to this page");
                            } else {
                                login();
                            }
                        }
                    }}/>
                </div>
                <div className="flex items-center justify-center gap-3">
                    {loggedIn && (
                        <Button size="small" variant="outline" label="LOG OUT" onClick={logOut}/>
                    )}
                    {!loggedIn && (
                        <>
                            <Button size="small" variant="outline" label="SIGN UP" onClick={signUp}/>
                            <Button size="small" variant="outline" label="LOGIN" onClick={login}/>
                        </>
                    )}
                </div>
            </div>
            )}
            {isMobile && (
            <div className="block box-border w-full rounded-xl bg-white shadow-standard z-50">
                <div className="box-border w-full h-[50px] p-3 flex items-center justify-between">
                    <a href="/">
                        <img src={Logo} alt="Seedkit logo" className="h-[25px]"/>
                    </a>
                    {menuOpen && (
                        <XMarkIcon 
                            className="h-6 text-black cursor-pointer" 
                            aria-label="Close nav menu"
                            onClick={toggleMenu}
                        />
                    )}
                    {!menuOpen && (
                        <Bars3Icon 
                            className="h-6 text-black cursor-pointer" 
                            aria-label="Open nav menu"
                            onClick={toggleMenu}
                        />
                    )}
                </div>
                <div className={menuOpen ? "box-border w-full px-4 pt-3 pb-6" : "hidden"}>
                    <Button size="small" variant="navlink" label="About" className="pl-0" onClick={() => doNavigate("/about")}/>
                    <Button size="small" variant="navlink" label="Resources" className="pl-0" onClick={() => doNavigate("/resources")}/>
                    <Button size="small" variant="navlink" label="Contact" className="pl-0" onClick={() => doNavigate("/contact")}/>
                    <div className="flex w-full mt-3 gap-3 justify-stretch">
                        {loggedIn && (
                            <Button size="small" variant="outline" label="LOG OUT" className="" onClick={logOut}/>
                        )}
                        {!loggedIn && (
                            <>
                                <Button size="small" variant="outline" label="SIGN UP" onClick={signUp}/>
                                <Button size="small" variant="outline" label="LOGIN" onClick={login}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
            )}
        </>
    );
}