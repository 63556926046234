import * as IndicatorConstants from '../constants/IndicatorConstants';

let LibraryUtils = {};

LibraryUtils.getDomainNeeds = (library) => {
   const domainDup = library.indicators ? library.indicators.map(ind => { 
      return { domain: ind.domain, reportingNeed: ind.reportingNeed };
   }) : [];
   let domainNeeds = [];
   domainDup.forEach(dn => {
      if (domainNeeds.filter(d => d.domain === dn.domain && d.reportingNeed === dn.reportingNeed).length === 0) {
         domainNeeds.push(dn);
      }
   })
   return domainNeeds;
};

const getOutcomeDomains = (library) => {
   let outcomeDomainDup = [];
   if (library.indicators) {
      library.indicators.forEach(ind => {
         if (ind.domain && ind.outcomeAreas) {
            ind.outcomeAreas.forEach(oa => {
               outcomeDomainDup.push({ outcomeArea: oa, domain: ind.domain });
            });
         }
      });    
   }
   let outcomeDomains = [];
   outcomeDomainDup.forEach(odm => {
      if (outcomeDomains.filter(od => od.outcomeArea === odm.outcomeArea && od.domain === odm.domain).length === 0) {
         outcomeDomains.push(odm)
      }
   });
   return outcomeDomains;
};

const getFullRequiredData = (library, indicator, useExisting) => {
   let fullReqData = [];
   if (useExisting && indicator.fullRequiredData) {
      return indicator.fullRequiredData
   } 
   indicator.requiredData.forEach(reqData => {
      let key = (typeof reqData === 'string' || reqData instanceof String) ? reqData : reqData.key;
      const index = library.requiredData.map(rd => rd.key).indexOf(key);
      if (index > -1) {
         fullReqData.push(library.requiredData[index]);
      } else {
         fullReqData.push({ key: key });
      }
   });
   return fullReqData;
}

/* Export Functions */

LibraryUtils.getFullRequiredData = getFullRequiredData;
LibraryUtils.getOutcomeDomains = getOutcomeDomains;

LibraryUtils.getOutcomeAreas = (library, domain) => {
   return getOutcomeDomains(library).filter(od1 => od1.domain === domain).map(od2 => od2.outcomeArea);
};

LibraryUtils.getDomainIndicators = (library, domain, withType) => {
   if (withType) {
      return library.indicators.filter(ind => ind.domain === domain && ind.indicatorType !== "");
   } else {
      return library.indicators.filter(ind => ind.domain === domain);
   }
};

LibraryUtils.getDomainAreaIndicators = (library, domain, area, options) => {
   let indicators = [];
   if (options.withType) {
      indicators = JSON.parse(JSON.stringify(library.indicators.filter(ind => ind.domain === domain && ind.outcomeAreas.indexOf(area) > -1 && ind.indicatorType !== "")));
   } else {
      indicators = JSON.parse(JSON.stringify(library.indicators.filter(ind => ind.domain === domain && ind.outcomeAreas.indexOf(area) > -1)));
   }
   if (options.setArea) {
      for (let i = 0; i < indicators.length; i++) {
         indicators[i].outcomeArea = area;
      }
   }
   if (options.fullRequiredData) {
      for (let j = 0; j < indicators.length; j++) {
         indicators[j].fullRequiredData = getFullRequiredData(library, indicators[j], true);
      }
   }
   return indicators;
};

LibraryUtils.getDomainOptions = (library, reportingNeed, addCustom) => {
   let domainOptDup = [];
   if (library.indicators) {
      library.indicators.forEach(ind => {
         if (ind.domain) {
            if (ind.reportingNeed) {
               if (reportingNeed === "" || ind.reportingNeed === reportingNeed) {
                  domainOptDup.push({ value: ind.domain, label: ind.domain });
               }
            } else {
               domainOptDup.push({ value: ind.domain, label: ind.domain });
            }
         }
      });
   }
   let domainOptions = [];
   domainOptDup.forEach(dod => {
      if (domainOptions.map(d => d.value).indexOf(dod.value) === -1) {
         domainOptions.push(dod);
      }
   })
   if (addCustom) {
      domainOptions.push({
         value: IndicatorConstants.CUSTOM_DOMAIN_VALUE,
         label: IndicatorConstants.CUSTOM_DOMAIN_LABEL
      });
   }
   return domainOptions;
};

LibraryUtils.getOutcomeAreaOptions = (library, domain, addCustom) => {
   let areaOptDup = [];
   if (library.indicators) {
      library.indicators.filter(ind1 => ind1.domain && ind1.domain === domain).forEach(ind2 => {
         if (ind2.outcomeAreas) {
            ind2.outcomeAreas.forEach(oa => {
               areaOptDup.push({ value: oa, label: oa });
            });
         }
      });
   }
   let outcomeAreaOptions = [];
   areaOptDup.forEach(oad => {
      if (outcomeAreaOptions.map(d => d.value).indexOf(oad.value) === -1) {
         outcomeAreaOptions.push(oad);
      }
   });
   if (addCustom) {
      outcomeAreaOptions.push({
         value: IndicatorConstants.CUSTOM_OUTCOME_AREA_VALUE,
         label: IndicatorConstants.CUSTOM_OUTCOME_AREA_LABEL
      });
   }
   return outcomeAreaOptions;
};

export default LibraryUtils;